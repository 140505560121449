import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormControl } from '@mui/material/';
import { Field } from 'formik';
import Checkbox from './Checkbox';
import {makeid} from '../../../CommonComponents';

const FormikCheckbox = props => {
  const {
    name, label, required, onChangeCallback, id, ...other
  } = props;

  let twochar = makeid(2);
  let newid = id +"-" + twochar;

  return (
    <Field name={name} id={name}>
      {({ field, form, meta: { touched, error } }) => {
        const isInvalid = touched || form.isValid ? error : null;
        return (
          <>
            <FormControl component="fieldset">
            {/* console.log("field and event is ", field, event) */}
              <FormControlLabel
                for = {id}
                {...other}
                control={(
                  <Checkbox
                    {...field}
                    id={id}
                    value={field?.value}
                    onChange={event => {
                      field.onChange(event);
                      onChangeCallback(event);
                    }}
                    checked={field?.value}
                    color="primary"
                  />
                )}
                label={label}
                labelPlacement="end"
                required={required}
                error={isInvalid}
              />
              {/* console.log("field.value is ", field?.value) */}
            </FormControl>
          </>
        );
      }}
    </Field>
  );
};

FormikCheckbox.defaultProps = {
  label: '',
  required: false,
  onChangeCallback: () => { },
  id: null,
};

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  onChangeCallback: PropTypes.func,
};

export default FormikCheckbox;
