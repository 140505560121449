const STATUS = {
  ERROR: 'ERROR',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  UNFETCHED: 'UNFETCHED',
  INVALIDATED: 'INVALIDATED',
  SUCCESS: 'SUCCESS',
};

export default STATUS;
