import React, { Component, useState} from 'react';
import PropTypes from 'prop-types'; // prop-types
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material/';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UOPHeader from '../layout/UopHeader';
import Footer from '../layout/Footer';
import styles from '../layout/styles/MobileFormStyles';
import UnsubscribeEmailForm from './UnsubscribeEmailForm';
import UnsubscribePhoneForm from './UnsubscribePhoneForm';
import UnsubscribePostalFormSmarty from './UnsubscribePostalFormSmarty';
import {dataLayer} from '../common/dataLayer';

function TabContent() {
  
    const [value,setValue] = useState('1');
  const handleChange = (event, newValue) => {setValue(newValue);};
   return (
    <Grid item md={12} sm={12} lg={6} xs={12}>
                <TabContext value={value}>
                
                    <TabList className="unsub-tablist" onChange={handleChange} aria-labelledby="unsubscribe">
                      <Tab className='unsub-tab' label="Email" value="1" />
                      <Tab className='unsub-tab' label="Phone" value="2" />
                      <Tab className='unsub-tab' label="Post mail" value="3" />
                    </TabList>
                  
                  <TabPanel className='unsub-TabPanel' value="1"><UnsubscribeEmailForm /></TabPanel>
                  <TabPanel className='unsub-TabPanel' value="2"><UnsubscribePhoneForm /></TabPanel>
                  <TabPanel className='unsub-TabPanel' value="3"><UnsubscribePostalFormSmarty /></TabPanel>
                 
                </TabContext>
                </Grid>
              
   );

}

class UnsubscribeForms extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      // isSubmit: false,
      formRef: null,
      variant: props.variant,
      activeStep: 1,
    };
    this.state = this.initialState;
    
  }
  
 

  componentDidMount() {
    window.pagePath = "page-mkt-unsub";
    formType = 'Non-RFI';
    formName = 'Marketing Unsubscribe';
    formStep = this.state.activeStep;
    document.querySelector('meta[name="description"]').setAttribute("content", "If you no longer want to receive emails, updates or other notifications from University of Phoenix, complete our unsubscribe form.");
    document.title = `unsubscribe from our marketing notifications`;
    dataLayer();
  }

  render() {
    const { classes } = this.props;
   
    return (
      <div>
        <UOPHeader />
        <main className={classes.root}>
          <Grid container justifyContent="center" className="mobileFormContainer">
            <Grid container className="maxWidthContainer-oneStepForm">
              <Grid item md={12} sm={12} lg={12} xs={12}>
                <h1 id="unsubscribe"  className="newOneForm-title">Unsubscribe from our marketing communications</h1>
              </Grid>
              <Grid item md={12} sm={12} lg={12} xs={12}>
                <h2 className="newOneForm-SubTitle">
                  Fill out a form below to unsubscribe.
                </h2>
                
              </Grid>
              <Grid container>
                <TabContent />
              </Grid>
                            
            </Grid>
          </Grid>
        </main>
        <Footer />
      </div>

    );
  }

}



UnsubscribeForms.defaultProps = {
  classes: {},
};

UnsubscribeForms.propTypes = {
  classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(UnsubscribeForms);
