import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import FormikErrorFocus from 'formik-error-focus';
import PropTypes from 'prop-types';
import date from 'date-and-time';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import { initValues, processJSONParameter } from '../common/configTools';
import {
  unescapeHTML,
  loadCookiesData,
  mapAdditionalFields,
  profanityCheck,
  fireAnalyticsEvent, fireNonRFISubmitEvent,
} from '../CommonComponents';
import styles from './SubscribeStyles';
import checkmark from '../layout/images/checkmark.svg';
import Button from '../layout/MaterialComponents/button/Button';

class SubscribeEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      initialValues: initialEmailFormValues,
      variant: props.variant,
      message: null,
      currentStep: 1,
    };
    this.ref = {
      form: React.createRef(),
      container: React.createRef(),
    };
  }

  componentDidMount() {
    const { props } = this;
    let { initialValues } = this.state;
    //initialValues = loadCookiesData(initialValues);
    initialValues.flex_EmailCaptureSource = 'NEWS';
    initValues(initialValues, this.props);
    this.setState({ initialValues });
    const { currentStep } = this.state;
    formStep = currentStep;
    formType = 'Non-RFI';
    formName = 'Newsletter Email Subscribe - 1 step';
    if (window.pageData && window.pagePath) {
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
    }
  }

  handleSubmit = async formValues => {
    if (document.activeElement.name === "submit") {
    const { classes } = this.props;
    const values = { ...this.state.initialValues, ...formValues };

    if (((values.fullName).trim().indexOf(' ')) > 0) {
      const i = (values.fullName.trim()).indexOf(' ');
      values.firstName = (values.fullName.trim()).substring(0, i); 
      values.lastName = (values.fullName.trim()).substring(i + 1);
    }
    this.setState({ initialValues: values });
    //hashValues(values);
    Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
    Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);

    let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
    if (window.location.host !== 'www.phoenix.edu') {
      postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
    }
    values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    mapAdditionalFields(values);
    try {
      const response = await fetch(postURL,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
        });
      // console.log ("form submit values" , values);

      this.setState({
        initialValues: values,
        isSubmit: true,
        message: null,
        // message: <div style={styles.success}>{responseJSON.message || 'No Message Given'}</div>,
      });
    //  setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
        fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType);
    } catch (e) {
      this.setState({
        message: <div className={classes.error}>{e.message || e}</div>,
      });
    }
    this.ref.container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }

  handleAnalyticsRFIFormStart = () => {
    if (this.state.rfi_begin !== true) {
      fireAnalyticsEvent('NonRFIStart', formName, formType);
      this.setState({ rfi_begin: true });
    }
  }

  validateForm(values) {
    const errors = {};

    if (!values.fullName) {
      errors.fullName = 'First and Last name requires a value';
    } else if (values.fullName.trim().indexOf(' ') === -1) {
      errors.fullName = 'Please enter a First and Last name';
    } else if (!profanityCheck(values.fullName)) {
      errors.fullName = 'Full Name appears invalid';
    }
    if (!values.emailAddress) {
      errors.emailAddress = 'Email Address requires a value';
    }
    if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.emailAddress = 'Email address appears invalid';
    }
    console.log('errors', errors, values);
    return errors;
  }

  render() {
    const { classes } = this.props;
    // console.log('SubEmail rendering...');
    const { isSubmit, initialValues } = this.state;
    let titleText = this.props.title || this.state.initialValues.title;
    let descriptionText = initialValues.description || this.state.initialValues.description;
    let cta = initialValues.cta || 'Sign up';
    let background;
    let disclaimerColor;
    let buttonColor;
    let buttonText;
    let privacyPolicyColor;
    if(this.props.theme == 'darkmode'){
        background = '#25576D';
        disclaimerColor = "#FFFFFF";
        buttonColor = "#FFFFFF";
        buttonText = "#30718D";
        privacyPolicyColor = "#fff";
    } else {
        background = "#ECF2F6";
        disclaimerColor = "#5E7079";
        buttonColor = "#30718D";
        privacyPolicyColor = "#000";
    }
    //console.log(background);
    return (
      <div className={classes.container} style={{ backgroundColor: background}}>
        {/*<UOPHeader /> */}
        <div className={classes.root}>
          <Grid container justifyContent="center" className="mobileFormContainer" ref={this.ref.container}>
            <Formik
              initialValues={initialValues}
              onSubmit={values => this.handleSubmit(values)}
              validate={values => this.validateForm(values)}
              innerRef={this.ref.form}
              validateOnChange
            >
              <Form
                className="Subscribe-email-form"
                style={{ position: 'relative' }}
                onChange={this.handleAnalyticsRFIFormStart}
              >
                <Grid container className="maxWidthContainer-oneStepForm" spacing={1}>
                  <Grid item md={10} sm={12} lg={10} xs={12}>
                    <div>{unescapeHTML(titleText)}</div>
                    <div>{unescapeHTML(descriptionText)}</div>
                  </Grid>
                  {!isSubmit
                    ? (
                      <>
                        <Grid item md={10} sm={12} lg={10} xs={12}>
                          {this.state.message}
                        </Grid>
                        <Grid item md={5} sm={6} lg={5} xs={12}>
                          <MaterialInput
                            name="fullName"
                            id="fullName"
                            type="text"
                            label="First and Last name *"
                            ariaRequired="true"
                            ariaLabel="Enter your full name"
                            variant="outlined"
                            onClick={this.handleAnalyticsRFIFormStart}
                          />
                        </Grid>
                        <Grid item md={5} sm={6} lg={5} xs={12}>
                          <MaterialInput
                            name="emailAddress"
                            id="emailAddress"
                            label="Email address *"
                            ariaRequired="true"
                            ariaLabel="Enter Email address"
                            variant="outlined"
                            onClick={this.handleAnalyticsRFIFormStart}
                          />
                        </Grid>
                        <Grid item md={10} sm={12} lg={10} xs={12}>
                          <div className="disclaimer" style={{color:disclaimerColor}}>
                            <p>
                              {this.state.initialValues.disclaimer}
                              {' '}Visit our {' '}
                              <a href="https://www.phoenix.edu/copyright-legal/privacy-policy.html" style={{color:privacyPolicyColor}}>Privacy Policy</a>
                              {' '}
                              for information about how we collect and use your personal information.
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={5} sm={6} lg={5} xs={12}>
                          <Button
                            name='submit'
                            size="large"
                            type="submit"                            
                            style={{backgroundColor: buttonColor, color: buttonText }}                            
                          >
                            {unescapeHTML(cta)}
                          </Button>
                        </Grid>
                      </>
                    )
                    : (
                      <>
                        <Grid item md={10} sm={12} lg={10} xs={12} className={classes.thanksContainer}>
                          <div className={classes.thanksHeader}>
                            <img src={checkmark} alt="Checked Circle" />
                            Thanks for subscribing
                          </div>
                          <div className={classes.thanksText}>
                            You'll soon get updates in your email inbox
                          </div>
                        </Grid>
                      </>
                    )}
                </Grid>

                <FormikErrorFocus
                  offset={-50}
                  align="top"
                  focusDelay={200}
                  ease="linear"
                  duration={1000}
                />
              </Form>
            </Formik>
          </Grid>
        </div>
      {/*  <Footer /> */}
      </div>
    );
  }
}
const initialEmailFormValues = {
  fullName: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  flex_more_info__c: '',
  flex_Info_Requested__c: 'something_else',
  appContext: 'EMAILCAPTURE',
  mcid: '',
  useCaseContext: 'NEW',
  title: '<h2 className="newOneForm-title" style="color:#30718D; font-size:24px; line-height:30px; margin-bottom:5px;">Get news delivered to you</h2>',
  description: '<div className="newOneForm-SubTitle">Want free updates about careers, financial aid, University of Phoenix educational offerings and more? We deliver the latest info to your inbox.</div>',
  disclaimer: 'By submitting this form, you consent to receive emails from the University regarding our educational programs and services.',
  flex_EmailCaptureSource: 'NEWS',
  userAgent: '',
  campaign: 'PHXEDU10',
  source: 'INET',
  requestingIP: '',
  requestingUrl: '',
};

SubscribeEmailForm.defaultProps = {
  classes: {},
};

SubscribeEmailForm.propTypes = {
  classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(SubscribeEmailForm);
