function getRequestOptions(values) {
  const endpointURL = process.env.REACT_APP_UNSUBSCRIBE_ROUTE;

  let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
  if (window.location.host !== 'www.phoenix.edu') baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
  if (['local.phoenix.edu', 'localhost:3000'].indexOf(window.location.host) !== -1) baseUrl = 'http://localhost:8080';

  return [baseUrl + endpointURL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  }];
}

export async function submitUnsubEmailForm(values) {
  const { emailAddress } = values;
  if (!emailAddress) throw new Error('Invalid Email address');

  const [endpointURL, fetchOpts] = getRequestOptions({
    service: 'email',
    ...values,
  });

  const response = await fetch(endpointURL, fetchOpts);
  const responseJSON = await response.json();
  // console.log('submitUnsubEmailForm response', responseJSON);
  return responseJSON;
}

export async function submitUnsubPhoneForm(values) {
  const { phoneNumber } = values;
  if (!phoneNumber) throw new Error('Invalid Phone number');

  const [endpointURL, fetchOpts] = getRequestOptions({
    service: 'phone',
    ...values,
  });

  const response = await fetch(endpointURL, fetchOpts);
  const responseJSON = await response.json();
  // console.log('submitUnsubPhoneForm response', responseJSON);
  return responseJSON;
}

export async function submitUnsubPostalForm(values) {
  const {
    firstName, lastName, city, state, zipcode,
  } = values;
  if (!zipcode) throw new Error('Invalid Zip Code');
  if (!firstName) throw new Error('Invalid First Name');
  if (!lastName) throw new Error('Invalid Last Name');
  if (!state) throw new Error('Invalid State');
  if (!city) throw new Error('Invalid City');

  const [endpointURL, fetchOpts] = getRequestOptions({
    service: 'postal',
    ...values,
  });

  const response = await fetch('https://www.phoenix.edu/request/unsubscribe', fetchOpts);
  const responseJSON = await response.json();
  // console.log('submitUnsubPostalForm response', responseJSON);
  return responseJSON;
}
