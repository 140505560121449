import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Input from './input/Input';
import { InputAdornment } from "@mui/material/";
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import {getStateProvince} from '../../CommonComponents'; 
import {makeid} from '../../CommonComponents';

function ZipCode({ type, label, id, handleChange, formRef, value, ariaRequired }) {
  const [states, setStates] = useState('');

  let zipcodeEndpoint = 'https://www.phoenix.edu/services/contacts/v1/validate/zipcode';

  if (window.location.host != 'www.phoenix.edu') {
    zipcodeEndpoint = 'https://stage.phoenix.edu/services/contacts/v1/validate/zipcode';
  }

  const validate = async values => {
    if (handleChange) {
      value = values.zipcode;
      handleChange(values.zipcode);
    }
    const errors = {};
    switch (type) {
      case 'zipcode':
        if (!values.zipcode) {
          errors.zipcode = 'Enter a ZIP code';
        } else if (!/^[\\0-9]*$/i.test(values.zipcode)) {
          errors.zipcode = 'ZIP code must be numeric';
        } else if (!(values.zipcode.length === 5)) {
          errors.zipcode = 'ZIP codes must include 5 numeric characters';
        } else if (values.zipcode.length === 5) {
          let data;
          data = await getzipcode(parseInt(values.zipcode));
          if (data !== undefined) {
          //  const result = data[0].zipcodes[0].state_abbreviation;
            const result = data;
            setStates(result);
            if (handleChange) {
              handleChange(values.zipcode, result);
            }
            console.log('state:', result);
            console.log(states);
          } else {
            errors.zipcode = 'ZIP code appears invalid';
            if (handleChange) {
              handleChange(values.zipcode, 'N/A');
            }
          }
        }
        return errors;
      default:
        return errors;
    }
  };

  const getzipcode = async code => {
    const response = await fetch(
      zipcodeEndpoint + `?zipcode=${code}`,
    );
    if (response.status === 200) {
      const json = await response.json();
      if (json[0].zipcodes.length > 0){
        let stateCode = json[0].zipcodes[0].state_abbreviation;
        return stateCode;
      } else {return undefined;}
    } 
    if (response.status === 424) {
      const response = await fetch(
        `https://www.phoenix.edu/services/contacts/v1/validate/zipcode?legacy=true&zipcode=${code}`,
      );
      if (response.status === 200) {
        const locationInfoObj = await response.json();
        let stateCode = locationInfoObj.state.toString();
        if (stateCode !== "") {
          return stateCode;
        } else {
          return undefined;
        }
      }
    }
    return undefined;
  };

  let twochar = makeid(2);
  let newid = id +"-" + twochar;

  return (
    <div>
      <Formik
        initialValues={{}}
        validateOnChange
        validateOnBlur={false}
        validate={validate}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setErrors,
            setFieldValue,
          } = props;

          // this is to set zipcode value when page is moved back and forth
        if (!values[type] && value) {
          values[type] = value;
        }

        if (!values.zipcode) {
          errors.zipcode = 'Enter a ZIP code';
        } else if (!/^[\\0-9]*$/i.test(values.zipcode)) {
          errors.zipcode = 'ZIP code must be numeric';
        } else if (!(values.zipcode.length === 5)) {
          errors.zipcode = 'ZIP codes must include 5 numeric characters';
        }  else if(values.zipcode && errors[type] === 'Enter a ZIP code'){
          errors.zipcode = undefined;
        }

          if (!values.zipcode && formRef?.current?.errors && formRef.current.errors[type]) {
            errors[type] = errors[type] || formRef.current.errors[type];
            touched[type] = formRef.current.touched[type] || formRef.current.touched['postalCode'] || touched[type];
          }

          return (
            <>
              <Input
                label={`${label}`}
                id={newid}
                name={type}
                value={values[type]}
                onChange={e => {
                  setErrors({});
                  handleChange(e);
                }}
                inputProps={{
                  maxLength: 5,
                  'aria-required': ariaRequired,
                  'aria-label': 'Enter Zip code'
                }}
                handleIsInvalid={e => {
                  handleBlur(e);
                }}
                isInvalid={errors[type] !== undefined && touched[type]}
                helperText={
                  errors[type] && touched[type] && <span>{errors[type]}</span>
                } 
                type="tel"
                InputProps={!errors[type] &&{
                  endAdornment: (
                      <InputAdornment position="end" tabIndex='0'>
                        {touched[type] && !errors[type]  && <Done role='img' focusable="true" aria-hidden="false" aria-label='validation successful' title="validation successful" color="secondary" />}
                        {touched[type] &&  errors[type]  && <Close color="primary" />}
                      </InputAdornment>
                  ),
                }}
                
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
}

ZipCode.propTypes = {
  /**
   * The type of the ZipCode element is required.
   * Use this prop to enable specific validations for  that input field
   * Example: if you pass type as "email", it will enable validations for email only.
   */
  type: PropTypes.string.isRequired,
  /**
   * label of the ZipCode element is also required.
   * The label is used in display label text inside the custom input field
   */
  label: PropTypes.string.isRequired,
};

ZipCode.defaultProps = {};

export default ZipCode;
