import React from 'react';

export function unescapeHTML(html) {
  if (html.indexOf('<') === -1) {
    return html;
  }
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

export function ucwords(str) {
  return (`${str}`).replace(/^([a-z])|\s+([a-z])/g, $1 => $1.toUpperCase());
}

export function addDropDownOption(value, dropdownList) {
  if (value && !dropdownList.some(e => e.value === value)) {
    dropdownList.unshift({
      label: ucwords(value.replace(/[_-]+/g, ' ')),
      value,
    });
  }
}

export function processJSONParameter(value) {
  if (typeof value === 'string' && ['{', '['].indexOf(value[0]) !== -1) {
    try {
      console.log('additionalProps: ', value);
      return JSON.parse(value);
    } catch (e) { console.error('Failed to process JSON parameter: ', value, e); }
  }
  return value;
}

export function initValues(initialValues, props) {
  //console.log('initialValues length and initialValues **** props.length and props in initValues', Object.keys(initialValues).length, initialValues, Object.keys(props).length, props);
  // Load initial values from properties.
  /* const AEMfieldName = ['title', 'theme', 'fieldsHidden', 'fieldsEnabled', 'fieldOfStudyOptions', 'degreeLevelOptions', 'classes',]
  Object.keys(props).forEach(propsKey => {
    //  console.log ("propsKey and Object.keys(initialValues)", propsKey, Object.keys(initialValues));
    if (AEMfieldName.indexOf(propsKey) === -1) { 
      console.log("in if props not in AEMfieldName array --- > ", propsKey);
      if (props[propsKey] !== "" || props[propsKey] !== undefined || props[propsKey] !== null) {
        if (!Object.keys(initialValues).includes(propsKey)){
          console.log("props not in initialValues  --- > ", propsKey);
          initialValues[propsKey] = props[propsKey];
          console.log("add props keyValue to initialValues ", initialValues[propsKey],  props[propsKey]);
        }
      } 
    }
  }); 
  console.log('initialValues after update ----', Object.keys(initialValues).length, initialValues); */
  const formNames = {
    education: 'flex_Community_College_University__c',
    infoRequested: 'flex_Info_Requested__c',
  };
  if (props.additionalProps) {
    //console.log('in if cond props.initialValues or props.additionalProps from properties', props.additionalProps);
    const overrideInitialValues = processJSONParameter(props.additionalProps);
    //console.log('overrideInitialValues', overrideInitialValues);
    Object.assign(initialValues, overrideInitialValues);
  }
  if (initialValues.appContext !== 'EMAILCAPTURE') {
    for (const fieldName of ['fieldOfStudy', 'isRegisteredNurse', 'degreeLevel', 'postalCode', 'thankyouPageUrl', 'education', 'infoRequested', 'code', 'title', 'description', 'cta', 'thankyoumessagetitle', 'thankyoumessagedescription', 'formname',]) {
      const value = props[fieldName];
      const formFieldName = formNames[fieldName] || fieldName;
      if (value && ['number', 'string'].includes(typeof value)) {
        initialValues[formFieldName] = value;
      }
    }
  } else {
    for (const fieldName of ['title', 'description', 'cta', 'thankyouMessage', 'to', 'formName']) {
      const value = props[fieldName];
      if (value && ['number', 'string'].includes(typeof value)) {
          initialValues[fieldName] = value;
      }
    }
  }
  
  //console.log('initialValues after update ----', Object.keys(initialValues).length, initialValues);
  //addDropDownOption(initialValues.fieldOfStudy, this.state.fieldOfStudyOptions);
  //addDropDownOption(initialValues.degreeLevel, this.state.degreeLevelOptions);
}
