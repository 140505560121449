import { profanityCheck } from '../CommonComponents';

export function validateInternationalStudent(values, step) {
  const errors = {};  
  switch (step) {
    case 1:
      if (!values.countryCode) {
        errors.countryCode = 'Country of residence requires a value';
      }

      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = 'U.S. Military affiliation requires a value';
      }
      if (values.isCitizenOfUnitedStates === '' || values.isCitizenOfUnitedStates === undefined) {
        errors.isCitizenOfUnitedStates = 'Are you a citizen of the United States requires a value';
      }
      break;
    case 2:
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = 'Area of study requires a value';
      }
      if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
        errors.isRegisteredNurse = 'Registered nurse requires a value';
      }
      if (values.fieldOfStudy !== 'nondegree' && !values.degreeLevel) {
        errors.degreeLevel = 'Degree level requires a value';
      }
      if (values.fieldOfStudy === 'nondegree' && !values.flex_Course_Category__c) {
        errors.flex_Course_Category__c = "Course category selection required";
      }
      if (!values.emailAddress) {
        errors.emailAddress = 'Email address requires a value';
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = 'Email address appears invalid';
      }

      if (!values.firstName) {
        errors.firstName = 'First name requires a value';
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = 'First name appears invalid';
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = 'First name cannot exceed 20 characters';
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = 'First name appears invalid';
        }
      }

      if (!values.lastName) {
        errors.lastName = 'Last name requires a value';
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = 'Last name appears invalid';
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = 'Last name cannot exceed 80 characters';
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = 'Last name appears invalid';
        }
      }

      if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone number requires a value';
      }
    /*  if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
      } */

      break;
    default:
      break;
  }
  return errors;
}

export function validateMobileInternationalStudent(values, step) {
  const errors = {};
  switch (step) {
    case 1:
      if (!values.countryOfResidence) {
        errors.countryOfResidence = 'Country of residence requires a value';
      }

      if (values.isMilitary === '' || values.isMilitary === undefined) {
        errors.isMilitary = 'U.S. Military affiliation requires a value';
      }
      if (values.isCitizenOfUnitedStates === '' || values.isCitizenOfUnitedStates === undefined) {
        errors.isCitizenOfUnitedStates = 'Are you a citizen of the United States requires a value';
      }
      break;
    case 2:
      if (!values.firstName) {
        errors.firstName = 'First name requires a value';
      }
      if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
        errors.firstName = 'First name appears invalid';
      }
      if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
        errors.firstName = 'First name cannot exceed 20 characters';
      }
      if (values.firstName) {
        const check = profanityCheck(values.firstName);
        if (check === false) {
          errors.firstName = 'First name appears invalid';
        }
      }

      if (!values.lastName) {
        errors.lastName = 'Last name requires a value';
      }
      if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
        errors.lastName = 'Last name appears invalid';
      }
      if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
        errors.lastName = 'Last name cannot exceed 80 characters';
      }
      if (values.lastName) {
        const check = profanityCheck(values.lastName);
        if (check === false) {
          errors.lastName = 'Last name appears invalid';
        }
      }
      if (!values.fieldOfStudy) {
        errors.fieldOfStudy = 'Field of Study requires a value';
      }
      if (!values.degreeLevel) {
        errors.degreeLevel = 'Degree Level requires a value';
      }
      break;
    case 3:
      if (!values.emailAddress) {
        errors.emailAddress = 'Email Address requires a value';
      }
      if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.emailAddress = 'Email address appears invalid';
      }

      if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone Number requires a value';
      }
      if (values.phoneNumber && values.phoneNumber.length !== 10) {
        errors.phoneNumber = 'Please enter a valid phone number.';
      }
      break;
    default:
      break;
  }
  return errors;
}
