import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import EmailFormInitialValues from './EmailusFormInitialValues';
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
import { validate } from "./EmailusValidation";
import getIPAddress, { unescapeHTML, loadCookiesData, submitForm, fireAnalyticsEvent, fireNonRFISubmitEvent, pageNameByPath, roleChange, updatePrePopCookieValue} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus'
import axios from 'axios';
import { Cookies } from "react-cookie";
import Button from '../layout/MaterialComponents/button/Button';
import { initValues, processJSONParameter } from '../common/configTools';
import SelectField from '../UopxNativeSelectField';
import { reEntryDropdown } from '../common/constants';

const formRef = {};
let prevHoldUp;
let defaultTextHTML = '<h3 style="text-align: center;">Email us</h3>';
const disclaimerText = EmailFormInitialValues.disclaimer;

const onTextAreaChange= (e) => {
    formRef.current.values.flex_more_info__c = e.target.value;
  }

class ReEntryContactUs extends Component {
    constructor(props) {
        super(props);
//        console.log('Re-entry props ', props);
        this.initialState = {
            isSubmit: false,
            initialValues: EmailFormInitialValues,
            formRef: null,
            currentStep: 1,
            reEntry: processJSONParameter([...reEntryDropdown]),
        };
        this.state = this.initialState;
    }

    componentDidMount() { 
        const { props } = this;   
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initialValues =  loadCookiesData(initialValues);
        initValues(initialValues, this.props);
        /*
        if (props.additionalProps) {
            const overrideInitialValues = processJSONParameter(props.additionalProps);
        //    console.log('overrideInitialValues', overrideInitialValues);
            Object.assign(initialValues, overrideInitialValues);
        }
        for (const fieldName of ['title', 'description', 'cta', 'thankyouMessage', 'to', 'formName']) {
            const value = props[fieldName];
            if (value && ['number', 'string'].includes(typeof value)) {
                initialValues[fieldName] = value;
            }
        } 
        console.log ("initialValues ", initialValues); */
	    formType = 'Non-RFI';
    	formName = initialValues.formName || 'Email';
        if (initialValues.requestingUrl.indexOf('/re-entry') > -1 || initialValues.requestingUrl.indexOf('/reentry') > -1 || initialValues.formName === 'ReEntryContactUs') {
	        document.title = `Re-entry contact-us`;
            initialValues.thankyouPageUrl = '/request-info/thankyou-re-entry.html';
            this.setAnalytics('ReEntryContactUs');
        } else if (initialValues.requestingUrl.indexOf('/email-nurture-nd') > -1 || initialValues.formName === 'EmailNurtureNonDegree') {
            initialValues.thankyouPageUrl = '/request-info/thankyou-email-us-nd.html';
            initialValues.flex_EmailCaptureSource = 'ND';
            this.setAnalytics('EmailNurtureNonDegree');
        } else if (initialValues.requestingUrl.indexOf('/email-nurture') > -1 || initialValues.formName === 'EmailNurture') {
            initialValues.thankyouPageUrl = '/request-info/thankyou-email-nurture.html';
            this.setAnalytics('EmailNurture');
        } else if (initialValues.flex_EmailCaptureSource === "ND") {
            initialValues.thankyouPageUrl = '/request-info/thankyou-email-us-nd.html';
        } 
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress);
        const { currentStep } = this.state;
        formStep = currentStep;
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
	}

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    setAnalytics = (formname) => {
        setTimeout(function() { 
	        formType = 'Non-RFI';
    	    formName = formname;       
        }, 2000);   
    }

    dropdownChange = (name, value) => {
        const {initialValues} = this.state;
        if (name === 'holdUp') {
            if (value && prevHoldUp !== value) {
                updatePrePopCookieValue('holdUp', value);
                //fireFOS_DlEvent(name, value, formName, formType); may need to make one for "holdUp"
                prevHoldUp = value;
                initialValues.holdUp = value;
            }
        }
        
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    } 

    setFirstNameCookie (firstName)  {
        const cookies = new Cookies();
        cookies.set('firstname', firstName, { path: '/' });
    }

    handleSubmit = (values) => {
        if (document.activeElement.name === "submit"){
    //    hashValues(values);   
        values.firstName = (values.firstName).trim();
        values.lastName = (values.lastName).trim();  
        this.setState({ initialValues: values });
        console.log ("values", values);
        if (window.location.href.indexOf('/reentry') > -1 || values.formName === 'ReEntryContactUs') {
            const headers = {
                'Content-Type': 'application/json',
            };
            let URL = 'https://www.phoenix.edu/request/sendmail';
            const data = JSON.stringify(values);
            axios.post(URL, data, {
                headers,
              })
            .then((response) => {
                if (response.data.msg === 'success'){
                    console.log("Email sent, awesome!");
                    this.setFirstNameCookie(values.firstName);
                    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
                    if(window.location.host !== 'www.phoenix.edu'){
                        baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
                    }
                    window.location.assign(baseUrl + values.thankyouPageUrl);
                }else if(response.data.msg === 'fail'){
                    console.log("Oops, something went wrong. Try again")
                }
                fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
                //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
            })
            .catch(error => {
            console.log (error.message);   
            });                
        }else {
            submitForm(values);
        }
        }
    }
    
    render() {
        const {
            initialValues,
        } = this.state;
        let titleText = this.props.title || defaultTextHTML;
        let descriptionText = initialValues.description || '';
        let cta = initialValues.cta || 'Submit';
        return (   
            <div>
                <div className="ReEntryContactUs-main">
                    <Grid container justify='center' className="mobileFormContainer">
                    <Grid container md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">
						<Grid item md={12} sm={12} lg={12} xs={12} >
						    <div>{unescapeHTML(titleText)}</div>
                            <div>{unescapeHTML(descriptionText)}</div>
						</Grid>
                        <Grid item md={12} sm={12} lg={12} xs={12} >
                            <div className="reqinfo" > * Required information </div>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => validate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Email Us"
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container >                                               
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="firstName" id="firstName"
                                                        label="First name *" ariaRequired="true" ariaLabel="Enter First name"  onClick={this.handleAnalyticsRFIFormStart}/>
                                                </Grid>
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="lastName" id="lastName"
                                                        label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                {/* changes to add dropdown here. */}
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                <SelectField
                                                    id="holdUp"
                                                    name="holdUp"
                                                    label="I'm coming back from:"
                                                    data={reEntryDropdown}
                                                    className="MuiFormControl-fullWidth"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("holdUp", props.values.holdUp)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                    <MaterialTextArea
                                                        id="flex_more_info__c"
                                                        name="flex_more_info__c"
                                                        onChange={onTextAreaChange}
                                                        label="How can we help? *"
                                                        classNames="uopx-input more_info_textarea"
                                                        rows={3}
                                                        ariaLabel="What can we help you with?"   
                                                        ariaRequired="true"                                                
                                                    />
                                                </Grid>
                                                <div className="disclaimer">
                                                  {unescapeHTML(disclaimerText)}
                                                 </div>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ={["margin-OneStepForm", "submit-btn" ]} style={{ textAlign: 'center'}}>
                                                    <Button name='submit' size='large' type='submit' className="btn-submit"> {unescapeHTML(cta)} </Button>
                                                </Grid>
                                            </Grid>
											<ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

ReEntryContactUs.defaultProps = {
    classes: {},
};

ReEntryContactUs.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(ReEntryContactUs);