import React from 'react';
import MuiButton from '@mui/material/Button/Button';  
import PropTypes from 'prop-types';
import './Button.scss';

// Material-ui overides
// https://v4-10-2.material-ui.com/api/button/

function Button({
  variant,
  size,
  children,
  icon,
  disabled,
  href,
  color,
  ariaLabel,
  className,
  ...other
}) {
  return (
    <MuiButton
      {...other}
      variant={variant}
      size={size}
      startIcon={icon}
      aria-label={ariaLabel}
      href={href}
      disabled={disabled}
      disableRipple
      color={color || "primary"}
      className={className ? 'uopx-button ' + className : 'uopx-button'}
    >
      {children}
      {(variant === 'text') && (
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="uopx-tertiary-icon"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" /></svg>
      )}
    </MuiButton>
  );
}

Button.propTypes = {
  /**
   * If needed, the button label can be replaced with an aria-label.
   */
  ariaLabel: PropTypes.string,
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
  /**
   * If true, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The URL to link to when the button is clicked.
   * If defined, an a element will be used as the root node.
   */
  href: PropTypes.string,
  /**
  * Element placed before the children. Icon before the button text.
  */
  icon: PropTypes.node,
  /**
   * The size of the button. small is equivalent to the dense button styling.
   */
  size: PropTypes.oneOf(Object.values({ small: 'small', medium: 'medium', large: 'large' })),
  /**
   * The variant are our CTA (Primary, Secondary and Tertiary).
   * Primary doesn't require a variant as the 'contained' is the default if none set.
   * Secondary is the variant of 'outlined' and Tertiary is 'text'.
   */
  variant: PropTypes.oneOf(Object.values({ text: 'text', outlined: 'outlined', contained: 'contained', primaryteal: 'primaryteal', secondary:'Secondary' })),
};

Button.defaultProps = {
  size: 'large',
  variant: 'contained',
  ariaLabel: '',
  disabled: false,
  href: null,
  icon: null,
};

export default Button;
