import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { Grid, Typography } from '@mui/material/';
import FormikErrorFocus from 'formik-error-focus';
import { withStyles } from '@mui/styles';
import SelectField from '../UopxNativeSelectField';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { profanityCheck, fireAnalyticsEvent, fireNonRFISubmitEvent } from '../CommonComponents';
import { usStates } from '../common/constants';
import { submitUnsubPostalForm } from './UnsubscribeAPI';
import styles from '../inline/SubscribeStyles';
import Button from '../layout/MaterialComponents/button/Button';
import AddressAutoComplete from '../layout/MaterialComponents/UOPAutoCompleteSmartyStreets';
import { any } from 'prop-types';
import CheckCircle from '../layout/images/check-circle.svg';
import Warning from '../layout/images/warning.svg';

var disableField = true;
function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite");
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
        throw e;
    }
  }

class UnsubscribePostalFormSmarty extends Component {
    constructor(props) {
        super(props);
        // console.log('UnsubscribePostalForm', props);
        this.initialState = {
            initialValues: {},
            variant: props.variant,
            disableField: any,
            message: null,
            street: '',
            state: '',
            city: '',
            zipcode: ''
        };
        this.state = this.initialState;
        this.ref = {
            form: React.createRef(),
            container: React.createRef(),
        };
    }

    componentDidMount() {
    /*    let { initialValues } = this.state;
        initialValues.address='';
        this.setState({ initialValues }); */
        formType = 'Non-RFI';
        formName = 'postal Unsubscribe';

        let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }
        fetch(baseUrl + '/services/contacts/v1/validate/zipcode?zipcode=85040', { method: 'HEAD' })
        .then(response => {
            if (response.status === 424){
                disableField = false;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
        getIPAddress(this.updateIPAddress);
    }
    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            this.ref.form.current.values.zipcode = zipcode;
        }
    }


    getAddressInfo(e) {
        const getStreet = async (inputText) => {
            let response = await fetch(
                `/services/contacts/v2/suggest/address?prefix=${encodeURI(inputText)}`,
            );
            console.log ("getAddressInfo inputText", inputText);
            const { initialValues } = this.state;
            if (response.status === 200) {
                let responseData = await response.json();
                console.log(responseData);
                const newStreet = responseData?.suggestions[0]?.street_line;
                const newCity = responseData?.suggestions[0]?.city;
                const newState = responseData?.suggestions[0]?.state;
                const newZip = responseData?.suggestions[0]?.zipcode
                const oldStreet = this.state.street;
                // Entering a new address
                if (oldStreet !== newStreet) {
                    this.setState({
                        street: newStreet,
                        city: newCity,
                        state: newState,
                        zipcode: newZip,
                    });

                    if (initialValues.zipcode !== newZip) {                        
                        //this.zipcodeChange(newZip);
                        initialValues.zipcode = newZip;
                        initialValues.state = newState;
                        this.setState({ initialValues });
                    }
                }
            } 
        /*    if (response.status === 424) {
                this.setState({street: inputText});
                console.log ("street value is ", this.state.street);
            } */
        }
        if (e !== null) {
            getStreet(e);
        } else {
            this.setState({
                street: '',
                state: '',
                city: '',
                zipcode: ''
            });
            formRef.current.values.zipcode = '';
        }

    }
    validatePostal(values) {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = 'First Name requires a value';
        }
        if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
            errors.firstName = 'First Name appears invalid';
        }
        if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
            errors.firstName = 'First Name cannot exceed 20 characters';
        }
        if (values.firstName) {
            const check = profanityCheck(values.firstName);
            if (check === false) {
                errors.firstName = 'First name appears invalid';
            }
        }

        if (!values.lastName) {
            errors.lastName = 'Last Name requires a value';
        }
        if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
            errors.lastName = 'Last Name appears invalid';
        }
        if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
            errors.lastName = 'Last Name cannot exceed 80 characters';
        }
        if (values.lastName) {
            const check = profanityCheck(values.lastName);
            if (check === false) {
                errors.lastName = 'Last name appears invalid';
            }
        }
        if (!values.address) {
            this.state.street?values.address = this.state.street:
            errors.address = 'Address requires a value';
        }
        if (!values.city) {
            this.state.city?values.city = this.state.city:
            errors.city = 'City requires a value';
        }
        if (!values.state) {
            this.state.state?values.state = this.state.state:
            errors.state = 'State requires a value';
        }
        if (!values.zipcode) {
            this.state.zipcode?values.zipcode = this.state.zipcode:
            errors.zipcode = 'Enter a ZIP code';
        }
        if (values.zipcode && values.zipcode.length !== 5) {
            errors.zipcode = 'Zip codes must include 5 numeric characters';
        }
        if (values.zipcode && !/^[\\0-9]*$/i.test(values.zipcode)) {
            errors.zipcode = 'Zip code must be numeric';
        } else if (values.zipcode && !values.state) {
            errors.zipcode = 'Zip Code appears invalid';
        }
        console.log('validate postal', values, errors);
        return errors;
    }

    async handlePostalSubmit(values) {
        if (document.activeElement.name === "submit") {
        values.firstName = (values.firstName).trim();
        values.lastName = (values.lastName).trim();
        const { classes } = this.props;       
        console.log('handlePostalSubmit', values);
        //fireEvent('rfi_unsubscribe_postal_submit');
        let responseJSON;
        try {
            responseJSON = await submitUnsubPostalForm(values);
            console.log(responseJSON);
        } catch (e) {
            console.log(e);
            responseJSON = { message: `${e}`, success: false };
            console.log(responseJSON);
        }
        
        this.setState({
            initialValues: values,
            isSubmit: !!responseJSON.success,
            message: <div id='thankstitle' aria-labelledby='thankstitle' role='region' aria-live='assertive' className={responseJSON.success ? classes.unsubSuccess : classes.error}><img alt='checkmark' src={CheckCircle} style={{paddingRight: "0.5rem"}}/>{responseJSON.message || 'No Message Given'}</div>,}, () => {
            this.ref.container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        });
        srSpeak("Unsubscribe Postal request sent successfully.");
        fireNonRFISubmitEvent( 'NonRFISubmit', values, 'postal Unsubscribe', 'Non-RFI');
        }
    }

    handleAnalyticsRFIFormStart = () => {
        if (this.state.rfi_begin_postal !== true) {
            fireAnalyticsEvent('NonRFIStart', 'postal Unsubscribe', 'Non-RFI');
            this.setState({ rfi_begin_postal: true });
        }
    }

    render() {
        const { classes } = this.props;
        const { isSubmit, initialValues, message } = this.state;
    //    console.log("disableField and message", disableField, message);
        return (
            <div ref={this.ref.container} className="unsubscribe">
                {!isSubmit
                    ? (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={values => this.handlePostalSubmit(values)}
                            validate={values => this.validatePostal(values)}
                            innerRef={this.ref.form}
                            validateOnChange
                        >
                            <Form
                                className="unsubscribe-postal-form"
                                style={{ position: 'relative', paddingBottom: '1rem' }}
                                onChange={this.handleAnalyticsRFIFormStart}
                            >
                                <Grid>
                                    <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                    {message}
                                    <Grid item md={12} sm={12} lg={12} xs={12} className="margin-OneStepForm" id="postalForm">
                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                            <MaterialInput
                                                name="firstName"
                                                id="firstName"
                                                type="text"
                                                label="First name"
                                                required={true}
                                                ariaLabel="Enter First name"
                                                onClick={this.handleAnalyticsRFIFormStart}
                                            />
                                        </Grid>
                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                            <MaterialInput
                                                name="lastName"
                                                id="lastName"
                                                type="text"
                                                label="Last name"
                                                required={true}
                                                ariaLabel="Enter Last name"
                                            />
                                        </Grid>
                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                        {disableField &&     <AddressAutoComplete id='address' name='address' label='Current Address' required={true}
                                                onChange={addressInfo => this.getAddressInfo(addressInfo)}
                                                street={this.state.street}
                                            /> 
                                        }
                                        {disableField === false && 
                                            <MaterialInput
                                                name="address"
                                                id="address"
                                                label="Current Address"
                                                required={true}
                                                ariaLabel="Current Address"
                                            >
                                            </MaterialInput>
                                        }
                                        </Grid>
                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                        {disableField &&  
                                            <MaterialInput
                                                name="city"
                                                id="city"
                                                label="City"
                                                required
                                                ariaLabel="City"
                                                value={this.state.city}
                                                disabled={disableField}>
                                                {this.value}
                                            </MaterialInput> 
                                        }
                                        {disableField === false && 
                                            <MaterialInput
                                                name="city"
                                                id="city"
                                                label="City"
                                                required={true}
                                                ariaLabel="City"
                                                disabled={disableField}>
                                            </MaterialInput>
                                        }
                                        </Grid>
                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                        {disableField &&    
                                            <MaterialInput 
                                                name="state" 
                                                id="state" 
                                                label="State"  
                                                ariaLabel="Enter State" 
                                                value={this.state.state}
                                                required 
                                                disabled={disableField}>
                                                {this.value}
                                            </MaterialInput> 
                                        }
                                        {disableField === false &&    
                                            <SelectField
                                                    id="state"
                                                    name="state"
                                                    label="State"
                                                    data={usStates}
                                                    required={true}
                                                    disabled={disableField}
                                                > 
                                                </SelectField>  
                                        }
                                        </Grid>
                                        <Grid item md={12} sm={12} lg={12} xs={12}>                                            
                                        {disableField &&
                                            <MaterialInput 
                                                name="zipcode" 
                                                id="zipcode" 
                                                label="ZIP code "  
                                                ariaLabel="Enter Zip code" 
                                                value={this.state.zipcode}
                                                required 
                                                disabled={disableField}
                                            >
                                            {this.value}
                                            </MaterialInput>
                                        }
                                        {disableField === false &&
                                            <ZipCode value={this.ref.form?.current?.values.zipcode} type="zipcode" id="zipcode" label="ZIP code *" aria-required="true" aria-describedby="Enter Zip code" aria-label="Enter Zip code" handleChange={this.handleZipcodeChange} ></ZipCode>
                                        }
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} sm={12} lg={12} xs={12}>
                                        <div>
                                            <Button
                                                name='submit'
                                                size="large"
                                                type="submit"
                                                className='rfi-btn-submit submit-btn'
                                                ariaLabel="Submit Postal Unsubscribe Form"
                                                style={{backgroundColor: "#30718d"}}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <FormikErrorFocus
                                    offset={-50}
                                    align="top"
                                    focusDelay={200}
                                    ease="linear"
                                    duration={1000}
                                />
                            </Form>
                        </Formik>
                    )
                    : message}
            </div>
        );
    }
}

export default withStyles(styles)(UnsubscribePostalFormSmarty);
