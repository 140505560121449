export default {
  firstName: '',
  lastName: '',
  emailAddress: '',
  flex_more_info__c: '',
  flex_Info_Requested__c: 'something_else',
  appContext: 'EMAILCAPTURE',
  mcid: '',
  useCaseContext: 'NEW',
  disclaimer: 'By submitting this form, you consent to receive University of Phoenix emails about our educational programs and services. Visit our <a target="_blank" href="https://www.phoenix.edu/copyright-legal/privacy-policy.html">Privacy Policy</a> for more information.',
  flex_EmailCaptureSource: 'EU',
  userAgent: '',
  campaign: 'PHXEDU10',
  source: 'INET',
  requestingIP: '',
  requestingUrl: '',
  thankyouPageUrl: '/request-info/thankyou-referral.html',
  fullName: '',
  persona: '',
  flex_referrer_emailAddress: '',
  flex_referrer_fullName: '',
  flex_referrer_firstName: '',
  flex_referrer_lastName: '',
  flex_referrer_analyticsId: '',
};
