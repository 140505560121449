import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function SingleCheckbox({ parentCallback, label, ariaLabel, checkboxName, className, legend, options, defaultValue, defaultValueGroup, id }) {
  const [checked, setChecked] = React.useState(defaultValue); // State used for only single checkboxes
  let multiCheckedArray = [];
  // If initial state hasn't been created with multiple option choice, create an array using the multiple options
  if(options && multiCheckedArray.length === 0) {
    options.forEach((option) => {
      let boxChecked = typeof defaultValueGroup !== "undefined" ? defaultValueGroup.includes(option.value) : option.isChecked;
      console.log(option.key, option.value);
      multiCheckedArray.push({
        key: option.key,
        name: option.value,
        label: option.label,
        checked: boxChecked,
      });
    })
  }
  const [multiChecked, setMultiChecked] = React.useState( // State used for checkAll checkbox with children
    multiCheckedArray
  );
  multiCheckedArray = multiChecked;
  const handleChange = (e) => {
  //  console.log(e)
    if (e.target) { // Only used if there is a checkAll parent checkbox
    //  console.log('e.target')
      if (e.target.name === "checkAll") {
        multiCheckedArray.forEach(f => { // Sets the checked property of each child to be true or false to match checkAll
          f.checked = e.target.checked;
        });
        setMultiChecked(multiCheckedArray);
        setChecked(!checked, parentCallback(e.target.name, e.target.checked));
      } else if (e.target.name === "isCBE" || e.target.name === "employer_tuition_discounts" || e.target.name === "transfer_credits" || e.target.name === "hasConsent" || e.target.name === "isText" || e.target.name === "isPhone" || e.target.name === "isScheduledCall" || e.target.name === "flex_Transfer_Credits_from_ValueExchange__c"){
      //  console.log("e.target.name is isCBE");
        setChecked(!checked, parentCallback(e.target, e.target.checked));
      }
      
    } else if (!e.target){
    //  console.log('!e.target')
      if (e.name === "checkAll") {
        multiCheckedArray.forEach(f => {
          f.checked = e.checked
        });
        setMultiChecked(multiCheckedArray);
        setChecked(!checked, parentCallback(e.name, e.checked))
      } else if (e.name === "isCBE" || e.name === "employer_tuition_discounts" || e.target.name === "transfer_credits" || e.name === "hasConsent" || e.name === "isText" || e.name === "isPhone" || e.target.name === "isScheduledCall" || e.target.name === "flex_Transfer_Credits_from_ValueExchange__c"){
       //console.log("e.name is isCBE");
       //console.log("Returning from here");
        setChecked(!checked, parentCallback(e, e.checked))
      }
    }
  };
  const handleMultipleChange = (e) => {
    const newTempArray = [...multiCheckedArray]; // Create a temporary array, this is then used to update state and re render children with updated state
    if(e.target){
    //  console.log(e.target.name);
      newTempArray.forEach(f => {
      if (e.target.name === f.name) {
        newTempArray[f.key].checked = e.target.checked;   
      //  console.log(newTempArray)     
      }
      setMultiChecked(newTempArray,parentCallback(e.target.name, e.target.checked));
    });
    } else if (!e.target){
    //  console.log(e.name)
      newTempArray.forEach(f => {
      //  console.log(e.name,f.name);        
        if (e.name === f.name) {
          newTempArray[f.key].checked = e.checked;        
        }
        
        setMultiChecked(newTempArray,parentCallback(e.name, e.checked));
      });
    } else {
    //  console.log(e);
    }
  }
  const handleKeyPress = (event) => {
  //  console.log(event);
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the form from submitting
      const target = event.target;
      if (target.type === 'checkbox') {        
        target.checked===true?target.checked=false:target.checked=true;
        handleChange(event.target);
      }
    }
  };
  const handleKeyPressMultiple = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the form from submitting
      const target = event.target;
      if (target.type === 'checkbox') {
      //  console.log('about to call function handleMultipleChange()');
        target.checked===true?target.checked=false:target.checked=true;
        handleMultipleChange(target);
      }
    } 
  };
  if (legend && checkboxName === "checkAll") { // Only renders this option if there is both a legend prop and the checkbox is named checkAll
   console.log('legend && checkAll');
    const children = multiCheckedArray.map((option) => {
      return (
        <FormControlLabel
          key={(option.key)}
          for={checkboxName.name}
          label={option.label}
          /* aria-label={option.label} */
          control={<Checkbox id={id} name={option.name} 
          checked={multiChecked[option.key].checked} 
          //aria-checked={checked} 
          onChange={handleMultipleChange} 
          onKeyDown={handleKeyPressMultiple}/>}
        />
      )
    });
    
    return (
      <FormControl className={className} component="fieldset">
        <legend style={{marginBottom:"10px"}}>{legend}</legend>
        {children}
        <FormControlLabel
          label={label}
          /* aria-label={label} */
          for={checkboxName}
          name={checkboxName}
          control={
            <Checkbox
              id={id}
              checked={checked}
              //aria-checked={checked}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            />
          }
        />
      </FormControl>
    );

  }
  else if (legend) { // renders if there is a legend but no checkAll checkboxName
  //  console.log('legend but no checkAll');
    return (
      <FormControl className={className} component="fieldset">
        <legend style={{marginBottom:"10px"}}>{legend}</legend>
        <FormControlLabel
          label={label}
          /* aria-label={ariaLabel} */
          for={checkboxName}
          name={checkboxName}
          control={
            <Checkbox
              checked={checked}
              id={id}
              //aria-checked={checked}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            />
          }
        />

      </FormControl>
    );
  } else { // renders if there is no legend and is a stand alone checkbox
    console.log('no legend, single checkbox');
    return (
      <FormControl className={className} component="fieldset">
        <FormControlLabel
          label={label}
          for={checkboxName}
          /* aria-label={ariaLabel} */
          name={checkboxName}
          control={
            <Checkbox
              checked={checked}
              id={id}
              //aria-checked={checked}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
            />
          }
        />

      </FormControl>
    );
  }

}
