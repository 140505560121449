import React from 'react';
import { connect } from 'formik';

class ErrorFocusDialog extends React.Component {
    componentDidUpdate(prevProps) {
        const { isSubmitting, isValidating, errors, values } = prevProps.formik;
        const keys = Object.keys(errors);
        if (keys.length > 0 && isSubmitting && !isValidating) {
            const selector = `[id="${keys[0]}"]`;
            const errorElement = document.querySelector(selector);
            if (errorElement) {
            //    errorElement.scrollIntoView();
                errorElement.focus();
            }
            else {
                // few elements doesnot have ids. so check with name
                const selector = `[name="${keys[0]}"]`;
                const errorElement = document.querySelector(selector);
                if (errorElement) {
                //    errorElement.scrollIntoView();
                    errorElement.focus();
                }
            }
        }
    }

    render() {
        return null;
    }
}

export default connect(ErrorFocusDialog);