import React, { useEffect, useRef } from "react";
import moment from 'moment';

function useListener(ref, eventName, handler) {
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      element.addEventListener(eventName, handler)
      return () => element.removeEventListener(eventName, handler)
    }
  }, [eventName, handler, ref])
}

function isWeekend(date) {
  let newDate = new Date(date).toISOString().split('T')[0];
  let today = new Date().toISOString().split('T')[0];
  let year = new Date().getFullYear();
  let christmas = new Date(`12-25-${year}`).toISOString().split('T')[0];
  if (today === (moment().dayOfYear(359).format("YYYY-MM-DD"))) {
    return date.getDay() === 0 || date.getDay() === 6
  } else if (newDate === christmas) {
    return true
  } else {
    return date.getDay() === 0
  }
}

function formatDateInput(input) {
  let inputValue = input.replace(/\D/g, '');
  let inputValueArr = inputValue.split('');
  if (inputValueArr.length > 2 && inputValueArr.length <= 4) { // Input looks like 1020
    inputValueArr.splice(2, 0, '/');
  } else if (inputValueArr.length > 4) { // Input looks like 10202
    inputValueArr.splice(2, 0, '/');
    inputValueArr.splice(5, 0, '/');
  }
  inputValue = inputValueArr.join('');
  let checkValueArr = inputValue.split('/');
  if (checkValueArr[0]) checkValueArr[0] = checkValue(checkValueArr[0], 12);
  if (checkValueArr[1]) checkValueArr[1] = checkValue(checkValueArr[1], 31);
  inputValue = checkValueArr.join('/');
  inputValue = inputValue.substring(0, 10);
  input = inputValue;
  return input;
}

function checkValue(str, max) { // ex (13,12)
  if (str.includes('m') || str.includes('d') || str.includes('y')) {
    return str;
  }

  if (str.charAt(0) !== '0' || str == '00') {
    let num = parseInt(str); // parses '13' > 13
    if (isNaN(num) || num <= 0 || num > max) num = '01'; // isNaN(13):false || num<= 0 :false || num > max : true -> num = 1;
    if (num == '01') {
      str = num
    } else {
      str = num.toString();
    }
  };
  return str;
};

export function UOPDatePicker({
  onChange,
  onFocus,
  onBlur,
  onOpen,
  onClose,
  dateAdapter,
  localization,
  ...props
}) {
  const ref = useRef(null);
  useListener(ref, "duetChange", onChange)
  useListener(ref, "duetFocus", onFocus)
  useListener(ref, "duetBlur", onBlur)
  useListener(ref, "duetOpen", onOpen)
  useListener(ref, "duetClose", onClose)

  let dateValue = null;
  let todaysDateWasPicked = true;
  let picker = document.querySelector("duet-date-picker");
  let placeholderValue = "mm/dd/yyy";
  props.value !== "" ? placeholderValue = "" : placeholderValue = "mm/dd/yyyy";
  const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/
  // set timeout
  setTimeout(function () {
    picker = document.querySelector("duet-date-picker");
    if (picker === null)
      return;

    let scheduleYourCallHeader = document.getElementsByClassName('duet-date__mobile-heading');
    scheduleYourCallHeader.ariaLabel = 'Scheduled your call in the next few days';
    scheduleYourCallHeader.tabindex = 0;
    let dateOption = document.getElementsByClassName('duet-date__day is-month');
    dateOption.tabIndex = 0;
    let closeButton = document.getElementsByClassName('duet-date__close');
    closeButton.ariaLabel = 'Close button';
    const pickerInput = document.querySelector("#datePicker input");
    
    

    picker.isDateDisabled = isWeekend;
    

    picker.addEventListener("duetBlur", function () { // Fires once the input has been unfocused
      //document.querySelector("#datePicker label").innerHTML=scheduledContactDateLabel;
      let input = document.getElementById('date').value;
      let currentDate = new Date();
      if (input !== '') {
        dateValue = new Date(input);
      }
      if (dateValue.toLocaleDateString() == currentDate.toLocaleDateString()) {
        todaysDateWasPicked = true;
      } else {
        todaysDateWasPicked = false;
      }
      props.passedDate(dateValue, todaysDateWasPicked);
    });
    
    picker.addEventListener("duetChange", function (e) { // Fires once a date has been chosen
      console.log("inside formatDateandWeekend.picker.addEventListener('duetChange')");
      dateValue = e.detail.valueAsDate;
      let currentDate = new Date();
      if (dateValue.toLocaleDateString() == currentDate.toLocaleDateString()) {
        todaysDateWasPicked = true;
      } else {
        todaysDateWasPicked = false;
      }
      props.passedDate(dateValue, todaysDateWasPicked);
    });

    picker.addEventListener('input', (e) => {
      let input = document.querySelector('.duet-date__input');
      let newPh = [];
      if (input.value.length > 7) {
        let ph = input.value.split('/');
        for (let i = 0; i < 3; i++) {
          if (ph[i].length === 1 && ph[i] != "0" && i < 2) {
            ph[i] = ph[i].padStart(2, '0');
          }
          newPh.push(ph[i])
        }
      }
      if (newPh.length === 0) {
        input.value = formatDateInput(input.value);
      } else {
        input.value = formatDateInput(newPh.join('/'));
      }
    });

    if(pickerInput === null) {
      return
    } else {
      document.querySelector("#datePicker input").ariaRequired='true';
      document.querySelector("#datePicker input").ariaLabel='schedule contact date';
    }
  }, 500);
  localization = {
    buttonLabel: "Choose date",
    placeholder: placeholderValue,
    selectedDateMessage: "Selected date is",
    prevMonthLabel: "Previous month",
    nextMonthLabel: "Next month",
    monthSelectLabel: "Month",
    yearSelectLabel: "Year",
    closeLabel: "Close window",
    calendarHeading: "Schedule your call in the next few days",
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    locale: "en-US",
  }
  dateAdapter = {
    parse(value = "", createDate) {
      const matches = value.match(DATE_FORMAT_US)
      if (matches) {
        return createDate(matches[3], matches[1], matches[2])
      }
    },
    format(date) {
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
  }

  useEffect(() => {
    ref.current.localization = localization
    ref.current.dateAdapter = dateAdapter
  }, [localization, dateAdapter])

  return <duet-date-picker ref={ref} {...props}></duet-date-picker>
}

