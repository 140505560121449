import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
import { alumnicontactValidate } from "./EmailusValidation";
import getIPAddress, { unescapeHTML, roleChange, pageNameByPath, fireAnalyticsEvent, fireNonRFISubmitEvent} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus'
import axios from 'axios';
import checkmark from '../layout/images/checkmark.svg';
import Button from '../layout/MaterialComponents/button/Button';
import { initValues, processJSONParameter } from '../common/configTools';

const initialAlumniContactFormValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    help_topic:'',
    flex_more_info__c: '',
    requestingUrl: '',
    disclaimer:'By submitting this form, you are giving your consent for University of Phoenix to contact you regarding our education programs and services using email. This consent is not required to purchase goods or services and you may always call us directly at 1-800-795-2586.',
    formName: 'AlumniContactForm',
};

const formRef = {};
let prevHelpTopic;
//let defaultTextHTML = "<h1>Email us</h1>";
const disclaimerText = initialAlumniContactFormValues.disclaimer;

const onTextAreaChange= (e) => {
    formRef.current.values.flex_more_info__c = e.target.value;
  }

const helpTopic = [
    { label: "Login or alumni profile", value: "Login or alumni profile" },
    { label: "Benefits & discounts", value: "Benefits & discounts" },
    { label: "Career resources", value: "Career resources" },
    { label: "Networking", value: "Networking" },
    { label: "Something Else", value: "Something Else" },
];

class AlumniContactForm extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialAlumniContactFormValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
        const { props } = this;
    //    document.title = `Re-entry contact-us`;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href;
    //    initValues(initialValues, this.props);
    //    initialValues.thankyouPageUrl = '/request-info/thankyou-uopx.html';
    //    initialValues =  loadCookiesData(initialValues);
        this.setState({ initialValues });
    //    getIPAddress(this.updateIPAddress);
        const { currentStep } = this.state;
        formStep = currentStep;  
	    formType = 'Non-RFI';
    	formName = 'AlumniContactForm';
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
    }

    dropdownChange = (name, value) => {
        if (name === 'help_topic') {
            if (value && prevHelpTopic !== value) {
                prevHelpTopic = value;
            }
        }
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
	        fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }
      

    handleSubmit = (values) => {
    //    hashValues(values);   
        values.firstName = (values.firstName).trim();
        values.lastName = (values.lastName).trim();
        this.setState({ initialValues: values });
        const headers = {'Content-Type': 'application/json'};
        let URL = 'https://www.phoenix.edu/request/sendmail';
        const data = JSON.stringify(values);
        axios.post(URL, data, {headers})
        .then((response) => {
            if (response.data.msg === 'success'){
                console.log("Email sent, awesome!");
                this.setState({
                    initialValues: values,
                    isSubmit: true,
                  });
            }else if(response.data.msg === 'fail'){
                console.log("Oops, something went wrong. Try again")
            }
        })
        .catch(error => {
            console.log (error.message);   
        });
	    fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
    }
    
    render() {
    //    const { classes } = this.props;
        const { isSubmit, initialValues } = this.state;

    //    let titleText = this.props.title || defaultTextHTML;

        return (
            
            <div>
            {/*   <Header />   */ }
                <div className="AlumniContactForm-main">
                    <Grid container justify='center' className="mobileFormContainer">
                    <Grid container md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">

                     {!isSubmit
                    ? (
                        <>
                        <Grid item md={12} sm={12} lg={12} xs={12} >
                            <div className="reqinfo" > * Required information </div>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => alumnicontactValidate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Alumni contact us"
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                            <Grid container >
                                                <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <SelectField
                                                            id="help_topic"
                                                            name="help_topic"
                                                            label="What can we help you with?"
                                                            data={helpTopic}
                                                            className="MuiFormControl-fullWidth"
                                                            formRef={formRef}
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                            onChange={this.dropdownChange("help_topic", props.values.help_topic)}
                                                        ></SelectField>
                                                        {roleChange()}
                                                    </Grid>                                               
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="firstName" id="firstName"
                                                            label="First name *" ariaRequired="true" ariaLabel="Enter First name"  />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="lastName" id="lastName"
                                                            label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ={["margin-OneStepForm alumni-question"]}>
                                                        <MaterialTextArea
                                                            id="flex_more_info__c"
                                                            name="flex_more_info__c"
                                                            onChange={onTextAreaChange}
                                                            label="Tell us what you need help with *"
                                                            classNames="uopx-input more_info_textarea"
                                                            ariaLabel="Tell us what you need help with"
                                                            ariaRequired="true"
                                                        />
                                                    </Grid>
                                                </Grid>    

                                                    <Grid item md={9} sm={12} lg={9} xs={12} className ={["margin-OneStepForm"]} style={{ alignItems: 'center', display: 'flex' }}>
                                                        <div className="disclaimer">
                                                            { unescapeHTML(disclaimerText) }
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3} sm={12} lg={3} xs={12} className ={["margin-OneStepForm", "submit-btn" ]}>
                                                        <Button size='large' type='submit' className="btn-submit" style={{ width: '100%'}}> Submit </Button>
                                                    </Grid>

                                            </Grid>
							                <ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        </>
                    )
                    : (
                          <>
                            <Grid item md={12} sm={12} lg={12} xs={12} className='thanksContainer'>
                              <div className='thanksHeader' style={{ fontWeight: '700' }}>
                                <img src={checkmark} alt="Checked Circle" />
                                Thanks for sharing your feedback
                              </div>
                            </Grid>
                          </>
                        )}
                          </Grid>
                    </Grid>
                </div>
            { /*   <Footer /> */ } 
            </div>
        );
    }
}

AlumniContactForm.defaultProps = {
    classes: {},
};

AlumniContactForm.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(AlumniContactForm);