/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createGenerateClassName, StylesProvider, withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues';
import MaterialRadio from '../layout/MaterialComponents/MaterialRadio';
import styles from '../layout/styles/SimpleApplyNowFormStyles';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import { unescapeHTML, addDropDownOption, processJSONParameter, initValues} from '../common/configTools';
import getIPAddress, {
    conditionalCBE,
    getDegreeLevel,
    loadCookiesData,
    profanityCheck,
    roleChange,
    submitForm,
    updatePrePopCookieValue,
    fireAnalyticsEvent, fireFOS_DlEvent,
} from '../CommonComponents';
import { courseCategoryDropdown, degreeLevel, dynamicFieldOfStudyDropdownVariant, radioOption, higherEducation, usStates, } from '../common/constants';
import Button from '../layout/MaterialComponents/button/Button';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
import UOPHeader from '../layout/UopHeader';
import Footer from '../layout/Footer';
import AddressAutoComplete from '../layout/MaterialComponents/UOPAutoCompleteSmartyStreets';
import {dataLayer} from '../common/dataLayer';

let prevFOS, prevDegreeLevel, prevState, prevCourseCategory;
let courseCategoryFlag = false;
var disableField = true;
const disclaimerText = rfiFormInitialValues.disclaimer;

// Allow unescaped HTML
class SimpleApplyNow2StepSmarty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            thankyouPageUrl: '/request-info/apply-thank-you.html',
            showIntlText: false,
            currentStep: 1,
            street: '',
            stateProvince: '',
            city: '',
            postalCode: '',
            fieldOfStudyOptions: processJSONParameter([...dynamicFieldOfStudyDropdownVariant]),
            degreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevel]),
            initialDegreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevel]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
        };
        this.ref = {
            container: React.createRef(),
            form: React.createRef(),
        };
    }

    componentDidMount() {
        let { initialValues } = this.state;
        initialValues = loadCookiesData(initialValues);
        initialValues.street='';
        initialValues.appContext = 'SIMPLEAPPLY';
        initialValues.thankyouPageUrl = '/request-info/apply-thank-you.html';
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress).then();
        if (window.uopxDataLayer) {
          formType = "RFI";
          formName = 'Simple Apply Now';
        }
        const { currentStep } = this.state;
        formStep = currentStep;

        let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }
        fetch(baseUrl + '/services/contacts/v1/validate/zipcode?zipcode=85040', { method: 'HEAD' })
        .then(response => {
            if (response.status === 424){
                disableField = false;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

        const onScroll = () => {
            const footerContainerElm = document.querySelector('.sticky-footer-container');
            const formContainerElm = this.ref.container.current;
            if (!footerContainerElm || !formContainerElm) {
                return;
            }

            const guideContainerTop = formContainerElm.offsetTop;
            const guideContainerBottom = formContainerElm.offsetTop
                + formContainerElm.offsetHeight;
            const screenTop = window.scrollY;
            const screenBottom = window.scrollY + window.innerHeight;
            const isMobileView = window.innerWidth <= 640;

            const isFormNotInTheView = guideContainerTop > screenBottom || guideContainerBottom < screenTop;

            if (isMobileView && isFormNotInTheView) {
                footerContainerElm.setAttribute('style', 'display: block !important');
            } else {
                footerContainerElm.setAttribute('style', 'display: none !important');
            }
        };
        document.addEventListener('scroll', onScroll);
        setTimeout(onScroll, 100);

        if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        dataLayer(); 
    }

    isFieldEnabled(fieldName) {
        if (this.props[`${fieldName}Enabled`]) return true;
        const { fieldsEnabled } = this.props;
        return fieldsEnabled && (fieldsEnabled.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
    }

    isFieldHidden(fieldName) {
        const { fieldsHidden } = this.props;
        return fieldsHidden && (fieldsHidden.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
    }

    getTitleText() {
        const defaultTextHTML = `<h1 class="title-large" role="heading" area-level="1">Great! Let’s begin your application.</h1>`;
        return this.props.title || defaultTextHTML;
    }

    dropdownChange = (name, value) => {
        // Update the component state
        const { initialValues } = this.state;
        //initialValues[name] = value;        
        //this.setState({ initialValues });
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                if(initialValues.fieldOfStudy === "nondegree") {
                    courseCategoryFlag = true;
                }else {
                    courseCategoryFlag = false;
                    this.resetDegreeLevel(value);
                }
                /*if (this.props.theme === 'twostep' && value !== 'nondegree') {
                    this.resetDegreeLevel(value);                                    
                }*/     
            }
        }
        if (name === 'degreeLevel') {
            //this.ref.form.current.touched.degreeLevel = true;
            if ((value && prevDegreeLevel !== value)) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
                initialValues.degreeLevel = value;
                this.validate(initialValues);
            }
        }
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                updatePrePopCookieValue('courseCategory', value);
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            this.ref.form.current.values.zipcode = zipcode;
            this.ref.form.current.values.postalCode = zipcode;
            this.ref.form.current.values.stateProvince = state;
            if (state && prevState !== state) {
                this.updateAndNotify(state);
                prevState = state;
            }
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
    }

    changeStep = (step, e) => {
        if (step === 2) {
            this.handleNextButton();
            return;
        }
        this.setState({ currentStep: step });
        fireAnalyticsEvent('RFIStep'+ step, formName, formType);
	    formStep = this.state.currentStep;
    }

    getAddressInfo(e) {
        const getStreet = async (inputText) => {
            let response = await fetch(
                `/services/contacts/v2/suggest/address?prefix=${encodeURI(inputText)}`,
            );
            const { initialValues } = this.state;
            if (response.status === 200) {
                let responseData = await response.json();
                console.log(responseData);
                const newStreet = responseData?.suggestions[0]?.street_line;
                const newCity = responseData?.suggestions[0]?.city;
                const newState = responseData?.suggestions[0]?.state;
                const newZip = responseData?.suggestions[0]?.zipcode
                const oldStreet = this.state.street;
                // Entering a new address
                if (oldStreet !== newStreet) {
                    this.setState({
                        street: newStreet,
                        city: newCity,
                        stateProvince: newState,
                        postalCode: newZip,
                    });

                    if (initialValues.postalCode !== newZip) {                        
                        //this.zipcodeChange(newZip);
                        initialValues.postalCode = newZip;
                        initialValues.stateProvince = newState;
                        this.setState({ initialValues });
                    }
                    initialValues.address = newStreet;
                    initialValues.city = newCity;
                    this.setState({ initialValues });
                }
            }
            this.validate(initialValues);
        }
        if (e !== null) {
            getStreet(e);
        } else {
            this.setState({
                street: '',
                stateProvince: '',
                city: '',
                postalCode: ''
            });
            formRef.current.values.postalCode = '';
        }
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        switch (name) {
            case 'isMilitary':
                this.ref.form.current.values.isMilitary = value;
                break;
            case 'isRegisteredNurse':
                this.ref.form.current.values.isRegisteredNurse = value;
                this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
                break;
            case 'isScholarship':
                this.ref.form.current.values.isScholarship = value;
                break;
            default:
                break;
        }
    };

    handleAnalyticsRFIFormStart = () => {
        if (this.state.rfi_begin !== true) {
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }

    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel,fosValue,this.ref.form.current.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        //if ((this.ref.form.current.touched['degreeLevel'] === true)) {
        if ((this.ref.form.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
            var optionExists = newDegreeLevelOptions.some((e) => {this.state.initialValues.degreeLevel === e.value;});
            //if prev degree level doesnt exist, reset
            if (!optionExists) this.ref.form.current.setFieldValue('degreeLevel', '')
        }
        this.setState({degreeLevel:newDegreeLevelOptions}); // EX: Bachelors, Individual Courses
    }

    handlePhoneNumberBlur = () => {
        // this.ref.form.current.values.phoneNumber = value;
    }
    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }
    isCBEConsent = (event, checked) => {
        //const { checked } = event.target;
        //this.ref.form.current.values.isCBE = checked;
        this.ref.form.current.values.isCBE = checked;
    }

    handleNextButton = () => {
        const values = { ...this.state.initialValues, ...this.ref.form?.current?.values };
        const errors = this.validate(values);
        this.ref.form.current.setStatus(errors);
        if (Object.values(errors).length === 0
            && this.state.currentStep === 1) {
            this.setState({ currentStep: 2, initialValues: values });
            fireAnalyticsEvent('RFIStep2', formName, formType);
            formStep = this.state.currentStep;
        } else {
            this.ref.form.current.submitForm();
        }
    }

    handleSubmit = formValues => {
        const values = { ...this.state.initialValues, ...formValues };
        if(values.fieldOfStudy === 'nondegree'){
            values.degreeLevel = '';
        } else if (values.fieldOfStudy !== 'nondegree') {            
            values.flex_Course_Category__c = '';
        }
        const errors = this.validate(values);
        if ( this.state.currentStep === 1 && document.activeElement.name === "next") {
	        fireAnalyticsEvent('RFIStep2', formName, formType);
            this.setState({ currentStep: 2, initialValues: values });
	        formStep = this.state.currentStep;
        } else {
        if (((values.fullName).trim().indexOf(' ')) > 0) {
            const i = (values.fullName.trim()).indexOf(' ');
            values.firstName = (values.fullName.trim()).substring(0, i); 
            values.lastName = (values.fullName.trim()).substring(i + 1);
        }
        if (values.street !== ''){
            values.line1 = values.street;
        } 
        if(document.activeElement.name === "submit") {
            this.setState({ initialValues: values });
            submitForm(values, this.props);
        }
      }
    }

    isRegisteredNurseOptionVisible() {
        const { initialValues } = this.state;
        return [
            'healthcare',
            'nursing',
        ].indexOf(initialValues.fieldOfStudy) !== -1;
    }

    isMilitaryOptionVisible() {
        const { initialValues } = this.state;
        return !!initialValues.fieldOfStudy;
        // return [
        //     'technology'
        // ].indexOf(this.state.fieldOfStudy) !== -1
    }

    validateOneStep(values) {
        const errors = {};
        if (!values.highestLevelOfEducation) {
            errors.highestLevelOfEducation = 'Education level requires a value';
        }
        if (!values.fieldOfStudy) {
            errors.fieldOfStudy = 'Area of study requires a value';
        }
        if (values.fieldOfStudy !== 'nondegree'){
            if (!values.degreeLevel) {
                errors.degreeLevel = 'Degree level requires a value';
            }
        }
        if (values.fieldOfStudy === 'nondegree'){
            if (!values.flex_Course_Category__c){
                errors.flex_Course_Category__c = 'Course category requires a value';
            }
        }
        if (this.isRegisteredNurseOptionVisible() && !values.isRegisteredNurse) {
            errors.isRegisteredNurse = 'Registered nurse requires a value';
        }
        return errors;
    }

    validateTwoStep(values) {
        const errors = {};
        /*
        if (!values.fullName) {
            errors.fullName = 'First and Last name requires a value';
        } else if (values.fullName.trim().indexOf(' ') === -1) {
            errors.fullName = 'Please enter a First and Last name';
        }
        if (values.fullName) {
            const check = profanityCheck(values.fullName);
            if (check === false) {
                errors.fullName = 'Name appears invalid';
            }
        }  */
        if (!values.firstName) {
            errors.firstName = 'First name requires a value';
        }
        if (values.firstName.trim() === "") {
            errors.firstName = 'Please enter a First name';
        }
        if (values.firstName && !values.firstName.match(/^[A-Za-z ]*$/)) {
            errors.firstName = 'First name appears invalid';
        }
        if (values.firstName) {
            const check = profanityCheck(values.firstName);
            if (check === false) {
                errors.firstName = 'First name appears invalid';
            }
        }
        if (!values.lastName) {
            errors.lastName = 'Last name requires a value';
        }
        if (values.lastName.trim() === "") {
            errors.lastName = 'Please enter a Last name';
        }
        if (values.lastName && !values.lastName.match(/^[A-Za-z ]*$/)) {
            errors.lastName = 'Last name appears invalid';
        }
        if (values.lastName) {
            const check = profanityCheck(values.lastName);
            if (check === false) {
                errors.lastName = 'Last name appears invalid';
            }
        } 
        if (!values.street) {
            this.state.street?values.street = this.state.street:
            errors.street = 'Address requires a value';
        }
        if (!values.city) {
            this.state.city?values.city = this.state.city:
            errors.city = 'City requires a value';
        }
        if (!values.stateProvince) {
            this.state.stateProvince?values.stateProvince = this.state.stateProvince:
            errors.stateProvince = 'State requires a value';
        }

        if (!values.emailAddress) {
            errors.emailAddress = 'Email address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            errors.emailAddress = 'Email address appears invalid';
        }

        if (!values.phoneNumber) {
            errors.phoneNumber = 'Phone number requires a value';
        }
        if (values.phoneNumber && values.phoneNumber.length !== 10) {
            errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
        }
        if (values.isMilitary === '' || values.isMilitary === undefined) {
            errors.isMilitary = 'U.S. Military affiliation requires a value';
        }

        if (!values.postalCode) {
            this.state.postalCode?values.postalCode = this.state.postalCode:
            errors.zipcode = 'Enter a ZIP code';
        }
        if (values.postalCode && values.postalCode.length !== 5) {
            errors.zipcode = 'ZIP codes must include 5 numeric characters';
        }
        if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
            errors.zipcode = 'ZIP code must be numeric';
        } else if (values.postalCode && values.stateProvince === 'N/A') {
            errors.zipcode = 'ZIP code appears invalid';
        }
        return errors;
    }

    validate(values) {
        const { currentStep } = this.state;
        const errors = {};
        if (currentStep === 1) Object.assign(errors, this.validateOneStep(values));
        else Object.assign(errors, this.validateTwoStep(values));
        console.log('errors', errors);
        return errors;
    }

    renderForm() {
        return (
            <Form
                className="mobile-form fix-notched-outline"
                aria-label="Request For Information"
                style={{ position: 'relative' }}
                onChange={this.handleAnalyticsRFIFormStart}
            >
                {this.renderFormContent()}
                <FormikErrorFocus
                    offset={-50}
                    align="top"
                    focusDelay={200}
                    ease="linear"
                    duration={1000}
                />
            </Form>
        );
    }

    renderFormContent() {
        const { classes } = this.props;
        if (this.state.currentStep === 1 && this.ref.form?.current?.values.fieldOfStudy !== 'nondegree') {
            return (
                <Grid container className="MuiOutlinedInput-noOutline">
                    {this.renderStepFields()}
                    {this.renderHigherEducationLevel()}
                    {this.renderSelectFieldOfStudy()}
                    {this.renderIsRegisteredNurseOption()}             
                    {this.renderSelectDegreeLevel()}                    
                    {/* {this.renderCheckboxIsCBE()} */}
                    {this.renderOptionHasCommunicationConsent()}
                    <div className="legal-text">
                        {this.renderNextButton()}
                    </div>
                </Grid>
            );
        } else if (this.state.currentStep === 1 && this.ref.form?.current?.values.fieldOfStudy === 'nondegree') {
            return (
                <Grid container className="MuiOutlinedInput-noOutline">
                    {this.renderStepFields()}
                    {this.renderHigherEducationLevel()}
                    {this.renderSelectFieldOfStudy()}
                    {this.renderIsRegisteredNurseOption()}                         
                    {this.renderSelectCourseCategory()}                
                    {this.renderOptionHasCommunicationConsent()}
                    <div className="legal-text">
                        {this.renderNextButton()}
                    </div>
                </Grid>
            );
        }
        return (
            <Grid container className="MuiOutlinedInput-noOutline">
                {this.renderStepFields()}
                {/*this.renderInputFullName() */}
                {this.renderInputFirstName() }
                {this.renderInputLastName()}
                {this.renderInputEmail()}
                {this.renderInputStreetAddress()}
                {this.renderInputCity()}
                {this.renderInputState()}
                {this.renderInputZipCode()}
                {this.renderInputPhoneNumber()}
                {this.renderOptionIsMilitary()}
                {/*this.renderCheckboxHasStateConsent() */}
                <div className="legal-text">
                    {this.renderLegalText()}
                    {this.renderSubmitButton()}
                    <a className={classes.backLink} onClick={() => this.setState({ currentStep: 1 })}>
                        &lt; Back
                    </a>
                </div>
            </Grid>
        );
    }

    renderStepFields() {
        const { classes } = this.props;
        const { currentStep } = this.state;
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <div className={classes.stepSelectionContainer}>
                    <div
                        className={classes.stepSelection}
                        tabIndex={0}
                        onClick={() => this.changeStep(1)}
                    >
                        <span
                            className={classes.navCircle + (currentStep === 1 ? ' active' : '')}
                        >
                            1
                        </span>
                        <span className={classes.stepSelectionText}>INTEREST</span>
                    </div>
                    <div
                        className={classes.stepSelection}
                        tabIndex={0}
                        onClick={() => this.changeStep(2)}
                    >
                        <span
                            className={classes.navCircle + (currentStep === 2 ? ' active' : '')}
                        >
                            2
                        </span>
                        <span className={classes.stepSelectionText}>INFORMATION</span>
                    </div>
                </div>
            </Grid>
        );
    }

    renderHigherEducationLevel() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3 className='simpleH3'>
                    What is the highest level of education you achieved?
                </h3>
                <SelectField
                    id="highestLevelOfEducation"
                    name="highestLevelOfEducation"
                    label="Education level *"
                    data={higherEducation}
                    ariaRequired="true"
                    className="MuiFormControl-fullWidth"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('highestLevelOfEducation', e.target.value)}
                />
                {/* {roleChange()} */}
            </Grid>
        );
    }

    renderSelectFieldOfStudy() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('fieldOfStudy')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <h3 className='simpleH3'>
                    Which area of study are you interested in?
                </h3>
                <SelectField
                    id="fieldOfStudy"
                    name="fieldOfStudy"
                    label="Area of study *"
                    data={dynamicFieldOfStudyDropdownVariant}
                    ariaRequired="true"
                    className="MuiFormControl-fullWidth"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('fieldOfStudy', e.target.value)}
                />
                {/* {roleChange()} */}
            </Grid>
        );
    }

    renderIsRegisteredNurseOption() {
        return this.isRegisteredNurseOptionVisible() ? (
            <Grid item xs={12} className="selectRadioLabel margin-InlineForm">
                <MaterialRadio
                    className="selectRadioLabel"
                    value={this.ref.form?.current?.values.isRegisteredNurse}
                    row
                    onChange={this.handleChange}
                    items={radioOption}
                    label="Are you currently a registered nurse? *"
                    name="isRegisteredNurse"
                    ariaLabel="Are you currently a Registered Nurse?"
                />
            </Grid>
        ) : null;
    }

    renderSelectDegreeLevel() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('degreeLevel')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <h3 className='simpleH3'>
                    What degree level are you seeking?
                </h3>
                <SelectField
                    id="degreeLevel"
                    name="degreeLevel"
                    label="Degree level *"
                    data={this.state.degreeLevel}
                    className="MuiFormControl-fullWidth"
                    ariaRequired="true"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('degreeLevel', e.target.value)}
                />
                {roleChange()}
            </Grid>
        );
    }

    renderSelectCourseCategory() {
        const classes = ['margin-InlineForm'];
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <h3 className='simpleH3'>
                    Which course category are you interested in?
                </h3>
                <SelectField
                    id="flex_Course_Category__c"
                    name="flex_Course_Category__c"
                    label="Course category *"
                    data={courseCategoryDropdown}
                    className="MuiFormControl-fullWidth"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange("flex_Course_Category__c", e.target.value )}
                ></SelectField>
                {roleChange()}
            </Grid>
        );
    }

    renderInputFullName() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>
                    Full Name
                </h3>
                <MaterialInput
                    name="fullName"
                    id="fullName"
                    type="text"
                    label="Full Name"
                    ariaRequired="true"
                    ariaLabel="Enter your full name"
                />
            </Grid>
        );
    }

    renderInputFirstName() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>
                    First name
                </h3>
                <MaterialInput
                    name="firstName"
                    id="firstName"
                    type="text"
                    label="First name"
                    ariaRequired="true"
                    ariaLabel="Enter your first name"
                />
            </Grid>
        );
    }
    renderInputLastName() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>
                    Last name
                </h3>
                <MaterialInput
                    name="lastName"
                    id="lastName"
                    type="text"
                    label="Last name"
                    ariaRequired="true"
                    ariaLabel="Enter your last name"
                />
            </Grid>
        );
    }

    renderInputEmail() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>
                    Email address
                </h3>
                <MaterialInput
                    name="emailAddress"
                    id="emailAddress"
                    type="email"
                    label="Email address"
                    ariaRequired="true"
                    ariaLabel="Enter Email address"
                    variant="outlined"
                />
            </Grid>
        );
    }

    renderInputPhoneNumber() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>Phone number</h3>
                <MaterialPhoneNumber
                    name="phoneNumber"
                    id="phoneNumber"
                    label="Phone number"
                    ariaRequired="true"
                    ariaLabel="Enter phone number"
                    handleBlur={this.handlePhoneNumberBlur}
                />
            </Grid>
        );
    }

    renderInputStreetAddress() {
        return (
            <Grid item xs={12} className="margin-InlineForm" style={{ width: 'auto', position:'relative' }}>
                <h3>Street address</h3>                
                {disableField &&     
                    <AddressAutoComplete id='street' name='street' label='Current address' required={true}
                        onChange={addressInfo => this.getAddressInfo(addressInfo)}
                        street={this.state.street}
                        ariaRequired={true}
                    /> 
                }
                {disableField === false && 
                    <MaterialInput
                        name="address"
                        id="address"
                        label="Current address"
                        required={true}
                        ariaLabel="Current Address"
                    >
                    </MaterialInput>
                }
            </Grid>
        );
    }

    renderInputCity() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>City</h3>
                {disableField &&  
                    <MaterialInput
                        name="city"
                        id="city"
                        label="City"
                        required
                        ariaLabel="City"
                        value={this.state.city}
                        disabled={disableField}>
                        {this.value}
                    </MaterialInput> 
                }
                {disableField === false && 
                    <MaterialInput
                        name="city"
                        id="city"
                        label="City"
                        required={true}
                        ariaLabel="City"
                        disabled={disableField}>
                    </MaterialInput>
                }
            </Grid>
        );
    }

    renderInputState() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>State</h3>
                {disableField &&    
                    <MaterialInput 
                        name="stateProvince" 
                        id="stateProvince" 
                        label="State"  
                        ariaLabel="Enter State" 
                        value={this.state.stateProvince}
                        required 
                        disabled={disableField}>
                        {this.value}
                    </MaterialInput> 
                }
                {disableField === false &&    
                    <SelectField
                        id="stateProvince"
                        name="stateProvince"
                        label="State"
                        data={usStates}
                        required={true}
                        disabled={disableField}
                    > 
                    </SelectField>  
                }
            </Grid>
        );
    }

    renderInputZipCode() {
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <h3>ZIP code</h3>
                {disableField &&
                    <MaterialInput 
                        name="zipcode" 
                        id="zipcode" 
                        label="ZIP code "  
                        ariaLabel="Enter Zip code" 
                        value={this.state.postalCode}
                        required 
                        disabled={disableField}
                    >
                    {this.value}
                    </MaterialInput>
                }
                {disableField === false &&
                    <ZipCode value={this.ref.form?.current?.values.zipcode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" aria-describedby="Enter Zip code" ariaLabel="Enter Zip code" handleChange={this.handleZipcodeChange} ></ZipCode>
                }
            </Grid>
        );
    }

    renderInputEducation() {
        if (!this.isFieldEnabled('education')) return null;
        return (
            <Grid item xs={12} className="margin-InlineForm">
                <MaterialInput
                    name="flex_Community_College_University__c"
                    id="flex_Community_College_University__c"
                    type="text"
                    label="College or university"
                    ariaRequired="false"
                    ariaLabel="Enter a college or university name"
                />
            </Grid>
        );
    }

    renderOptionHasCommunicationConsent() {
        if (!this.isFieldEnabled('isScholarship')) return null;
        return (
            <Grid item xs={12} className="selectRadioLabel margin-InlineForm">
                <MaterialRadio
                    className="selectRadioLabel"
                    value={this.ref.form?.current?.values.isScholarship}
                    row
                    onChange={this.handleChange}
                    items={radioOption}
                    label="Would you like to know about scholarships?"
                    name="isScholarship"
                    ariaLabel="Would you like to know about scholarships?"
                />
            </Grid>
        );
    }

    renderOptionIsMilitary() {
        return (
            <Grid item xs={12} className="selectRadioLabel margin-InlineForm">
                <MaterialRadio
                    className="selectRadioLabel"
                    value={this.ref.form?.current?.values.isMilitary}
                    row
                    onChange={this.handleChange}
                    items={radioOption}
                    label="Do you have any U.S. Military affiliation? *"
                    name="isMilitary"
                    ariaLabel="Do you have any U.S. Military affiliation?"
                />
            </Grid>
        );
    }

    /*renderCheckboxHasStateConsent() {
        return (this.state.stateProvince === 'IN'
            ? (
                <Grid item xs={12} className="margin-InlineForm">
                    <FormikCheckbox
                        type="checkbox"
                        id="hasConsent"
                        name="hasConsent"
                        className="disclaimer-checkbox"
                        onChangeCallback={this.handleStateConsent}
                        label="I am an Indiana resident. By clicking this box, I expressly give University of Phoenix authority and permission to call me regarding its educational programs and services at the telephone number I have provided."
                    />
                </Grid>
            ) : null
        );
    } */

    renderCheckboxIsCBE() {
        // console.log("renderCheckboxIsCBE fos and dl values are " + this.ref.form?.current?.values.fieldOfStudy + "  " + this.ref.form?.current?.values.degreeLevel);
        return (conditionalCBE(this.ref.form?.current?.values.fieldOfStudy, this.ref.form?.current?.values.degreeLevel)
            ? (
                <Grid item xs={12} className="margin-InlineForm">
                    <div className='cbe'>
                        <SingleCheckbox
                            className="cbe-checkbox"
                            id="isCBE"
                            label={unescapeHTML(this.getCBEText())}
                            /* ariaLabel={unescapeHTML(this.getCBEText())} */
                            checkboxName="isCBE"
                            parentCallback={this.isCBEConsent}
                            // defaultChecked={initialValues.isCBE}
                            defaultValue={this.ref.form.current.values.isCBE}  
                        />
                    </div>
                </Grid>
            ) : null
        );
    }

    renderLegalText() {
        return (
            <div className="disclaimer">
                {unescapeHTML(disclaimerText)}
            </div>
        );
    }

    renderNextButton() {
        return (
            <Button
                name='next'
                size="large"
                type="button"
                className="btn-submit inline-rfi-btn"
                onClick={e => this.handleNextButton(e)}
            >
                Next step
            </Button>
        );
    }

    renderSubmitButton() {
        return (
            <Button name='submit' size="large" type="submit" className="btn-submit inline-rfi-btn">
                Submit
            </Button>
        );
    }

    render() {
        const { classes } = this.props;
        const {
            initialValues,
        } = this.state;

        return (
            <div>
                <UOPHeader />
                <main className="rfi">
                    <section
                        aria-label="Request For Information"
                        className={classes.root}
                    >
                        <Grid container className="inlineFormGrid" ref={this.ref.container}>
                            <Grid item xs={12}>
                                <div className="margin-InlineForm newOneForm-Eyebrow">UNIVERSITY OF PHOENIX</div>
                                <div className="margin-InlineForm">{unescapeHTML(this.getTitleText())}</div>
                                <div className="margin-InlineForm newOneForm-SubTitle">Our website maintenance doesn’t have to stop your progress. Share a few details for your application, and we’ll contact you to answer questions.</div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={values => this.validate(values)}
                                        innerRef={form => this.ref.form.current = form}
                                    >
                                        {() => this.renderForm()}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </Grid>
                    </section>
                </main>
                <Footer />
            </div>
        );
    }
}

SimpleApplyNow2StepSmarty.defaultProps = {
    classes: {},
    title: null,
    theme: 'default',
    fieldsEnabled: '',
    fieldsHidden: '',
    // fieldOfStudy: 'healthcare'
};

SimpleApplyNow2StepSmarty.propTypes = {
    theme: PropTypes.string,
    title: PropTypes.string,
    classes: PropTypes.instanceOf(Object),
    fieldsEnabled: PropTypes.string,
    fieldsHidden: PropTypes.string,
};

const SimpleApplyNow2StepSmartyStyled = withStyles(styles)(SimpleApplyNow2StepSmarty);

const generateClassName = createGenerateClassName({
    productionPrefix: 'simpleApplyRFI',
});

export default function (props) {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <SimpleApplyNow2StepSmartyStyled {...props} />
        </StylesProvider>
    );
}
