/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createGenerateClassName, StylesProvider, withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import Box from '@mui/material/Box';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues';
import MaterialRadio from '../layout/MaterialComponents/MaterialRadio';
import styles from '../layout/styles/Employer-Inline-Styles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import { unescapeHTML, addDropDownOption, processJSONParameter, initValues} from '../common/configTools';
import getIPAddress, {
    getDegreeLevel,
    conditionalCBE,
    loadCookiesData,
    profanityCheck,
    submitForm,
    updatePrePopCookieValue,
    fireAnalyticsEvent, fireFOS_DlEvent,
} from '../CommonComponents';
import { degreeLevelVariant as degreeLevelOptions, dynamicFieldOfStudyDropdownVariant as fieldOfStudyOptions, radioOption, courseCategoryDropdown } from '../common/constants';
import Button from '../layout/MaterialComponents/button/Button';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';

let prevFOS;
let prevDegreeLevel;
let prevCourseCategory;
let prevState;
let courseCategoryFlag = false;
const disclaimerText = rfiFormInitialValues.disclaimer;

// Allow unescaped HTML
class White2Step extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      initialValues: rfiFormInitialValues,
      thankyouPageUrl: '/request-info/thank-you.html',
      showIntlText: 'US',
      currentStep: 1,
      activeStep:0,
      fieldOfStudyOptions: processJSONParameter(props.fieldOfStudyOptions || [...fieldOfStudyOptions]),
      degreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelOptions]),
      initialDegreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelOptions]),
      courseCategory: processJSONParameter([...courseCategoryDropdown]),
      areStepsDisabled: true,
    };
    this.ref = {
      container: React.createRef(),
      form: React.createRef(),
    };
  }
/*
  initValues(initialValues) {
    const { props } = this;
    // Load initial values from properties.
    const formNames = {
      education: 'flex_Community_College_University__c',
      infoRequested: 'flex_Info_Requested__c',
    };
    if (props.initialValues) {
      const overrideInitialValues = processJSONParameter(props.initialValues);
      Object.assign(initialValues, overrideInitialValues);
    }
    for (const fieldName of ['fieldOfStudy', 'isRegisteredNurse', 'degreeLevel', 'postalCode', 'thankyouPageUrl', 'education', 'infoRequested', 'code',]) {
      const value = props[fieldName];
      const formFieldName = formNames[fieldName] || fieldName;
      if (value && ['number', 'string'].includes(typeof value)) {
        initialValues[formFieldName] = value;
        // this.dropdownChange(fieldName, value);
      }
    }

    addDropDownOption(initialValues.fieldOfStudy, this.state.fieldOfStudyOptions);
    addDropDownOption(initialValues.degreeLevel, this.state.degreeLevel);
  }
  */
  componentDidMount() {
    let { initialValues } = this.state;
    initialValues = loadCookiesData(initialValues);
    prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
    prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
    this.handleZipCodeChange(initialValues.postalCode, initialValues.stateProvince);
    initValues(initialValues, this.props);
    addDropDownOption(initialValues.fieldOfStudy, this.state.fieldOfStudyOptions);
    addDropDownOption(initialValues.degreeLevel, this.state.degreeLevel);
    this.setState({ initialValues });
    getIPAddress(this.updateIPAddress).then();
    const { currentStep } = this.state;
    formStep = this.state.currentStep;

    if (window.uopxDataLayer) {
      formType = "RFI";
      switch (this.props.theme) {
        case 'twostep':
          formName = 'RFI inline - 2 step';
          break;
        default:
          formName = 'RFI inline - 1 step';
          break;
      }
    }
    if (window.pageData && window.pagePath) {
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
    }
    

    const onScroll = () => {
      const footerContainerElm = document.querySelector('.sticky-footer-container');
      const formContainerElm = this.ref.container.current;
      if (!footerContainerElm) {
        return;
      }
      const guideContainerTop = formContainerElm.offsetTop;
      const guideContainerBottom = formContainerElm.offsetTop
                + formContainerElm.offsetHeight;
      const screenTop = window.scrollY;
      const screenBottom = window.scrollY + window.innerHeight;
      const isMobileView = window.innerWidth <= 640;
      const isFormNotInTheView = guideContainerTop > screenBottom || guideContainerBottom < screenTop;

      if (isMobileView && isFormNotInTheView) {
        footerContainerElm.setAttribute('style', 'display: block !important');
      } else {
        footerContainerElm.setAttribute('style', 'display: none !important');
      }
    };
    document.addEventListener('scroll', onScroll);
    setTimeout(onScroll, 100);

    if (this.state.RFIStep1 !== true) {
      setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
      this.setState({ RFIStep1: true });
    //  console.log ("RFIStep1 event fired from white2Step");
    } 
  }
  isFieldEnabled(fieldName) {
    if (this.props[`${fieldName}Enabled`]) return true;
    let { fieldsEnabled } = this.props;
    switch (this.props.theme) {
      default:
        break;
      case 'twostep':
        // Default two-step form with scholarship question
        if (!fieldsEnabled) fieldsEnabled = 'isScholarship';
        break;
    }
    return fieldsEnabled && (fieldsEnabled.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
  }

  isFieldHidden(fieldName) {
    const { fieldsHidden } = this.props;
    return fieldsHidden && (fieldsHidden.split(',').map(f => f.trim()).indexOf(fieldName) !== -1);
  }

  getTitleText() {
    let defaultTextHTML;
    const defaultIntlTextTitle = "<a class='btn-right-arrow' href='https://www.phoenix.edu/request/international-student'>International students start here <span class='right-arrow'></span></a>";
    switch (this.props.theme) {
      default:
        defaultTextHTML = "<h2 class='title-small'>Let's talk about all the ways you could save time and money on your degree.</h2>";
        break;
      case 'twostep':
        defaultTextHTML = "<h2 style='color: #0D819C; font-weight:700; font-size: 24px; line-height: 30px;'>Big dreams? Let's answer your questions and help you achieve them.</h2>";
        break;
    }
    let titleText = this.props.title || defaultTextHTML;
        const showIntlText = localStorage.getItem('country');
                if (this.state.showIntlText !== showIntlText) {
      // International Zip Code:
      titleText += this.props.intlTitle || defaultIntlTextTitle;
    }
    return titleText;
  }

  getDescriptionText() {
      let descriptionText = this.props.description || '';
      return descriptionText;

      }

    dropdownChange = (name, value) => {
        // Update the component state
        const { initialValues } = this.state;
        //initialValues[name] = value;        
        //this.setState({ initialValues });
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                if(initialValues.fieldOfStudy === "nondegree") {
                    courseCategoryFlag = true;
                }else {
                    courseCategoryFlag = false;
                    this.resetDegreeLevel(value);
                }
                /*if (this.props.theme === 'twostep' && value !== 'nondegree') {
                    this.resetDegreeLevel(value);                                    
                }*/     
            }
        }
        if (name === 'degreeLevel') {
            //this.ref.form.current.touched.degreeLevel = true;
            if ((value && prevDegreeLevel !== value)) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
                initialValues.degreeLevel = value;
                this.validate(initialValues);
            }
        }
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    changeStep = (step, e)=> {
        if (step === 2) {
            this.handleNextButton();
            return;
        }
        this.setState({ currentStep: step });
        fireAnalyticsEvent('RFIStep' + step, formName, formType);
        formStep = this.state.currentStep;
    }

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
            initialValues: this.ref.form.current.values
        });
        fireAnalyticsEvent('RFIStepBack', formName, formType);
        scrollToTop();
        this.focusStep.current.focus();
    };

    handleChange = e => {
      const { name, value, checked } = e.target;
      switch (name) {
        case 'isMilitary':
          this.ref.form.current.values.isMilitary = value;
          break;
        case 'isRegisteredNurse':
          this.ref.form.current.values.isRegisteredNurse = value;
          this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
          break;
        case 'isScholarship':
          this.ref.form.current.values.isScholarship = value;
          break;
        case 'isCBE':
          this.ref.form.current.values.isCBE = checked;
          break;
        default:
          break;
      }
    };

    handleAnalyticsRFIFormStart = () => {
      if (this.state.rfi_begin !== true) {
        fireAnalyticsEvent('RFIStart', formName, formType);
        this.setState({ rfi_begin: true });
      }
    }

    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel, fosValue, this.ref.form.current.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        //if ((this.ref.form.current.touched['degreeLevel'] === true)) {
        if ((this.ref.form.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
            var optionExists = newDegreeLevelOptions.some((e) => {this.state.initialValues.degreeLevel === e.value;});
            //if prev degree level doesnt exist, reset
            if (!optionExists) this.ref.form.current.setFieldValue('degreeLevel', '')
        }
        this.setState({ degreeLevel: newDegreeLevelOptions }); // EX: Bachelors, Individual Courses
    }

    handleZipCodeChange = (zipcode, state) => {
      this.ref.form.current.values.postalCode = zipcode;
      this.ref.form.current.values.stateProvince = state;
      updatePrePopCookieValue('zipcode', zipcode);
      updatePrePopCookieValue('state', state);

        if (state && prevState !== state) {  
            prevState = state;
        }
        this.updateAndNotify(state);
    }

    updateAndNotify = state => {
      this.setState({ stateProvince: state });
    }

    handlePhoneNumberBlur = () => {
      // this.ref.form.current.values.phoneNumber = value;
    }

    /* handleStateConsent = event => {
      const { checked } = event.target;
      this.ref.form.current.values.hasConsent = checked;
    } */
    getCBEText() {
      let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
      return cbeText;
    }

    isCBEConsent = (event, checked) => {
      //const { checked } = event.target;
      //this.ref.form.current.values.isCBE = checked;
      this.ref.form.current.values.isCBE = checked;
    }

    handleNextButton = () => {
      const values = { ...this.state.initialValues, ...this.ref.form?.current?.values };
      const errors = this.validate(values);
      this.ref.form.current.setStatus(errors);
      if (Object.values(errors).length === 0
            && this.props.theme === 'twostep'
            && this.state.currentStep === 1) {
        this.setState({ currentStep: 2, initialValues: values });
        fireAnalyticsEvent('RFIStep2', formName, formType);
        formStep = this.state.currentStep;
      } else {
        this.ref.form.current.submitForm();
      }
    }

    handleSubmit = formValues => {
      const values = { ...this.state.initialValues, ...formValues };
        if(values.fieldOfStudy === 'nondegree'){
            values.degreeLevel = '';
        } else if (values.fieldOfStudy !== 'nondegree') {            
            values.flex_Course_Category__c = '';
        }
        console.log(values);
        const errors = this.validate(values);
        this.ref.form.current.setStatus(errors);
        if ((this.props.theme === 'twostep')
            && this.state.currentStep === 1 && document.activeElement.name === "next") {                 
                    fireAnalyticsEvent('RFIStep2', formName, formType);
                    this.setState({ currentStep: 2, initialValues: values });
                    formStep = this.state.currentStep;
                    setTimeout(function(){document.querySelector('#fullName').focus()}, 300);                
        } else {
            if (((values.fullName).trim().indexOf(' ')) > 0) {
              const i = (values.fullName.trim()).indexOf(' ');
              values.firstName = (values.fullName.trim()).substring(0, i); 
              values.lastName = (values.fullName.trim()).substring(i + 1);
            }
            if (document.activeElement.name === "submit") {
                this.setState({ initialValues: values });
                //    values = mapAdditionalFields(values);
                //submitForm(values);
                submitForm(values, this.props);
            }
        }
    }

    isRegisteredNurseOptionVisible() {
      const { initialValues } = this.state;
      return [
        'healthcare',
        'nursing',
      ].indexOf(initialValues.fieldOfStudy) !== -1;
    }

    isMilitaryOptionVisible() {
      const { initialValues } = this.state;
      return !!initialValues.fieldOfStudy;
      // return [
      //     'technology'
      // ].indexOf(this.state.fieldOfStudy) !== -1
    }

    validateOneStep(values) {
        const errors = {};
        if (!values.fieldOfStudy) {
            errors.fieldOfStudy = 'Area of study requires a value';
        }
        if (values.fieldOfStudy !== 'nondegree'){
            if (!values.degreeLevel) {
                errors.degreeLevel = 'Degree level requires a value';
            }
        }
        if (values.fieldOfStudy === 'nondegree'){
            if (!values.flex_Course_Category__c){
                errors.flex_Course_Category__c = 'Course category requires a value';
            }
        }
        if (this.isRegisteredNurseOptionVisible() && !values.isRegisteredNurse) {
            errors.isRegisteredNurse = 'Registered nurse requires a value';
        }
        if (!values.postalCode) {
            errors.zipcode = 'Enter a ZIP code';
        }
        if (values.postalCode && values.postalCode.length !== 5) {
            errors.zipcode = 'ZIP codes must include 5 numeric characters';
        }
        if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
            errors.zipcode = 'ZIP code must be numeric';
        } else if (values.postalCode && values.stateProvince === 'N/A') {
            errors.zipcode = 'ZIP code appears invalid';
        }
        if (values.flex_Community_College_University__c) {
            const check = profanityCheck(values.flex_Community_College_University__c);
            if (check === false) {
                errors.flex_Community_College_University__c = 'Education appears invalid';
            }
        }
        return errors;
    }

    validateTwoStep(values) {
        const errors = {};
        if (!values.fullName) {
            errors.fullName = 'First and last name requires a value';
        } else if (values.fullName.trim().indexOf(' ') === -1) {
            errors.fullName = 'Please enter a First and last name';
        }
        if (values.fullName) {
            const check = profanityCheck(values.fullName);
            if (check === false) {
                errors.fullName = 'Name appears invalid';
            }
        }

        if (!values.emailAddress) {
            errors.emailAddress = 'Email address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            errors.emailAddress = 'Email address appears invalid';
        }

        if (!values.phoneNumber) {
            errors.phoneNumber = 'Phone number requires a value';
        }
        if (values.phoneNumber && values.phoneNumber.length !== 10) {
            errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
        }
        if (values.isMilitary === '' || values.isMilitary === undefined) {
            errors.isMilitary = 'U.S. Military affiliation requires a value';
        }

      return errors;
    }

    validate(values) {
      const { currentStep } = this.state;
      const { theme } = this.props;
      const errors = {};
      switch (theme) {
        case 'twostep':
          if (currentStep === 1) Object.assign(errors, this.validateOneStep(values));
          else Object.assign(errors, this.validateTwoStep(values));
          break;

        default:
          Object.assign(errors, this.validateOneStep(values));
          Object.assign(errors, this.validateTwoStep(values));
          break;
      }
      if (Object.keys(errors).length === 0) {
        this.setState({ areStepsDisabled: false }, console.log(this.state.areStepsDisabled));
      };
      return errors;
    }

    render() {
      const { classes } = this.props;
      const {
        initialValues,
      } = this.state;

      return (
        <div className="inlineFormContainer">
          <section
            aria-label="Request For Information"
            className={`${classes.root} businessGuideInlineFormGrid`}
          >
            <Box>
              <Grid container className="inlineFormGrid" ref={this.ref.container}>
                <Grid item xs={12}>
                  <div className={classes.inlineFormTitle}>
                  {this.state.currentStep === 1 ?
                    unescapeHTML(this.getTitleText()) : 
                    <h2 style={{ color: "#0D819C", fontWeight: "700", fontSize: "24px", lineHeight: "30px"}}>
                      Let's talk about ways to save time and money.
                    </h2>
                  }
                  </div>
                  <div className={classes.inlineFormTitle}>
                   {this.state.currentStep === 1 ?
                    unescapeHTML(this.getDescriptionText()) : ''}
                    </div>

                </Grid>
                <Grid item xs={12}>
                  <Grid>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={this.handleSubmit}
                      validate={values => this.validate(values)}
                      innerRef={form => this.ref.form.current = form}
                    >
                      {() => this.renderForm()}
                    </Formik>
                  </Grid>
                </Grid>
              </Grid>

            </Box>
          </section>
          {/*    <Footer />  */}
        </div>
      );
    }

    renderForm() {
      return (
        <Form
          className="mobile-form fix-notched-outline"
          aria-label="Request For Information"
          style={{ position: 'relative' }}
          onChange={this.handleAnalyticsRFIFormStart}
        >
          {this.renderFormContent()}
          <FormikErrorFocus
            offset={-50}
            align="top"
            focusDelay={200}
            ease="linear"
            duration={1000}
          />
        </Form>
      );
    }

    renderFormContent() {
      const { theme } = this.props;
      const { classes } = this.props;
      switch (theme) {
        default:
          return (
            <Grid container className="MuiOutlinedInput-noOutline">
              {this.renderSelectFieldOfStudy()}
              {this.renderIsRegisteredNurseOption()}
              {this.renderSelectDegreeLevel()}
              {this.renderInputFullName()}
              {this.renderInputEmail()}
              {this.renderInputPhoneNumber()}
              {this.renderInputZipCode()}
              {this.renderInputEducation()}
              {this.renderOptionHasCommunicationConsent()}
              {this.renderOptionIsMilitary()}
              {this.renderCheckboxIsCBE()}
              {/*this.renderCheckboxHasStateConsent() */}
              <div className="legal-text">
                {this.renderLegalText()}
                {this.renderSubmitButton()}
              </div>
            </Grid>
          );
        case 'twostep':
          if (this.state.currentStep === 1) {
                    if(this.state.initialValues.fieldOfStudy === 'nondegree'){
                        return (
                            <Grid container className="MuiOutlinedInput-noOutline">
                                {this.renderStepFields()}
                                {this.renderSelectFieldOfStudy()}
                                {this.renderIsRegisteredNurseOption()} 
                                {this.renderCourseCategory()}                                                   
                                {this.renderInputZipCode()}
                                {this.renderInputEducation()}                            
                                {this.renderCheckboxIsCBE()}
                                <div className="legal-text">
                                    {this.renderNextButton()}
                                </div>
                            </Grid>
                        );
                    }
                    else {
            return (
              <Grid container className="MuiOutlinedInput-noOutline">
                {this.renderStepFields()}
                {this.renderSelectFieldOfStudy()}
                {this.renderIsRegisteredNurseOption()}
                {this.renderSelectDegreeLevel()}
                {this.renderInputZipCode()}
                {this.renderInputEducation()}
                {this.renderOptionHasCommunicationConsent()}
                {this.renderCheckboxIsCBE()}
                <div className="legal-text">
                  {this.renderNextButton()}
                </div>
              </Grid>
            );
          }
	}
          return (
            <Grid container className="MuiOutlinedInput-noOutline">
              {this.renderStepFields()}
              {this.renderInputFullName()}
              {this.renderInputEmail()}
              {this.renderInputPhoneNumber()}
              {this.renderInputEmployerName()}
              {this.renderOptionIsMilitary()}
              {/*this.renderCheckboxHasStateConsent()*/}
              <div className="legal-text">
                {this.renderLegalText()}
                {this.renderSubmitButton()}
                <button style={{background:"#FFF", border:"none"}} className={classes.backLink + ' redesign-link'} onClick={() => this.setState({ currentStep: 1 })}>
                  &lt; Back
                </button>
              </div>
            </Grid>
          );
      }
    }

    renderStepFields() {
      const { classes } = this.props;
      const { currentStep } = this.state;
      return (
        <Grid item xs={12} className="margin-InlineForm">
          <div className={classes.stepSelectionContainer}>
            <div style={this.state.areStepsDisabled ? { fontWeight: 700, cursor: "auto" } : { fontWeight: 700 }}
              disabled={this.state.areStepsDisabled}
              aria-disabled={this.state.areStepsDisabled}
              className={classes.stepSelection}
              tabIndex={0}
              onClick={() => this.changeStep(1)}
                        onKeyDown={(e) => {if (e.keyCode === 13 || e.keyCode === 32)
                            this.changeStep(1, e);
                        }}
            >
              <span className={classes.navCircle + (currentStep === 1 ? ' active' : '')}> 1 </span>
              <span className={classes.stepSelectionText + (currentStep === 1 ? ' active ' : '')}>Area of Interest</span>
            </div>
            <div
              style={this.state.areStepsDisabled ? { fontWeight: 700, cursor: "auto" } : { fontWeight: 700 }}
              disabled={this.state.areStepsDisabled}
              aria-disabled={this.state.areStepsDisabled}
              className={classes.stepSelection}
              tabIndex={(currentStep === 2 ? '0' : '-1')}
              onClick={() => this.changeStep(2)}
                        onKeyDown={(e) => {if (e.keyCode === 13 || e.keyCode === 32)
                            this.changeStep(2, e);
                        }}
            >
              <span style = {this.state.areStepsDisabled && currentStep === 1?{color: "#5E7079"}: {color: "#FFF"}}
                className={classes.navCircle + (currentStep === 2 ? ' active' : '')}> 2 </span>
              <span style = {this.state.areStepsDisabled && currentStep === 1?{color: "#5E7079"}: {color: "#8C8C8C"}}
                className={classes.stepSelectionText + (currentStep === 2 ? ' active ' : '')}>Contact Info</span>
            </div>
          </div>
        </Grid>
      );
    }

    renderSelectFieldOfStudy() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('fieldOfStudy')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <SelectField
                    id="fieldOfStudy"
                    name="fieldOfStudy"
                    label="Area of study"
                    ariaLabel="select Area of study option"
                    data={this.state.fieldOfStudyOptions}
                    className="MuiFormControl-fullWidth"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('fieldOfStudy', e.target.value)}
                />
            </Grid>
        );
    }

    renderIsRegisteredNurseOption() {
      const classes = ['selectRadioLabel margin-InlineForm '];
      if (this.isFieldHidden('isRegisteredNurse')) classes.push('hidden');
      return this.isRegisteredNurseOptionVisible() ? (
        <Grid item xs={12} className={classes.join(' ')}>
          <MaterialRadio
            className="selectRadioLabel-bold"
            value={this.ref.form?.current?.values.isRegisteredNurse}
            row
            onChange={this.handleChange}
            items={radioOption}
            label="Are you currently a registered nurse? *"
            name="isRegisteredNurse"
            ariaLabel="Are you currently a Registered Nurse?"
          />
        </Grid>
      ) : null;
    }

    renderSelectDegreeLevel() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('degreeLevel')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <SelectField
                    id="degreeLevel"
                    name="degreeLevel"
                    label="Degree level"
                    ariaLabel="select Degree level option"
                    data={this.state.degreeLevel}
                    className="MuiFormControl-fullWidth"
                    ariaRequired="true"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('degreeLevel', e.target.value)}
                />
            </Grid>
        );
    }

    renderCourseCategory() {
        const classes = ['margin-InlineForm'];
        if (this.isFieldHidden('flex_Course_Category__c')) classes.push('hidden');
        return (
            <Grid item xs={12} className={classes.join(' ')}>
                <SelectField
                    id="flex_Course_Category__c"
                    name="flex_Course_Category__c"
                    label="Course category"
                    data={this.state.courseCategory}
                    className="MuiFormControl-fullWidth"
                    ariaRequired="true"
                    formRef={this.ref.form}
                    onClick={this.handleAnalyticsRFIFormStart}
                    onChange={e => this.dropdownChange('flex_Course_Category__c', e.target.value)}
          />
        </Grid>
      );
    }

    renderInputFullName() {
      return (
        <Grid item xs={12} className="margin-InlineForm">
          <MaterialInput
            name="fullName"
            id="fullName"
            type="text"
            label="First and last name"
            ariaRequired="true"
            ariaLabel="Enter your full name"
          />
        </Grid>
      );
    }

    renderInputEmail() {
      return (
        <Grid item xs={12} className="margin-InlineForm">
          <MaterialInput
            name="emailAddress"
            id="emailAddress"
            type="email"
            label="Email address"
            ariaRequired="true"
            ariaLabel="Enter Email address"
            variant="outlined"
          />
        </Grid>
      );
    }

    renderInputPhoneNumber() {
      return (
        <Grid item xs={12} className="margin-InlineForm">
          <MaterialPhoneNumber
            name="phoneNumber"
            id="phoneNumber"
            label="Phone number"
            ariaRequired="true"
            ariaLabel="Enter phone number"
            handleBlur={this.handlePhoneNumberBlur}
          />
        </Grid>
      );
    }

    renderInputZipCode() {
      return (
        <Grid item xs={12} className="margin-InlineForm">
          <ZipCode
            value={this.ref.form?.current?.values.postalCode}
            type="zipcode"
            id="zipcode"
            label="ZIP code"
            ariaLabel="Enter Zip code"
            ariaRequired="true"
            handleChange={this.handleZipCodeChange}
            formRef={this.ref.form}
          />
        </Grid>
      );
    }

    renderInputEducation() {
      if (!this.isFieldEnabled('education')) return null;
      return (
        <Grid item xs={12} className="margin-InlineForm">
          <MaterialInput
            name="flex_Community_College_University__c"
            id="flex_Community_College_University__c"
            type="text"
            label="College or university"
            ariaRequired="false"
            ariaLabel="Enter a college or university name"
          />
        </Grid>
      );
    }

    renderInputEmployerName() {
      return (
        <Grid item xs={12} className="margin-InlineForm hidden">
          <div style={{ fontWeight: "700",}}>Interested in learning about a tuition discount through your employer?</div>
          <MaterialInput
            name="flex_Employer_Disclosed_on_RFI__c"
            id="flex_Employer_Disclosed_on_RFI__c"
            type="text"
            label="Employer name"
            ariaRequired="false"
            ariaLabel="Employer name"
          />
        </Grid>
      );
    }

    renderOptionHasCommunicationConsent() {
      if (!this.isFieldEnabled('isScholarship')) return null;
      return (
        <Grid item xs={12} className="selectRadioLabel margin-InlineForm">
          <MaterialRadio
            className="selectRadioLabel-bold"
            value={this.ref.form?.current?.values.isScholarship}
            row
            onChange={this.handleChange}
            items={radioOption}
            label="Would you like to know about scholarships?"
            name="isScholarship"
            ariaLabel="Would you like to know about scholarships?"
          />
        </Grid>
      );
    }

    renderOptionIsMilitary() {
      return (
        <Grid item xs={12} className="selectRadioLabel margin-InlineForm">
          <MaterialRadio
            className="selectRadioLabel-bold"
            value={this.ref.form?.current?.values.isMilitary}
            row
            onChange={this.handleChange}
            items={radioOption}
            label="Do you have any U.S. Military affiliation? *"
            name="isMilitary"
            ariaLabel="Do you have any U.S. Military affiliation?"
          />
        </Grid>
      );
    }

  /*  renderCheckboxHasStateConsent() {
      return (this.state.stateProvince === 'IN'
        ? (
          <Grid item xs={12} className="margin-InlineForm">
            <FormikCheckbox
              type="checkbox"
              id="hasConsent"
              name="hasConsent"
              className="disclaimer-checkbox"
              onChangeCallback={this.handleStateConsent}
              label="I am an Indiana resident. By clicking this box, I expressly give University of Phoenix authority and permission to call me regarding its educational programs and services at the telephone number I have provided."
            />
          </Grid>
        ) : null
      );
    } */

    renderCheckboxIsCBE() {
      // console.log("renderCheckboxIsCBE fos and dl values are " + this.ref.form?.current?.values.fieldOfStudy + "  " + this.ref.form?.current?.values.degreeLevel);
      return (conditionalCBE(this.ref.form?.current?.values.fieldOfStudy, this.ref.form?.current?.values.degreeLevel)
              || this.isFieldEnabled('isCBE')
        ? (
          <Grid item xs={12} className="margin-InlineForm">
            <div className='cbe'>
            {/*<FormikCheckbox
              type="checkbox"
              id="isCBE"
              name="isCBE"
              className="cbe-checkbox"
              onChangeCallback={this.handleChange}
              label={(
                <div>
                  I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>
                </div>
              )}
            /> */}
            <SingleCheckbox
              className="cbe-checkbox"
              id="isCBE"
              label={unescapeHTML(this.getCBEText())}
              /* ariaLabel={unescapeHTML(this.getCBEText())} */
              checkboxName="isCBE"
              parentCallback={this.isCBEConsent}
              // defaultChecked={initialValues.isCBE}
              defaultValue={this.ref.form.current.values.isCBE}  
            />
            </div>
          </Grid>
        ) : null
      );
    }

    renderLegalText() {
      return (
        <div className="disclaimer">
          {unescapeHTML(disclaimerText)}
        </div>
      );
    }

    renderNextButton() {
        return (
            <Button
                name='next'
                size="large"
                type="button"
                className="btn-submit inline-rfi-btn"
                onClick={e => this.handleNextButton(e)}
            >
                Next step
            </Button>
        );
    }

    renderSubmitButton() {
        return (
            <Button name='submit' size="large" type="submit" className="btn-submit inline-rfi-btn">
                Request information
            </Button>
        );
    }
}

White2Step.defaultProps = {
  classes: {},
  title: null,
  description: null,
  theme: 'default',
  fieldsEnabled: '',
  fieldsHidden: '',
  fieldOfStudyOptions: null,
  degreeLevelOptions: null,
  // fieldOfStudy: 'healthcare'
};

White2Step.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  classes: PropTypes.instanceOf(Object),
  fieldsEnabled: PropTypes.string,
  fieldsHidden: PropTypes.string,
  fieldOfStudyOptions: PropTypes.string,
  degreeLevelOptions: PropTypes.string,
};

const White2StepStyled = withStyles(styles)(White2Step);

const generateClassName = createGenerateClassName({
  productionPrefix: 'inlineRFI',
});

export default function (props) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <White2StepStyled {...props} />
    </StylesProvider>
  );
}
