import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
//import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
//import { PhoenixLuminaryValidate } from "./EmailusValidation";
import getIPAddress, { unescapeHTML, roleChange, pageNameByPath, fireAnalyticsEvent, fireNonRFISubmitEvent, profanityCheck, srSpeak} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus'
import axios from 'axios';
//import checkmark from '../layout/images/checkmark.svg';
import Button from '../layout/MaterialComponents/button/Button';
import MaterialRadio from '../layout/MaterialComponents/MaterialRadio';
import { radioOption } from '../common/constants';
//import { initValues } from '../common/configTools';

const initialPhoenixLuminaryValues = {
    yourfirstName: '',
    yourlastName: '',
    yourfullName: '',
    youremailAddress: '',
    //yourphoneNumber:'',
    relationshipWithAlumni:'',
    nomineefirstName: '',
    nomineelastName: '',
    nomineefullName: '',
    nomineeemailAddress: '',
    //nomineephoneNumber:'',
    nomineeCompanyName:'',
    nomineeLinkedInURL:'',
    accomplishments:'',
    isNominatingYourself:'',
    moreInfo: '',
    degreeLevel:'',
    requestingUrl: '',
    disclaimer:'By providing your personal information or the personal information of another nominee, you understand that we may contact you or your nominee for purposes of administering this recognition program. Further, if you provide the personal information of another person, you understand and agree that we will share your contact information with them. Any nomination, whether of yourself or someone else, does not guarantee acceptance of the nomination or result in recognition. All decisions regarding acceptance and recognition are in the sole discretion of the University. Nominees do not need to attend the event to be recognized. Please see our <a href="https://www.phoenix.edu/copyright-legal/privacy-policy.html">Privacy Policy</a> for more information on how we collect and use your personal information.',
    formName: 'PhoenixLuminary',
    thankyouPageUrl: '/request-info/thankyou-luminaries.html',
};

const formRef = {};
let prevDegreeLevel;
//let defaultTextHTML = "<h1>Email us</h1>";
const disclaimerText = initialPhoenixLuminaryValues.disclaimer;
const isMobileView = window.innerWidth <= 640;
let formStep;
let formType;
let formName;
let formLocation;
let isSelf, accomplishmentsCopy, moreInfoCopy;
let secondaryTitle, secondaryDesc;

// let headerDesktop = '<h2 id="dialogTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 28px; font-weight: 700; line-height: 40px; display:flex; align-items:left; justify-content: left; margin-top:0;">Nominate a UOPX Luminary</h2>';
// let descriptionDesktop = '<div id="dialogDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">Do you know a University of Phoenix graduate who should be honored? Before you tell us their story, tell us a bit about you.</div>';
// let headerMobile = '<h2 id="dialogTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 21px; font-weight: 700; line-height: 28px; display:flex; align-items:left; justify-content: left; margin-top:0;">Nominate a UOPX Luminary</h2>';
// let descriptionMobile = '<div id="dialogDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">Do you know a University of Phoenix graduate who should be honored? Before you tell us their story, tell us a bit about you.</div>';
// let thankyouMessageTitleMobile = '<h2 id="thanksdialogtitle" class="mainHeader" style="font-size: 21px; font-weight: 700; line-height: 24px; display:flex; align-items:center; justify-content: left; text-align:center; margin-top:0;">Thank you!</h2>';
// let thankyouMessageTitleDesktop = '<h2 id="thanksdialogtitle" class="mainHeader" style="font-size: 27px; font-weight: 700; line-height: 32px; display:flex; align-items:center; justify-content: left; text-align:center; margin-top:0;">Thank you!</h2>';
// let thankyouMessageDescriptionMobile = `<div id="thanksdialogdesc" class="newOneForm-SubTitle thanks" style="font-size: 16px; line-height: 21px; display:flex; align-items:center; justify-content: left; margin-top:0;">You're helping to make our alumni community stronger.</div>`;
// let thankyouMessageDescriptionDesktop = `<div id="thanksdialogdesc" class="newOneForm-SubTitle thanks" style="font-size: 18px; line-height: 22px; display:flex; align-items:center; justify-content: left; margin-top:0;">You're helping to make our alumni community stronger.</div>`;

let headerDesktop, descriptionDesktop, headerMobile, descriptionMobile, thankyouMessageTitleMobile, thankyouMessageTitleDesktop, thankyouMessageDescriptionMobile, thankyouMessageDescriptionDesktop;

function generateYearsBetween(startYear = 1976, endYear) {
    let endDate = endYear || new Date().getFullYear();
    let years = [];
    for (var i = endDate; i >= startYear; i--) {
      years.push(endDate);
      endDate--;
    }
    return years;
}

const yearsArray = generateYearsBetween();
const yearOpts = yearsArray.map((year) => {
    return ( { label: year, value: year} );
});

const onTextAreaChange= (e) => {
    formRef.current.values.flex_more_info__c = e.target.value;
}

const degreeLevel = [
    { label: "Associate", value: "Associate" },
    { label: "Bachelors", value: "Bachelors" },
    { label: "Masters", value: "Masters" },
    { label: "Doctorate", value: "Doctorate" },
]; 

class PhoenixLuminary extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            isNominatingYourselfRadio:'',
            initialValues: initialPhoenixLuminaryValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
    //    document.title = `Re-entry contact-us`;
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href;
        /*prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : 'Associate';
        if (prevDegreeLevel === 'Associate') {
            formRef.current.values.degreeLevel = prevDegreeLevel;
        } */
    //    initValues(initialValues, this.props);
        this.setState({ initialValues });
    //    getIPAddress(this.updateIPAddress);
        const { currentStep } = this.state;
        formStep = currentStep;  
	    formType = 'Non-RFI';
    	formName = 'PhoenixLuminary';
        formLocation = 'Phoenix Luminary form';
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formLocation"] = formLocation;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
    }

    dropdownChange = (name, value) => {
        if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                prevDegreeLevel = value;
            }
        }
    }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
	        //fireAnalyticsEvent('NonRFIStart', formName, formType);
            fireEvent('NonRFIStart', 'Phoenix Lumimary', formName, formType, 'Start Phoenix Lumimary form', formLocation);
            this.setState({ rfi_begin: true });
        }
    }

    conditionalRadio(values) {
        const {isNominatingYourself} = values;
        if(isNominatingYourself === 'Yes' || isNominatingYourself === 'No'){
            return true;
        }
    };

    isNominatingYourself(values) {
        if(this.state.isNominatingYourselfRadio){
            return true;
        }
    }
    
    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isNominatingYourself') {
            formRef.current.values.isNominatingYourself = value;
            if(value === "Yes"){ 
                isSelf = '';
                accomplishmentsCopy='yourself';
                moreInfoCopy=`Add URLs of your LinkedIn profile, business websites or articles about you or your business.`;
                this.setState({isNominatingYourselfRadio: true});
                secondaryTitle = `Tell us about yourself`;
                //secondaryDesc = `Share the accomplishments and qualities you think we should know about.`;
            }
            if(value === "No") {
                isSelf= "Nominee's ";
                accomplishmentsCopy='your candidate';
                moreInfoCopy=`Add URLs of your candidate's LinkedIn profile, business websites or articles about them.`;
                this.setState({isNominatingYourselfRadio: false});
                secondaryTitle = `Please tell us about your candidate.`;
                //secondaryDesc = `Please tell us who you're nominating and why. Share the accomplishments and qualities you think we should know about.`;
            }
            this.handleAnalyticsRFIFormStart()
        }
    };

    handleSubmit = (values) => {
    //    hashValues(values);   
        //values.firstName = (values.firstName).trim();
        //values.lastName = (values.lastName).trim();
        this.setState({ initialValues: values });
        const headers = {'Content-Type': 'application/json'};
        let URL = 'https://www.phoenix.edu/request/sendmail';
        const data = JSON.stringify(values);
        axios.post(URL, data, {headers})
        .then((response) => {
            if (response.data.msg === 'success'){
                console.log("Email sent, awesome!");
                let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
                if(window.location.host !== 'www.phoenix.edu'){
                    baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
                }
                window.location.assign(baseUrl + values.thankyouPageUrl);
            /*    this.setState({
                    initialValues: values,
                    isSubmit: true,
                  }); */
            }else if(response.data.msg === 'fail'){
                console.log("Oops, something went wrong. Try again")
            }
        })
        .catch(error => {
            console.log (error.message);   
        });
	    //fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
        if (window.uopxDataLayer && window.uopxDataLayer.track) {
            window.uopxDataLayer&&window.uopxDataLayer.track('NonRFISubmit', {'componentName' : 'Zero Party', 'name': formName, 'type': formType, 'text': 'Submit Phoenix Luminary form', 'location': formLocation, 'formInformation': {'profileDetails':{'firstName' :values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}, 'formDetails':{'campaign' : 'Phoenix Luminary', 'campaignOption' : values.isNominatingYourself},},});
        }
        srSpeak('your request has been submitted');
        window.setTimeout(function () {
            document.querySelector('#thanksdialogtitle').setAttribute('tabIndex', '-1');
            document.querySelector('#thanksdialogtitle').focus();      
        }, 300);
    }

    PhoenixLuminaryValidate (values) {
        const errors = {};
        if (values.isNominatingYourself === '' || values.isNominatingYourself === undefined) {
            errors.isMilitary = 'Are you nominating yourself requires a value';
        }
        if ((values.isNominatingYourself === 'Yes' || values.isNominatingYourself === 'No')) {
            if (!values.yourfullName) {
                errors.yourfullName = 'Your first and last name requires a value';
            } else if (values.yourfullName.trim().indexOf(' ') === -1) {
                errors.yourfullName = 'Please enter a first and last name';
            } else if (!profanityCheck(values.fullName)) {
                errors.yourfullName = 'Your first and last name appears invalid';
            }
            if (!values.youremailAddress) {
                errors.youremailAddress = 'Your email eddress requires a value';
            }
            if (values.youremailAddress && !values.youremailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                errors.youremailAddress = 'Your email address appears invalid';
            }
            /*if (!values.yourphoneNumber) {
                errors.yourphoneNumber = 'Your Phone number requires a value';
            }
            if (values.yourphoneNumber && values.yourphoneNumber.length !== 10) {
                errors.yourphoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
            } */
        }
        if (values.isNominatingYourself === 'Yes') {
            if (!values.accomplishments) {
                errors.accomplishments = `Please tell us why you're nominating yourself requires a value.`;
            }
        } 
        if (values.isNominatingYourself === 'No') {
            if (!values.nomineefullName) {
                errors.nomineefullName = `Nominee's first and last name requires a value`;
            } else if (values.nomineefullName.trim().indexOf(' ') === -1) {
                errors.nomineefullName = `Please enter Nominee's first and fast name`;
            } else if (!profanityCheck(values.nomineefullName)) {
                errors.nomineefullName = `Nominee's first and last name appears invalid`;
            }
            if (!values.nomineeemailAddress) {
              errors.nomineeemailAddress = `Nominee's email eddress requires a value`;
            }
            if (values.nomineeemailAddress && !values.nomineeemailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
              errors.nomineeemailAddress = `Nominee's email address appears invalid`;
            }
            /*if (!values.nomineephoneNumber) {
                errors.nomineephoneNumber = `Nominee's Phone number requires a value`;
            }
            if (values.nomineephoneNumber && values.nomineephoneNumber.length !== 10) {
                errors.nomineephoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
            }*/
            if (!values.relationshipWithAlumni) {
                errors.relationshipWithAlumni = 'Your relationship to alumni requires a value';
            }
            if (!values.accomplishments) {
                errors.accomplishments = `Please tell us why you're nominating your candidate requires a value.`;
            }
        } 
        
        //console.log('errors', errors, values);
        return errors;
    }

    
    render() {
    //    const { classes } = this.props;
        let secondaryheaderDesktop = `<h3 id="secondaryTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 21px; font-weight: 700; line-height: 40px; display:flex; align-items:left; justify-content: left; margin-top:0;">${secondaryTitle}</h3>`;
        // let secondarydescriptionDesktop = `<div id="secondaryDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">${secondaryDesc}</div>`;
        let secondaryheaderMobile = `<h3 id="secondaryTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 18px; font-weight: 700; line-height: 28px; display:flex; align-items:left; justify-content: left; margin-top:0;">${secondaryTitle}</h3>`;
        // let secondarydescriptionMobile = `<div id="secondaryDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">${secondaryDesc}</div>`;
        
        let secondarydescriptionDesktop, secondarydescriptionMobile;
        const { isSubmit, initialValues } = this.state;
        let titleText = isMobileView ? (this.props.title || headerMobile) : (this.props.title || headerDesktop);
        let descriptionText = isMobileView ? (this.props.desc || descriptionMobile) : (this.props.desc || descriptionDesktop);
        let thankyouMessageTitle = isMobileView ?  (this.props.thankyoumessagetitle || thankyouMessageTitleMobile) : (this.props.thankyoumessagetitle || thankyouMessageTitleDesktop);
        let thankyouMessageDescription = isMobileView ? (this.props.thankyoumessagedescription || thankyouMessageDescriptionMobile) : (this.props.thankyoumessagedescription || thankyouMessageDescriptionDesktop);
        let secondarytitleText = isMobileView ? (this.props.secondarytitle || secondaryheaderMobile) : (this.props.secondarytitle || secondaryheaderDesktop);
        let secondarydescriptionText = isMobileView ? (this.props.secondarydesc || secondarydescriptionMobile) : (this.props.secondarydesc || secondarydescriptionDesktop);
        
        return (
            
            <div>
            {/*   <Header />   */ }
                <div className="PhoenixLuminary-main">
                    <Grid container justify='center' className="mobileFormContainer">
                    <Grid item md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">

                     {!isSubmit
                    ? (
                        <>
                        <Grid item md={12} sm={12} lg={12} xs={12}>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.PhoenixLuminaryValidate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  //aria-label="Phoenix 76"
                                                  aria-labelledby={isSubmit? "thanksdialogtitle thanksdialogdesc" : "dialogTitle dialogDesc"}
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                                { unescapeHTML(titleText)}
                                                { unescapeHTML(descriptionText)}
                                                <div className="reqinfo" > * Required information </div>
                                            <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm" style={{ maxWidth: '100%'}}>
                                                <MaterialRadio className="selectRadioLabel" value={formRef?.current?.values.isNominatingYourself} row onChange={this.handleChange} items={radioOption} label="Are you nominating yourself? *" name="isNominatingYourself" ariaLabel="Are you nominating yourself? *" />
                                            </Grid>
                                            {this.conditionalRadio(props.values) && <>        
                                            <Grid container >
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput
                                                            name="yourfullName"
                                                            id="yourfullName"
                                                            label="Your first and last name *"
                                                            ariaRequired="true"
                                                            ariaLabel="Your first and last name"
                                                            variant="outlined"
                                                            className='uopx-input modalfield'
                                                            //onClick={this.handleAnalyticsRFIFormStart}
                                                        />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="youremailAddress" id="youremailAddress" label="Your email address *" ariaRequired="true" ariaLabel="Enter your Email address" variant="outlined" />
                                                    </Grid>
                                            {/*        <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                            <MaterialPhoneNumber name="yourphoneNumber" id="yourphoneNumber" label="Your Phone number *" ariaLabel="Enter your phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                    </Grid>  */}
                                                    { !this.isNominatingYourself(props.values) &&
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="relationshipWithAlumni" id="relationshipWithAlumni"
                                                            label="Your relationship to alumni *" ariaRequired="true" ariaLabel="your relationship to alumni" onClick={this.handleAnalyticsRFIFormStart} />
                                                    </Grid>
                                                    }
                                                </Grid>
                                                <Grid container 
                                                    aria-labelledby={"secondaryTitle secondaryDesc"} role="group">
                                                    {this.isNominatingYourself(props.values) &&
                                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                                        <hr className="separator"></hr>
                                                        { unescapeHTML(secondarytitleText)}
                                                        { unescapeHTML(secondarydescriptionText)}
                                                    </Grid>
                                                    }
                                                    { !this.isNominatingYourself(props.values) && <>
                                                        <Grid item md={12} sm={12} lg={12} xs={12}>
                                                        <hr className="separator"></hr>
                                                        { unescapeHTML(secondarytitleText)}
                                                        { unescapeHTML(secondarydescriptionText)}
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput
                                                            name="nomineefullName"
                                                            id="nomineefullName"
                                                            label="Nominee's first and last name *"
                                                            ariaRequired="true"
                                                            ariaLabel="enter nominee's first and last name"
                                                            variant="outlined"
                                                            className='uopx-input'
                                                        />
                                                    </Grid> 
                                                    
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="nomineeemailAddress" id="nomineeemailAddress" label="Nominee's email address *" ariaRequired="true" ariaLabel="enter nominee's email address" variant="outlined" />
                                                    </Grid> 
                                                    
                                            {/*        <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                            <MaterialPhoneNumber name="nomineephoneNumber" id="nomineephoneNumber" label="Nominee's Phone number" ariaLabel="Enter nominee's phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                    </Grid>  */}
                                                    </>}
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <SelectField
                                                            id={isSelf+"degreeLevel"}
                                                            name="degreeLevel"
                                                            label={this.state.isNominatingYourselfRadio ? "Highest degree earned": isSelf+ "highest degree earned"}
                                                            data={degreeLevel}
                                                            className="MuiFormControl-fullWidth"
                                                            formRef={formRef}
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                            onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                        ></SelectField>
                                                        {roleChange()}
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="nomineeCompanyName" id="nomineeCompanyName"
                                                            label={this.state.isNominatingYourselfRadio ? "Company name": isSelf + "company name"} ariaLabel={"Enter "+ isSelf +"Company Name"} />
                                                    </Grid>
                                            {/*    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="nomineeLinkedInURL" id="nomineeLinkedInURL"
                                                            label={isSelf + "linkedIn URL"} ariaRequired="true" ariaLabel={"Enter "+ isSelf + "LinkedIn URL"} />
                                                    </Grid> */}
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm" >
                                                    <MaterialTextArea
                                                        id="accomplishments"
                                                        name="accomplishments"
                                                        onChange={onTextAreaChange}
                                                        rows={2}
                                                        label={`Please tell us why you're nominating ${accomplishmentsCopy}. *`}
                                                        classNames="uopx-input more_info_textarea"
                                                        ariaLabel={`Please tell us why you're nominating ${accomplishmentsCopy}`}
                                                        ariaRequired="true"
                                                        //placeholder="Suggestions: What inspired you to go to college? What motivated you to keep going? What did you learn about yourself? Any advice for someone considering enrolling?"
                                                    />
                                                </Grid>     
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm" >
                                                    <MaterialTextArea
                                                        id="moreInfo"
                                                        name="moreInfo"
                                                        onChange={onTextAreaChange}
                                                        rows={2}
                                                        label={moreInfoCopy}
                                                        classNames="uopx-input more_info_textarea"
                                                        ariaLabel={moreInfoCopy}
                                                        //ariaRequired="true"
                                                        //placeholder="Suggestions: What inspired you to go to college? What motivated you to keep going? What did you learn about yourself? Any advice for someone considering enrolling?"
                                                    />
                                                </Grid>     
                                                  

                                                <Grid item md={9} sm={12} lg={12} xs={12} className ={"margin-OneStepForm"} style={{ alignItems: 'center', display: 'flex' }}>
                                                    <div className="disclaimer">
                                                        { unescapeHTML(disclaimerText) }
                                                    </div>
                                                </Grid>
                                                <Grid item md={3} sm={12} lg={12} xs={12} className ={"margin-OneStepForm submit-btn"}>
                                                    <Button size='large' type='submit' className="btn-submit"> Submit </Button>
                                                </Grid>
                                            </Grid>
                                            </>}
							                <ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        </>
                    )
                    : (
                          <>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                {unescapeHTML(thankyouMessageTitle)}
                                {unescapeHTML(thankyouMessageDescription)}
                            </Grid>
                          </>
                        )}
                          </Grid>
                    </Grid>
                </div>
            { /*   <Footer /> */ } 
            </div>
        );
    }
}

PhoenixLuminary.defaultProps = {
    classes: {},
};

PhoenixLuminary.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(PhoenixLuminary);