export const Business = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 4.5H10.9247H7V1.74677C7 1 7.57588 1 8.28627 1H13.7137C14.4241 1 15 1 15 1.74677V4.5Z" stroke="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1 4.5V5.21969V14.9968V16.1185C1 17.7144 2.28059 19 3.86014 19H18.1326C19.7167 19 21 17.7099 21 16.1185V14.9968V5.21969V4.5H1Z" stroke="#001823" />
            <path d="M9.89685 11.5C9.16641 11.5 7.79259 11.5 5.77539 11.5C4.32529 11.5 3.14829 10.3233 3.14829 8.87067V7.38069C3.14829 5.64572 2.23071 5.34973 1 4.5" stroke="#001823" />
            <path d="M12.5248 11.5C13.0517 11.5 14.2506 11.5 16.1215 11.5C17.5716 11.5 18.7486 10.3233 18.7486 8.87067V7.38069C18.7486 5.64572 19.6661 5.34973 20.8969 4.5" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 12.9487L11.6769 13.3899C11.4025 13.5381 11.0703 13.5364 10.7968 13.3865L10 12.9487V9.5H12.5V12.9487Z" stroke="#001823" />
        </svg>
    )
}

export const Technology = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 2H0.5V14H19.5V2Z" stroke="#001823" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.95074 2H0V0.654751C0 0.292568 0.244565 0 0.547861 0H8.95074H19.4521C19.7546 0 20 0.293509 20 0.654751V2H8.95074ZM2.1167 1.04435C2.1167 0.813913 1.93061 0.626957 1.70017 0.626957C1.46974 0.626957 1.28278 0.813913 1.28278 1.04435C1.28278 1.27478 1.46974 1.46087 1.70017 1.46087C1.93061 1.46087 2.1167 1.27478 2.1167 1.04435ZM3.85583 1.04435C3.85583 0.813913 3.66974 0.626957 3.4393 0.626957C3.20887 0.626957 3.02191 0.813913 3.02191 1.04435C3.02191 1.27478 3.20887 1.46087 3.4393 1.46087C3.66974 1.46087 3.85583 1.27478 3.85583 1.04435Z" fill="#001823" />
            <path d="M6.89261 6.65329L5.25 7.74894L6.89261 8.84372" stroke="#DB3725" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.2585 8.84382L14.9011 7.74903L13.2585 6.65338" stroke="#DB3725" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.7822 5L8.36913 10.4974" stroke="#DB3725" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const Healthcare = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.17548 8.55173V7.31114C5.17548 7.28998 5.16059 7.27274 5.14257 7.27274H4.12924C4.112 7.27274 4.09711 7.28998 4.09711 7.31114V8.55173H2.85652C2.83536 8.55173 2.81812 8.56662 2.81812 8.58465V9.59797C2.81812 9.616 2.83536 9.6301 2.85652 9.6301H4.09711V10.8707C4.09711 10.8919 4.112 10.9091 4.12924 10.9091H5.14257C5.16059 10.9091 5.17548 10.8919 5.17548 10.8707V9.6301H6.41608C6.43724 9.6301 6.45448 9.616 6.45448 9.59797V8.58465C6.45448 8.56662 6.43724 8.55173 6.41608 8.55173H5.17548Z" fill="#001823"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.01404 3.63636C4.82202 3.63636 4.72875 3.53567 4.68956 3.47541C4.61589 3.36099 4.61902 3.22139 4.69818 3.0612C4.74913 2.95669 4.81653 2.85829 4.89804 2.7698C5.11514 2.53333 5.43178 2.35864 5.86676 2.2343C5.89419 2.22667 6.44753 2.0558 6.66227 1.16101C6.73908 0.767397 7.1772 0.00076282 8.26584 0C9.36858 0.00076282 9.8067 0.767397 9.88586 1.17245C10.0983 2.0558 10.6516 2.22667 10.6751 2.23354C11.114 2.35864 11.4306 2.53333 11.647 2.76904C11.7293 2.85829 11.7951 2.95364 11.8476 3.06043C11.9268 3.22139 11.9299 3.36099 11.8554 3.47541C11.817 3.53567 11.723 3.63636 11.5318 3.63636H5.01404Z" fill="#001823"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.72721 1.36363C8.72721 1.61466 8.52379 1.81818 8.27243 1.81818C8.02153 1.81818 7.81812 1.61466 7.81812 1.36363C7.81812 1.11261 8.02153 0.909088 8.27243 0.909088C8.52379 0.909088 8.72721 1.11261 8.72721 1.36363Z" fill="white"/>
            <path d="M13.7273 7.27274H8.27271V8.27274H13.7273V7.27274Z" fill="#DB3725"/>
            <path d="M13.7273 8.72726H8.27271V9.72726H13.7273V8.72726Z" fill="#DB3725"/>
            <path d="M13.7273 10.1818H8.27271V11.1818H13.7273V10.1818Z" fill="#DB3725"/>
            <path d="M13.7272 12H2.81812V13H13.7272V12Z" fill="#DB3725"/>
            <path d="M13.7272 13.5H2.81812V14.5H13.7272V13.5Z" fill="#DB3725"/>
            <path d="M13.7272 15H2.81812V16H13.7272V15Z" fill="#DB3725"/>
            <path d="M13.5455 3.63635H3C1.89543 3.63635 1 4.53178 1 5.63635V18C1 19.1046 1.89543 20 3 20H13.5455C14.65 20 15.5455 19.1046 15.5455 18V5.63635C15.5455 4.53178 14.65 3.63635 13.5455 3.63635Z" stroke="#001823"/>
        </svg>
    )
}

export const Nursing = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8265 11V16.3969C13.8265 18.3788 12.2519 20 10.3269 20C8.40192 20 6.82645 18.3788 6.82645 16.3969C6.82645 16.0487 6.82645 15.4163 6.82645 14.4998" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.8265 11C16.8265 11.8285 16.155 12.5 15.3265 12.5C14.4979 12.5 13.8265 11.8285 13.8265 11C13.8265 10.1715 14.4979 9.5 15.3265 9.5C16.155 9.5 16.8265 10.1715 16.8265 11Z" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.23786 1.5H3.41504C3.09114 1.5 2.82645 1.23018 2.82645 0.9V0.6C2.82645 0.269823 3.09114 0 3.41504 0H4.23786C4.56176 0 4.82645 0.269823 4.82645 0.6V0.9C4.82645 1.23018 4.56176 1.5 4.23786 1.5Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.47658 1.5H10.1763C10.534 1.5 10.8265 1.20178 10.8265 0.836982V0.66213C10.8265 0.298225 10.534 0 10.1763 0H9.47658C9.11888 0 8.82645 0.298225 8.82645 0.66213V0.836982C8.82645 1.20178 9.11888 1.5 9.47658 1.5Z" fill="#DB3725" />
            <path d="M3.32645 7C2.9092 5.90396 2.28332 4.2599 1.44882 2.06782C1.44882 2.06782 0.81702 0.259308 2.42462 0.527042" stroke="#001823" />
            <path d="M10.3265 7C10.743 5.91177 11.3678 4.27943 12.2009 2.10297C12.2009 2.10297 12.8496 0.253912 11.1978 0.527648" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.7837 6.51002L9.76205 6.5L8.24141 9.49178V11.2917C8.24141 11.6472 8.09811 11.9697 7.87028 12.2141C7.8445 12.2422 7.81873 12.2692 7.79089 12.2943C7.74966 12.3323 7.70532 12.3674 7.65893 12.3994C7.62594 12.4235 7.59089 12.4465 7.55481 12.4675C7.50739 12.4956 7.45893 12.5226 7.40841 12.5447C7.36615 12.5637 7.32182 12.5777 7.27852 12.5917C7.22903 12.6088 7.18058 12.6258 7.128 12.6378C7.05274 12.6538 6.97439 12.6619 6.89398 12.6649C6.8713 12.6659 6.84965 12.6709 6.82697 12.6709C6.80325 12.6709 6.7816 12.6659 6.75892 12.6649C6.67851 12.6619 6.60016 12.6538 6.5249 12.6378C6.47335 12.6258 6.42387 12.6088 6.37438 12.5917C6.33108 12.5777 6.28675 12.5637 6.24449 12.5447C6.19397 12.5226 6.14552 12.4956 6.09809 12.4675C6.06201 12.4465 6.02696 12.4235 5.99397 12.3994C5.94758 12.3674 5.90325 12.3323 5.86201 12.2943C5.83417 12.2692 5.80943 12.2422 5.78366 12.2141C5.55479 11.9697 5.41149 11.6472 5.41149 11.2917V9.49178L3.89085 6.5L3.86921 6.51002C2.94342 6.95373 2.56301 8.04347 3.01971 8.94391L3.51662 9.92147V11.1985C3.51662 12.736 4.58571 14.1352 6.13624 14.4437C6.37129 14.4898 6.60119 14.5018 6.82697 14.4998C7.05274 14.5018 7.28161 14.4898 7.51666 14.4437C9.06719 14.1352 10.1363 12.736 10.1363 11.1985V9.92147L10.6332 8.94391C11.0899 8.04347 10.7095 6.95373 9.7837 6.51002Z" stroke="#001823" />
        </svg>
    )
}

export const Psychology = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 15.75L17.8284 18.5784" stroke="#DB3725" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 13.25C16 14.9065 14.6571 16.25 12.9995 16.25C11.3429 16.25 10 14.9065 10 13.25C10 11.5926 11.3429 10.25 12.9995 10.25C14.6571 10.25 16 11.5926 16 13.25Z" stroke="#DB3725" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.4516 13.875C19.8733 13.9453 21 12.207 21 10.3562C21 8.2406 19.6841 6.46957 17.8767 5.85631C17.3336 3.08399 15.0124 1 12.2307 1C10.3869 1 8.74889 1.91989 7.69299 3.34539C7.21562 3.09846 6.68678 2.94741 6.11979 2.94741C4.21562 2.94741 2.67169 4.51764 2.54481 6.49942C1.61136 7.26102 1 8.44321 1 9.78641C1 12.0649 2.73026 13.9092 4.87578 13.9453V13.9517H8.31411" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.75 10.875C6.75 12.1171 7.34336 13.2147 8.25 13.875" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.375 10.0641C8.22539 10.1665 8.06791 10.2607 7.90343 10.343C6.33475 11.1302 4.5421 10.7361 3.375 9.5" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.2174 5.125C13.2684 5.21394 13.3154 5.30558 13.3564 5.40171C13.7484 6.31358 13.5534 7.29014 12.9404 7.875" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.125 7.81888C13.4604 7.69359 13.8204 7.625 14.1954 7.625C15.7509 7.625 17.0519 8.80109 17.375 10.375" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.5 7.5C7.5 6.39542 8.39474 5.5 9.5 5.5" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const Education = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 12H9V17L7 15.8478L5 17V12Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9885 6.04504C17.2279 6.03693 17.423 6.27466 17.423 6.27466C17.7345 6.63532 17.9451 6.49305 18.0803 6.32957C18.6291 5.61825 18.9957 4.49136 19 3.64713C19.008 1.86882 17.3444 2.68934 17.0045 2.68685C16.6321 2.68435 15.0081 1.89066 15 3.62093C14.9958 4.46516 15.3517 5.59766 15.8935 6.31522C16.0293 6.47621 16.2254 6.65154 16.5514 6.26905C16.5514 6.26905 16.7486 6.04504 16.988 6.04504" fill="#DB3725" />
            <path d="M16.874 2C16.874 2 16.989 1.32828 17.626 1" stroke="#001823" strokeWidth="0.5" strokeLinecap="round" />
            <path d="M3.16216 15.5C1.96803 15.5 1 14.6046 1 13.5V9.5C1 8.39543 1.96803 7.5 3.16216 7.5L21 7.5" stroke="#001823" strokeLinecap="round" />
            <path d="M18.5 9.5H3.5C3.22386 9.5 3 9.72386 3 10C3 10.2761 3.22386 10.5 3.5 10.5H18.5C18.7761 10.5 19 10.2761 19 10C19 9.72386 18.7761 9.5 18.5 9.5Z" fill="#001823" />
            <path d="M18.5 10.75H3.5C3.22386 10.75 3 10.9739 3 11.25C3 11.5261 3.22386 11.75 3.5 11.75H18.5C18.7761 11.75 19 11.5261 19 11.25C19 10.9739 18.7761 10.75 18.5 10.75Z" fill="#001823" />
            <path d="M4 12.5C4 12.2239 3.77614 12 3.5 12C3.22386 12 3 12.2239 3 12.5C3 12.7761 3.22386 13 3.5 13C3.77614 13 4 12.7761 4 12.5Z" fill="#001823" />
            <path d="M4 13.75C4 13.4739 3.77614 13.25 3.5 13.25C3.22386 13.25 3 13.4739 3 13.75C3 14.0261 3.22386 14.25 3.5 14.25C3.77614 14.25 4 14.0261 4 13.75Z" fill="#001823" />
            <path d="M18.5 12H10.5C10.2239 12 10 12.2239 10 12.5C10 12.7761 10.2239 13 10.5 13H18.5C18.7761 13 19 12.7761 19 12.5C19 12.2239 18.7761 12 18.5 12Z" fill="#001823" />
            <path d="M18.5 13.25H10.5C10.2239 13.25 10 13.4739 10 13.75C10 14.0261 10.2239 14.25 10.5 14.25H18.5C18.7761 14.25 19 14.0261 19 13.75C19 13.4739 18.7761 13.25 18.5 13.25Z" fill="#001823" />
            <path d="M10 15.25C12.4444 15.25 16.1111 15.25 21 15.25" stroke="#001823" strokeLinecap="round" />
        </svg>
    )
}

export const CriminalJustice = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3C10.8535 3.63434 9.47653 4 7.99685 4C6.51627 4 5.13925 3.63434 4 3" stroke="#001823" strokeLinecap="round" />
            <path d="M20 3C18.8535 3.63434 17.4765 4 15.9968 4C14.5163 4 13.1393 3.63434 12 3" stroke="#001823" strokeLinecap="round" />
            <path d="M7 9L3.99948 3L1 9" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 9L19.9995 3L17 9" stroke="#001823" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 9C0.117974 11.2275 1.85471 13 4.00048 13C6.14529 13 7.88203 11.2275 8 9H0Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16 9C16.118 11.2275 17.8547 13 20.0005 13C22.1453 13 23.882 11.2275 24 9H16Z" fill="#DB3725" />
            <path d="M12.5 0.5C12.5 0.223858 12.2761 0 12 0C11.7239 0 11.5 0.223858 11.5 0.5V19.5C11.5 19.7761 11.7239 20 12 20C12.2761 20 12.5 19.7761 12.5 19.5V0.5Z" fill="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22 19.5C22 19.7236 21.8188 19.9048 21.5952 19.9048L2.40476 19.9048C2.18122 19.9048 2 19.7236 2 19.5C2 19.2765 2.18122 19.0952 2.40476 19.0952L21.5952 19.0952C21.8188 19.0952 22 19.2765 22 19.5Z" fill="#001823" />
        </svg>
    )
}

export const LiberalArts = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.51933 14.097L2.26754 15.3488C2.26754 15.3488 4.30425 17.1965 5.85671 16.0758L5.89845 16.1112C4.8052 17.6159 6.55968 19.5196 6.65115 19.6171L6.49829 19.7731L1 20.6592L2.09704 15.5021L3.51904 14.0967L3.51933 14.097Z" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1 20.6592L3.31594 20.3401L1.33519 18.3348L1 20.6592Z" fill="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.549 7.45697L14.5 2.42048L15.8714 1.03982C16.5925 0.320061 17.7618 0.320061 18.4829 1.03982L20.9592 3.51729C21.6803 4.23831 21.6803 5.40634 20.9592 6.12736L19.549 7.45697Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.26754 15.3488L14.8355 2.78085L17.013 4.9584L6.12528 15.8461C4.55105 17.4204 2.26754 15.3488 2.26754 15.3488Z" stroke="#001823" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.65484 19.621L19.1909 7.13627L17.0133 4.95872L6.1256 15.8465C4.55137 17.4207 6.65484 19.621 6.65484 19.621Z" stroke="#001823" strokeLinejoin="round" />
        </svg>
    )
}

export const BehavioralSciences = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.65167 4.28579C7.27057 4.66773 7.27057 5.28505 7.65167 5.6661C8.03277 6.04626 8.65116 6.04626 9.03124 5.6661C9.41234 5.28505 9.41234 4.66773 9.03124 4.28579C8.65116 3.90474 8.03277 3.90474 7.65167 4.28579Z" fill="#DB3725"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.8953 7.21236C17.5146 7.59306 17.5146 8.21112 17.8953 8.59182C18.2769 8.97346 18.894 8.97346 19.2747 8.59182C19.6564 8.21112 19.6564 7.59306 19.2747 7.21236C18.894 6.83165 18.2769 6.83165 17.8953 7.21236Z" fill="#DB3725"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0666 12.091C10.6851 12.4716 10.6851 13.0885 11.0666 13.47C11.4472 13.8515 12.065 13.8515 12.4456 13.47C12.8271 13.0885 12.8271 12.4716 12.4456 12.091C12.065 11.7095 11.4472 11.7095 11.0666 12.091Z" fill="#DB3725"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.23706 16.9687C3.85596 17.3507 3.85596 17.968 4.23706 18.349C4.61816 18.7292 5.23655 18.7292 5.61663 18.349C5.99773 17.968 5.99773 17.3507 5.61663 16.9687C5.23655 16.5877 4.61816 16.5877 4.23706 16.9687Z" fill="#DB3725"/>
            <path d="M9.57477 5.81631C11.3427 7.20621 12.871 8.56005 13.8515 9.80662C15.7875 12.2692 20.0428 19.584 18.8729 20.8195C17.5098 22.2575 11.7289 17.2213 9.79297 14.7596C7.85702 12.2979 3.88113 5.45658 5.18383 4.21741C5.5181 3.90022 6.15599 3.97142 6.71681 4.16839" stroke="#001823" strokeLinecap="round"/>
            <path d="M18.0976 9.36585C16.9932 11.3898 15.4594 13.5118 14.3617 14.6277C12.1879 16.8358 5.73083 21.6918 4.64065 20.3569C4.41884 20.0848 4.42922 19.7348 4.4481 19.4517" stroke="#001823" strokeLinecap="round"/>
            <path d="M5.90244 15.7073C7.28791 13.373 8.7846 11.2725 10.0702 9.97943C12.2398 7.79466 18.2699 3.30732 19.3623 4.77803C19.5838 5.07707 19.6092 5.55281 19.4923 6.14272" stroke="#001823" strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4136 15.1992C9.51444 15.029 1.95071 13.5861 2.00024 12.1596C2.05912 10.4987 9.27051 9.70609 12.1696 9.87629C15.0688 10.0465 22.139 11.3966 21.9979 12.9092C21.854 14.461 15.3136 15.3693 12.4136 15.1992Z" stroke="#001823" strokeLinecap="round"/>
        </svg>
    )
}

export const Other = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0739 3L20.2298 2.10637C20.3361 1.49743 20.0126 1 19.5112 1C19.0097 1 18.5121 1.49743 18.4059 2.10637L18.25 3H20.0739Z" fill="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.5 1.28014H18.3469H7.66714C6.68429 1.28014 5.76327 2.1681 5.61995 3.25456L3.5 13.2801H15.9192L18.1185 2.6599C18.216 1.91979 18.8314 1.31469 19.4993 1.28576L19.5 1.28014Z" stroke="#001823" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.085 18.0404L11.96 17.0898L13.9406 18.0404V12.0404L13.564 13.1969L12.6624 12.8845L12.0128 13.625L11.2637 12.8845L10.3607 13.1969L10.085 12.0404V18.0404Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.9998 7.375L12.6302 8.02284L13.4997 7.77686L13.7217 8.65328L14.5981 8.8749L14.3522 9.74475L15 10.3748L14.3522 11.0052L14.5981 11.8751L13.7217 12.0967L13.4997 12.9731L12.6302 12.7272L11.9998 13.375L11.3698 12.7272L10.4999 12.9731L10.2779 12.0967L9.40186 11.8751L9.64784 11.0052L9 10.3748L9.64784 9.74475L9.40186 8.8749L10.2779 8.65328L10.4999 7.77686L11.3698 8.02284L11.9998 7.375Z" stroke="#DB3725" />
            <path d="M15.25 4.125H7.25V5.125H15.25V4.125Z" fill="#001823" />
            <path d="M4 4.125H0V5.125H4V4.125Z" fill="#001823" />
            <path d="M3 5.625H0V6.625H3V5.625Z" fill="#001823" />
            <path d="M2 7.125H0V8.125H2V7.125Z" fill="#001823" />
        </svg>
    )
}