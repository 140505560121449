import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import FormikErrorFocus from 'formik-error-focus';
import PropTypes from 'prop-types';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import { initValues, processJSONParameter } from '../common/configTools';
import {
  unescapeHTML,
  profanityCheck,
  fireAnalyticsEvent, fireNonRFISubmitEvent,
} from '../CommonComponents';
import styles from '../inline/SubscribeStyles';
import ILSLogo from '../layout/images/ILS-2022-logo.png';
import axios from 'axios';
import Button from '../layout/MaterialComponents/button/Button';

class SummitEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      initialValues: initialEmailFormValues,
      variant: props.variant,
      message: null,
      currentStep: 1,
    };
    this.ref = {
      form: React.createRef(),
      container: React.createRef(),
    };
  }

  componentDidMount() {
    const { props } = this;
    let { initialValues } = this.state;
    initialValues.requestingUrl = window.location.href;
    initValues(initialValues, this.props);
    this.setState({ initialValues });
    const { currentStep } = this.state;
    formStep = currentStep;
	  formType = 'Non-RFI';
	  formName = 'Summit Email Subscribe';
    if (window.pageData && window.pagePath) {
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
    }
  }

  handleSubmit = (values) => {
    //hashValues(values);
    values.fullName = (values.fullName).trim();   
    this.setState({ initialValues: values });
    const headers = {
        'Content-Type': 'application/json',
    };
    let URL = 'https://www.phoenix.edu/request/sendmail';
    const data = JSON.stringify(values);
    axios.post(URL, data, {
        headers,
      })
    .then((response) => {
        if (response.data.msg === 'success'){
            console.log("Email sent, awesome!");
            this.setState({
              initialValues: values,
              isSubmit: true,
              message: null,
            });    
        }else if(response.data.msg === 'fail'){
            console.log("Oops, something went wrong. Try again")
        }
    })
    .catch(error => {
    console.log (error.message);   
    });
    fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
    //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
    
}

  handleAnalyticsRFIFormStart = () => {
    if (this.state.rfi_begin !== true) {
      this.setState({ rfi_begin: true });
    }
  }

  validateForm(values) {
    const errors = {};

    if (!values.fullName) {
      errors.fullName = 'First and Last name requires a value';
    } else if (values.fullName.trim().indexOf(' ') === -1) {
      errors.fullName = 'Please enter a First and Last name';
    } else if (!profanityCheck(values.fullName)) {
      errors.fullName = 'Full Name appears invalid';
    }
    if (!values.emailAddress) {
      errors.emailAddress = 'Email Address requires a value';
    }
    if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.emailAddress = 'Email address appears invalid';
    }
  //  console.log('errors', errors, values);
    return errors;
  }

  render() {
    const { classes } = this.props;
    // console.log('SubEmail rendering...');
    const { isSubmit, initialValues } = this.state;
    return (
      <div className={classes.container}>
        {/*    <UOPHeader />    */}
        <div className={classes.root}>
          <Grid container justifyContent="center" className="mobileFormContainer" ref={this.ref.container}>
            <Formik
              initialValues={initialValues}
              onSubmit={values => this.handleSubmit(values)}
              validate={values => this.validateForm(values)}
              innerRef={this.ref.form}
              validateOnChange
            >
              <Form
                className="Subscribe-email-form"
                style={{ position: 'relative' }}
                onChange={this.handleAnalyticsRFIFormStart}
              >
                <Grid container className="maxWidthContainer-oneStepForm" spacing={1}>
                  <Grid item md={10} sm={12} lg={10} xs={12}>
                    <h2 className="newOneForm-title">
                      {this.state.initialValues.title}
                    </h2>
                  </Grid>
                  <Grid item md={10} sm={12} lg={10} xs={12}>
                    <div className="newOneForm-SubTitle">
                      {this.state.initialValues.description}
                    </div>
                  </Grid>
                  {!isSubmit
                    ? (
                      <>
                        <Grid item md={10} sm={12} lg={10} xs={12}>
                          {this.state.message}
                        </Grid>
                        <Grid item md={5} sm={6} lg={5} xs={12}>
                          <MaterialInput
                            name="fullName"
                            id="fullName"
                            type="text"
                            label="First and Last name *"
                            ariaRequired="true"
                            ariaLabel="Enter your full name"
                            variant="outlined"
                            onClick={this.handleAnalyticsRFIFormStart}
                          />
                        </Grid>
                        <Grid item md={5} sm={6} lg={5} xs={12}>
                          <MaterialInput
                            name="emailAddress"
                            id="emailAddress"
                            label="Email address *"
                            ariaRequired="true"
                            ariaLabel="Enter Email address"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={10} sm={12} lg={10} xs={12}>
                          <div className="disclaimer">
                            <p>
                              {this.state.initialValues.disclaimer}
                              Visit our
                              {' '}
                              <a href="https://www.phoenix.edu/copyright-legal/privacy-policy.html">Privacy Policy</a>
                              {' '}
                              for information about how we collect and use your personal information.
                            </p>
                          </div>
                        </Grid>
                        <Grid item md={5} sm={6} lg={5} xs={6}>
                          <Button
                            size="large"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </>
                    )
                    : (
                      <>
                        <Grid item md={6} sm={6} lg={8} xs={12} className={classes.thanksContainer}>
                          <img src={ILSLogo} alt="Checked Circle" style={{ maxWidth: '325px', maxHeight: '175px' }} />
                          <div className={classes.thanksHeader}> 
                            Thanks for registering for our summit
                          </div>
                          <div className={classes.thanksText}>
                            We’ll follow up with additional information about summit registration soon!
                          </div>
                        </Grid>
                      </>
                    )}
                </Grid>

                <FormikErrorFocus
                  offset={-50}
                  align="top"
                  focusDelay={200}
                  ease="linear"
                  duration={1000}
                />
              </Form>
            </Formik>
          </Grid>
        </div>
      </div>
    );
  }
}
const initialEmailFormValues = {
  fullName: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  title: 'Register for our summit',
  description: "Join us as we envision a world where creating a diverse, equitable and inclusive workplace is everyone’s responsibility.",
  disclaimer: 'By submitting this form, you are giving your consent for University of Phoenix to contact you regarding our Inclusive Leadership Summit using email.',
  requestingUrl: '',
  formName:'summit'
};

SummitEmail.defaultProps = {
  classes: {},
};

SummitEmail.propTypes = {
  classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(SummitEmail);
