export const Bachelor = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4186 6.34882H15.4186V11.3488H16.4186V6.34882Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.4023 7.51163L2.14455 5.71935C1.8588 5.56538 1.8588 5.16504 2.14455 5.01004L9.28193 1.14012C9.62778 0.953294 10.0474 0.953294 10.3922 1.14012L17.5307 5.01004C17.8154 5.16504 17.8154 5.56538 17.5307 5.71935L13.1519 7.4757C12.8073 5.31949 11.7024 4.24619 9.83721 4.25581C7.97205 4.26544 6.82708 5.35071 6.4023 7.51163Z" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.093 7.50396C13.0968 9.30226 11.6431 10.7632 9.84482 10.7674C8.04651 10.7717 6.58616 9.31756 6.5814 7.51926C6.57759 5.72096 8.03128 4.26006 9.82959 4.25581C11.6279 4.25156 13.0892 5.70566 13.093 7.50396Z" stroke="#001823" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1 16.6199C1 15.1202 2.11382 13.8149 3.69555 13.4627L7.43982 12.6279L9.32773 15.6431C9.6573 16.1696 10.4822 16.1696 10.8118 15.6431L12.6641 12.6848L16.4084 13.482C18.0074 13.8219 19.1395 15.1351 19.1395 16.6479L19.0246 19.7473C19.0246 20.4393 18.4163 21 17.6656 21H2.44848C1.69881 21 1.08951 20.4393 1.08951 19.7473L1 16.6199Z" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.6092 18.5457L17.0103 17.6408C17.198 17.5203 17.2982 17.3352 17.276 17.1473L16.7067 13.3171L16.4407 13.2514L14.3064 12.7966L13.4003 12.6048L13.0939 17.1872C13.0833 17.3541 13.1709 17.5167 13.3337 17.632L14.5778 18.5247C14.7213 18.6277 14.91 18.6843 15.1084 18.6843C15.2914 18.6843 15.4695 18.635 15.6092 18.5457Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5304 18.5457L3.12929 17.6408C2.94151 17.5203 2.84136 17.3352 2.86351 17.1473L3.44054 13.2879L3.70663 13.2182L5.81993 12.749L6.68988 12.5609L7.04566 17.1872C7.05625 17.3541 6.96862 17.5167 6.80588 17.6321L5.56173 18.5247C5.41825 18.6277 5.22951 18.6843 5.03114 18.6843C4.84818 18.6843 4.67003 18.635 4.5304 18.5457Z" fill="#DB3725" />
        </svg>
    )
}

export const Masters = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4186 6.34885H15.4186V11.3488H16.4186V6.34885Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.4023 7.51163L2.14455 5.71935C1.8588 5.56538 1.8588 5.16504 2.14455 5.01004L9.28193 1.14012C9.62778 0.953294 10.0474 0.953294 10.3922 1.14012L17.5307 5.01004C17.8154 5.16504 17.8154 5.56538 17.5307 5.71935L13.1519 7.4757C12.8073 5.31949 11.7024 4.24619 9.83721 4.25581C7.97205 4.26544 6.82708 5.35071 6.4023 7.51163Z" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.093 7.50399C13.0968 9.30229 11.6431 10.7632 9.84482 10.7674C8.04651 10.7717 6.58616 9.31759 6.5814 7.51929C6.57759 5.72099 8.03128 4.26009 9.82959 4.25584C11.6279 4.25159 13.0892 5.7057 13.093 7.50399Z" stroke="#001823" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1 16.6199C1 15.1202 2.11382 13.8149 3.69555 13.4627L7.43982 12.6279L9.32773 15.6431C9.6573 16.1696 10.4822 16.1696 10.8118 15.6431L12.6641 12.6848L16.4084 13.482C18.0074 13.8219 19.1395 15.1351 19.1395 16.6479L19.0246 19.7473C19.0246 20.4393 18.4163 21 17.6656 21H2.44848C1.69881 21 1.08951 20.4393 1.08951 19.7473L1 16.6199Z" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.8838 20.0698C6.10723 20.0497 3.85753 14.7402 3.61312 14.1351L3.32559 13.2997C4.01978 13.1475 4.55687 13.0222 4.93686 12.9237C5.19306 12.8575 5.73326 12.7471 6.55746 12.5925L6.71522 13.2997C7.03216 14.7936 8.22481 17.8849 9.88985 17.9015C11.5471 17.8849 12.7398 14.7936 13.0558 13.2997L13.3056 12.5645C15.0007 12.8985 15.8762 13.0849 15.932 13.1239L16.4868 13.2392L16.1588 14.1343C16.0612 14.3763 13.739 20.0497 9.88899 20.0698H9.8838Z" fill="#DB3725" />
        </svg>
    )
}

export const Doctorate = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.9268 9.36584H12.9268V6.78011L13.9268 6.36584V9.36584Z" fill="#DB3725"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4342 6.36887C11.2323 4.70918 10.0076 3.43497 8.52974 3.43904C7.05775 3.4431 5.84262 4.71378 5.63959 6.36488L3.01543 5.04221C2.67863 4.85015 2.57921 4.40201 2.80289 4.08016L4.75517 1.2782C4.8803 1.09841 5.08341 0.99493 5.29852 1.00107L8.50462 1.08701L11.7741 1.00019C11.9892 0.99493 12.1924 1.09841 12.3175 1.2782L14.2706 4.08104C14.4934 4.40201 14.3949 4.85015 14.0589 5.04221L11.4342 6.36887Z" stroke="#001823" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4634 6.84564C11.4668 8.73166 10.16 10.2638 8.54343 10.2683C6.92683 10.2727 5.61404 8.7477 5.60976 6.86168C5.60634 4.97566 6.91314 3.44349 8.52974 3.43904C10.1463 3.43458 11.46 4.95962 11.4634 6.84564Z" stroke="#001823" strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.41729 18.561C5.23169 18.561 3.48573 14.9229 2.66295 12.5485L2.53863 12.188C2.33308 12.2852 2.44567 12.2316 2.87641 12.027C3.52253 11.7201 4.18399 11.3613 5.0632 11.2783L5.43043 11.2439L5.56047 11.9205C5.82628 13.2989 6.90832 16.1528 8.56205 16.1772C10.2191 16.1528 11.3019 13.2989 11.5669 11.9205L11.6969 11.2439L12.0642 11.2783C12.9442 11.3613 13.6486 11.7201 14.2956 12.027L14.6341 12.188L14.509 12.5485C13.6846 14.9229 11.8883 18.5543 8.65284 18.5551L8.57023 18.561L8.41729 18.5534V18.4291" fill="#DB3725"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.32205 20.9933C2.51318 20.9933 0.152966 14.7838 0.0555462 14.5193L0.0183496 14.4178L0.0378336 14.3122C0.0449186 14.2723 -0.280081 13.8375 0.855301 13.0424L1.33443 12.7073L1.53635 13.2345C2.25553 16.188 4.91679 19.5567 8.33002 19.5567C8.37873 19.5584 8.43364 19.5625 8.53814 19.5642L8.60988 19.56C12.0922 19.5501 14.7364 16.24 15.6332 13.2345L15.836 12.7073L16.3142 13.0424C17.4505 13.8383 16.972 14.2723 16.9791 14.3122L16.9986 14.4178L16.9614 14.5193C16.864 14.7838 14.5029 20.9942 8.66479 20.9942L8.51689 21L8.53814 20.9933" fill="#DB3725"/>
            <path d="M10.9756 11.9378L10.4343 11.7831L10.3284 11.9993C9.84274 12.9914 8.64479 13.4019 7.65273 12.9162C7.25833 12.7231 6.93844 12.4056 6.74243 12.0126L6.60232 11.7317L6.09756 11.8683" stroke="#001823"/>
            <path d="M17.0816 19.0847V15.6276L16.1607 17.3401L16.1309 17.4585L16.1299 19.1268C16.1125 19.4998 15.8235 19.7934 15.4794 19.7934H14.4411L14.2744 19.857L13.25 20.7728L15.535 20.7717C16.4025 20.7378 17.0816 19.9901 17.0816 19.0847Z" fill="#001823"/>
            <path d="M2.38419e-07 19.0847V15.6276L0.920909 17.3401L0.950728 17.4585L0.951765 19.1268C0.969074 19.4998 1.25817 19.7934 1.60218 19.7934H2.64056L2.80718 19.857L3.83162 20.7728L1.5466 20.7717C0.679082 20.7378 2.38419e-07 19.9901 2.38419e-07 19.0847Z" fill="#001823"/>
        </svg>
    )
}

export const Associate = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4186 6.34885H15.4186V11.3488H16.4186V6.34885Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.4023 7.51163L2.14455 5.71935C1.8588 5.56538 1.8588 5.16504 2.14455 5.01004L9.28193 1.14012C9.62778 0.953294 10.0474 0.953294 10.3922 1.14012L17.5307 5.01004C17.8154 5.16504 17.8154 5.56538 17.5307 5.71935L13.1519 7.4757C12.8073 5.31949 11.7024 4.24619 9.83721 4.25581C7.97205 4.26544 6.82708 5.35071 6.4023 7.51163Z" stroke="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.093 7.50399C13.0968 9.30229 11.6431 10.7632 9.84482 10.7674C8.04651 10.7717 6.58616 9.31759 6.5814 7.51929C6.57759 5.72099 8.03128 4.26009 9.82959 4.25584C11.6279 4.25159 13.0892 5.7057 13.093 7.50399Z" stroke="#001823" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1 16.6199C1 15.1202 2.11382 13.8149 3.69555 13.4627L7.43982 12.6279L9.32773 15.6431C9.6573 16.1696 10.4822 16.1696 10.8118 15.6431L12.6641 12.6848L16.4084 13.482C18.0074 13.8219 19.1395 15.1351 19.1395 16.6479L19.0246 19.7473C19.0246 20.4393 18.4163 21 17.6656 21H2.44848C1.69881 21 1.08951 20.4393 1.08951 19.7473L1 16.6199Z" stroke="#001823" />
        </svg>
    )
}

export const Certificate = () => {
    return (
        <svg style={{ margin: "10px" }}
            width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.69766 20.875L7.83126 19.4095L12.1977 20.875L12.0813 11.625L11.3674 13.4079L9.31601 13.0977L7.94766 14.4717L6.58912 13.0977L4.73637 13.5874L3.69766 11.625V20.875Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.94723 1.125L9.36572 2.58265L11.322 2.02918L11.8215 4.00114L13.7935 4.49978L13.24 6.45695L14.6977 7.87456L13.24 9.29305L13.7935 11.2502L11.8215 11.7489L11.322 13.7208L9.36572 13.1674L7.94723 14.625L6.52961 13.1674L4.57244 13.7208L4.07293 11.7489L2.10184 11.2502L2.65531 9.29305L1.19766 7.87456L2.65531 6.45695L2.10184 4.49978L4.07293 4.00114L4.57244 2.02918L6.52961 2.58265L7.94723 1.125Z" stroke="#DB3725" />
            <path d="M5.25 6.75L8.9211 9.64138L15.25 3" stroke="#001823" strokeWidth="2" />
        </svg>
    )
}

export const Individual = () => {
    return (
        <svg style={{ margin: "10px" }} 
            width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0739 3L20.2298 2.10637C20.3361 1.49743 20.0126 1 19.5112 1C19.0097 1 18.5121 1.49743 18.4059 2.10637L18.25 3H20.0739Z" fill="#001823" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.5 1.28014H18.3469H7.66714C6.68429 1.28014 5.76327 2.1681 5.61995 3.25456L3.5 13.2801H15.9192L18.1185 2.6599C18.216 1.91979 18.8314 1.31469 19.4993 1.28576L19.5 1.28014Z" stroke="#001823" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.085 18.0404L11.96 17.0898L13.9406 18.0404V12.0404L13.564 13.1969L12.6624 12.8845L12.0128 13.625L11.2637 12.8845L10.3607 13.1969L10.085 12.0404V18.0404Z" fill="#DB3725" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.9998 7.375L12.6302 8.02284L13.4997 7.77686L13.7217 8.65328L14.5981 8.8749L14.3522 9.74475L15 10.3748L14.3522 11.0052L14.5981 11.8751L13.7217 12.0967L13.4997 12.9731L12.6302 12.7272L11.9998 13.375L11.3698 12.7272L10.4999 12.9731L10.2779 12.0967L9.40186 11.8751L9.64784 11.0052L9 10.3748L9.64784 9.74475L9.40186 8.8749L10.2779 8.65328L10.4999 7.77686L11.3698 8.02284L11.9998 7.375Z" stroke="#DB3725" />
            <path d="M15.25 4.125H7.25V5.125H15.25V4.125Z" fill="#001823" />
            <path d="M4 4.125H0V5.125H4V4.125Z" fill="#001823" />
            <path d="M3 5.625H0V6.625H3V5.625Z" fill="#001823" />
            <path d="M2 7.125H0V8.125H2V7.125Z" fill="#001823" />
        </svg>
    )
}