export default {
  useCaseContext: 'NEW',
  appContext: 'PHXEDU',
  source: 'INET',
  ipAddress: '',
  requestingUrl: '',
  requestingIP: '',
  referringUrl: '',
  userAgent: '',
  campaign: 'PHXEDU10',
  googleId: '',
  kwcId: '',
  mcid: '',
  submissionTimestamp: '',
  rfiId: '',
  firstName: '',
  lastName: '',
  fullName: '',
  emailAddress: '',
  phoneNumber: '',
  areaCode: '',
  stateProvince: '',
  postalCode: '',
  academicAward: '',
  academicAwardLevel: '',
  code: '',
  area: '',
  modality: '',
  isMilitary: '',
  disclaimer: 'By submitting, you consent to University of Phoenix contacting you regarding our educational programs and services using email, telephone or text — including our use of automated technology for calls, pre-recorded messages and periodic texts to any phone number you provide, including any wireless number. Message and data rates may apply. This consent is not required to purchase goods or services and you may always call us directly at <a target="_blank" href="tel:8667660766">866-766-0766</a>. Visit our <a href="https://www.phoenix.edu/copyright-legal/privacy-policy.html">Privacy Policy.</a>',
  isMobileDevice: false,
  flex_Info_Requested__c: '',
  fieldOfStudy: '',
  degreeLevel: '',
  isRegisteredNurse: '',
  hasConsent: '',
  flex_more_info__c: '',
  degree: '',
  flex_isEmployed__c: '',
  flex_Employer_Disclosed_on_RFI__c: '',
  flex_DunsNumber__c: '',
  isLostFocus: false,
  flex_isCertified__c: '',
  flex_Student_Institution_Name__c: '',
  type: 'Mobile',
  firstNameAndLastName: '',
  phoneCountryCode : '',
  countryCode: '',
  country: '',
  isCitizenOfUnitedStates: '',
  flex_Course_Category__c: '',
  isScholarship: '',
  thankyouPageUrl: '/request-info/thank-you.html',
  flex_Community_College_University__c: '',
  flex_StudentDUNSNumber__c: '',
  date: null,
  time: null,
  flex_RFI_Callback__c:'',
  flex_rfiCallback: '',
  flex_rfiCallbackDateTime: '',
  flex_rfiCallbackTimezone: '',
  isCBE: '',
  isScheduledCall:'',
  highestLevelOfEducation: '',
  line1: '',
  city: '',
  segmentAnonymousId: '',
  segmentUserId: '',
  flex_Student_Employer_Name__c:'',
  flex_Savings_Explorer__c:'',
  zipcode:'',
  flex_DirectMail_OptIn__c:'',
};
