import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues'
import MaterialRadio from "../layout/MaterialComponents/MaterialRadio";
import { Formik, Form } from "formik";
import styles from "../layout/styles/MobileFormStyles";
import MuiButton from '@mui/material/Button/Button';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { unescapeHTML, loadCookiesData, roleChange, submitForm, conditionalCBE, pageNameByPath, fireAnalyticsEvent, fireFOS_DlEvent, updatePrePopCookieValue, scrollToTop, getDegreeLevel, isUserEligible } from '../CommonComponents';
import { courseCategoryDropdown, degreeLevelVariant, dynamicFieldOfStudyDropdownVariant, programTypeOptions, radioOption } from '../common/constants';
import ErrorFocus from '../common/ErrorFocus';
import { validateInternationalStudent } from './validations';
import UopHeader from '../layout/UopHeader';
import { countries } from '../common/countriesList';
import Footer from '../layout/Footer';
import MaterialInternationalPhoneNumber from '../layout/MaterialComponents/MaterialInternationalPhoneNumber';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import Button from '../layout/MaterialComponents/button/Button';
import {dataLayer} from '../common/dataLayer';
import { processJSONParameter } from '../common/configTools';
import skillsblade from '../layout/images/skillsblade.svg';

const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};
const formRef = {};
var feedback = {};
let prevFOS, prevDegreeLevel, prevState, prevCountry, dialCodeLength, prevCourseCategory;
const disclaimerText = rfiFormInitialValues.disclaimer;
let isSavingsExplorer = false;
let subHeader = "Answer a few questions and an enrollment representative will connect with you to chat about options that can help you save time and money on your degree.";
class InternationalStudentRFI extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            activeStep: 1,
            totalSteps: 2,
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            thankyouPageUrl: '/request-info/thank-you.html',
            formRef: null,
            variant: props.variant,
            feedback: any,
            isEligible: true,
            defaultCountry: 'us',
            dynamicFieldOfStudyDropdown: processJSONParameter([...dynamicFieldOfStudyDropdownVariant]),
            degreeLevel: processJSONParameter(props.degreeLevelVariant || [...degreeLevelVariant]),
            initialDegreeLevel: processJSONParameter(props.degreeLevelVariant || [...degreeLevelVariant]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
        };
        this.state = this.initialState;
    }

    dropdownChange = (name, value) => {
        const { initialValues } = this.state;
        initialValues[name] = value;
        //this.setState({ initialValues });
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                this.resetDegreeLevel(value);
            }
        }
        else if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value || value==='') {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
                validateInternationalStudent(initialValues);
            }
        }
        else if (name === 'countryCode' && value && prevCountry !== value) {
            this.setState({ defaultCountry: value.toLowerCase() });
            prevCountry = value;
            if (formRef.current.values.isMilitary || formRef.current.values.isCitizenOfUnitedStates) {
                var isEligible = isUserEligible(value, formRef.current.values.isMilitary, formRef.current.values.isCitizenOfUnitedStates);
                this.setState({ isEligible: isEligible });
            }
        } else if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                updatePrePopCookieValue('courseCategory', value);
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
        
    }

    componentDidMount() {
        window.pagePath = "page-di_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - International RFI Desktop`;
        let { initialValues } = this.state;
        //initialValues =  loadCookiesData(initialValues);

        //prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        //prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        //this.handleZipcodeChange(initialValues.postalCode,initialValues.state);
        if (document.referrer.indexOf('/cost-savings/ways-to-save/results')>0){
            isSavingsExplorer=true;
            subHeader="Answer a few questions and an enrollment representative will reach out soon.";
        }
        this.setState({ initialValues });
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        if (prevFOS){ this.resetDegreeLevel(prevFOS)};
        getIPAddress(this.updateIPAddress);
        formType = "Internation Student RFI";
        formName = "InternationStudent RFI desktop - 2 stepp";
        const { activeStep } = this.state;
        formStep = activeStep;

        // fetch feedback
        let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        // console.log("Current env is: " + process.env.NODE_ENV);
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }

        fetch(baseUrl + '/services/feedback/v1/summary',{
                method: 'GET',
            })
            .then(response => response.json())
            .then(data => feedback = data)
            .catch(function (error) {
            console.log(error);
        });
        
        if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        dataLayer();    
    }

    async componentDidUpdate(prevState) {
        const { activeStep } = this.state;
        if(prevState.activeStep !== activeStep && activeStep === 2){
            await window.scrollTo(0,0)
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
            initialValues: formRef.current.values
        });
        fireAnalyticsEvent('RFIStep'+ activeStep, formName, formType);
        formStep = currentStep;
        scrollToTop();
    };

    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
            this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
        }
        else if (name === 'isCitizenOfUnitedStates') {
            formRef.current.values.isCitizenOfUnitedStates = value;
        }
        var isEligible = isUserEligible(formRef.current.values.countryCode, formRef.current.values.isMilitary,formRef.current.values.isCitizenOfUnitedStates);
        this.setState({isEligible : isEligible});
    };

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }    

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            formRef.current.values.postalCode = zipcode;
            formRef.current.values.stateProvince = state;
            updatePrePopCookieValue('zipcode',zipcode);
            updatePrePopCookieValue('state', state);
            if (state && prevState !== state) {
                this.updateAndNotify(state);
                prevState = state;
            }
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
     }
    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel,fosValue,formRef?.current?.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        if ((formRef.current.values.degreeLevel !== '')) {
          var optionExists = newDegreeLevelOptions.some((e)=>this.state.initialValues.degreeLevel===e.value);
          //if prev degree level doesnt exist, reset
          if (!optionExists) formRef.current.setFieldValue('degreeLevel', '')
        }
        this.setState({degreeLevel:newDegreeLevelOptions}); // EX: Bachelors, Individual Courses
    }
    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }

    isCBEConsent = (event) => {
        const { checked } = event.target;
        formRef.current.values.isCBE = checked;
    }

    handleSubmit = (values) => {
        const { activeStep, totalSteps } = this.state;
        this.setState({ initialValues: values });
        if (activeStep === totalSteps) {
            const { initialValues } = this.state;
            initialValues.country= values.countryCode;
            initialValues.type= 'Home';
            initialValues.phoneNumber = values.phoneNumber.replace(/[^+0-9]/g, '').substr(dialCodeLength, values.phoneNumber.length);
            if (document.activeElement.name === "submit") {
                this.setState({ initialValues: values });
            //    submitForm(values);
                submitForm(values, this.props);
            }
        }
        else {
            if (this.state.isEligible && document.activeElement.name === "next") {                
                this.setState({ activeStep: activeStep + 1 });
                // scrollToTop();
                // document.querySelector('body').scrollTo(0,0);
                fireAnalyticsEvent('RFIStep2', formName, formType);
            }
        }
    }

    render() {
        const { classes } = this.props;
        const {
            initialValues,
            activeStep,
            totalSteps
        } = this.state;

        return (
            <div>
               <UopHeader />   
                <main className="rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer international-student-rfi">
                    <Grid container className="maxWidthContainer-oneStepForm">
                        <Grid item md={12} sm={12} lg={7} xs={12} className="stepCounter">
                            <Typography
                                style={{fontSize:'1.6rem'}}
                                variant='body2'
                                component='div'
                                className={classes.stepCounter}
                            >
                                Step <span className="activeStepCount">{activeStep}</span> of <span className="totalStepsCount">{totalSteps}</span>
                            </Typography>
                        </Grid>
						<Grid item md={12} sm={12} lg={12} xs={12} >
						<div className="newOneForm-Eyebrow">REQUEST INFO – International students</div>
						    {isSavingsExplorer?
                                <Grid item md={12} sm={12} lg={7} xs={12}><h1 className="newOneForm-title" role="heading" aria-level="1">Every dollar counts. Share your info so we can talk about saving options.</h1></Grid>:
                                <Grid item md={12} sm={12} lg={8} xs={12}><h1 className="newOneForm-title" role="heading" aria-level="1">We'd love to get to know you — and your goals</h1></Grid>
                            }
						</Grid>
                        <Grid item md={12} sm={12} lg={7} xs={12} >
                            <div className="newOneForm-SubTitle">{subHeader}</div>
                            <Typography
                                variant='body2'
                                component='div'
                                className="reqinfo"
                            >
                                * Required information
                            </Typography>
                                <Grid item md={12} sm={12} lg={12} xs={12}>
                                    {activeStep === 1 &&
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={this.handleSubmit}
                                            validate={(values) => validateInternationalStudent(values, activeStep)}
                                            innerRef={form => formRef.current = form}
                                            validateOnChange>
                                            {(props) => {
                                                return (
                                                    <Form className="mobile-form"
                                                          aria-label="Request For Information"
                                                          style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                                        <Grid container>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className="margin-OneStepForm">
                                                            <Typography
                                                                    variant='body2'
                                                                    component='div'
                                                                    className='customLabel'
                                                                    id="country"
                                                                >
                                                                    Which country do you currently live in?
                                                                </Typography> <br></br>

                                                                <SelectField
                                                                    id="countryCode"
                                                                    name="countryCode"
                                                                    label="Country of residence *"
                                                                    data={countries}
                                                                    className="MuiFormControl-fullWidth"
                                                                    formRef={formRef}
                                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                                    onChange={this.dropdownChange("countryCode", props.values.countryCode)}
                                                                ></SelectField>
                                                                {roleChange()}
                                                            </Grid>
                                                            <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
                                                                <MaterialRadio value={formRef?.current?.values.isCitizenOfUnitedStates} row={true} className='customLabel' onChange={this.handleChange} items={radioOption} label="Are you a citizen of the United States? *" name='isCitizenOfUnitedStates' ariaLabel="Are you a citizen of the United States?"></MaterialRadio>
                                                            </Grid>
                                                            <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
                                                                <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className='customLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
                                                            </Grid>
                                                            <div className="disclaimer">
                                                                University of Phoenix is accredited in the United States of America by the Higher Learning Commission (hlcommission.org). University of Phoenix degree programs have not been evaluated for accreditation or acceptance in any other country and some or all parts of University programs offered are offered online. The language of instruction is English. It is your sole responsibility to independently verify that the accreditation and all other aspects of the academic offering is valid in my country of residence.
                                                            </div>
                                                            {
                                                                !this.state.isEligible &&
                                                                <div className="user-not-eligible-message">
                                                                    <h3>We're sorry</h3>
                                                                    <span>We're currently not accepting inquiries from this region.</span>
                                                                </div>
                                                            }
                                                            <div className={this.state.isEligible ? "" : "not-eligible-btn"}>
                                                            <Button name='next' size='large' type='submit'>
                                                                Next step
                                                        </Button>
                                                        </div>
                                                        </Grid>
                                                        <ErrorFocus />
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    }
                                    {activeStep === 2 &&
                                        <Formik
                                            initialValues={initialValues}
                                            onSubmit={this.handleSubmit}
                                            validate={(values) => validateInternationalStudent(values, activeStep)}
                                            innerRef={form => formRef.current = form}
                                            validateOnChange>
                                            {(props) => {
                                                return (
                                                    <Form className="mobile-form"
                                                          aria-label="Request For Information"
                                                          style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                                        <Grid container>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className="margin-OneStepForm">
                                                                <SelectField
                                                                    id="fieldOfStudy"
                                                                    name="fieldOfStudy"
                                                                    label="Area of study *"
                                                                    data={dynamicFieldOfStudyDropdownVariant}
                                                                    className="MuiFormControl-fullWidth"
                                                                    formRef={formRef}
                                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                                    onChange={this.dropdownChange("fieldOfStudy", props.values.fieldOfStudy)}
                                                                ></SelectField>
                                                                {roleChange()}
                                                            </Grid>
                                                            {conditionalRadio(props.values.fieldOfStudy) && <>
                                                                <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} className="selectRadioLabel-bold" row={true} onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                            </>}          
                                                            {props.values.fieldOfStudy !== "nondegree" && <>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className="margin-OneStepForm">
                                                                <SelectField
                                                                    id="degreeLevel"
                                                                    name="degreeLevel"
                                                                    label="Degree level *"
                                                                    data={this.state.degreeLevel}
                                                                    className="MuiFormControl-fullWidth"
                                                                    ariaRequired="true"
                                                                    formRef={formRef}
                                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                                    onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                                ></SelectField>
                                                                {roleChange()}
                                                            </Grid> 
                                                            </>}
                                                            {props.values.fieldOfStudy === "nondegree" && <>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                <SelectField
                                                                    id="flex_Course_Category__c"
                                                                    name="flex_Course_Category__c"
                                                                    label="Course category *"
                                                                    data={courseCategoryDropdown}
                                                                    className="MuiFormControl-fullWidth"
                                                                    formRef={formRef}
                                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                                    onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                                ></SelectField>
                                                                {roleChange()}
                                                            </Grid>
                                                            </>}
                                                            {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">
                                                                    <FormikCheckbox type="checkbox" id="isCBE" name="isCBE" className="cbe-checkbox" onChangeCallback={this.isCBEConsent} label={unescapeHTML(this.getCBEText())}>
                                                                    </FormikCheckbox>
                                                                </Grid>
                                                            </>}                                                       
                                                            <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
                                                                <MaterialInput name="firstName" id="firstName"
                                                                    label="First name *" ariaRequired="true" ariaLabel="Enter First name" />
                                                            </Grid>
                                                            <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
                                                                <MaterialInput name="lastName" id="lastName"
                                                                    label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                            </Grid>
                                                            <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
                                                                <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                            </Grid>
                                                            <Grid item md={12} sm={12} lg={6} xs={12} className="margin-OneStepForm">
                                                                {/* <MaterialPhoneNumber name="phoneNumber" label="Phone number *" ariaRequired="true" ariaLabel="Enter phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber> */}
                                                                <MaterialInternationalPhoneNumber
                                                                    name="phoneNumber"
                                                                    label="Phone number"
                                                                    defaultCountry={this.state.defaultCountry}
                                                                    value={this.state.phone}
                                                                    onChange={(phone, country) => {this.setState({ phone });
                                                                    //    props.values.phoneCountryCode = country.countryCode.toUpperCase() + ' +' + country.dialCode;
                                                                    //    props.values.phoneCountryCode = '+' + country.dialCode;
                                                                        props.values.phoneCountryCode = country.countryCode.toUpperCase();
                                                                        dialCodeLength = country.dialCode.length+1;
                                                                    }}
                                                                                                                               
                                                                />
                                                            </Grid>
                                                            <div className="disclaimer">
                                                                {unescapeHTML(disclaimerText)}
                                                            </div>
                                                            <Button name='submit' size='large' type='submit' className="btn-submit">
                                                                Request information
                                                        </Button>
                                                            <div class="backButton">
                                                                <MuiButton color="primary" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></MuiButton>
                                                            </div>
                                                        </Grid>
                                                        <ErrorFocus />
                                                    </Form>
                                                )
                                            }}
                                        </Formik>
                                    }
                                </Grid>
                        </Grid>
                        <Grid item md={10} sm={10} lg={1} xs={12}>
                                                </Grid>
                        <Grid item md={12} sm={12} lg={3} xs={12} className="maxWidthContainer-twoGridoneStepForm">
                        <h2 className="oneForm-four-grid-step internation-form-side-bar" role="heading" aria-level="2"><span className="oneStepSolidBlock">Why</span> choose us</h2>
                        <div className="oneForm-Rectangleline">
                            <ul >
                                <li className="oneStepForm-li" ><strong>No application fee, SAT, GMAT or essay requirements.</strong> We'll request your prior college transcripts for you, at no cost, when possible.</li>
                                <li className="oneStepForm-li">If you are a qualifying new student, you’ll be <strong>awarded one of our scholarships or the best savings</strong>  you qualify for.</li>
                                <li className="oneStepForm-li">Earn your degree faster, and for less. Eligible transfer credits could cover up to <strong>70% of your bachelor's degree</strong>.</li>
                                <li className="oneStepForm-li">Interactions with our academic counselors earned a <strong>5-star rating from 85% of our surveyed students*</strong>.</li>
                            {/*    <li className="oneStepForm-li">More than <strong>100 online programs</strong> aligned to 300+ occupations.</li> */}                                
                            </ul>
                            <ul style={{listStyleType: "none"}}>
                                <li>
                                    <strong>*</strong> Transactional Survey; September 2022 – August 2023 (23,739 respondents)
                                </li>
                            </ul>
                            <img src={skillsblade} alt="Earn career-relevant skills in weeks - not years" style={{ width: "100%", marginTop:"24px" }}></img>
                            
						</div>
                        </Grid>
                          </Grid>
                    </Grid>
                </main>
            <Footer></Footer>
            </div>
        );
    }
}

InternationalStudentRFI.defaultProps = {
    classes: {},
};

InternationalStudentRFI.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(InternationalStudentRFI);