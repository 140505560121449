export const countries = [

    {label: "Afghanistan", value: "AF"},
    {label: "Åland Islands", value: "AX"},
    {label: "Albania", value: "AL"},
    {label: "Algeria", value: "DZ"},
    {label: "American Samoa", value: "AS"},
    {label: "Andorra", value: "AD"},
    {label: "Angola", value: "AO"},
    {label: "Anguilla", value: "AI"},
    {label: "Antarctica", value: "AQ"},
    {label: "Antigua and Barbuda", value: "AG"},
    {label: "Argentina", value: "AR"},
    {label: "Armenia", value: "AM"},
    {label: "Aruba", value: "AW"},
    {label: "Australia", value: "AU"},
    {label: "Austria", value: "AT"},
    {label: "Azerbaijan", value: "AZ"},
    {label: "Bahamas", value: "BS"},
    {label: "Bahrain", value: "BH"},
    {label: "Bangladesh", value: "BD"},
    {label: "Barbados", value: "BB"},
    {label: "Belarus", value: "BY"},
    {label: "Belgium", value: "BE"},
    {label: "Belie", value: "BZ"},
    {label: "Benin", value: "BJ"},
    {label: "Bermuda", value: "BM"},
    {label: "Bhutan", value: "BT"},
    {label: "Bolivia", value: "BO"},
    {label: "Bonaire", value: "BQ"},
    {label: "Bosnia and Herzegovina", value: "BA"},
    {label: "Botswana", value: "BW"},
    {label: "Bouvet Island", value: "BV"},
    {label: "Brazil", value: "BR"},
    {label: "British Antarctic Territory", value: "AQ"},
    {label: "British Indian Ocean Territory", value: "IO"},
    {label: "British Virgin Islands", value: "VG"},
    {label: "Brunei", value: "BN"},
    {label: "Bulgaria", value: "BG"},
    {label: "Burkina Faso", value: "BF"},
    {label: "Burundi", value: "BI"},
    {label: "Cambodia", value: "KH"},
    {label: "Cameroon", value: "CM"},
    {label: "Canada", value: "CA"},
    {label: "Cape Verde", value: "CV"},
    {label: "Caribbean Netherlands", value: "BQ"},
    {label: "Cayman Islands", value: "KY"},
    {label: "Central African Republic", value: "CF"},
    {label: "Chad", value: "TD"},
    {label: "Chile", value: "CL"},
    {label: "China", value: "CN"},
    {label: "Christmas Island", value: "CX"},
    {label: "Cocos Islands", value: "CC"},
    {label: "Colombia", value: "CO"},
    {label: "Comoros", value: "KM"},
    {label: "Cook Islands", value: "CK"},
    {label: "Costa Rica", value: "CR"},
    {label: "Croatia", value: "HR"},
    {label: "Cuba", value: "CU"},
    {label: "Curacao", value: "CW"},
    {label: "Cyprus", value: "CY"},
    {label: "Czech Republic", value: "CZ"},
    {label: "Democratic Republic Of The Congo", value: "CD"},
    {label: "Denmark", value: "DK"},
    {label: "Djibouti", value: "DJ"},
    {label: "Dominica", value: "DM"},
    {label: "Dominican Republic", value: "DO"},
    {label: "East Timor", value: "TL"},
    {label: "Ecuador", value: "EC"},
    {label: "Egypt", value: "EG"},
    {label: "El Salvador", value: "SV"},
    {label: "Equatorial Guinea", value: "GQ"},
    {label: "Eritrea", value: "ER"},
    {label: "Estonia", value: "EE"},
    {label: "Ethiopia", value: "ET"},
    {label: "Falkland Islands", value: "FK"},
    {label: "Faroe Islands", value: "FO"},
    {label: "Fiji", value: "FJ"},
    {label: "Finland", value: "FI"},
    {label: "France", value: "FR"},
    {label: "French Guiana", value: "GF"},
    {label: "French Polynesia", value: "PF"},
    {label: "French Southern Territories", value: "TF"},
    {label: "Gabon", value: "GA"},
    {label: "Gambia", value: "GM"},
    {label: "Georgia", value: "GE"},
    {label: "Germany", value: "DE"},
    {label: "Ghana", value: "GH"},
    {label: "Gibraltar", value: "GI"},
    {label: "Greece", value: "GR"},
    {label: "Greenland", value: "GL"},
    {label: "Grenada", value: "GD"},
    {label: "Guadeloupe", value: "GP"},
    {label: "Guam", value: "GU"},
    {label: "Guatemala", value: "GT"},
    {label: "Guernsey", value: "GG"},
    {label: "Guinea", value: "N"},
    {label: "Guinea-Bissau", value: "GW"},
    {label: "Guyana", value: "GY"},
    {label: "Haiti", value: "HT"},
    {label: "Heard and McDonald Islands", value: "HM"},
    {label: "Honduras", value: "HN"},
    {label: "Hong Kong", value: "HK"},
    {label: "Hungary", value: "HU"},
    {label: "Iceland", value: "IS"},
    {label: "India", value: "IN"},
    {label: "Indonesia", value: "ID"},
    {label: "Iran", value: "IR"},
    {label: "Iraq", value: "IQ"},
    {label: "Ireland", value: "IE"},
    {label: "Isle of Man", value: "IM"},
    {label: "Israel", value: "IL"},
    {label: "Italy", value: "IT"},
    {label: "Ivory Coast", value: "CI"},
    {label: "Jamaica", value: "JM"},
    {label: "Japan", value: "JP"},
    {label: "Jersey", value: "JE"},
    {label: "Jordan", value: "JO"},
    {label: "Kazakhstan", value: "KZ"},
    {label: "Kenya", value: "KE"},
    {label: "Kiribati", value: "KI"},
    {label: "Kuwait", value: "KW"},
    {label: "Kyrgyzstan", value: "KG"},
    {label: "Laos", value: "LA"},
    {label: "Latvia", value: "LV"},
    {label: "Lebanon", value: "LB"},
    {label: "Lesotho", value: "LS"},
    {label: "Liberia", value: "LR"},
    {label: "Libya", value: "Y"},
    {label: "Liechtenstein", value: "LI"},
    {label: "Lithuania", value: "LT"},
    {label: "Luxembourg", value: "LU"},
    {label: "Macau", value: "MO"},
    {label: "Macedonia", value: "MK"},
    {label: "Madagascar", value: "MG"},
    {label: "Malawi", value: "MW"},
    {label: "Malaysia", value: "MY"},
    {label: "Maldives", value: "MV"},
    {label: "Mali", value: "ML"},
    {label: "Malta", value: "MT"},
    {label: "Marshall slands", value: "MH"},
    {label: "Martinique", value: "MQ"},
    {label: "Mauritania", value: "MR"},
    {label: "Mauritius", value: "MU"},
    {label: "Mayotte", value: "YT"},
    {label: "Mexico", value: "MX"},
    {label: "Micronesia", value: "FM"},
    {label: "Moldova", value: "MD"},
    {label: "Monaco", value: "MC"},
    {label: "Mongolia", value: "MN"},
    {label: "Montenegro", value: "ME"},
    {label: "Montserrat", value: "MS"},
    {label: "Morocco", value: "MA"},
    {label: "Mozambique", value: "M"},
    {label: "Myanmar", value: "MM"},
    {label: "Namibia", value: "NA"},
    {label: "Nauru", value: "NR"},
    {label: "Nepal", value: "NP"},
    {label: "Netherlands", value: "NL"},
    {label: "New Caledonia and Dependencies", value: "NC"},
    {label: "New Zealand", value: "NZ"},
    {label: "Nicaragua", value: "NI"},
    {label: "Niger", value: "NE"},
    {label: "Nigeria", value: "NG"},
    {label: "Niue", value: "NU"},
    {label: "Norfolk Island", value: "NF"},
    {label: "North Korea", value: "KP"},
    {label: "Northern Mariana Islands", value: "MP"},
    {label: "Norway", value: "NO"},
    {label: "Oman", value: "OM"},
    {label: "Pakistan", value: "PK"},
    {label: "Palau", value: "PW"},
    {label: "Palestine", value: "PS"},
    {label: "Panama", value: "PA"},
    {label: "Papua New Guinea", value: "PG"},
    {label: "Paraguay", value: "PY"},
    {label: "Peru", value: "PE"},
    {label: "Philippines", value: "PH"},
    {label: "Pitcairn", value: "PN"},
    {label: "Poland", value: "PL"},
    {label: "Portugal", value: "PT"},
    {label: "Puerto Rico", value: "PR"},
    {label: "Qatar", value: "QA"},
    {label: "Republic Of The Congo", value: "CG"},
    {label: "Reunion", value: "RE"},
    {label: "Romania", value: "RO"},
    {label: "Russia", value: "RU"},
    {label: "Rwanda", value: "RW"},
    {label: "Saba", value: "BQ"},
    {label: "Saint Barthelemy", value: "BL"},
    {label: "Saint Helena, Ascension Island, Tristan da Cunha", value: "SH"},
    {label: "Saint Kitts And Nevis", value: "KN"},
    {label: "Saint Lucia", value: "LC"},
    {label: "Saint Martin", value: "MF"},
    {label: "Saint Pierre And Miquelon", value: "PM"},
    {label: "Saint Vincent And The Grenadines", value: "VC"},
    {label: "Samoa", value: "WS"},
    {label: "San Marino", value: "SM"},
    {label: "Sao Tome and Principe", value: "ST"},
    {label: "Saudi Arabia", value: "SA"},
    {label: "Senegal", value: "SN"},
    {label: "Serbia", value: "RS"},
    {label: "Seychelles", value: "SC"},
    {label: "Sierra Leone", value: "SL"},
    {label: "Singapore", value: "SG"},
    {label: "Sint Eustatius", value: "BQ"},
    {label: "Sint Maarten", value: "SX"},
    {label: "Slovakia", value: "SK"},
    {label: "Slovenia", value: "SI"},
    {label: "Solomon Islands", value: "SB"},
    {label: "Somalia", value: "SO"},
    {label: "South Africa", value: "ZA"},
    {label: "South Georgia and the South Sandwich Islands", value: "GS"},
    {label: "South Korea", value: "KR"},
    {label: "South Sudan", value: "SS"},
    {label: "Spain", value: "ES"},
    {label: "Sri Lanka", value: "LK"},
    {label: "Sudan", value: "SD"},
    {label: "Suriname", value: "SR"},
    {label: "Svalbard And Jan Mayen", value: "SJ"},
    {label: "Swaziland", value: "SZ"},
    {label: "Sweden", value: "SE"},
    {label: "Switzerland", value: "CH"},
    {label: "Syria", value: "SY"},
    {label: "Taiwan", value: "TW"},
    {label: "Tajikistan", value: "TJ"},
    {label: "Tanzania", value: "TZ"},
    {label: "Thailand", value: "TH"},
    {label: "Togo", value: "TG"},
    {label: "Tokelau", value: "TK"},
    {label: "Tonga", value: "TO"},
    {label: "Trinidad and Tobago", value: "TT"},
    {label: "Tunisia", value: "TN"},
    {label: "Turkey", value: "TR"},
    {label: "Turkmenistan", value: "TM"},
    {label: "Turks and Caicos Islands", value: "TC"},
    {label: "Tuvalu", value: "TV"},
    {label: "U.S. Virgin Islands", value: "VI"},
    {label: "Uganda", value: "UG"},
    {label: "Ukraine", value: "UA"},
    {label: "United Arab Emirates", value: "AE"},
    {label: "United Kingdom", value: "GB"},
    {label: "United States", value: "US"},
    {label: "United States Minor Outlying Islands", value: "UM"},
    {label: "Uruguay", value: "UY"},
    {label: "Uzbekistan", value: "UZ"},
    {label: "Vanuatu", value: "VU"},
    {label: "Vatican", value: "VA"},
    {label: "Venezuela", value: "VE"},
    {label: "Vietnam", value: "VN"},
    {label: "Wallis and Futuna", value: "WF"},
    {label: "Western Sahara", value: "EH"},
    {label: "Yemen", value: "YE"},
    {label: "Zambia", value: "ZM"},
    {label: "Zimbabwe", value: "ZW"}
    ]
    
    export const restrictedCountries = [
    {label: "Albania",value: "AL"},
    {label: "Austria",value: "AT"},
    {label: "Belgium",value: "BE"},
    {label: "Bulgaria",value: "BG"},
    {label: "Croatia",value: "HR"},
    {label: "Cyprus",value: "CY"},
    {label: "Czech Republic",value: "CZ"},
    {label: "Denmark",value: "DK"},
    {label: "Estonia",value: "EE"},
    {label: "Finland",value: "FI"},
    {label: "France",value: "FR"},
    {label: "Germany",value: "DE"},
    {label: "Greece",value: "GR"},
    {label: "Hungary",value: "HU"},
    {label: "Ireland",value: "IE"},
    {label: "Italy",value: "IT"},
    {label: "Latvia",value: "LV"},
    {label: "Liechtenstein", value: "LI"},
    {label: "Lithuania",value: "LT"},
    {label: "Luxembourg",value: "LU"},
    {label: "Malta",value: "MT"},
    {label: "Netherlands",value: "NL"},
    {label: "Norway", value: "NO"},
    {label: "Poland",value: "PL"},
    {label: "Portugal",value: "PT"},
    {label: "Romania",value: "RO"},
    {label: "Russia",value: "RU"},
    {label: "Slovakia",value: "SK"},
    {label: "Slovenia",value: "SI"},
    {label: "Spain",value: "ES"},
    {label: "Sweden",value: "SE"},
    {label: "Great Britain",value: "GB"},
    {label: "United Kingdom",value: "GB"},
    {label: "Cuba",value: "CU"},
    {label: "Iran",value: "IR"},
    {label: "North Korea",value: "KP"},
    {label: "Syria",value: "SY"},
    {label: "Ukraine",value: "UA"},
    {label: "Macedonia",value: "MK"},
    {label: "Montenegro",value: "ME"},
    {label: "Serbia",value: "RS"},
    {label: "Turkey",value: "TR"},
    {label: "Anguilla (UK)",value: "AI"},
    {label: "Aruba (NL)",value: "AW"},
    {label: "Bermuda (UK)",value: "BM"},
    {label: "Bonaire (NL)",value: "BQ"},
    {label: "Brazil",value: "BR"},
    {label: "British Antarctic Territory (UK)",value: "AQ"},
    {label: "British Indian Ocean Territory (UK)",value: "IO"},
    {label: "British Virgin Islands (UK)",value: "VG"},
    {label: "Cayman Islands (UK)",value: "KY"},
    {label: "Curacao (NL)",value: "CW"},
    {label: "Falkland Islands (UK)",value: "FK"},
    {label: "French Polynesia (FR)",value: "PF"},
    {label: "French Southern and Antarctic Territories (FR)",value: "TF"},
    {label: "Greenland (DK)",value: "GL"},
    {label: "Iceland", value: "IS"},
    {label: "India",value: "IN"},
    {label: "Montserrat (UK)",value: "MS"},
    {label: "New Caledonia and Dependencies (FR)",value: "NC"},
    {label: "Pitcairn (UK)",value: "PN"},
    {label: "Saba (NL)",value: "BQ"},
    {label: "Saint Barthelemy (FR)",value: "BL"},
    {label: "Saint Helena, Ascension Island, Tristan da Cunha (UK)",value: "SH"},
    {label: "Saint Pierre and Miquelon (FR)",value: "PM"},
    {label: "Sint Eustatius (NL)",value: "BQ"},
    {label: "Sint Maarten (NL)",value: "SX"},
    {label: "South Georgia and South Sandwich Islands (UK)",value: "GS"},
    {label: "Turks and Caicos Islands (UK)",value: "TC"},
    {label: "Wallis and Futuna Islands (FR)",value: "WF"}
    ]
     