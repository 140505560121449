import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import styles from '../styles/ImageUploadStyles'
import uploadIconImage from '../images/logo-upload-Icon.svg';

/*const useStyles = makeStyles(() => ({
    fileUploader : {
        width: "100%"
    },
    fileContainer: {
        background: "#fff",
        boxShadow: "2px 2px 3px 0 rgba(0, 0, 0, 0.05)",
        position: "relative",
        borderRadius: "10px",
        padding: "20px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "10px auto",
        transition: "all 0.3s ease-in",
        paddingTop:"0px",
        marginTop: "0px"
    },
    chooseFileInput : {
        opacity: "0",
        position: "absolute",
        zIndex: "-1"
    },
    p : {
        fontSize: "12px",
        margin: "8px 0 4px"
    },
    chooseFileButton : {
        padding: "16px 16px",
        background: "#ffffff",
        borderRadius: "30px",
        color: "#97a3a9",
        fontWeight: "300",
        fontSize: "14px",
        margin: "0",
        transition: "all 0.2s ease-in",
        cursor: "pointer",
        outline: "none",
        border: "none",
        display: "flex",
        borderStyle: "dashed",
        width: "100%",
        alignItems: "center",
        borderColor: "#97a3a9",
        borderWidth: "1px",

        "&:hover": {
            background: "#ffffff",
            borderColor: "#000000"
        }
    },
    uploadIcon: {
        width: "24px",
        height: "24px",
        marginRight: "16px"
    },
    uploadPicturesWrapper : {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        width: "100%"
    },
    uploadPictureContainer : {
        width: "25%",
        margin: "5%",
        padding: "10px",
        background: "#edf2f6",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
        boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)",
        border: "1px solid #d0dbe4",
        position: "relative"
    },
    uploadPicture : {
        width: "100%"
    },
    deleteImage : {
        position: "absolute",
        top: "-9px",
        right: "-9px",
        color: "#fff",
        background: "#ff4081",
        borderRadius: "50%",
        textAlign: "center",
        cursor: "pointer",
        fontSize: "26px",
        fontWeight: "bold",
        lineHeight: "30px",
        width: "30px",
        height: "30px"
    },
    errorsContainer : {
        width: "98%",
        fontSize: "12px",
        color: "red",
        textAlign: "left"
    }

}));
*/
function ImageUpload(props) {
    
    const [picture, setPicture] = useState([]);
    const [files, setFiles] = useState([]);
    const [fileErrors, setFileErrors] = useState([]);
    const [withPreview, setWithPreview] = useState(props.withPreview);
    //const classes = useStyles();
    let inputElement = '';

    //const styles = {
        
    //}
    const renderIcon = () => {
        if (props.withIcon) {
            return <img src={uploadIconImage} className="uploadIcon"	alt="Upload Icon" />;
        }
    }

    const ERROR = {
        NOT_SUPPORTED_EXTENSION: 'NOT_SUPPORTED_EXTENSION',
        FILESIZE_TOO_LARGE: 'FILESIZE_TOO_LARGE',
        NOT_SUPPORTED_DIMENSIONS: 'NOT_SUPPORTED_DIMENSIONS'
    }
    
    const renderLabel = () => {
        if (props.withLabel) {
            return <p className={props.labelClass} style={props.labelStyles}>{props.label}</p>
        }
    }
    /*
   Check if any errors && render
   */
    const renderErrors = () => {
        
        return fileErrors.map((fileError, index) => {
        return (
            <div className={'errorMessage ' + props.errorClass} key={index} style={props.errorStyle}>
            * {fileError.name} {fileError.type === ERROR.FILESIZE_TOO_LARGE ? props.fileSizeError: props.fileTypeError}
            </div>
        );
        });
    }

    /*
	 Check file extension (onDropFile)
	 */
    const hasExtension = (fileName) => {
        const pattern = '(' + props.imgExtension.join('|').replace(/\./g, '\\.') + ')$';
        return new RegExp(pattern, 'i').test(fileName);
    }

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          // Read the image via FileReader API and save image result in state.
          reader.onload = function (e) {
            // Add the file name to the data URL
            let dataURL = e.target.result;
            dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
            resolve({file, dataURL});
            
            /*let img = new Image();
            img.src = dataURL;
            // Check for file dimensions
            if(img.width > props.maxWidth && img.height > props.maxHeight) {
                fileError = Object.assign(fileError, {
                  type: ERROR.NOT_SUPPORTED_DIMENSIONS
                });
                fileErrors.push(fileError);
                //continue;
            }

            setFileErrors(fileErrors);

            if(!fileErrors.length > 0){*/
                setPicture(dataURL);
                setFiles(file);
                if(file.size > 0 && dataURL.length > 0){
                    setWithPreview(true);
                    props.onChange(file, dataURL)
                }
            //}
            
          };
    
          reader.readAsDataURL(file);
        });
    }

    /*
   Remove the image from state
   */
    const removeImage = (picture)=> {
        
        setPicture([]);
        setFiles([]);       
        setWithPreview(false);
        props.onChange([], []);
        
    }

    const renderPreviewPictures = () => {
        return (
            <div  className="uploadPictureContainer">
              <div className="deleteImage" onClick={() => removeImage(picture)}>X</div>
              <img src={picture} className="uploadPicture" alt="preview"/>
            </div>
        )
    }

    /*
   Render preview images
   */
    const renderPreview = () => {
        return (
            <div className="uploadPicturesWrapper">
                {renderPreviewPictures()}
            </div>
        );
    }    

    /*
   Handle file validation
   */
    const onDropFile = (e) => {
        const files = e.target.files;
        const allFilePromises = [];
        const fileErrors = [];

        if(files.length > 0){
            let file = files[0];
            let fileError = {
              name: file.name,
            };
            // Check for file extension
            if (!hasExtension(file.name)) {
              fileError = Object.assign(fileError, {
                type: ERROR.NOT_SUPPORTED_EXTENSION
              });
              fileErrors.push(fileError);
              //continue;
            }
            // Check for file size
            if(file.size > props.maxFileSize) {
              fileError = Object.assign(fileError, {
                type: ERROR.FILESIZE_TOO_LARGE
              });
              fileErrors.push(fileError);
              //continue;
            }
      
            setFileErrors(fileErrors);
            if(!fileErrors.length > 0){
                allFilePromises.push(readFile(file));            
            }else{
                removeImage(picture);
            }            
            
        }
        
    }

    const onUploadClick = (e) => {
        // Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
        e.target.value = null;
    }

    /*
   On button click, trigger input file to open
   */
    const triggerFileUpload = () => {
        inputElement.click();
    }
    return (
        <div className={"fileUploader"+" " + props.className} style={props.style}>
            <div className="fileContainer" style={props.fileContainerStyle}>
                        
            <button
                type={props.buttonType}
                className={"chooseFileButton"+" " + props.buttonClassName}
                style={props.buttonStyles}
                onClick={triggerFileUpload}
            >
                {renderIcon()}
                {/*renderLabel()*/}
                {props.buttonText}
                
            </button>
            <input
                type="file"
                ref={input => inputElement = input}
                name={props.name}
                multiple={!props.singleImage}
                onChange={onDropFile}
                onClick={onUploadClick}
                accept={props.accept}
                className="chooseFileInput"
            />

            {withPreview ? renderPreview() : null}

            <div className="errorsContainer">
                {renderErrors()}
            </div>
            </div>
        </div>
    )
}

ImageUpload.defaultProps = {
    className: '',
    style: {},
    fileContainerStyle: {},
    buttonType: "button",
    buttonClassName: "",
    buttonStyles: {},
    buttonText: "Upload company logo (300px x 180px, jpeg, png, gif)",
    withPreview: false,
    name:"",
    singleImage: false,
    accept: "image/*",
    withLabel: true,
    onChange: () => {},
    fileSizeError: " file size is too big",
    fileTypeError: " is not a supported file extension",
    errorClass: "",
    errorStyle: {},
}
ImageUpload.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    fileContainerStyle: PropTypes.object,
    buttonType: PropTypes.string,
    buttonClassName: PropTypes.string,
    buttonStyles: PropTypes.object,
    buttonText: PropTypes.string,
    withPreview: PropTypes.bool,
    name: PropTypes.string,
    singleImage: PropTypes.bool,
    accept: PropTypes.string,
    withLabel: PropTypes.bool,
    onChange: PropTypes.func,
    fileSizeError: PropTypes.string,
    fileTypeError: PropTypes.string,
    errorClass: PropTypes.string,
    errorStyle: PropTypes.object,
}

export default withStyles(styles)(ImageUpload);

