import React from 'react';
import AppBar from '@mui/material/AppBar';
//import { makeStyles } from '@mui/styles';
import Toolbar from "@mui/material/Toolbar";
import logo from "./images/uop-logo.svg";

/*const useStyles = makeStyles({
  uopLogo: {
    height: "6rem",
    width: "18rem",
    margin: "11px 0px",
    "@media (max-width: 900px)": {
      height: "5.6rem",
      width: "4.8rem",

    }
  },
  uopLogoTitle: {
       display:"none",
         "@media (max-width: 600px)": {
        display:"block",
        height: "auto",
        fontSize: "20px",
        color: "#001823",
        lineHeight: "26px",
        width: "100%",
        marginLeft:"2rem",
        marginBottom: "0px",
       }
    },
});*/

const UopHeader = () => {
//  const { uopLogo, uopLogoTitle } = useStyles();
  return(
    <AppBar color="default" position="static" elevation={1}>
      <Toolbar>
        <a href="/"> <img src={logo} alt="University of Phoenix Homepage" className="uopLogo" /> </a>
        {window.location.href.indexOf("/unsubscribe") === -1 && window.location.href.indexOf("/communication/preferences") === -1 && <h1 className="uopLogoTitle">Request information</h1> }
        </Toolbar>
    </AppBar>
  );
}

export default UopHeader;