import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import rfiFormInitialValues from './layout/FormModels/RFIFormInitialValues'
import MaterialRadio from "./layout/MaterialComponents/MaterialRadio";
import { Formik, Form } from "formik";
import styles from './layout/styles/MobileFormStyles';
import MaterialInput from './layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from './layout/MaterialComponents/MaterialPhoneNumber';
import { validateOneStep } from './layout/MobileFormValidation';
import getIPAddress, { unescapeHTML, loadCookiesData, roleChange, submitForm, conditionalCBE, pageNameByPath, fireAnalyticsEvent, fireFOS_DlEvent, updatePrePopCookieValue } from './CommonComponents';
import { degreeLevel, fieldOfStudyDropdown, radioOption } from './common/constants';
import FormikErrorFocus from 'formik-error-focus';
import UOPHeader from './layout/UopHeader';
import Footer from './layout/Footer';
import Button from './layout/MaterialComponents/button/Button';
import ZipCode from './layout/MaterialComponents/ZipCode';
import FormikCheckbox from './layout/MaterialComponents/checkbox/FormikCheckbox';
import {dataLayer} from './common/dataLayer';

const formRef = {};
var feedback = {};
let prevFOS, prevDegreeLevel, prevState;
const disclaimerText = rfiFormInitialValues.disclaimer;

const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};

class AAPFRfi extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            formRef: null,
            variant: props.variant,
            feedback: any,
            activeStep: 1,
        };
        this.state = this.initialState;
    }

    dropdownChange = (name, value) => {
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value; 
            }
        }
        else if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
            }
        }
    }

    componentDidMount() {
        window.pagePath = "page-dp_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - Desktop`;
        formPageType = "RFI";
        formType = "RFI";
        formName = "RFI desktop - 1 step";
        let { initialValues } = this.state;
        initialValues =  loadCookiesData(initialValues);
        formRef.current.values.fieldOfStudy = 'Liberal Arts'; 
        formRef.current.values.degreeLevel = 'Associates'; 
        this.handleZipcodeChange(initialValues.postalCode,initialValues.stateProvince);
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress);
        const { activeStep } = this.state;
        formStep = activeStep;

        // fetch feedback
    /*    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        // console.log("Current env is: " + process.env.NODE_ENV);
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }

        fetch(baseUrl + '/services/feedback/v1/summary',
            {
                method: 'GET',
            })
            .then(response => response.json())
            .then(data => feedback = data)
            .catch(function (error) {
                console.log(error);
            }); */
	    if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        } 
        dataLayer();
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
        }
    };

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }    

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            formRef.current.values.postalCode = zipcode;
            formRef.current.values.stateProvince = state;
            updatePrePopCookieValue('zipcode',zipcode);
            updatePrePopCookieValue('state', state);

            if (state && prevState !== state) { 
                prevState = state;
            }
            this.updateAndNotify(state);
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
     }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    getCBEText() {
        let cbeText = "I’d like to know if I meet the admission requirements to earn my competency-based master’s degree in <strong>less than 1 year for under $11K.</strong>";
        return cbeText;
    }

    isCBEConsent = (event) => {
        const { checked } = event.target;
        formRef.current.values.isCBE = checked;
    }

    handleSubmit = (values) => {
        values.fieldOfStudy = 'liberal-arts';
        values.degreeLevel = 'associates';
        values.code = 'AA/PF';
        if(document.activeElement.name === "submit"){
            this.setState({ initialValues: values });
            submitForm(values, this.props);
        }
    }

    render() {
        const { classes } = this.props;
        const {
            activeStep,
            initialValues,
        } = this.state;

        return (
            <div>
                <UOPHeader /> 
                <main className="rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
						<Grid item md={12} sm={12} lg={12} xs={12} >
						<div className="newOneForm-Eyebrow">UNIVERSITY OF PHOENIX</div>
						<h1 className="newOneForm-title">We'd love to get to know you &mdash; and your goals</h1>
						</Grid>
                        <Grid item md={12} sm={12} lg={7} xs={12} >
                            <div className="newOneForm-SubTitle">An enrollment representative will contact you soon to better understand your career goals, programs you're interested in, and any questions about the admissions process and more.</div>
                            <Typography
                                variant='body2'
                                component='div'
                                className="reqinfo"
                            >
                                * Required information
                            </Typography>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => validateOneStep(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Request For Information"
                                                  style={{ position: 'relative' }}
                                                  onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container>
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="fieldOfStudy" id="fieldOfStudy"
                                                    label="Area of study" ariaRequired="true" ariaLabel="Enter Field of study" isDisabled={true}  />
                                                 </Grid>
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="degreeLevel" id="degreeLevel"
                                                    label="Degree level" ariaRequired="true" ariaLabel="Enter degree Level" isDisabled={true}  />
                                                 </Grid>
                                                 {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">
                                                        <FormikCheckbox type="checkbox" id="isCBE" name="isCBE" className="cbe-checkbox" onChangeCallback={this.isCBEConsent} label={unescapeHTML(this.getCBEText())}>
                                                        </FormikCheckbox>
                                                    </Grid>
                                                </>}
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                 <MaterialInput name="firstName" id="firstName"
                                                    label="First name *" ariaRequired="true" ariaLabel="Enter First name"  />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="lastName" id="lastName"
                                                    label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialPhoneNumber name="phoneNumber" label="Phone number *" ariaRequired="true" ariaLabel="Enter phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                </Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" aria-describedby="Enter Zip code" aria-label="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef}></ZipCode>
												</Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className= 'selectRadioLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
												 </Grid>
                                                 {conditionalRadio(props.values.fieldOfStudy) && <>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} row={true} className="selectRadioLabel" onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                    </Grid>    
                                                    </>}
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                   <div style={{fontSize:"14px", fontWeight:"normal", marginBottom:'18px'}}>You might be <strong>eligible for tuition savings</strong> if your employer has an alliance with us. Talk to your enrollment representative about it — as well as all the other ways you can <strong>save time and money</strong> on your degree.</div>
                                                </Grid>												
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                     <div className="employer">
                                                        <MaterialInput name="flex_Employer_Disclosed_on_RFI__c" id="flex_Employer_Disclosed_on_RFI__c"
                                                            label="Employer name (optional)" ariaLabel="Employer name (optional)"  />
                                                    </div>
                                                 </Grid> 
                                                <div className="disclaimer">
                                                  {unescapeHTML(disclaimerText)}    
                                                </div>
                                                <Button name='submit' size='large' type='submit' className="btn-submit">
                                                            Request information
                                                        </Button>
                                                </Grid>
												<FormikErrorFocus offset={-50} align={'top'} focusDelay={200} ease={'linear'} duration={1000} />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        <Grid item md={10} sm={10} lg={1} xs={12}>
                                                </Grid>
                        <Grid item md={12} sm={12} lg={3} xs={12} className="maxWidthContainer-twoGridoneStepForm">
                        <h2 className="oneForm-four-grid-step"><span className="oneStepSolidBlock">Why</span> choose us</h2>

                        {/*        <div className="trust_score_wrapper" style={{ fontSize: "16px", lineHeight: '18px', color: "#001823", textDecoration: "none solid rgb(0, 24, 35)", fontFamily: "Roboto", maxHeight: "20px", display: "none", marginBottom: "3.5rem", marginTop: "2.5rem" }}>
                                    <span className="score_block" style={{ color: "#001823", paddingRight: "9px" }}><a href="/students/reviews.html"
                                                                                                                       rel="noopener noreferrer"
                                                                                                                       target="_blank"><span className="trust_score" style={{ color: "#001823", fontWeight: "bold", paddingRight: "4px", marignLeft: "4px" }}>{feedback?.score?.trustScore}</span></a> out  of  5</span>
                                    <span className="star_rating" style={{ paddingLeft: "3px", paddingRight: "2px" }}>
                                        {feedback?.score?.stars === 0 &&
                                            <img className="0_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_0star_grey-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 1 &&
                                            <img className="1_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_1star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 1.5 &&
                                            <img className="1.5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_1halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 2 &&
                                            <img className="2_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_2star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 2.5 &&
                                            <img className="2.5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_2halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 3 &&
                                            <img className="3_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_3star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 3.5 &&
                                            <img className="3.5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_3halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 4 &&
                                            <img className="4_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_4star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 4.5 &&
                                            <img className="4.5_star_icon star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_4halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 5 &&
                                            <img className="5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_5star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                    </span>
                                    <span className="reviews_block" style={{ paddingLeft: "10px" }}>
                                        <a href="/students/reviews.html"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <span className="reviews" style={{ color: "#001823", fontWeight: "bold" }}>{feedback?.numberOfReviews?.total} reviews
                                            </span>
                                        </a>
                                        from
                                    </span>
                                    <a style={{ height: "20px" }}
                                       rel="noopener noreferrer"
                                       href="https://www.trustpilot.com/review/phoenix.edu"
                                       target="_blank"><img style={{ height: "21px" }} className="trust_store_icon" src="/content/dam/uopx/images/Trustpilot_brandmark_gr-blk_RGB-144x36-M.png.png" alt="Trustpilot home"></img></a>
                                </div>
                        */}
                        <div className="oneForm-Rectangleline">
						<ul >
                            <li className="oneStepForm-li" ><strong>No application fee, SAT, GMAT or essay requirements.</strong> We'll request your prior college transcripts for you, at no cost, when possible.</li>
                            <li className="oneStepForm-li">If you are a qualifying new student, you’ll be <strong>awarded one of our scholarships or the best savings</strong>  you qualify for.</li>
                            <li className="oneStepForm-li">Earn your degree faster, and for less. Eligible transfer credits could cover up to <strong>70% of your bachelor's degree</strong>.</li>
                            <li className="oneStepForm-li">Interactions with our academic counselors earned a <strong>5-star rating from 85% of our surveyed students*</strong>.</li>
                            {/*<li className="oneStepForm-li">More than <strong>100 online programs</strong> aligned to 300+ occupations.</li>*/}
                        </ul>
                        <ul style={{listStyleType: "none"}}>
                            <li>
                                <strong>*</strong> Transactional Survey; September 2022 – August 2023 (23,739 respondents)
                            </li>
                        </ul>
						</div>
                        </Grid>
                          </Grid>
                    </Grid>
                </main>
                <Footer />
            </div>
        );
    }
}

AAPFRfi.defaultProps = {
    classes: {},
};

AAPFRfi.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(AAPFRfi);