const loadedScripts = {};

export const importScript = (resourceUrl, onLoad) => {
  const script = document.createElement('script');
  script.src = resourceUrl;
  script.type = 'text/javascript';
  script.addEventListener('load', onLoad);
  script.async = true;
  //    console.log("script ",script);
  document.body.appendChild(script);
};

export const importScriptOnce = (scriptPath, onLoad = null) => {
  if (loadedScripts[scriptPath]) return;
  loadedScripts[scriptPath] = true;
  importScript(scriptPath, onLoad);
};

export const importCss = resourceUrl => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = resourceUrl;
  link.type = 'text/css';
  //    console.log("link ",link);
  document.body.appendChild(link);
};

export const importCssOnce = resourceUrl => {
  if (loadedScripts[resourceUrl]) return;
  loadedScripts[resourceUrl] = true;
  importCss(resourceUrl);
};
