import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
import { alumniBusinessFormValidate } from "./EmailusValidation";
import getIPAddress, { unescapeHTML, roleChange, pageNameByPath, fireAnalyticsEvent, fireNonRFISubmitEvent} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus';
import { businessCategoryList } from '../common/constants';
import axios from 'axios';
import checkmark from '../layout/images/checkmark.svg';
import ImageUpload from '../layout/MaterialComponents/ImageUpload';
import Button from '../layout/MaterialComponents/button/Button';
import { initValues, processJSONParameter } from '../common/configTools';

const initialalumniBusinessFormValues ={
    firstAndLastName:'',
    companyName:'',
    companyEmailAddress:'',
    companyWebsite:'',
    companyPhoneNumber:'',
    callToAction:'',
    callToActionURL:'',
    businessCategory:'',
    flex_more_info_business:'',
    flex_more_info_instructions:'',
    flex_more_info_eligibility:'',    
    uploadCompanyLogo:'',
    uploadedFileName:'',
    uploadedFileType:'',
    disclaimer:"By submitting this form, you acknowledge that you have the authority to submit the foregoing information on behalf of the business represented. You further acknowledge that: a.) the participation of your business is solely in the University’s discretion and it may either not list you and/or terminate your participation at any time for any reason or no reason; b.) neither you, nor anyone acting on behalf of the business will engage in any activity that would violate the University’s <a href='https://www.phoenix.edu/copyright-legal/terms_and_conditions.html'> Terms and Conditions </a> and c.) the University of Phoenix will be sharing this information with its vendor(s) providing services with respect to the Phoenix Discount Center. View our <a href='https://www.phoenix.edu/copyright-legal/privacy-policy.html'> Privacy Policy </a>.",
    formName:'AlumniBusinessForm',
    requestingUrl:''
};
const formRef = {};
let prevBusinessCategory;
const disclaimerText = initialalumniBusinessFormValues.disclaimer;

//const businessCategoryList = businessCategoryList;

const onBusinessTextAreaChange= (e) => {
    formRef.current.values.flex_more_info_business = e.target.value;
  }
  const onInstructionsTextAreaChange= (e) => {
    formRef.current.values.flex_more_info_instructions = e.target.value;
  }
  const onEligibilityTextAreaChange= (e) => {
    formRef.current.values.flex_more_info_eligibility = e.target.value;
  }
  
  class AlumniBusinessForm extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialalumniBusinessFormValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState; 
    }
    componentDidMount() {
        //const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href;
        //initValues(initialValues, this.props);
        this.setState({ initialValues });
        const { currentStep } = this.state;
        formStep = currentStep;  
	    formType = 'Non-RFI';
    	formName = 'AlumniBusinessForm';
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
    }
    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }
    uploadedImage = (file,dataURL) => {
    /* console.log("file"+ file);
        console.log("dataURL"+ dataURL);*/
        let { initialValues } = this.state;
        initialValues.uploadCompanyLogo = dataURL.length > 0 ? dataURL.split("base64,")[1] : '';//dataURL;
        initialValues.uploadedFileName = file.name;
        initialValues.uploadedFileType = file.type;
        this.setState({initialValues});    
        formRef.current.values.uploadCompanyLogo
    }

    dropdownChange = (name, value) => {
        if (name === 'businessCategory') {
            if (value && prevBusinessCategory !== value) {
                prevBusinessCategory = value;
            }
        }
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }
    handleSubmit = (values) => { 
    //    hashValues(values);
    if (document.activeElement.name === "submit"){
        values.uploadCompanyLogo = this.state.initialValues.uploadCompanyLogo;
        values.uploadedFileName = this.state.initialValues.uploadedFileName;
        values.uploadedFileType = this.state.initialValues.uploadedFileType; 
        this.setState({ initialValues: values });
        const headers = {'Content-Type': 'application/json'};
        let URL = 'https://www.phoenix.edu/request/sendMailWithAttachment';
        const data = JSON.stringify(values);
        axios.post(URL, data, {headers})
        .then((response) => {
            if (response.data.msg === 'success'){
                console.log("Email sent, awesome!");
                this.setState({
                    initialValues: values,
                    isSubmit: true,
                });
            }else if(response.data.msg === 'fail'){
                console.log("Oops, something went wrong. Try again")
            }
        })
        .catch(error => {
            console.log (error.message);   
        });
        fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
    }
    }

    render() {
        //    const { classes } = this.props;
        const { isSubmit, initialValues } = this.state;
        //    let titleText = this.props.title || defaultTextHTML;

        return (
            
            <div>
            {/*   <Header />   */ }
                <div className="AlumniBusinessform-main">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">

                     {!isSubmit
                    ? (
                        <>
                        <Grid item md={12} sm={12} lg={12} xs={12} >
                            <div className="reqinfo" > * Required information </div>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => alumniBusinessFormValidate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="AlumniBusinessform"
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                            <Grid container >
                                                <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm">                                               
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="firstAndLastName" id="firstAndLastName"
                                                            label="First and Last name *" ariaRequired="true" ariaLabel="First and last name" onClick={this.handleAnalyticsRFIFormStart} />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="companyName" id="companyName"
                                                            label="Company name *" ariaRequired="true" ariaLabel="Company name" />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="companyEmailAddress" id="companyEmailAddress"
                                                            label="Company email address *" ariaRequired="true" ariaLabel="Company email address" />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="companyWebsite" id="companyWebsite" label="Company website *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <div className="employer">
                                                            <MaterialPhoneNumber name="companyPhoneNumber" id="companyPhoneNumber" label="Company phone number"  handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="callToAction" id="callToAction"
                                                            label="Call to action (i.e. Learn more, Shop now, Enroll here)" required ariaRequired="true" ariaLabel="Call to action (i.e. Learn more, Shop now, Enroll here)" />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <MaterialInput name="callToActionURL" id="callToActionURL"
                                                            label="Call to action URL *" ariaRequired="true" ariaLabel="Call to action URL" />
                                                    </Grid>
                                                    
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <SelectField
                                                            id="businessCategory"
                                                            name="businessCategory"
                                                            label="Business category *"
                                                            className="MuiFormControl-fullWidth"
                                                            data={businessCategoryList}
                                                            ariaRequired="true"
                                                            formRef={formRef}
                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                            onChange={this.dropdownChange("businessCategory", props.values.businessCategory)}
                                                        ></SelectField>
                                                        {roleChange()}
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={6} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm reduceHeight" >
                                                        <MaterialTextArea
                                                            id="flex_more_info_business"
                                                            name="flex_more_info_business"
                                                            onChange={onBusinessTextAreaChange}
                                                            label="Description of business (500 characters, max) *"
                                                            classNames="uopx-input more_info_textarea"
                                                            minRows={1}
                                                            ariaLabel="Description of business (500 characters, max) *"
                                                            ariaRequired="true"
                                                            /*placeholder="Suggestions: What inspired you to go to college? What motivated you to keep going? What did you learn about yourself? Any advice for someone considering enrolling?"*/
                                                        />
                                                    </Grid>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm reduceHeight" >
                                                        <MaterialTextArea
                                                            id="flex_more_info_instructions"
                                                            name="flex_more_info_instructions"
                                                            onChange={onInstructionsTextAreaChange}
                                                            label="Offer details and instructions. What it is and what monetary discount are you offering? For example: use code UOPX for 10% off. Discount for community members is required. *"                                                    
                                                            classNames="uopx-input more_info_textarea "
                                                            minRows={1}                                                            
                                                            ariaRequired="true"
                                                        /> {roleChange()}
                                                    </Grid>
                                                    
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm reduceHeight" >
                                                        <MaterialTextArea
                                                            id="flex_more_info_eligibility"
                                                            name="flex_more_info_eligibility"
                                                            onChange={onEligibilityTextAreaChange}
                                                            label="Eligibility, restrictions or other notices (if applicable)"
                                                            classNames="uopx-input more_info_textarea reduceHeight"
                                                            minRows={1}
                                                            ariaLabel="Eligibility, restrictions or other notices (if applicable)"
                                                
                                                            /*placeholder="Eligibility, restrictions or other notices"*/
                                                        /> 
                                                    </Grid>
                                                
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm alumni-story" >
                                                    <ImageUpload
                                                            id="uploadCompanyLogo"
                                                            withIcon={true}
                                                            buttonText='Upload company logo (300px x 180px, jpeg, png, gif)'
                                                            onChange={this.uploadedImage}
                                                            imgExtension={['.jpg', '.gif', '.png']}
                                                            maxFileSize={1048576}
                                                            label="Upload company logo (300px x 180px, jpeg, png, gif)"
                                                        />
                                                    </Grid>
                                                    
                                                </Grid>
                                                    <Grid item md={9} sm={12} lg={8} xs={12} className ="margin-OneStepForm" style={{ alignItems: 'center', display: 'flex' }}>
                                                        <div className="disclaimer">
                                                            { unescapeHTML(disclaimerText) }
                                                        </div>
                                                    </Grid>
                                                    
                                                    <Grid item md={3} sm={12} lg={4} xs={12} className ="margin-OneStepForm submit-btn" style={{ textAlign: 'right'}}>
                                                     <Button name='submit' size='large' type='submit' className="btn-submit"> Submit </Button>
                                                    </Grid>

                                            
                                            </Grid>
							                <ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        </>
                    )
                    : (
                          <>
                            <Grid item md={12} sm={12} lg={12} xs={12} className='thanksContainer'>
                              <div className='thanksHeader' style={{ fontWeight: '700' }}>
                                <img src={checkmark} alt="Checked Circle" />
                                Thanks for Business Form Submission
                              </div>
                            </Grid>
                          </>
                        )}
                          </Grid>
                    </Grid>
                </div>
            { /*   <Footer /> */ } 
            </div>
        );
    }
}


AlumniBusinessForm.defaultProps = {
    classes: {},
};

AlumniBusinessForm.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(AlumniBusinessForm);