export default function SubscribeStyles() {
  return {
    container: {
      position: 'relative',
      overflow: 'hidden',
    },
    root: {
      margin: '5px', // theme.spacing(3),
      '& .uopx-input.MuiTextField-root': {
        marginBottom: '0 !important',
      },

      '& .disclaimer': {
        color: 'rgb(94, 112, 121)',
        marginBottom: '0 !important',
      },
      zIndex: 2,
      '& h1.newOneForm-title': {
        fontSize: '32px',
        lineHeight: '38px',
        fontWeight: '400',
        '@media (max-width: 900px)': {
          fontSize: '24px',
          lineHeight: '28px',
        },
      },
      '& div.newOneForm-SubTitle': {
        fontFamily: '"IBM Plex Serif", serif',
      },
      // subscribeFormSubTitle: {
      //   fontSize: '16px',
      //   lineHeight: '24px',
      //  fontWeight: '400',
      //   marginBottom: '15px',
      // },'
    },
    error: {
      color: 'red',
      fontWeight: 'bold',
      padding: '0.5em',
      '& >img' :{
        display: 'none',
      },
    },
    success: {
      fontWeight: 'bold',
      padding: '1em',
    },
    thanksHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      '& > img': {
        margin: 12,
      },
    },
    thanksText: {
      fontSize: '20px',
    },
    thanksContainer: {
      margin: 'auto',
      marginLeft: '10px !important',
      border: '1px solid',
      textAlign: 'center',
      paddingBottom: '18px !important',
    },
    unsubSuccess:{
    padding: '1em',
    fontWeight: 'bold',
    background: '#CDF6EC',
    display:'flex',
    '& >img' :{
      display: 'block',
    },
    },
    unsubCheck:{
      background: '#FFF1E4',
      fontWeight: '400',
      padding: '12px 16px',
      marginBottom: '2rem',
      display:'flex',
    },
  };
}
