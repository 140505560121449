import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { Grid, Typography } from '@mui/material/';
import FormikErrorFocus from 'formik-error-focus';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
//import { fireEvent } from '../CommonComponents';
import { fireAnalyticsEvent, fireNonRFISubmitEvent } from '../CommonComponents';
import { submitUnsubEmailForm } from './UnsubscribeAPI';
import { withStyles } from '@mui/styles';
import styles from '../inline/SubscribeStyles';
import Button from '../layout/MaterialComponents/button/Button';
import CheckCircle from '../layout/images/check-circle.svg';
//import ReCAPTCHA from 'react-google-recaptcha';

function srSpeak(text, priority) {
  try{
      var el = document.createElement("div");
      var id = "make-a-screen-reader-talk-" + Date.now();
      el.setAttribute("id", id);
      el.setAttribute("aria-live", priority || "polite");
      el.classList.add("visually-hidden");
      document.body.appendChild(el);
      
      window.setTimeout(function () {
          document.getElementById(id).innerHTML = text;      
      }, 100);
      
      window.setTimeout(function () {
          if (document.getElementById(id)){
              document.body.removeChild(document.getElementById(id));
          }
      }, 1000);
  } catch(e){
      console.log ("error", e);
      throw e;
  }
}

class UnsubscribeEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      initialValues: {},
      variant: props.variant,
      message: null,
    };
    this.ref = {
      form: React.createRef(),
      container: React.createRef(),
      //recaptcha: React.createRef(),

    };
  }

  componentDidMount() {
    formType = 'Non-RFI';
    formName = 'email Unsubscribe';
    
  }

  async handleEmailSubmit(values) {
    values.requestingUrl = window.location.href;
    if (document.activeElement.name === "submit") {
    values.emailAddress = (values.emailAddress).trim();
    const { classes } = this.props;
  //  fireEvent('rfi_unsubscribe_email_submit');
    let responseJSON;
    try {
      responseJSON = await submitUnsubEmailForm(values);
    } catch (e) {
     responseJSON = { message: `${e}`, success: false };
    }
    this.setState({
      initialValues: values,
      isSubmit: !!responseJSON.success,
      message: <div id='thankstitle' aria-labelledby='thankstitle' role='region' aria-live='assertive'  className={responseJSON.success ? classes.unsubSuccess : classes.error}><img alt='checkmark' src={CheckCircle} style={{paddingRight: "0.5rem"}}/>{responseJSON.message || 'No Message Given'}</div>,
    });
    srSpeak("Unsubscribe Email request sent successfully.");
    this.ref.container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    fireNonRFISubmitEvent( 'NonRFISubmit', values, 'email Unsubscribe', 'Non-RFI');
    }
  }

  handleAnalyticsRFIFormStart = () => {
    if (this.state.rfi_begin_email !== true) {
      fireAnalyticsEvent('NonRFIStart', 'email Unsubscribe', 'Non-RFI');
      this.setState({ rfi_begin_email: true });
    }
  }

  validateEmail(values) {
    // console.log('validate', values);
    const errors = {};
    if (!values.emailAddress) {
      errors.emailAddress = 'Email Address requires a value';
    }
    if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.emailAddress = 'Email address appears invalid';
    }
    return errors;
  }

  render() {
    // console.log('UnsubEmail rendering...');
    const { isSubmit, initialValues } = this.state;
    return (
      <div ref={this.ref.container} className="unsubscribe">
        {!isSubmit
          ? (
            <Formik
              initialValues={initialValues}
              onSubmit={values => this.handleEmailSubmit(values)}
              validate={values => this.validateEmail(values)}
              innerRef={this.ref.form}
              validateOnChange
            >
              <Form
                className="unsubscribe-email-form"
                style={{ position: 'relative', paddingBottom: '2rem' }}
                onChange={this.handleAnalyticsRFIFormStart}
              >
                <Grid containerid="emailForm">
                <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                  {this.state.message}
                  <Grid
                    item
                    md={12}
                    sm={12}
                    lg={12}
                    xs={12}
                    className="margin-OneStepForm"
                    id="emailFields"
                  >
                    <MaterialInput
                      name="emailAddress"
                      id="emailAddress"
                      label="Email address"
                      required="true"
                      ariaLabel="Enter Email address"
                      variant="outlined"
                      onClick={this.handleAnalyticsRFIFormStart}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} lg={12} xs={12}>
                    <div>
                    
                      <Button
                        name='submit'
                        size="large"
                        type="submit"
                        className='rfi-btn-submit submit-btn'
                        ariaLabel="Submit Email Unsubscribe Form"
                        style={{backgroundColor: "#30718d"}}
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                
                <FormikErrorFocus
                  offset={-50}
                  align="top"
                  focusDelay={200}
                  ease="linear"
                  duration={1000}
                />
              </Form>
            </Formik>
          )
          : this.state.message}
      </div>
    );
  }
}

export default withStyles(styles)(UnsubscribeEmailForm);
