import React, {useEffect, useState} from "react";
import { FormHelperText, InputAdornment } from "@mui/material/";
import { at } from 'lodash';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import { useField } from "formik";
import MuiPhoneInput from 'material-ui-phone-number'
import '../styles/Input.scss';
import {makeid} from '../../CommonComponents';

function MaterialInternationalPhoneNumber(props) { 
  const { errorText, isInvalid, id, name, label, handleBlur, ariaLabel, ariaLabelledBy, ariaDescribedBy, required, defaultCountry, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const [state, setState] = useState('')

  const [value] = at(meta, "value");
  //const newValue = value.replace(/[^0-9]/g, '').substr(-10, 10);
  const newValue = value.replace(/[^+0-9]/g, '');

  // const { value: selectedValue } = field;
  const [error] = at(meta, 'error');
  const isError = props.formRef?.current?.touched[props.name] && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText aria-live='assertive'>{error}</FormHelperText>;
    }
  }  
  useEffect(() => {
    helpers.setValue(newValue);
  },[value]);

  
  const onChange = (value, country) => {   
    field.onChange && field.onChange(value, country);
    props.onChange && props.onChange(value, country);
    helpers.setValue(value, country);
  }

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
        return error;
    }
  }
  let twochar = makeid(2);
  let newid = id +"-" + twochar;

  return (
    <MuiPhoneInput
        defaultCountry={defaultCountry}
        label={label}
        value={props.value ? props.value : ''}
        onChange={onChange}
        id={newid}
        fullWidth
        required={true}
        countryCodeEditable={false}
        ariaLabelledBy ="phoneNumber"
        variant="outlined"
        className="uopx-input"     
        InputProps={ !meta.error && {
          endAdornment: (
             <InputAdornment position="end" tabIndex='0'>
               {meta.touched && !meta.error && <Done role='img' focusable="true" aria-hidden="false" aria-label='validation successful' tabIndex='0' color="secondary" />}
               {/*meta.touched && meta.error && <Close role='img' aria-label='validation failed' color="primary" /> */}
             </InputAdornment>
         ),
       //  length: 10,                                                                       
        }}
        error={meta.touched && meta.error && true}
        helperText={_renderHelperText()}
    />
  );
 }

export default MaterialInternationalPhoneNumber;
