import { useTheme } from '@mui/material/styles';

const styles = () => {
    const theme = useTheme();
    return {
      root: {
        margin: theme.spacing(3),
      },
      infoLabel: {
      },
      label: {
        color,
        fontSize: 16,
        paddingBottom: 8,
        fontWeight: 'bold',
      },
      header: {
        fontSize: 20,
        lineHeight: 1.875,
      },
      stepCounter: {
        fontSize: '1.6rem',
        lineHeight: '24px',
        borderBottom: '3px solid #d2d7d9',
        height: 40
      },
      formGroup: {
        margin: theme.spacing(3, 0),
      },
      customBtn: {
        '&:hover': {
          backgroundColor: '#de3518',
          color: '#fff',
        },
        textAlign: 'center',
        backgroundColor: '#de3518',
        borderRadius: 6,
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
        borderColor: '#de3518',
      },
      moreInfoMessage:{
          fontSize:12,
          marginTop:25,
          lineHeight:'18px'
      },
      submitConsent:{
        fontSize:12,
        marginBottom:25,
        lineHeight:'18px'
      },
      backBtn: {
          fontSize:16,
          paddingLeft:0,
          marginTop:10,
      },
      mobileGrid: {
          marginTop: '14px'
      },
      autoCompleteContainter:{
         width:'100%',
         maxWidth:'100%',
         height:'150px',
         position:'absolute',
         minWidth:'266px',
         overflowY:'scroll',
         overflowX: 'hidden',
         backgroundColor:'#fff',
         borderRadius:'4px',
         "margin-top":'4px',
         "z-index":`9999`,
         "padding-top":`4px`,
         "box-shadow":`0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`
       
      },
      autoCompleteUl:{
        width:'100%',
        height:'156px',
        position:'absolute',
        minWidth:'266px',
        listStyle:'none',
        paddingLeft:0,
        margin: 'auto !important',
  
       
     },
     autoCompleteLi:{
       "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
       "background-color":`#fff`,
       "letter-spacing": `0.00938em`,
       "font-weight":`400`,
       "height":`24px`,
       "font-size":`16px !important`,
       "border-color":`#000`,
       "line-height":`1.5`,
       "padding-top":`6px`,
       "padding-bottom":`6px`,
       "padding-left":`16px`,
       "padding-right":`16px`,
       "white-space":`nowrap`,
       "width":`auto`,
       "overflow":`hidden`,
       "display":`block`,
       "min-height":`48px`
     },
     suggestionContainer:{
       "width":`200%`
     },
     error: {
      color: 'red',
      fontWeight: 'bold',
      padding: '0.5em',
      }
    };
  };

  const color = '#3c2320';

  export default styles;