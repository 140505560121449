import React, { useEffect } from 'react';
import { makeGetCall } from '../../utils/apiClient';
import { importScript } from '../../utils/customHooks';

const MobileFooter = props => {
  // required for all data driven components
  const [footerContent, setFooterContent] = React.useState('');

  useEffect(() => {
    if (props.theme !== 'hidden') {
      const url = process.env.REACT_APP_PHXEDU_FOOTER_URL;
      // fire the api call to get the header content
      makeGetCall(url, (data => {
        setFooterContent(data);
      }));
    }
  }, []);

  useEffect(() => {
    importScript(process.env.REACT_APP_PHXEDU_CHAT_JS);
    if (footerContent) {
      importScript(process.env.REACT_APP_PHXEDU_FOOTER_JS, e => {
        window.footerFunction && window.footerFunction();
      });
      //  importScript(process.env.REACT_APP_PHXEDU_FOOTER_JS);
    }
  }, [footerContent]);

  if (footerContent) {
    return (
      <>
        <div className="footer" dangerouslySetInnerHTML={{ __html: footerContent }} />
      </>
    );
  }

  return null;
};

export default MobileFooter;
