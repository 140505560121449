import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { Typography, Grid } from '@mui/material/';
import { Formik, Form } from "formik";
import date from 'date-and-time';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { updatePrePopCookieValue, fireFOS_DlEvent, submitForm, mapAdditionalFields, feedDataToSessionStorage, loadLocalStorageData, unescapeHTML, userData, fireEvent, fireAnalyticsEvent, fireNonRFISubmitEvent, getmcid, profanityCheck } from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocusDialog';
import Button from '../layout/MaterialComponents/button/Button';
import { initValues, processJSONParameter } from '../common/configTools';
import { uopxRelationshipRole } from '../common/constants';
import SelectField from '../UopxNativeSelectField';
import RadioGroupContext from '@mui/material/RadioGroup/RadioGroupContext';

let prevRelationship_UOPX;
const formRef = {}; 
// let headerDesktop = '<h2 id="dialogTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 24px; font-weight: 700; line-height: 32px; display:flex; align-items:left; justify-content: left; margin-top:0;">Keep your credits. Get the Transfer Student Guide.</h2>';
// let descriptionDesktop = '<div id="dialogDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">Take the guesswork out of transferring prior eligible college credits with this guide. Learn how you can graduate faster and for less.</div>';
// let headerMobile = '<h2 id="dialogTitle" class="newOneForm-title" style="tabIndex=-1; font-size: 21px; font-weight: 700; line-height: 24px; display:flex; align-items:left; justify-content: left; margin-top:0;">Keep the credits. Get the Transfer Student Guide.</h2>';
// let descriptionMobile = '<div id="dialogDesc" class="newOneForm-SubTitle" style="font-weight: 400; font-size: 16px; line-height: 22px;">Take the guesswork out of transferring prior eligible college credits with this guide. Learn how you can graduate faster and for less.</div>';
// let thankyouMessageTitleMobile = '<h2 id="thanksdialogtitle" class="mainHeader" style="font-size: 21px; font-weight: 700; line-height: 24px; display:flex; align-items:center; justify-content: left; text-align:center; margin-top:0;">Thank you for requesting our Transfer Student Guide</h2>';
// let thankyouMessageTitleDesktop = '<h2 id="thanksdialogtitle" class="mainHeader" style="font-size: 27px; font-weight: 700; line-height: 32px; display:flex; align-items:center; justify-content: left; text-align:center; margin-top:0;">Thank you for requesting our Transfer Student Guide</h2>';
// let thankyouMessageDescriptionMobile = '<div id="thanksdialogdesc" class="newOneForm-SubTitle thanks" style="font-size: 16px; line-height: 21px; display:flex; align-items:center; justify-content: center; margin-top:0;">You can download the PDF now, or access it in the email we send you.</div>';
// let thankyouMessageDescriptionDesktop = '<div id="thanksdialogdesc" class="newOneForm-SubTitle thanks" style="font-size: 18px; line-height: 22px; display:flex; align-items:center; justify-content: center; margin-top:0;">Download the PDF now or access it in the email we send you.</div>';
let headerDesktop, descriptionDesktop, headerMobile, descriptionMobile, thankyouMessageTitleMobile, thankyouMessageTitleDesktop, thankyouMessageDescriptionMobile, thankyouMessageDescriptionDesktop;
let ctaText = '<span style="font-size: 15px; font-weight: 500; line-height: 24px;">Get the report</span>';
let downloadctaText = '<span style="font-size: 15px; font-weight: 500; line-height: 24px;">Download now</span>';
let grouptext = '<div class="sr-only">Fill out this form to get the report.</div>';
 const initialEmailFormValues = {
    degreeLevel: '',
    fullName: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    flex_more_info__c: '',
    flex_Info_Requested__c: '',
    flex_Relationship_UOPX:'',
    appContext: 'EMAILCAPTURE',
    mcid: '',
    useCaseContext: 'NEW',
    flex_EmailCaptureSource: '',
    userAgent: '',
    campaign: 'ValueExchange',
    source: 'PREP',
    requestingIP: '',
    requestingUrl: '',
    disclaimer: 'By submitting this form, you consent to receiving University of Phoenix emails about our educational programs and services. Visit our <a tabindex="0" href="https://www.phoenix.edu/copyright-legal/privacy_policy.html">Privacy Policy</a> for more information.',
  };
const disclaimerText = initialEmailFormValues.disclaimer;
const ecidValue = getmcid();
let preProspectInfoData = {};
let device;
let tcValue = '';
const isMobileView = window.innerWidth <= 640;
const isTabletView = !isMobileView && window.innerWidth <= 990;
let formStep;
let formType;
let formName;
let formNameTemp

function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite");            
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
        throw e;
    }
}

function trapFocus(element, focusElmtValue) {
    //console.log ("element", element);
    var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    //console.log ("focusableEls", focusableEls);
    var firstFocusableEl = focusableEls[focusElmtValue];  
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    //console.log ("focusableEls.length, firstFocusableEl, lastFocusableEl", focusableEls.length, firstFocusableEl, lastFocusableEl);
    element.addEventListener('keydown', function(e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) { 
        return; 
      }
      if ( e.shiftKey ) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          //console.log ("lastFocusableEl", lastFocusableEl);
          lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          //console.log ("firstFocusableEl", firstFocusableEl);
          firstFocusableEl.focus();
            e.preventDefault();
          }
        }
    });
  }

class GatedContentForm extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialEmailFormValues,
            formRef: null,
            currentStep: 1,
            flex_Relationship_UOPX: processJSONParameter([...uopxRelationshipRole]),
        };
        this.state = this.initialState;
    }
    reset() {
        this.setState(this.initialState);
    }

    componentDidMount() {
        const { props } = this;
        //console.log ("props ", props);
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initialValues.flex_EmailCaptureSource = 'GROW';
        initialValues.thankyouPageUrl= '';
        initValues(initialValues, this.props);
        loadLocalStorageData(initialValues); 
        this.setState({ initialValues }); 
        //this.setState({ isSubmit: true }); 
        getIPAddress(this.updateIPAddress);
        
        if (isMobileView){device = 'Mobile';}else {device = 'Desktop';}
        const { currentStep } = this.state;
        formStep = currentStep;
        formType = 'Preprospect Zero Party';
    	formNameTemp = this.props.formname || 'GROWForm';
        formName = formNameTemp + ' ' + device;
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
        // BLUR EVENT
        // document.querySelector('input[name="fullName"]').addEventListener("blur", function() { 
        //     setTimeout(function () {
        //         if (document.querySelector('[id^="fullName"][id$="-helper-text"]')) {
        //             document.querySelector('input[name="emailAddress"]').focus()
        //         }
        //     }, 300);
        // });

        // KEYDOWN EVENT
        if (document.querySelector('.abandonment input[name="fullName"]')) {
            document.querySelector('.abandonment input[name="fullName"]').addEventListener("keydown", function(e) {
                if(e.keyCode == 9 || e.key === 'Tab') {
                    if ( e.shiftKey ) /* shift + tab */ {} 
                    else {
                        setTimeout(function () {
                            if (document.querySelector('[id^="fullName"][id$="-helper-text"]')) {
                                document.querySelector('input[name="emailAddress"]').focus()
                            }
                        }, 300);
                    }
                }
            });
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.requestingIP = ipAddress;
        this.setState({ initialValues });    
    }

    setAnalytics = (formname) => {
           
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireEvent(formNameTemp +'Start', 'Zero Party', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }    
      
    isTransferCredit = (event, checked) => {
        //console.log(event); 
        //console.log(checked)
        formRef.current.values.flex_Transfer_Credits_from_ValueExchange__c = checked;
        if (checked === true) {
            tcValue='checked';
        }
        if (checked === false) {
            tcValue='unchecked';
        }      
    }

    handleSubmit = async values => {
      if (document.activeElement.name === "submit") {
        if (((values.fullName).indexOf(' ')) > 0) {
            const i = (values.fullName).indexOf(' ');
            values.firstName = (values.fullName).substring(0, i);
            values.lastName = (values.fullName).substring(i + 1);
        }    
        this.setState({ initialValues: values });  
        window.uopSegment&&window.uopSegment.identify({'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress});
        window.uopSegment&&window.uopSegment.track(formNameTemp +' Submitted', {'firstName':values.firstName, 'lastName':values.lastName, 'email': values.emailAddress, 'analytics': {'mcid': ecidValue}});
        //window.uopxDataLayer&&window.uopxDataLayer.track('PreProspectInfoSubmitted', {'componentName' : 'RequestInfo', 'name': formName, 'type': formType, 'formInformation': {'profileDetails':{'firstName':values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}}, 'external': {'segmentAnonymousId': window.analytics.user().anonymousId(), 'segmentUserId': window.analytics.user().id()}});
        srSpeak('email submitted');
        //submitForm(values, this.props);
        fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        //this.setState({ isSubmit: true, currentStep:2}); 
        mapAdditionalFields(values);
        feedDataToSessionStorage(values);
        values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        Object.keys(values).map(k => values[k] = typeof values[k] === 'string' ? values[k].trim() : values[k]);
        Object.keys(values).forEach(k => (values[k] === '' || values[k] === undefined || values[k] === null) && delete values[k]);

        let postURL = process.env.REACT_APP_PHXEDU_PROD_POST_URL;
        if (window.location.host !== 'www.phoenix.edu') {
            postURL = process.env.REACT_APP_PHXEDU_STAGE_POST_URL;
        }
        // this.setState({ isSubmit: true, currentStep: 2 }); // TEST
        try {
            const response = await fetch(postURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
            });
            // console.log ("form submit values" , values);

            this.setState({
                initialValues: values,
                isSubmit: true,
                currentStep:2,
                message: null,
            });

            if (window.uopxDataLayer && window.uopxDataLayer.track) {
                window.uopxDataLayer&&window.uopxDataLayer.track(formNameTemp+'Submit', {'componentName' : 'Zero Party', 'name': formName, 'type': formType, 'text': 'Request my guide', 'formInformation': {'profileDetails':{'firstName':values.firstName, 'lastName':values.lastName, 'email':values.emailAddress}, 'formDetails':{'campaign':formNameTemp, 'campaignOption':values.flex_Relationship_UOPX},},});
            }
        } catch (e) {
            this.setState({
                message: <div className="">{e.message || e}</div>,
            });
        }
        if (window.FS) {
            preProspectInfoData.source= formNameTemp;
            //calling FS and passing the tracking data set
            window.FS.event('Submitted PreProspectInfo', preProspectInfoData);
            //console.log("Submitted PreProspectInfo", preProspectInfoData);
        }
        //document.querySelector('#gatedCopy').style.display='none';
        //document.querySelector('#gatedThanksCopy').style.display='block';
        //document.querySelector('#gatedThanksCopyExt').style.display='block';
        document.querySelectorAll('.gatedCopy').forEach((element) => {
            element.style.display='none';
        });
        document.querySelectorAll('.gatedThanksCopy').forEach((element) => {
            element.style.display='block';
        });
        document.querySelectorAll('.gatedThanksCopyExt').forEach((element) => {
            element.style.display='block';
        });
        
        //document.querySelector('#announce').innerHTML = 'email submitted';
        //if (document.querySelector('.btn-close')) {
            //document.querySelector('#downloadcta').focus();
            document.querySelector('#gatedThanksCopy h2').setAttribute('tabIndex', '-1');
            document.querySelector('#gatedThanksCopy h2').focus();
        /*    if (isMobileView){
                document.querySelector('#transferCreditGuideContainer').scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
            } 
            if (document.querySelector('#modalrfiTCG .mobileFormContainer').style.maxWidth){
                if (document.querySelector(".thanksclose")) {
                    document.querySelector(".thanksclose").classList.remove("hidden");
                }
            }   */
            //trapFocus(document.querySelector('#modalrfiTCG'), 0);
        //}
      }   
    }
    
    validateGatedContentForm(values) {
        const errors = {};
        if (!values.fullName) {
            errors.fullName = 'First and Last name requires a value';
          } else if (values.fullName.trim().indexOf(' ') === -1) {
            errors.fullName = 'Please enter a First and Last name';
          } else if (!profanityCheck(values.fullName)) {
            errors.fullName = 'Full Name appears invalid';
          }
        if (!values.emailAddress) {
          errors.emailAddress = 'Email Address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errors.emailAddress = 'Email address appears invalid';
        }
        if (!values.flex_Relationship_UOPX) {
            errors.flex_Relationship_UOPX = 'How are you affiliated with us requires a value';
        }
        //console.log('errors', errors, values);
        return errors;
    }

    dropdownChange = (name, value) => {
        const {initialValues} = this.state;
        if (name === 'flex_Relationship_UOPX') {
            if (value && prevRelationship_UOPX !== value) {
                //updatePrePopCookieValue('degreeLevel', value);
                //fireFOS_DlEvent(name, value, formName, formType);
                prevRelationship_UOPX = value;
                initialValues.flex_Relationship_UOPX = value;
                this.validateGatedContentForm(initialValues);
            }
        }
    }

    /*modalClosebtn = () => {
        this.setState({ isSubmit: false, currentStep:1});
    } */

    handleDownload =(e)=> {
        //window.open('https://www.phoenix.edu/content/dam/edu/tuition-financial-aid/doc/scholarship-guide.pdf', "_blank", "noreferrer");
        //const pdfUrl = 'https://www.phoenix.edu/content/dam/edu/tuition-financial-aid/doc/transfer-guide.pdf';
        //console.log ("link is ", this.props.pdfUrl);
        const pdfUrl = this.props.pdfUrl || '/career-institute/generating-rural-opportunities-workforce.html';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.role = 'link'
        //link.target='_blank';
        //link.download = "business-programs-guide.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.uopxDataLayer.track(formNameTemp+"Download",{
            'componentName' : 'Zero Party',
            'name': formName, 
            'type': formType,
            'text': 'Download Now'
        });
    }
    
    keydownDownload = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.handleDownload;
        }         
    }

    modalClose = () => {
        //console.log ("in modalClose Code");
        //const { initialValues } = this.state;

        var flexDiv = document.querySelector('#flex-div');
        // console.log("flexDiv", flexDiv);
        if(flexDiv.parentNode)
            flexDiv.parentNode.removeChild(flexDiv);

        let modal = document.querySelector("#modalrfiTCG");
        let overlay = document.querySelector(".overlay0P");
        if (modal !== null || overlay !== null){
            //modal.classList.add("hidden");
            overlay.classList.add("hidden");
            modal.removeAttribute("style");
            modal.removeAttribute("tabIndex");
            document.querySelector('#modalrfiTCG').removeAttribute("style"); 
			document.querySelector('#modalrfiTCG').removeAttribute("tabIndex"); 
            if (document.querySelector('#modalrfiTCG .mobileFormContainer').style.length > 0 ){
                document.querySelector('#modalrfiTCG .mobileFormContainer').removeAttribute("style");
            }
            document.querySelectorAll('.modaltcgstack').forEach((element) => {
                element.removeAttribute("style");
            });
            if (document.querySelector('#modalrfiTCG .abandonment').style.length > 0 ){
                document.querySelector('#modalrfiTCG .abandonment').removeAttribute("style");
            }
            if (isMobileView || isTabletView) {
                if(document.querySelector(".nothanks"))
                	document.querySelector(".nothanks").classList.add("hidden");
				if(document.querySelector(".thanksclose"))
					document.querySelector(".thanksclose").classList.add("hidden");
                if(document.querySelector("#close-modal"))
                    document.querySelector("#close-modal").classList.add("hidden");

            } 
            else { 
              document.querySelector(".btn-close").classList.add("hidden");
            }
            if (!document.querySelector('#modalrfiTCG .mobileFormContainer').style.maxWidth){
                if (document.querySelector(".thanksclose")){
                    document.querySelector(".thanksclose").classList.add("hidden");
                }  
            }
            document.querySelector('#modalrfiTCG h2').classList.remove("modaltitle");
            document.querySelector('.transferCreditGuide.rfiabandonment').classList.remove("transferCreditGuideModalImg");
            if (document.querySelector('#modalrfiTCG #dialogDesc')) {
                document.querySelector('#modalrfiTCG #dialogDesc').classList.remove("modaldes");
            }
            if (document.querySelector('#modalrfiTCG #thanksdialogdesc')) {
                document.querySelector('#modalrfiTCG #thanksdialogdesc').classList.remove("modaldes");
            }
        }
        window.uopxDataLayer.track(formNameTemp+"Close",{
            'componentName' : 'Zero Party',
            'name': formName + " " + this.state.currentStep, // depends on step closing 
            "type": formType,
            "text": "close",
        });
        // this.setState({ isSubmit: false, currentStep:1});  // Ask team about behavior
    };

    keydownClose = (e) => {
        //console.log ("in modal keydown Close", e.keyCode);
        if (e.keyCode === 13 || e.keyCode === 32){
            this.modalClose();
        }         
    }
      
    render() {
    //    const { classes } = this.props;
        const { initialValues,  isSubmit} = this.state;

        let titleText = isMobileView ? (this.props.title || headerMobile) : (this.props.title || headerDesktop);
        let descriptionText = isMobileView ? (this.props.title || descriptionMobile) : (this.props.title || descriptionDesktop);
        let cta = this.props.cta || ctaText;
        let downloadcta = this.props.downloadcta || downloadctaText;
        let color = this.props.color || 'primary';
        let thankyouMessageTitle = isMobileView ?  (this.props.thankyoumessagetitle || thankyouMessageTitleMobile) : (this.props.thankyoumessagetitle || thankyouMessageTitleDesktop);
        let thankyouMessageDescription = isMobileView ? (this.props.thankyoumessagedescription || thankyouMessageDescriptionMobile) : (this.props.thankyoumessagedescription || thankyouMessageDescriptionDesktop);
        let srgrouptext = this.props.srgrouptext || grouptext;

        return (    
            <div>
            {/* <UOPHeader /> */}
                <section className="abandonment">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.validateGatedContentForm(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-labelledby={isSubmit? "thanksdialogtitle thanksdialogdesc" : "dialogTitle dialogDesc"}
                                                    style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                                    <Grid container >
                                                        { !isSubmit && isTabletView && 
                                                            <div 
                                                                // style={{paddingTop: '3rem'}}
                                                            >
                                                                {unescapeHTML(titleText)}
                                                                {unescapeHTML(descriptionText)}
                                                            </div>
                                                        }
                                                        {/* <Grid item md={6} sm={6} lg={6} xs={12} className="transferCreditGuide rfiabandonment" role="img" aria-label="Stellina a 2017 University of Phoenix graduate smiles as the Phoenix logo floats over her right shoulder.">
                                                            <div className=""> </div>
                                                        </Grid> 
                                                        */}
                                                {!isSubmit
                                                    ? (
                                                        <>  
                                                            <fieldset>
                                                                <legend>{unescapeHTML(srgrouptext)}</legend>
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="rfiabandonmentcopy">
                                                                { unescapeHTML(titleText)}
                                                                { unescapeHTML(descriptionText)}
                                                                <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                                                <Grid container justifyContent={'center'} >
                                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm modaltcgstack">
                                                                        <MaterialInput
                                                                            name="fullName"
                                                                            id="fullName"
                                                                            label="First and Last name *"
                                                                            ariaRequired="true"
                                                                            ariaLabel="Enter your First and Last name"
                                                                            variant="outlined"
                                                                            className='uopx-input modalfield'
                                                                            onClick={this.handleAnalyticsRFIFormStart}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm modaltcgstack">
                                                                        <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" className='uopx-input modalfield' />
                                                                    </Grid>
                                                                
                                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                                        {/* <SingleCheckbox
                                                                            id="flex_Transfer_Credits_from_ValueExchange__c"
                                                                            className='ways2save-checkbox'
                                                                            // label="I have college credits and may be interested in the Transfer Student Scholarship."
                                                                            // ariaLabel="I have college credits and may be interested in the Transfer Student Scholarship."
                                                                            label="Checkbox"
                                                                            ariaLabel="Checkbox"
                                                                            checkboxName="flex_Transfer_Credits_from_ValueExchange__c"
                                                                            parentCallback={this.isTransferCredit}
                                                                        /> */}
                                                                        <SelectField
                                                                            id="flex_Relationship_UOPX"
                                                                            name="flex_Relationship_UOPX"
                                                                            label="How are you affiliated with us? *"
                                                                            data={this.state.flex_Relationship_UOPX}
                                                                            className="MuiFormControl-fullWidth"
                                                                            ariaRequired="true"
                                                                            formRef={formRef}
                                                                            // onClick={this.handleAnalyticsRFIFormStart}
                                                                            onChange={this.dropdownChange("flex_Relationship_UOPX", props.values.flex_Relationship_UOPX)}
                                                                        ></SelectField>
                                                                    </Grid>
                                                                    <div className="disclaimer"> {unescapeHTML(disclaimerText)} </div>
                                                                    <Button name='submit' size='large' color={color} type='submit' className='rfi-btn-submit submit-btn'> {unescapeHTML(cta)} </Button>
                                                                    { (isMobileView || isTabletView) && <Button size='small' variant = 'Secondary' color='secondary' onMouseDown={this.modalClose} onKeyDown={this.keydownClose} className='nothanks hidden' style={{color:'#5e7079', margin:'auto', marginTop:'16px'}}> No thanks </Button> }
                                                                </Grid>
                                                            </Grid> 
                                                            <ErrorFocus />
                                                            </fieldset>    
                                                        </>
                                                    ):(
                                                        <>
                                                            
                                                            <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm modalthankscopy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                                            <div className ="rfiabandonmentcopy" id="rfiabandonmentcopy">
                                                                {unescapeHTML(thankyouMessageTitle)}
                                                                {unescapeHTML(thankyouMessageDescription)}
                                                                <div style={{ padding: '1.5rem 3rem'}} >
                                                                    <div>
                                                                        <Button size='large' id='downloadcta' color={color} onClick={this.handleDownload} onKeyDown={this.keydownDownload} className='rfi-btn-submit btnclose' aria-labelledby='downloadcta'>{unescapeHTML(downloadcta)}</Button>
                                                                    </div>
                                                                    <div>
                                                                        <Button size='small' variant='Secondary' color='secondary' onMouseDown={this.modalClose} onKeyDown={this.keydownClose} 
                                                                        className={ document.querySelector('.modal') && document.querySelector('.modal').getAttribute('style') && 
                                                                            document.querySelector('.modal').getAttribute('style').indexOf('z-index:') != -1 ? 'thanksclose' : 'thanksclose hidden'} 
                                                                        style={{color:'#5e7079', margin:'auto', fontSize:'18px', marginTop:'16px'}}> Close </Button>
                                                                    </div>
                                                                </div>
                                                            </div>    
                                                            </Grid>
                                                            {/* this.modalClose() */}
                                                        </> 
                                                    )}
                                                    { <button id="close-modal" aria-label="Close dialog" title="Close" onClick={this.modalClose} onKeyDown={this.keydownClose} type="button" className="btn-close hidden"></button> }
                                                    </Grid>
                                                </Form>
                                            )
                                        }} 
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </div>
        );
    }
}

GatedContentForm.defaultProps = {
    classes: {},
};

GatedContentForm.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default GatedContentForm;