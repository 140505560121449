export function dataLayer(){
(function (window, document) {

    //set the namespace to avoid a potential race condition
    window.adobeDataLayer = window.adobeDataLayer || [];

    // set the namespace for global Util functions
    window.adobeDataLayer.Util = {};
	window.pageData = {};
	const pageNameByPath = function (){
	  let pageName =  '';
	  let prefix = 'mktg:rfi';
	  let pathFormatted = window.location.pathname.replace(/\//g, ':');
		pageName = prefix + pathFormatted;
		//console.log ("pageName " + pageName);
		return pageName;
	  }
	
/*	var title = pageProperties.get("jcr:title", "");
	var templatePath = getTemplateName(currentPage);
	var pageURL = getCanonicalURL();
	var description = getDescription();
	var modifiedDate = getModifiedDate();
	var pagePathURL = getpageURL();
	var tags = getTags();
	var path = getPagePath(currentPage);
	var pageName = getPageName(currentPage, getPagePath(currentPage));
	var pageType = getPageType( getPagePath(currentPage), pageName);
	*/
	var pageObj = {};
	pageObj["repo:path"] = window.location.pathname;
	pageObj["site:domain"] = window.location.host;
	pageObj["page:name"] = pageNameByPath();
	pageObj["xdm:page:title"] = document.title;
	pageObj["@type"] = 'edu/components/structure/page';
/*	pageObj.addProperty("repo:modifyDate", modifiedDate);
	pageObj.addProperty("repo:path", window.location.pathname);
	pageObj.addProperty("site:domain", window.location.host);
	pageObj.addProperty("page:name", pageNameByPath);
	pageObj.addProperty("xdm:page:title", document.title);
	pageObj.addProperty("xdm:page:description", description);
	pageObj.addProperty("xdm:page:tags", tags);
	pageObj.addProperty("xdm:page:template", templatePath);
	pageObj.addProperty("xdm:page:type", pageType);
	if(pagePathArr.length >1)
		pageObj.addProperty("xdm:page:siteSection",getSiteSection(pagePathArr, 1));
	if(pagePathArr.length >2)
		pageObj.addProperty("xdm:page:siteSubSection1",getSiteSection(pagePathArr, 2) );
	if(pagePathArr.length >3)
		pageObj.addProperty("xdm:page:siteSubSection2",getSiteSection(pagePathArr, 3) );
	if(pagePathArr.length >4)
		pageObj.addProperty("xdm:page:siteSubSection3",getSiteSection(pagePathArr, 4) );
	if(pagePathArr.length >5)
		pageObj.addProperty("xdm:page:siteSubSection4",getSiteSection(pagePathArr, 5) );
	pageObj.addProperty("xdm:pageURL", pagePathURL);
	*/
	
    // wrapper function to call launch track function
    window.adobeDataLayer.Util.sendDataForTracking = function(eventName, eventData){
        if(window.uopxDataLayer && typeof window.uopxDataLayer.track === 'function'){
            uopxDataLayer.track(eventName, eventData);
        }
    }

    const readCookie = function(name) {
        return (document.cookie.match("(^|;) *" + name + "=([^;]*)") || "")[2];
    }

    const checkvalue = function(value) {
        return typeof value !== "undefined" && value;
    }

    const getMcidFromCookie = function() {
        const value = readCookie("AMCV_8DF667C25245B0070A490D4C%40AdobeOrg");
        if (checkvalue(value)) {
          let str = decodeURI(value);
          str = str.substring(str.lastIndexOf("|MCMID|") + 7);
          str = str.substring(0, str.indexOf("|"));
          return str;
        }
        return "";
    }

    const getPreviousPage = function(param) {
        try {
          const value = window.localStorage.getItem(param) || "";
          if (checkvalue(pageData['pageName'])) {
              window.localStorage.setItem(param, pageData['pageName']);
          }
          return value;
        } catch (e) {
          console.error(e);
        }
    }
    
    // const getFormValues = function () {
    //     const rfiWrapper = document.querySelector(".react-rfi-wrapper-container");
    //     if (rfiWrapper) {
    //       return JSON.parse(rfiWrapper.getAttribute("data-json-properties"));
    //     }
    //     return {};
    // };
    
    // const getVisitorType = function () {
    //     let visitorType = "prospect";
    //     if (checkvalue(readCookie("rfi_email"))) {
    //       visitorType = "prospect-rficomplete";
    //     }
    //     return visitorType;
    // }

    const getDomReferrer = function () {
        return document.referrer;
    }

    const getFbpFromCookie = function () {
        const value = readCookie("_fbp");
        return checkvalue(value) ? value: "";
    }

    const getFbcFromCookie = function () {
        const value = readCookie("_fbc");
        return checkvalue(value) ? value: "";
    }
    
    const getGoogleId = function () {
        const value = readCookie("_ga");
        return checkvalue(value) ? value: "";
    }

    const getSegmentAnonymousId = function () {
        const value = readCookie("ajs_anonymous_id");
        return checkvalue(value) ? value: "";
    }

    const getSegmentUserId = function () {
        const value = readCookie("ajs_user_id");
        return checkvalue(value) ? value: "";
    }

    const getLandingPageUrl = function(param) {
        try {
          const value = window.localStorage.getItem(param) || "";
          if (!checkvalue(value)) {
              window.localStorage.setItem(param, window.location.href);
          }
          return value || window.location.href;
        } catch (e) {
          console.error(e);
        }
    }

    const getQueryParam = function (key) {
        const url = window.location.search.slice(1);
        let param = url.slice(url.indexOf(key));
        if(param.indexOf('&') > -1){
            param = param.slice(param.indexOf(key) + key.length + 1, param.indexOf('&'));
        }else {
            param = param.slice(param.indexOf(key) + key.length + 1);
        }
        return param || "";
    }

    const getPageLoadTime = function () {
        return performance.now();
    }

    const getFieldOfStudy = function () {
        const key = 'degrees/';
        let url = window.location.pathname;
        if(url.indexOf(key) < 0) return "";
        url = url.slice(url.indexOf(key) + key.length);
        return url.slice(0, url.indexOf('/')) || "";
    }

    const getProgramName = function () {
        const programName = document.querySelector('.program-hero-header h1');
        return programName ? programName.textContent : "";
    }

    const getDegreeType = function () {
        const degreeType = document.querySelector('.program-hero .degree-type-container p.degree-type');
        return degreeType ? degreeType.textContent : "";
    }

    const getPathName = function () {
        return window.location.pathname;
    }
	
    window.pageData[window.pagePath] = pageObj;
    //console.log ("window.pageData", window.pageData);
    //console.log ("window.pagePath", window.pagePath);
    //console.log ("pageObj", pageObj);

    document.addEventListener("DOMContentLoaded", function (event) {
        if (window.adobeDataLayer && window.pageData && window.pagePath) {
            const dataLayerObj = pageData[Object.keys(pageData)[0]];
			//var dataLayerObj = {};
			//dataLayerObj["repo:path"] = window.location.pathname;
			//dataLayerObj["site:domain"] = window.location.host;
			//dataLayerObj["page:name"] = pageNameByPath();
			//dataLayerObj["xdm:page:title"] = document.title;
            dataLayerObj["site:ecid"] = getMcidFromCookie();
            dataLayerObj["site:rfiPage:orderId"] = getQueryParam('orderID');
            dataLayerObj["site:page:rrid"] = getQueryParam('rrid');
            dataLayerObj["site:page:rmid"] = getQueryParam('rmid');
            dataLayerObj["site:page:link_id"] = getQueryParam('LINK_ID');
            // Commented lines will be implemented in Adobe launch
            // dataLayerObj["page:previousPage"] = getPreviousPage("previousPageName");
            dataLayerObj["site:domReferrer"] = getDomReferrer();
            dataLayerObj["xdm:site:landingPageUrl"] = getLandingPageUrl("landingPageUrl");
            /*
            dataLayerObj["xdm:external:fbp"] = getFbpFromCookie();
            dataLayerObj["xdm:external:fbc"] = getFbcFromCookie();
            dataLayerObj["xdm:external:googleId"] = getGoogleId();
            dataLayerObj["xdm:external:segmentAnonymousId"] = getSegmentAnonymousId();
            dataLayerObj["xdm:external:segmentUserId"] = getSegmentUserId();
            */
            dataLayerObj["xdm:page:tagManagerProfile"] = "uopxprospects";
            dataLayerObj["xdm:page:metrics:loadTime"] = getPageLoadTime();
            dataLayerObj["xdm:page:fieldOfStudy"] = getFieldOfStudy();
            dataLayerObj["xdm:page:programName"] = getProgramName();
            dataLayerObj["xdm:page:degreeType"] = getDegreeType();
            dataLayerObj["xdm:rfiPage:formName"] = formName;
            dataLayerObj["xdm:rfiPage:formType"] = formType;
            dataLayerObj["xdm:rfiPage:formStep"] = formStep.toString();
            dataLayerObj["repo:path"] = getPathName();
            // dataLayerObj["xdm:visitorType"] = getVisitorType();
            // pageData[Object.keys(pageData)[0]] = { ...dataLayerObj, ...getFormValues() };
			pageData[window.pagePath] = dataLayerObj;
            window.pagePath = 'page.'+ pagePath;
            console.log ("pageData ", pageData);
            adobeDataLayer.push({
                page: pageData,
                event: "cmp:show",
                eventInfo: {
                    path: pagePath,
                },
            });
        }
    });

})(window, document);
}