import { useTheme } from '@mui/material/styles';

const styles = () => {
    const theme = useTheme();
    return {
        fileUploader : {
            width: "100%"
        },
        fileContainer: {
            background: "#fff",
            /*boxShadow: "2px 2px 3px 0 rgba(0, 0, 0, 0.05)",*/
            position: "relative",
            borderRadius: "10px",
            padding: "20px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            margin: "10px auto",
            transition: "all 0.3s ease-in",
            paddingTop:"0px",
            marginTop: "0px"
        },
        chooseFileInput : {
            opacity: "0",
            position: "absolute",
            zIndex: "-1"
        },
        p : {
            fontSize: "12px",
            margin: "8px 0 4px"
        },
        chooseFileButton : {
            padding: "16px 16px",
            background: "#ffffff",
            /*borderRadius: "30px",*/
            color: "#97a3a9",
            fontWeight: "300",
            fontSize: "14px",
            margin: "0",
            transition: "all 0.2s ease-in",
            cursor: "pointer",
            outline: "none",
            border: "none",
            display: "flex",
            borderStyle: "dashed",
            width: "100%",
            alignItems: "center",
            borderColor: "#97a3a9",
            borderWidth: "1px",
    
            "&:hover": {
                background: "#ffffff",
                borderColor: "#000000"
            }
        },
        uploadIcon: {
            width: "24px",
            height: "24px",
            marginRight: "16px"
        },
        uploadPicturesWrapper : {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            width: "100%"
        },
        uploadPictureContainer : {
            width: "25%",
            margin: "5%",
            padding: "10px",
            background: "#edf2f6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "inherit",
            boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)",
            border: "1px solid #d0dbe4",
            position: "relative"
        },
        uploadPicture : {
            width: "100%"
        },
        deleteImage : {
            position: "absolute",
            top: "-9px",
            right: "-9px",
            color: "#fff",
            background: "#ff4081",
            borderRadius: "50%",
            textAlign: "center",
            cursor: "pointer",
            fontSize: "26px",
            fontWeight: "bold",
            lineHeight: "30px",
            width: "30px",
            height: "30px"
        },
        errorsContainer : {
            width: "98%",
            fontSize: "12px",
            color: "red",
            textAlign: "left"
        }
    }
};

export default styles;

//test