import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    Box,
    InputLabel,
    FormControl,
    NativeSelect,
    FormHelperText
} from '@mui/material';
import {makeid} from './CommonComponents';


export default function SelectField(props) {
    const { label, data, name, id, required, selectLabel, ariaLabel, ariaRequired, ...rest } = props;
    const [field, meta] = useField(props);
    const { value: selectedValue } = field;
    const [error] = at(meta, 'error');
    const isError = props.formRef?.current?.touched[props.name] && error && true;
    function _renderHelperText() {
        if (isError) {
            return <FormHelperText id={`${id}-nativeSelect`} aria-live='assertive'>{error}</FormHelperText>;
        }
    }
    const onChange = (e) => {
        field.onChange && field.onChange(e);
        props.onChange && props.onChange(e);
    }

   /* const onBlur = (e) => {
   field.onBlur && field.onBlur(e);
        props.onBlur && props.onBlur(e);
    } */

    let twochar = makeid(2);    
    let newid = id +"-" + twochar;
    const labelID = id+'-label-'+twochar;
    
    delete rest.formRef;
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl {...rest} error={isError} fullWidth variant="outlined" className="uopx-select uopx-native-select">
                <InputLabel id={labelID} htmlFor={id}>
                    {label}
                </InputLabel>
                <NativeSelect
                    disableUnderline name={name} id={id} onChange={onChange} value={selectedValue ? selectedValue : ''}
                    inputProps={{'aria-required':ariaRequired, 'aria-label':ariaLabel, "aria-describedby": id+"-nativeSelect"}} 
                >
                {name !== "holdUp" && <> 
                    <option key={0} value=""/> {/* Makes the first option blank and no value */} 
                    {data.map((item, index) => {
                        return (                       
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        )                                              
                    })} 
                </>}    
                {name === "holdUp" && <> 
                    <optgroup label="Select a reason">
                        {data.map((item, index) => {
                            if (index===0){
                                return (
                                    <option key={index} value={item.value} disabled hidden>
                                        {item.label}
                                    </option>
                                )
                            } else {
                                return (                       
                                    <option key={index} value={item.value}>
                                        {item.label}
                                    </option>
                                )   
                            }                                          
                        })} 
                    </optgroup>
                </>}                                                                                
                </NativeSelect>
                {_renderHelperText()}
            </FormControl>
        </Box>
    );
}

//class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled css-1sumxir-MuiFormLabel-root-MuiInputLabel-root"
//export default SelectField;