import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MUICheckbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import './Checkbox.scss';

function Checkbox({
  checkboxes,
  isHorizontal,
  legendText,
  legend,
  onChange,
  ...other
}) {
  const initialCheckboxValues = {};
  // Creating initial checkboxes state
  for (let i = 0, len = checkboxes.length; i < len; i += 1) {
    const checkboxName = checkboxes[i].name;
    const { isChecked } = checkboxes[i];
    initialCheckboxValues[`${checkboxName}`] = isChecked;
  }

  const [checkedItems, setCheckedItems] = React.useState(initialCheckboxValues || {});
  console.log(other);
  const handleChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
    onChange(event);
  };

  const CheckboxOutlineBlankIcon = () => (
    <svg className="MuiSvgIcon-root jss67" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1" title="CheckBoxOutlineBlankSharp" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="CheckBoxOutlineBlankSharp"><path d="M19 5v14H5V5h14m2-2H3v18h18V3z" /></svg>
  );
  const CheckboxIcon = () => (
    <svg className="MuiSvgIcon-root jss67" focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1" title="CheckBoxSharp" data-ga-event-category="material-icons" data-ga-event-action="click" data-ga-event-label="CheckBoxSharp"><path d="M21 3H3v18h18V3zM10 17l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
  );

  const Legend = () => {
    if (legend) {
      return <legend className="MuiFormLabel-root">{legendText}</legend>;
    }
    return <legend className="MuiFormLabel-root" style={{ display: 'none' }}>{legendText}</legend>;
  };

  return (
    <>
      <FormControl
        component="fieldset"
        fullWidth
        className="uopx-checkbox"
      >
        <Legend />
        <FormGroup
          row={isHorizontal}
        >
          {checkboxes.map(checkbox => (
            <FormControlLabel
              key={checkbox.name}
              control={(
                <MUICheckbox
                  {...other}
                  name={checkbox.name}
                  icon={<CheckboxOutlineBlankIcon />}
                  checkedIcon={<CheckboxIcon />}
                  onChange={handleChange}
                  inputProps={{
                    'aria-label': checkbox.ariaLabel,
                  }}
                  // checked={checkedItems[checkbox.name]}
                  checked={other?.value}
                />
              )}
              label={checkbox.label}
            />
          ))}
        </FormGroup>
        <FormHelperText aria-live='assertive' />
      </FormControl>
    </>
  );
}

Checkbox.propTypes = {
  /**
   * The checkbox legend is required. The legend is displayed above the checkbox or checkboxes.
   */
  legendText: PropTypes.string.isRequired,
  /**
   * If true the legend will be shown.
   */
  legend: PropTypes.bool,
  /**
   * If isHorizontal is set, the layout of the checkboxes will be inline (in row).
   * Not setting this prop will display the list of checkboxes vertically.
   */
  isHorizontal: PropTypes.bool,
  /**
   * Checkbox array list
   *
   * name:
   *
   * - Name attribute of the checkbox element is required.
   * The name is used in announcing the form control to screen readers. This name is not visible.
   *
   * isChecked:
   *
   * - The checked bool is by default false.
   *
   * label:
   *
   * - The checkbox label is required. This is the text showing beside the checkbox control.
   *
   * ariaLabel:
   *
   * - The aria-label is required.
   */
  checkboxes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool,
    label: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
  })),
};

Checkbox.defaultProps = {
  legend: false,
  checkboxes: [{}],
  isHorizontal: null,
};

export default Checkbox;
