import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
//import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import { Formik, Form } from "formik";
//import styles from '../layout/styles/MobileFormStyles';
//import styles from '../inline/SubscribeStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import getIPAddress, { unescapeHTML, fireAnalyticsEvent, fireNonRFISubmitEvent, getmcid } from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocusDialog';
import Button from '../layout/MaterialComponents/button/Button';
//import checkmark from '../layout/images/checkmark.svg';
import { initValues, processJSONParameter } from '../common/configTools';


const formRef = {};
let defaultTextHTML = '<h2 class="newOneForm-title" style="display:flex; align-items:center; justify-content: center; margin-top:0;">Give us 10 seconds...</h2>';
let defaultDescriptionTextHTML = '<div class="newOneForm-SubTitle" style="font-weight: 400"> and your email address. We’ll send you options to save time and money on tuition.</div>';
const initialEmailFormValues = {
    emailAddress: '',
    disclaimer: 'By submitting this form, you consent to receive University of Phoenix emails about our educational programs and services. Visit our <a tabindex="0" href="https://www.phoenix.edu/copyright-legal/privacy-policy.html">Privacy Policy</a> for more information.',
    formName:'Abandonment modal'
  };
const disclaimerText = initialEmailFormValues.disclaimer;
const ecidValue = getmcid();
let preProspectInfoData = {};
const isMobileView = window.innerWidth <= 640;

function srSpeak(text, priority) {
    try{
        var el = document.createElement("div");
        var id = "make-a-screen-reader-talk-" + Date.now();
        el.setAttribute("id", id);
        el.setAttribute("aria-live", priority || "polite");            
        el.classList.add("visually-hidden");
        document.body.appendChild(el);
        
        window.setTimeout(function () {
            document.getElementById(id).innerHTML = text;      
        }, 100);
        
        window.setTimeout(function () {
            if (document.getElementById(id)){
                document.body.removeChild(document.getElementById(id));
            }
        }, 1000);
    } catch(e){
        console.log ("error", e);
        throw e;
    }
}

function trapFocus(element, focusElmtValue) {
    //console.log ("element", element);
    var focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])');
    //console.log ("focusableEls", focusableEls);
    var firstFocusableEl = focusableEls[focusElmtValue];  
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    //console.log ("focusableEls.length, firstFocusableEl, lastFocusableEl", focusableEls.length, firstFocusableEl, lastFocusableEl);
    element.addEventListener('keydown', function(e) {
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) { 
        return; 
      }
      if ( e.shiftKey ) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          //console.log ("lastFocusableEl", lastFocusableEl);
          lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          //console.log ("firstFocusableEl", firstFocusableEl);
          firstFocusableEl.focus();
            e.preventDefault();
          }
        }
    });
  }
class EmailSubscribe extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: initialEmailFormValues,
            formRef: null,
            currentStep: 1,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
        const { props } = this;
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
    //    initialValues.flex_EmailCaptureSource = 'SAVE';
    //    initialValues.flex_Info_Requested__c = ''
    //    initialValues =  loadCookiesData(initialValues);
    //    document.title = `Email Subscribe`;
    //    initialValues.thankyouPageUrl = '/request-info/thankyou-email-subscribe.html';
    //    this.setAnalytics('Email Subscribe');
        initValues(initialValues, this.props);       
        this.setState({ initialValues }); 
    //    this.setState({ isSubmit: true }); 
    //    getIPAddress(this.updateIPAddress);
        const { currentStep } = this.state;
        formStep = currentStep;
        formType = 'Non-RFI';
    	formName = 'RFIAbandoned';
        if (window.pageData && window.pagePath) {
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
            pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
        }
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
        
    }

    setAnalytics = (formname) => {
           
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }    
    

    handleSubmit = (values) => {    
	if (document.activeElement.name === "submit") {
        //this.setState({ initialValues: values });  
        window.uopSegment&&window.uopSegment.identify({'email': values.emailAddress});
        window.uopSegment&&window.uopSegment.track('RFI Abandoned', {'email': values.emailAddress, 'analytics': {'mcid': ecidValue}});
        window.uopxDataLayer&&window.uopxDataLayer.track('RFI Abandoned', {'componentName' : 'RequestInfo', 'name': formName, 'type': formType, 'text': 'Submit', 'formInformation': {'profileDetails':{'email': values.emailAddress}}, 'external': {'segmentAnonymousId': window.analytics.user().anonymousId(), 'segmentUserId': window.analytics.user().id()}});
        this.setState({ isSubmit: true, currentStep:2, initialValues: values });
        srSpeak('email submitted'); 
        fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
        if (window.FS) {
            preProspectInfoData.source= "Abandoned-Email-Only";
            //calling FS and passing the tracking data set
            window.FS.event('Submitted JO-RFIAbandoned', preProspectInfoData);
            console.log("Submitted JO-RFIAbandoned", preProspectInfoData);
        }
        
    //    document.querySelector('#announce').innerHTML = 'email submitted';
        if (document.querySelector('.btn-close')) {
            document.querySelector('.btn-close').focus();
            trapFocus(document.querySelector('#modalrfi'), 0);
        }
      }   
    }
    
    validateForm(values) {
        const errors = {};
        if (!values.emailAddress) {
          errors.emailAddress = 'Email Address requires a value';
        }
        if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          errors.emailAddress = 'Email address appears invalid';
        }
      //  console.log('errors', errors, values);
        return errors;
    }

    /*modalClosebtn = () => {
        this.setState({ isSubmit: false, currentStep:1});
    } */

    keydownClose = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.modalClose;
        }         
    }

    modalClose = () => {
    //    const { initialValues } = this.state;
        this.setState({ isSubmit: false, currentStep:1});
        let modal = document.querySelector(".modal");
        let overlay = document.querySelector(".overlay0P");
        if (modal !== null || overlay !== null){
            modal.classList.add("hidden");
            overlay.classList.add("hidden");
        }
        window.uopxDataLayer.track("NonRFIModalClose",{
            'componentName' : 'RequestInfo',
            'name': formName + " " + this.state.currentStep, // depends on step closing 
            "type": formType,
            "text": "close",
        }); 
    };
      
    render() {
    //    const { classes } = this.props;
        const { initialValues,  isSubmit} = this.state;

        let titleText = this.props.title || defaultTextHTML;
        let descriptionText = this.props.description || initialValues.description || defaultDescriptionTextHTML;
        let cta = this.props.cta || initialValues.cta || 'Submit';

        return (    
            <div>
            {/* <UOPHeader /> */}
                <section className="abandonment">
                    <Grid container justify='center' className="mobileFormContainer">
                    <Grid container md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">
					    {/*	<Grid item md={12} sm={12} lg={12} xs={12} >
						<div>{unescapeHTML(titleText)}</div>
                        <div className="newOneForm-SubTitle" style={{ fontSize: '18px', fontWeight: 700 }}>Email us &mdash; and we’ll get the ball rolling.</div>
                        <div className="reqinfo" > * Required information </div>
						</Grid>   */}
                            <Grid item md={12} sm={12} lg={12} xs={12} style={{ maxWidth:'900px' }}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => this.validateForm(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    <Form className="mobile-form"
                                                aria-label="Email Us"
                                                style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                        <Grid container >
                                            <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm rfiabandonment">
                                                <div className=""> </div>
                                            </Grid>
                                    {!isSubmit
                                        ? (
                                            <>  
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm rfiabandonmentcopy">
                                                    {unescapeHTML(titleText)}
                                                    {unescapeHTML(descriptionText)}
                                                    <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                                                    <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" className='uopx-input footeremail modalfield' onClick={this.handleAnalyticsRFIFormStart} />
                                                    <div className="disclaimer"> {unescapeHTML(disclaimerText)} </div>
                                                    <Button name='submit' size='large' color='secondary' type='submit' className='rfi-btn-submit submit-btn'> {unescapeHTML(cta)} </Button>
                                                    {isMobileView && <Button size='small' variant = 'Secondary' color='secondary' onClick={this.modalClose} onKeyDown={this.keydownClose} className='' style={{color:'#5e7079', margin:'auto', marginTop:'16px'}}> No thanks </Button> }
                                                </Grid> 
												<ErrorFocus />
                                            </>
                                        ):(
                                            <>
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                <div className ="rfiabandonmentcopy">
                                                    <h2 className='newOneForm-title'>Awesome - thanks </h2>
                                                    <div className='newOneForm-SubTitle thanks' >Good info is headed your way. Check your inbox soon.</div>
                                                </div>
                                                <div style={{ padding: '3rem'}}>
                                                    <Button size='large' color='secondary' onClick={this.modalClose} onKeyDown={this.keydownClose} className='rfi-btn-submit btnclose'> Close </Button>
                                                </div>    
                                                </Grid>
                                                {/* this.modalClose() */}
                                            </> 
                                          )}
                                          {!isMobileView && <button id="close-modal" aria-label="Close dialog" title="Close" onClick={this.modalClose} onKeyDown={this.keydownClose} type="button" class="btn-close"></button>}
                                          </Grid>
                                        </Form> 
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
        {/*    <Footer /> */}
            </div>
        );
    }
}

EmailSubscribe.defaultProps = {
    classes: {},
};

EmailSubscribe.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default EmailSubscribe;
//export default withStyles(styles)(EmailSubscribe);