import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import rfiFormInitialValues from './layout/FormModels/RFIFormInitialValues'
import MaterialRadio from "./layout/MaterialComponents/MaterialRadio";
import { Formik, Form } from "formik";
import styles from './layout/styles/MobileFormStyles';
import SelectField from './UopxNativeSelectField';
import MaterialInput from './layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from './layout/MaterialComponents/MaterialPhoneNumber';
import { validateOneStepTest } from './layout/MobileFormValidation';
import { addDropDownOption, processJSONParameter } from './common/configTools';
import getIPAddress, { unescapeHTML, loadCookiesData, roleChange, submitForm, conditionalCBE, pageNameByPath, fireAnalyticsEvent, fireFOS_DlEvent, getDegreeLevel, updatePrePopCookieValue } from './CommonComponents';
import { courseCategoryDropdown, degreeLevel, degreeLevelVariant, dynamicFieldOfStudyDropdownVariant, radioOption } from './common/constants';
import FormikErrorFocus from 'formik-error-focus';
import Button from './layout/MaterialComponents/button/Button';
import ZipCode from './layout/MaterialComponents/ZipCode';
import FormikCheckbox from './layout/MaterialComponents/checkbox/FormikCheckbox';
import {dataLayer} from './common/dataLayer';

const formRef = {};
let prevFOS, prevDegreeLevel, prevState, prevCourseCategory;
let courseCategoryFlag = false;
const disclaimerText = rfiFormInitialValues.disclaimer;
let isSavingsExplorer = false;
let subHeader = "Answer a few questions and an enrollment representative will connect with you to chat about options that can help you save time and money on your degree.";
const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};

class EmployerReferralForm extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            formRef: null,
            variant: props.variant,
            feedback: any,
	        activeStep: 1,
            fieldOfStudyOptions: processJSONParameter([...dynamicFieldOfStudyDropdownVariant]),
            degreeLevel: processJSONParameter([...degreeLevelVariant]),
            initialDegreeLevel: processJSONParameter([...degreeLevelVariant]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
        };
        this.state = this.initialState;
    }

    componentDidMount() {
        window.pagePath = "page-dd_rfi";
    //    document.title = `Request Information about Programs and Courses at the University of Phoenix. - Desktop`;
        formPageType = "RFI";
        formType = "RFI";
        formName = "employerReferralForm - 1 step";
        let { initialValues } = this.state;
        initialValues =  loadCookiesData(initialValues);
        initialValues.thankyouPageUrl = '/request-info/thankyou-employerreferral.html';
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        this.handleZipcodeChange(initialValues.postalCode,initialValues.stateProvince);
        this.setState({ initialValues });
        if(prevFOS){
            this.resetDegreeLevel(prevFOS)
        }
        getIPAddress(this.updateIPAddress);
        const { activeStep } = this.state;
        formStep = activeStep;

	    if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        dataLayer();
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
            this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
        }
    };

    dropdownChange = (name, value) => {
        const {initialValues} = this.state;
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                if(initialValues.fieldOfStudy === "nondegree") {
                    courseCategoryFlag = true;
                }
                else {
                    courseCategoryFlag = false;
                    this.resetDegreeLevel(value);
                }
                
            }
        }
        else if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegreeLevel = value;
                initialValues.degreeLevel = value
                validateOneStep(initialValues);
            }
        }
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
    }
    
    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }    

    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel,fosValue,formRef?.current?.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        if ((formRef.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
          var optionExists = newDegreeLevelOptions.some((e)=>this.state.initialValues.degreeLevel===e.value);
          //if prev degree level doesnt exist, reset
          if (!optionExists) formRef.current.setFieldValue('degreeLevel', '')
        }
        this.setState({degreeLevel:newDegreeLevelOptions}); // EX: Bachelors, Individual Courses
    }

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            formRef.current.values.postalCode = zipcode;
            formRef.current.values.stateProvince = state;
            updatePrePopCookieValue('zipcode',zipcode);
            updatePrePopCookieValue('state', state);
            if (state && prevState !== state) {   
                prevState = state;
            }
            this.updateAndNotify(state);
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
     }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }

    isCBEConsent = (event) => {
        const { checked } = event.target;
        formRef.current.values.isCBE = checked;
    }

    handleSubmit = (values) => {
        values.fieldOfStudy = 'business';
        values.degreeLevel = 'bachelors';
        if (document.activeElement.name === "submit") {
        this.setState({ initialValues: values });
        submitForm(values, this.props);
        }
    }

    getTitleText() {
        let defaultTextHTML = "<h2 class='title-small' style='text-align:left;'>We'd love to get to know you — and your goals.</h2>";
        let titleText = this.props.title || defaultTextHTML;
        return titleText;
    }
    getDescriptionText() {
        let descriptionText = this.props.description || "<div class='newOneForm-SubTitle' style='text-align:left;'>Answer a few questions and an enrollment representative will connect with you to chat about options that can help you save time and money on your degree.</div>";
        return descriptionText;
    }

    render() {
        const { classes } = this.props;
        const {
            activeStep,
            initialValues,
        } = this.state;
        return (
            <div>
            {/*    <UOPHeader /> */}
                <section className="rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Typography
                                    variant='body2'
                                    component='div'
                                    className="reqinfo"
                                >
                                    * Required information
                                </Typography>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => validateOneStepTest(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Request For Information"
                                                  style={{ position: 'relative' }}
                                                  onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container>
                                        {/*        
                                               <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                               <SelectField
                                                    id="fieldOfStudy"
                                                    name="fieldOfStudy"
                                                    label="Area of study *"
                                                    data={this.state.fieldOfStudyOptions}
                                                    className="MuiFormControl-fullWidth"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("fieldOfStudy", props.values.fieldOfStudy)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                 {conditionalRadio(props.values.fieldOfStudy) && <>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} row={true} className="selectRadioLabel" onChange={this.handleChange} items={radioOption} label="Are you currently a Registered Nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                    </Grid>    
                                                    </>}                                                
                                                {props.values.fieldOfStudy !== "nondegree" && <>
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                 <SelectField
                                                    id="degreeLevel"
                                                    name="degreeLevel"
                                                    label="Degree level *"
                                                    data={this.state.degreeLevel}
                                                    className="MuiFormControl-fullWidth"
                                                    ariaRequired="true"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                </>}
                                                {props.values.fieldOfStudy === "nondegree" && <>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                    <SelectField
                                                        id="flex_Course_Category__c"
                                                        name="flex_Course_Category__c"
                                                        label="Course category *"
                                                        data={courseCategoryDropdown}
                                                        className="MuiFormControl-fullWidth"
                                                        formRef={formRef}
                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                        onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                    ></SelectField>
                                                    {roleChange()}
                                                </Grid>
                                                </>}
                                                 {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">
                                                        <FormikCheckbox type="checkbox" id="isCBE" name="isCBE" className="cbe-checkbox" onChangeCallback={this.isCBEConsent} label={unescapeHTML(this.getCBEText())}>
                                                        </FormikCheckbox>
                                                    </Grid>
                                                </>}
                                            */}
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                 <MaterialInput name="firstName" id="firstName"
                                                    label="First name *" ariaRequired="true" ariaLabel="Enter First name"  />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="lastName" id="lastName"
                                                    label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialPhoneNumber name="phoneNumber" label="Phone number *" ariaLabel="Enter phone number" ariaRequired="true" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                </Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" aria-label="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef}></ZipCode>
												</Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className= 'selectRadioLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
												 </Grid>
                                                 
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                   <div style={{fontSize:"14px", fontWeight:"normal", marginBottom:'18px'}}>You might be <strong>eligible for tuition savings</strong> if your employer has an alliance with us. Talk to your enrollment representative about it — as well as all the other ways you can <strong>save time and money</strong> on your degree.</div>
                                                </Grid>
												
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                     <div className="employer">
                                                        <MaterialInput name="flex_Employer_Disclosed_on_RFI__c" id="flex_Employer_Disclosed_on_RFI__c"
                                                            label="Employer name (optional)" ariaLabel="Employer name (optional)"  />
                                                    </div>
                                                 </Grid>
                                                <div className="disclaimer">
                                                  {unescapeHTML(disclaimerText)}    
                                                </div>
                                                <Button name='submit' size='large' type='submit' className="btn-submit">
                                                            Request information
                                                        </Button>
                                                </Grid>
												<FormikErrorFocus offset={-50} align={'top'} focusDelay={200} ease={'linear'} duration={1000} />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                          </Grid>
                    </Grid>
                </section>
            {/*   <Footer />  */}
            </div>
        );
    }
}

EmployerReferralForm.defaultProps = {
    classes: {},
};

EmployerReferralForm.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(EmployerReferralForm);