import React, { useEffect } from 'react';
import { makeGetCall } from '../../utils/apiClient';
import { importScriptOnce } from '../../utils/customHooks';

const Footer = () => {
  // required for all data driven components
  const [footerContent, setFooterContent] = React.useState('');
  useEffect(() => {
    const url = process.env.REACT_APP_PHXEDU_FOOTER_URL;
    // fire the api call to get the header content
    makeGetCall(url, (data => {
      setFooterContent(data);
    }));
  }, []);

  useEffect(() => {
    importScriptOnce(process.env.REACT_APP_PHXEDU_CHAT_JS);
    if (footerContent) {
      importScriptOnce(process.env.REACT_APP_PHXEDU_COMMON_JS, e => {
        window.footerFunction && window.footerFunction();
      });
    //  importScript(process.env.REACT_APP_PHXEDU_FOOTER_JS);
    }

  }, [footerContent]);
  
/*
  if (window.location.host === 'stage.phoenix.edu') {
    useEffect(() => {
      const url = process.env.REACT_APP_PHXEDU_FOOTER_URL;
      // fire the api call to get the header content
      makeGetCall(url, (data => {
        setFooterContent(data);
      }));
    }, []);

    useEffect(() => {
      importScriptOnce(process.env.REACT_APP_PHXEDU_CHAT_JS);
      if (footerContent) {
        importScriptOnce(process.env.REACT_APP_PHXEDU_COMMON_JS_QA, e => {
          window.footerFunction && window.footerFunction();
        });
      //  importScript(process.env.REACT_APP_PHXEDU_FOOTER_JS);
      }

    }, [footerContent]);

  } else {
    useEffect(() => {
      const url = process.env.REACT_APP_PHXEDU_FOOTER_URL;
      // fire the api call to get the header content
      makeGetCall(url, (data => {
        setFooterContent(data);
      }));
    }, []);

    useEffect(() => {
      importScriptOnce(process.env.REACT_APP_PHXEDU_CHAT_JS);
      if (footerContent) {
        importScriptOnce(process.env.REACT_APP_PHXEDU_FOOTER_JS, e => {
          window.footerFunction && window.footerFunction();
        });
      //  importScript(process.env.REACT_APP_PHXEDU_FOOTER_JS);
      }
    }, [footerContent]);

  }
 
  */
  

  if (footerContent) {
    return (
      <>
        <div className="footer" dangerouslySetInnerHTML={{ __html: footerContent }} />
      </>
    );
  }

  return null;
};

export default Footer;
