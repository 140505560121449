import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Popper from "@mui/material/Popper";
import '../styles/Input.scss';
//import { makeStyles, createStyles } from '@mui/styles';
import { at } from 'lodash';
import { ErrorMessage, Field, useField } from "formik";

/* const useStyles = makeStyles((theme) =>
  createStyles({
   
  })
); */

const CustomPopper = function (props) {
 // const classes = useStyles();
  
  return <Popper {...props} className="uopxAutocompletestyle" style={{ zIndex:99, width: '100%', borderRadius:'4px', padding: '0 1.5rem' }} placement="bottom" />;
};


export default function SmartyStreets(props) {
    const [addresses, setAddress] = useState([]);
    const [streetList, setStreetList] = useState([]);
    const {errorText, isInvalid, ariaRequired, id, name, label, ariaLabel, ...rest} = props;
    const inputProps = {
      'aria-required': ariaRequired,
    };

    const [field, meta] = useField(props);
    function _renderHelperText() {
      const [touched, error] = at(meta, 'touched', 'error');
      if (touched && error) {
        return error;
      }
    }
    const onChange = (e) => {
        const newAddress = e.target.value; // addresses+e.key;
        setAddress(newAddress);
        const getStreetList = async(inputText)=>{
            let response = await fetch(
                `/services/contacts/v2/suggest/address?prefix=${inputText}`,
            );
            if (response.status === 200) {                
                let responseData = await response.json();
                let testArray = responseData?.suggestions;                
                let newStreetList = []
                testArray.forEach(e => {                  
                  if (newStreetList.includes(e.text)){
                    console.log(`${e.text} already in array`);
                  } else {
                    newStreetList.push(e.text);
                  }                                                  
                });                                                                          
                setStreetList(newStreetList);
                //console.log(newStreetList);
            } else {
                console.log("oops");
            }
        }
        if(newAddress !== null ) {
            getStreetList(newAddress);
        }
        
    }
    const sendDataToParent = (value) => {
        if(props.onChange){
            props.onChange(value)
        }
    }
  return (
    <Autocomplete    
      filterOptions={(x)=>x}//{createFilterOptions({ limit:10 })}
      disablePortal
      id={id}
      name={name}
      autoSelect
      options={streetList}
      fullWidth
      value={props.street}
      onChange={(event, value) => sendDataToParent(value)}
      renderInput={(params) => <TextField {...params} onChange={onChange} className="uopx-input" value={props.street} label={label} required={props.required}  error={meta.touched && meta.error && true}
      helperText={_renderHelperText()} />}
      PopperComponent={CustomPopper}
      //OnClientDropDownClosing
    />
  );
}