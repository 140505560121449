import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material/';
import { at } from 'lodash';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import { Field, useField } from 'formik';
import '../styles/Input.scss';
import {makeid} from '../../CommonComponents';

function MaterialPhoneNumber(props) {
  const {
    id, name, label, handleBlur, required, ariaLabel, ariaRequired, ...rest
  } = props;
  const [, meta, helpers] = useField(props);

  const [value] = at(meta, 'value');
//  const [input, setInput] = useState('');
  const newValue = (`${value}`).replace(/[^0-9]/g, '').substr(-10, 10);
  useEffect(() => {
    if (newValue) helpers.setValue(newValue);
  }, [value]);

  function getValue() {
    if (newValue.length === 10) {
      handleBlur(newValue);
      return newValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return newValue;
  }

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
/*  const handleChange = (event) => {
    setInput(event.target.value);
  };
*/
  let twochar = makeid(2);
  let newid = id +"-" + twochar;
  return (
    <>
      <Field
        name={name}
        as={TextField}
        label={label}
        id={newid}
        fullWidth
        required={required}
        aria-label={ariaLabel}
        type="tel"
        variant="outlined"
        className="uopx-input"
        error={meta.touched && meta.error && true}
      //  onChange={handleChange}
        helperText={_renderHelperText()}
        value={getValue()}
        InputProps={ !meta.error && {
          endAdornment: (
            <InputAdornment position="end" tabIndex='0'>
              {meta.touched && !meta.error && <Done role='img' focusable="true" aria-hidden="false" aria-label='validation successful' color="secondary" title="validation successful" />}
              {/*meta.touched && meta.error && <Close role='img' aria-label='validation failed' color="primary" />*/}
            </InputAdornment>
          ),
          length: 10,
          ...rest
        }}
        inputProps={{'aria-required':ariaRequired, 'aria-label':ariaLabel}}
      />
    </>
  );
}

export default MaterialPhoneNumber;
