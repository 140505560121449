import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import './Input.scss';

// Material-ui overides
// https://v4-10-2.material-ui.com/api/input/

function Input({
  id,
  name,
  isInvalid,
  handleIsInvalid,
  ariaLabel,
  ...other
}) {
  return (
    <TextField
      {...other}
      id={id}
      name={name}
      aria-label = {ariaLabel}
      variant="outlined"
      error={isInvalid}
      onBlur={handleIsInvalid}
      fullWidth
      className="uopx-input"
    />
  );
}

Input.propTypes = {
  /**
  * The id of the input element is required.
  * Use this prop to make label and helperText accessible for screen readers.
  */
  id: PropTypes.string.isRequired,
  /**
   * Name attribute of the input element is also required.
   * The name is used in announcing the form control to screen readers.
   */
  name: PropTypes.string.isRequired,
  /**
   * If true, the outline will be shown in error mode.
   */
  isInvalid: PropTypes.bool,
  /**
   * Callback fired when the component looses focus.
   */
  handleIsInvalid: PropTypes.func,
};

Input.defaultProps = {
  isInvalid: null,
  handleIsInvalid: null,
};

export default Input;
