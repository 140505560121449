import React, { Component, useState} from 'react';
import { Form, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
//import { fireEvent } from '../CommonComponents';
import { fireAnalyticsEvent, fireNonRFISubmitEvent, fireEvent} from '../CommonComponents';
import { submitUnsubEmailForm } from './UnsubscribeAPI';
import PropTypes from 'prop-types'; // prop-types
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material/';
import UOPHeader from '../layout/UopHeader';
import Footer from '../layout/Footer';
import Button from '../layout/MaterialComponents/button/Button';
import styles from '../layout/styles/MobileFormStyles';
import CheckCircle from '../layout/images/check-circle.svg';
//import UnsubscribeEmailForm from './UnsubscribeEmailForm';
//import{loadCookiesData} from '../CommonComponents';
import axios from 'axios';
import qs from 'qs';
import {dataLayer} from '../common/dataLayer';
//import emailusFormInitialValues from '../email-us/EmailusFormInitialValues';

var profileData = {};

function srSpeak(text, priority) {
  try{
      var el = document.createElement("div");
      var id = "make-a-screen-reader-talk-" + Date.now();
      el.setAttribute("id", id);
      el.setAttribute("aria-live", priority || "polite");
      el.classList.add("visually-hidden");
      document.body.appendChild(el);
      
      window.setTimeout(function () {
          document.getElementById(id).innerHTML = text;      
      }, 100);
      
      window.setTimeout(function () {
          if (document.getElementById(id)){
              document.body.removeChild(document.getElementById(id));
          }
      }, 1000);
  } catch(e){
      console.log ("error", e);
      throw e;
  }
}

class IndividualEmailUnsubscribe extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isSubmit: false,
      initialValues: initialEmailFormValues,
      formRef: null,
      variant: props.variant,
      message: null,
      value: 1,
  };
  
  this.state = this.initialState;
    /*this.state = {
      isSubmit: false,
      formRef: null,
      initialValues: {},
      variant: props.variant,
      message: null,
      value: 1,
    };*/
    this.ref = {
      form: React.createRef(),
      container: React.createRef(),
    };
    
  }

  componentDidMount() {
    window.pagePath = "page-email-unsub";
    formType = 'Non-RFI';
    formName = 'Email Unsubscribe';
    formStep = 1;
    document.querySelector('meta[name="description"]').setAttribute("content", "If you no longer want to receive emails, updates or other notifications from University of Phoenix, complete our unsubscribe form.");
    document.title = `unsubscribe from our marketing notifications`;
    let { initialValues } = this.state;
    initialValues.requestingUrl = window.location.href;
    // get url query params details
	  const urlData = decodeURIComponent(window.location.href).split("?")[1];
	  const urlqueryParams = qs.parse(urlData, { comma: true });
	  //console.log("query params ", urlqueryParams);

	  Object.entries(urlqueryParams).map(([key, value]) => {
	    const trimmedKey = key.toLowerCase().trim();
	    if (value !== null && value !== undefined && value !== "undefined" && value !== '') {
	      if (trimmedKey === "rrId".toLowerCase()) {
	        initialValues.flex_referrer_analyticsId = value;
	      }
	    }
	  });
    if (initialValues.flex_referrer_analyticsId !== '' && initialValues.flex_referrer_analyticsId !== null){
      this.referrerProfile(initialValues); 
    }
    dataLayer();
   
  }

  referrerProfile(values) {
    const { initialValues } = this.state;
    let ID = values.flex_referrer_analyticsId;
    let URL = '/request/referFriend/'+ ID;
    axios.get(URL, {
        method: 'GET',  
    })
    .then((response) => {
        profileData = response.data;
        console.log ("profileData received as part of api call is " + profileData);
        initialValues.emailAddress = profileData.entries[0].profile.emailAddress[0].emailAddress;
        this.setState({ initialValues });
        //this.emailUpdate(initialValues);
        setTimeout(function(){fireAnalyticsEvent('NonRFIStart', 'email Unsubscribe', 'Non-RFI');}, 1000);
    })
    .catch(error => {
    console.log (error.message);   
    });
  }


  emailUpdate(initialValues){
    console.log("in email update", initialValues.emailAddress);
    if (Values.emailAddress !== '') {
      this.forceUpdate();
    }
  }

  async handleEmailSubmit(values) {
    //values.requestingUrl = window.location.href;
    //if (document.activeElement.name === "submit") {
    const { classes } = this.props;
  //  fireEvent('rfi_unsubscribe_email_submit');
    let responseJSON;
    try {
      responseJSON = await submitUnsubEmailForm(values);
    } catch (e) {
     responseJSON = { message: `${e}`, success: false };
    }

    this.setState({
      initialValues: values,
      isSubmit: responseJSON.success,
      message: <div className={responseJSON.success ? classes.unsubSuccess : classes.error}>{responseJSON.message || 'No Message Given'}</div>,
    });
    srSpeak("You're unsubscribed we're always here for you. Let us know if we can help at phoenix.edu");
    this.ref.container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    fireNonRFISubmitEvent( 'NonRFISubmit', values, 'email Unsubscribe', 'Non-RFI');
    //document.querySelector('#thankstitle').focus();
    //}
  }

  handleAnalyticsRFIFormStart = () => {
    if (this.state.rfi_begin_email !== true) {
      fireAnalyticsEvent('NonRFIStart', 'email Unsubscribe', 'Non-RFI');
      this.setState({ rfi_begin_email: true });
    }
  }

  validateEmail(values) {
    console.log('validate', values);
    const errors = {};
    if (!values.emailAddress) {
      errors.emailAddress = 'Email Address requires a value';
    }
    if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.emailAddress = 'Email address appears invalid';
    }
    return errors;
  }
  

  render() {
    const { classes } = this.props;
    const { isSubmit, initialValues } = this.state;
   
    return (
      <div>
        <UOPHeader />
        <main className={classes.root} style= {{margin: "0px"}}>
          <Grid container justifyContent="center" className="mobileFormContainer">
            <Grid container className="unsubscribeColoum">
            <Grid item md={12} sm={12} lg={6} xs={12} >
                <div className='unsubscribe-image'>
                <img alt="" />
              
              </div>
              </Grid>
              <Grid container item md={12} sm={12} lg={6} xs={12}>
                <div ref={this.ref.container} className="unsubscribeEmail">
        {!isSubmit
          ? (
            <Formik
              initialValues={initialValues}
              onSubmit={values => this.handleEmailSubmit(values)}
              validate={values => this.validateEmail(values)}
              innerRef={this.ref.form}
              validateOnChange
            >
              <Form
                className="unsubscribe-email-form"
                style={{ position: 'relative', paddingBottom: '2rem' }}
                onChange={this.handleAnalyticsRFIFormStart}
              >
                <Grid containerid="emailForm">
                <h1 className="newOneForm-title">Unsubscribe from email</h1>
                <div className="newOneForm-SubTitle">
                Good-bye doesn’t have to be forever. If you want to get info about scholarships, ways to save or online degrees and programs, check out <a href='http://www.phoenix.edu'>phoenix.edu</a>. 
                </div>
                  <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                  {this.state.message}
                  <Grid
                    item
                    md={12}
                    sm={12}
                    lg={12}
                    xs={12}
                    className="margin-OneStepForm"
                    id="emailFields"
                  >
                    <MaterialInput
                       name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined"
                      onClick={this.handleAnalyticsRFIFormStart}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} lg={12} xs={12}>
                    <div>
                    
                      <Button
                        name='submit'
                        size="large"
                        type="submit"
                        className='rfi-btn-submit submit-btn'
                        //style={{backgroundColor: "#30718d"}}
                      >
                        Unsubscribe
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                
                <FormikErrorFocus
                  offset={-50}
                  align="top"
                  focusDelay={200}
                  ease="linear"
                  duration={1000}
                />
              </Form>
                                          
            
            </Formik>
          ):(
            <>
              <Grid item md={12} sm={12} lg={12} xs={12} className={classes.thanksContainer} role='region' id='thanksmessagecontainer' aria-live='polite'>
              <h1 id='thankstitle' className="newOneForm-title" aria-labelledby='thankstitle thanksdesc'>You're unsubscribed</h1>
                <div id='thanksdesc' className="newOneForm-SubTitle">
                We're always here for you. Let us know if we can help at <a href='http://www.phoenix.edu'>phoenix.edu</a> 
                </div>
              </Grid>
            </>
          )}
       </div>
              </Grid>
            </Grid>
          </Grid>
        </main>
        <Footer />
      </div>

    );
  }

}

const initialEmailFormValues = {
  emailAddress: '',
  requestingUrl: '',
  flex_referrer_analyticsId: '',
};

IndividualEmailUnsubscribe.defaultProps = {
  classes: {},
};

IndividualEmailUnsubscribe.propTypes = {
  classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(IndividualEmailUnsubscribe);
