import STATUS from './globalStatuses';

export function makeGetCall(url, callbackFunction) {
  fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/html',
      'X-Requested-With': 'XMLHttpRequest',
    },
  }).then(response => response.text()).then(data => {
    callbackFunction(data);
  }).catch(err => {
    console.error('Error while making the get call - ', err);
    // send error status
    const data = {};
    data.status = STATUS.ERROR;
    callbackFunction(data);
  });
}

export function makeJsonGetCall(url, callbackFunction) {
  fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  }).then(response => response.json()).then(data => {
    callbackFunction(data);
  }).catch(err => {
    console.error('Error while making the json get call - ', err);
    // send error status
    const data = {};
    data.status = STATUS.ERROR;
    callbackFunction(data);
  });
}

export function makeMultipleJsonGetCalls(urls, callbackFunction) {
  Promise.all(urls.map(u => fetch(u, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  }))).then(responses => Promise.all(responses.map(res => res.json()))).then(data => {
    /* eslint-disable no-param-reassign */
    data.status = STATUS.SUCCESS;
    callbackFunction(data);
  }).catch(err => {
    console.error('Error while making multiple get calls - ', err);
    // send error status
    const data = {};
    data.status = STATUS.ERROR;
    callbackFunction(data);
  });
}
