import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import UOPHeader from '../layout/UopHeader';
import Footer from '../layout/Footer';
import EmailFormInitialValues from './EmailusFormInitialValues';
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
import { validate } from "./EmailusValidation";
import getIPAddress, { unescapeHTML, loadCookiesData, submitForm, fireAnalyticsEvent, pageNameByPath,} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus'
import axios from 'axios';
import { Cookies } from "react-cookie";
import Button from '../layout/MaterialComponents/button/Button';
import {dataLayer} from '../common/dataLayer';

const formRef = {};
let defaultTextHTML = "<h1>Email us</h1>";
const disclaimerText = EmailFormInitialValues.disclaimer;

const onTextAreaChange= (e) => {
    formRef.current.values.flex_more_info__c = e.target.value;
  }

const conditionalHeaderFooter = () => {
    let valid = false;
    if (window.location.href.indexOf('/email-us') > -1) {
        valid = true;
        return valid;
    }
};  

class EmailUs extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: EmailFormInitialValues,
            formRef: null,
        };
        this.state = this.initialState;
    }

    componentDidMount() {
        window.pagePath = "page-de_rfi";
        let { initialValues } = this.state;
        initialValues.requestingUrl = window.location.href; 
        initialValues =  loadCookiesData(initialValues);
	    formType = 'Non-RFI';
    	formName = 'Email';
        formStep = 1;
        if (initialValues.requestingUrl.indexOf('/re-entry') > -1) {
            initialValues.thankyouPageUrl = '/request-info/thankyou-re-entry.html';
            this.setAnalytics('Re-entry contact us');
        } else if (initialValues.requestingUrl.indexOf('/email-nurture-nd') > -1) {
            initialValues.thankyouPageUrl = '/request-info/thankyou-email-us-nd.html';
            this.setAnalytics('Email nurture non-degree');
        } else if (initialValues.requestingUrl.indexOf('/email-nurture') > -1) {
            initialValues.thankyouPageUrl = '/request-info/thankyou-email-nurture.html';
            this.setAnalytics('Email nurture');
        } else if (initialValues.flex_EmailCaptureSource === "ND") {
            initialValues.thankyouPageUrl = '/request-info/thankyou-email-us-nd.html';
        } else {
            document.title = `Email us`;
            initialValues.thankyouPageUrl = '/request-info/thankyou-email-us.html';
            this.setAnalytics('Email us');    
        }
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress);
        dataLayer(); 
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    setAnalytics = (formname) => {
        setTimeout(function() { 
	        formType = 'Non-RFI';
    	    formName = formname;       
        }, 2000);   
    }

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('NonRFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }    
    setFirstNameCookie (firstName)  {
        const cookies = new Cookies();
        cookies.set('firstname', firstName, { path: '/' });
    }

    handleSubmit = (values) => {    
        if (document.activeElement.name === "submit"){
        this.setState({ initialValues: values });
        console.log ("values", values);
        if (window.location.href.indexOf('/re-entry') > -1 || window.location.href.indexOf('/email-nurture-nd') > -1 ) {
            const headers = {
                'Content-Type': 'application/json',
            };
            let URL = '/request/sendmail';
            const data = JSON.stringify(values);
            axios.post(URL, data, {
                headers,
              })
            .then((response) => {
                if (response.data.msg === 'success'){
                    console.log("Email sent, awesome!");
                    this.setFirstNameCookie(values.firstName);
                    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
                    if(window.location.host !== 'www.phoenix.edu'){
                        baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
                    }
                    window.location.assign(baseUrl + values.thankyouPageUrl);
                }else if(response.data.msg === 'fail'){
                    console.log("Oops, something went wrong. Try again")
                }
            })
            .catch(error => {
            console.log (error.message);   
            });                
        }else {
        //    submitForm(values);
            submitForm(values, this.props);
        }  

        }
    }
    
    render() {
        // const { classes } = this.props;
        const {
            initialValues,
        } = this.state;

        let titleText = this.props.title || defaultTextHTML;

        return (
            
            <div>
            {   conditionalHeaderFooter() && <UOPHeader /> }
                <main className="emailus-main rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container md={12} sm={12} lg={12} xs={12} className="maxWidthContainer-oneStepForm">
						<Grid item md={12} sm={12} lg={12} xs={12} >
						<div>{unescapeHTML(titleText)}</div>
                        <div className="reqinfo" > * Required information </div>
						</Grid>   
                            <Grid item md={12} sm={12} lg={12} xs={12} style={{ maxWidth:'800px' }}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => validate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Email Us"
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container >                                               
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="firstName" id="firstName"
                                                        label="First name *" ariaRequired="true" ariaLabel="Enter First name" onClick={this.handleAnalyticsRFIFormStart} />
                                                </Grid>
                                                <Grid item md={6} sm={6} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="lastName" id="lastName"
                                                        label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                    <MaterialTextArea
                                                        id="flex_more_info__c"
                                                        name="flex_more_info__c"
                                                        onChange={onTextAreaChange}
                                                        label="How can we help? *"
                                                        classNames="uopx-input more_info_textarea"
                                                        rows={4}
                                                        ariaLabel="How can we help?"
                                                        ariaRequired="true"
                                                    />
                                                </Grid>
                                                <div className="disclaimer">
                                                  {unescapeHTML(disclaimerText)}
                                            {/*        <p>Visit our <a target="_blank" href="https://www.phoenix.edu/copyright-legal/privacy-policy.html">Privacy Policy</a> for information about how we collect and use your personal information.</p>    */}
                                                </div>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ={["margin-OneStepForm", "submit-btn" ]}>
                                                    <Button name='submit' size='large' type='submit' className="btn-submit"> Submit </Button>
                                                    </Grid>
                                                </Grid>
												<ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
                {   conditionalHeaderFooter() && <Footer /> }
                 
            </div>
        );
    }
}

EmailUs.defaultProps = {
    classes: {},
};

EmailUs.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(EmailUs);