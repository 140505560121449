import React from 'react';
import { Field, useField } from 'formik';
import { at } from 'lodash';
import { TextField } from '@mui/material/';
import '../styles/Input.scss';

function MaterialTextArea(props) {
  const { name, label, ariaRequired, ariaLabel, required, placeholder, rows } = props;
  const [, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <>
      {/* <InputLabel class="FormOne-label-6 jss6 MuiTypography-body2">{label}</InputLabel> */}
      <Field
        as={TextField}
        name={name}
        id={name}
        error={meta.touched && meta.error && true}
        helperText={_renderHelperText()}
        fullWidth
        label={label}
        className="uopx-input uopx-textarea"
        variant="outlined"
        multiline
        rows={rows}
        required={required}
        placeholder={placeholder}
        inputProps={{'aria-required':ariaRequired, 'aria-label':ariaLabel}}
      />
    </>
  );
}

export default MaterialTextArea;
