import React, { useState } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import MUIRadio from '@mui/material/Radio';
import { ErrorMessage, Field, useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { at } from 'lodash';
import { styled } from '@mui/styles';
import {makeid} from '../../CommonComponents';

const FormHelperTextWrap = styled(FormHelperText)({
    color: '#db3725',
    fontSize: '14px',
    backgroundColor: '#FFF',
    border: '1px solid #db3725',
    padding: '0.7rem 1.4rem',
});

const MaterialRadioGroup = styled(RadioGroup)({
    marginBottom: '0px !important',
});

const RadioButton = props => {
    const {
        children, name, className, checked, error, label, row, ariLabeledBy,
    } = props;
    const [field, meta] = useField(props);
    function _renderHelperText() {
        const [touched] = at(meta, 'touched');
        if (touched && error && meta.error) {
            return <FormHelperTextWrap aria-live='assertive' id="formHelperTextWrap">{error}</FormHelperTextWrap>;
        }
        return null;
    }

    return (
        <FormControl component="fieldset">
            {/* legend */}
            <legend className={className} name={name} style={{ marginBottom: "24px" }}>{label}</legend>
            <MaterialRadioGroup
                row={row}
                name={name}
                label={label}
                checked={checked}
                className={className + " radioBox"}
                aria-labelledby={ariLabeledBy}
                role="radiogroup"
            >
                {children}
            </MaterialRadioGroup>
            {_renderHelperText()}
        </FormControl>
    );
};

const MaterialRadioWithBox = ({
    items, name, className, onChange, row, value, props, ariLabeledBy, label,
}) => {
    const [SelectedValue, setSelectedValue] = useState('');
    const handleChange = event => {
        //  console.log('event.target.value', event.target.value)
        setSelectedValue(event.target.value);
        onChange(event, props);
    };
    if (!SelectedValue && value) {
        setSelectedValue(value);
    }
    let twochar = makeid(2);

    return (
        <Field
            as={RadioButton}
            name={name}
            label={label}
            size="large"
            checked={SelectedValue}
            row={row}
            className={className}
            error={!SelectedValue && <ErrorMessage name={name} />}
            ariLabeledBy={ariLabeledBy}
        >
            {items.map((item, index) => (
                <FormControlLabel
                        key={index}
                        value={SelectedValue}
                        aria-label={item.label}
                        control={(
                            <div style={{alignContent:"center", display:'flex'}}>
                                <MUIRadio
                                    role="radio"
                                    color="primary"
                                    size="medium"
                                    value={item.value}
                                    checked={SelectedValue === item.value}
                                    aria-checked={SelectedValue === item.value}
                                    onChange={handleChange}
                                    name={name}
                                    label={item.label}
                                    id={`${item.label}-${twochar}`}
                                />                                
                                {item.icon}              
                            </div>
                        )}
                        label={item.label}
                        htmlFor={`${item.label}-${twochar}`}
                        name={item.label}
                    />
            ))}
        </Field>
    );
};

export default MaterialRadioWithBox;
