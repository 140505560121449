import React, { Component } from 'react';
import PropTypes from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material/';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues'
import Button from '@mui/material/Button';
import MaterialRadio from "../layout/MaterialComponents/MaterialRadio";
import MaterialRadioWithBox from "../layout/MaterialComponents/MaterialRadioWithBox";
import { Formik, Form } from "formik";
import { addDays, isBefore } from 'date-fns';
import isToday from 'date-fns/isToday';
import { UOPDatePicker } from '../layout/MaterialComponents/UOPDatePicker';
import TimePicker from '../layout/MaterialComponents/CustomTimePicker';
import '../../../node_modules/@duetds/date-picker/dist/duet/themes/default.css';
import moment from 'moment';
import styles from '../layout/styles/MobileFormStyles';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import { unescapeHTML, processJSONParameter } from '../../components/common/configTools';
import getIPAddress, { loadCookiesData, profanityCheck, roleChange, submitForm, conditionalCBE, pageNameByPath, fireAnalyticsEvent, fireFOS_DlEvent, fireRFISavingsOptionEvent, fireCampaignEvent, fireCampaignRadioEvent, updatePrePopCookieValue, isUserEligible, scrollToTop, getDegreeLevel } from '../CommonComponents';
import { apptsAvailable, courseCategoryDropdown, degreeLevel, degreeLevelVariant, dynamicFieldOfStudyDropdownVariant, radioOption, groupedCheckboxOption } from '../common/constants';
import ErrorFocus from '../common/ErrorFocus';
import UopHeader from '../layout/UopHeader';
import MobileFooter from '../layout/MobileFooter';
import UOPXButton from '../layout/MaterialComponents/button/Button';
import SelectField from '../UopxNativeSelectField';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from '../layout/MaterialComponents/checkbox/SingleCheckbox';
import { dataLayer } from '../common/dataLayer';
import { values } from 'lodash';
import { validate } from '../layout/MobileFormValidation';

let h1Tag1Step = "Let's get your questions answered. Start here.";
let h1Tag2Step = "You're on your way..."
let h1Tag3Step = "Good job. You're making progress.";
let h1Tag4step = 'Almost there - keep going!';
let h1Tag5Step = "Yay - last step! We'll connect soon to answer your questions.";
let waysToSaveValueArray = ["", "", "", ""]; // Sets placeholder values to ensure order of values
let itemsToBeSentToFormRef = []; // New array used to not mutate waysToSaveValueArray
let dateHasBeenChosen = false;
let dateValue = null;
let timeValue = null;
let isDateValid = false;
let hasErrorMsg = false;
let hasErrorTimeMsg = false;
let today = new Date();
let tomorrow = new Date();
let endDate = addDays(today, 5);
let lastSlot = new Date(new Date().setHours(15, 30, 0, 0));
const isPastLastAppt = (new Date())-Date.parse(lastSlot);
let errors = {};
let isScheduledCallActive=false;
const timeErrorID = 'timeError';
const dateErrorID = 'dateError';
tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow = new Date(tomorrow).toISOString().split('T')[0];
today = today.toISOString().split('T')[0];
endDate = endDate.toISOString().split('T')[0];
const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};
const formRef = {};
const touched = {};
let prevDegree, prevFOS, prevDegreeLevel, prevCountry, prevCourseCategory, prevScheduleCall;
const disclaimerText = rfiFormInitialValues.disclaimer;
const scheduleACallRadioOption = [
    { value: "Yes", label: "Yes! Let's set up a call." },
    { value: "No", label: "No, but a University rep can call." },
];
let scheduledContactDateLabel = "Schedule contact date* (mm/dd/yyyy)";
let scheduleCallFSData = {}

class MrfiScheduleCall extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            activeStep: 1,
            totalSteps: 5,
            isScheduledCallFlag: false,
            initialValues: rfiFormInitialValues,
            thankyouPageUrl: '/request-info/thank-you.html',
            formRef: null,
            isEligible: true,
            allChecked: false,
            checkboxList: [
                { id: 1, name: 'Scholarships', checked: false, label: 'Scholarships', ariaLabel: 'Scholarships', },
                { id: 2, name: 'Transfer_credits', checked: false, label: 'Transfer credits', ariaLabel: 'Transfer credits', },
                { id: 3, name: 'Work_life_credits', checked: false, label: 'Work & life credits', ariaLabel: 'Work & life credits', },
                { id: 4, name: 'Employer_tuition_discounts', checked: false, label: 'Employer tuition discounts', ariaLabel: 'Employer tuition discounts', }
            ],
            degreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelVariant]),
            initialDegreeLevel: processJSONParameter(props.degreeLevelOptions || [...degreeLevelVariant]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
            rfi_step: "rfi_step1",
            todaysDateWasPicked:true,
        };
        this.state = this.initialState;
        //this.focusStep = React.createRef();
        this.h2Ref = React.createRef();
    }
    dropdownChange = (name, value) => {
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
    }
    componentDidMount() {
        window.pagePath = "page-md_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - Mobile`;
        formPageType = "RFI";
        formType = "RFI";
        formName = "RFI dynamic mobile - 5 step";
        getIPAddress(this.updateIPAddress);
        let initialValues = loadCookiesData(this.state.initialValues);
        if (document.referrer.indexOf('/cost-savings/results') > 0) {
            h1Tag1Step = 'Share your information so we can talk about savings opportunities.';
            h1Tag2Step = 'Confirm which degree level you want to explore savings in.';
            h1Tag3Step = 'These details will help when we explore saving options.';
            h1Tag4step = 'Confirm the savings opportunities you want to explore with us.';
            h1Tag5Step = 'Share your contact info so we can chat soon.';
        }
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        this.setState({ initialValues });
        if (prevFOS) {
            this.resetDegreeLevel(prevFOS)
        }
        const { activeStep } = this.state;
        formStep = activeStep;
        if (this.state.RFIStep1 !== true) {
            setTimeout(function () { fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        //this.focusStep.current.focus();
        this.h2Ref.current.focus();
        dataLayer();
    }

    validateDate = (value, time) => {    
        console.log('values passed into validateDate ', value, time );    
        const { todaysDateWasPicked } = this.state;
        let parsedDateObject = '';
        if (value === null) {        
            const todaysDate = moment().format("YYYY-MM-DD");
            const dateObject = new Date(`${todaysDate}T${time}`);
            parsedDateObject = Date.parse(dateObject);
            dateHasBeenChosen=false;
        //    console.log("parsedDateObject value null ", parsedDateObject);
        } else {
            const passedDateValue = moment(value).format("YYYY-MM-DD");
            const dateObject = new Date(`${passedDateValue}T${time}`);
            parsedDateObject = Date.parse(dateObject);
            dateHasBeenChosen=true;
        //    console.log("parsedDateObject value NOT null ", parsedDateObject);
        }
        let isValidTime = false;
        const date = new Date(value); // Fri Apr 22 2022 00:00:00 GMT-0700 (Mountain Standard Time)
        const timeInThirty = new Date().setMinutes(new Date().getMinutes() +30);
        if (timeInThirty<parsedDateObject){
            isValidTime = true
        }
        const dateString = new Date(value).toLocaleDateString('en-US',{
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }); // 4/22/2022
        const timeInThirtyFormatted = moment(timeInThirty).format('h:mm A'); // 3:45 PM
        //console.log('isDateValid is ', isDateValid); // True / False
        if(dateHasBeenChosen===true){
            // Passes in selected date in this format: Sat Apr 02 2022 00:00:00 GMT-0700 (Mountain Standard Time) 
        // If no value then run this code and return Date is required
        // Date validation
             if (date === null || date === undefined || date === '') { // date is undefined, null or empty string
                console.log('Date is required')
                document.getElementById(dateErrorID).innerHTML = 'Date is required';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                document.getElementById(dateErrorID).setAttribute('aria-invalid', true);
                document.getElementById(dateErrorID).setAttribute('aria-errormessage', 'Date is required');            
                hasErrorMsg = true;
            }
            else if (isDateValid===true && todaysDateWasPicked == true && isPastLastAppt > 0){ // if tdoay was chosen but there were no more available appointment slots
                console.log('There are no more available appointments today');
                document.getElementById(dateErrorID).innerHTML = 'There are no more available appointments today';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
            }
            else if (isDateValid===true && Date.parse(dateString) < Date.parse(new Date().toLocaleDateString('en-US'))){ // if parsed date is earlier than current date
                console.log('Date must be in the future', date, new Date());
                document.getElementById(dateErrorID).innerHTML = 'Date must be in the future';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
                //return false;
            }
            else if (isDateValid===true && !this.isWeekend(date) && moment(date).format("YYYY-MM-DD")==="2023-12-25") { // if date is christmas
                document.getElementById(dateErrorID).innerHTML = 'Company holiday';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
            }
            else if (isDateValid===true && this.isWeekend(date)) { // if date is a sunday
                console.log(moment(date).format("YYYY-MM-DD"));
                if(moment(date).format("YYYY-MM-DD")==="2023-12-25"){
                    document.getElementById(dateErrorID).innerHTML = 'Company holiday';
                    document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                    document.getElementById(dateErrorID).classList.add('Mui-error');
                    document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                    hasErrorMsg = true;
                } else {
                    console.log('Please select a day other than Sunday');
                    document.getElementById(dateErrorID).innerHTML = 'Please select a day other than Sunday';
                    document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                    document.getElementById(dateErrorID).classList.add('Mui-error');
                    document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                    hasErrorMsg = true;
                }
                //return false;
            }
            else if (isDateValid===true && (Date.parse(date)>=Date.parse(endDate) && moment(date).format("LLL") !== moment(endDate).format("LLL"))) { // if parsed date is further ahead than 5 business days
                console.log("Please select a date within the next 5 business days");
                document.getElementById(dateErrorID).innerHTML = 'Please select a date within the next 5 business days';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
            }
            else if ((isDateValid === true) && (date !== null) && (Date.parse(dateString) >= Date.parse(new Date().toLocaleDateString('en-US'))) && (this.isWeekend(date) === false)) { // if everything is good
                console.log("In else condition, everything all good");
            //    console.log(timeValue, timeInThirtyFormatted);
                if(document.getElementById(dateErrorID).innerHTML.length>0){
                    document.getElementById(dateErrorID).innerHTML = '';
                }
                if(document.getElementById(dateErrorID).classList.contains('MuiFormHelperText-root') && document.getElementById(dateErrorID).classList.contains('Mui-error')) {
                    document.getElementById(dateErrorID).classList.remove('MuiFormHelperText-root');
                    document.getElementById(dateErrorID).classList.remove('Mui-error');
                }
                if(document.getElementById('datePicker').classList.contains('uopx-wrapper--error')){
                    document.getElementById('datePicker').classList.remove('uopx-wrapper--error');
                }
                hasErrorMsg = false;
                formRef.current.values.date=date;            
            }
            else { // catch all
                console.log("Date is invalid");
                document.getElementById(dateErrorID).innerHTML = 'Date is invalid';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');
                hasErrorMsg = true;
                //return false;
            }
        }
        // Time validation
        if(isDateValid === true && dateString === moment().format('L') && isValidTime === false) {
        //    console.log(isDateValid, dateString===moment().format('L'), isValidTime);
            document.getElementById(timeErrorID).innerHTML = `Time must be scheduled at least 30 minutes past current time`;
            document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
            document.getElementById(timeErrorID).classList.add('Mui-error');
            document.getElementById('timePicker').classList.add('uopx-wrapper--error');
            hasErrorTimeMsg = true;
        } else {
            if(document.getElementById(timeErrorID).innerHTML.length>0){
                document.getElementById(timeErrorID).innerHTML = '';
            }
            if(document.getElementById(timeErrorID).classList.contains('MuiFormHelperText-root') && document.getElementById(timeErrorID).classList.contains('Mui-error')) {
                document.getElementById(timeErrorID).classList.remove('MuiFormHelperText-root');
                document.getElementById(timeErrorID).classList.remove('Mui-error');
            }
            if(document.getElementById('timePicker').classList.contains('uopx-wrapper--error')){
                document.getElementById('timePicker').classList.remove('uopx-wrapper--error');
            }
            hasErrorTimeMsg = false;        
        }
    }

    isWeekend(date) {
        let newDate = moment(`${date}`).format('YYYY-MM-DD');
        let today = moment().format("YYYY-MM-DD");
        let christmas = moment("12-25-2023").format("YYYY-MM-DD");
        if(today === (moment().dayOfYear(359).format("YYYY-MM-DD"))){
            return date.getDay() === 0 || date.getDay() === 6
        } else if (newDate === christmas) {
          return true
        } else {
          return date.getDay() === 0
        }
      }

    isValidDate(date, todayWasPicked) {
        console.log(date);
        console.log(todayWasPicked);
        let timestamp = Date.parse(date);
        this.setState({todaysDateWasPicked:todayWasPicked}, console.log(this.state.todaysDateWasPicked))
        dateValue = date;
        if (isNaN(timestamp) == false && timestamp > 0) {
            let d = new Date(timestamp);
            isDateValid = true;
        } else {
            isDateValid = false;
        }
        this.validateDate(dateValue, timeValue);
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleBack = () => {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1,
            initialValues: formRef.current.values
        });
        fireAnalyticsEvent('RFIStepBack', formName, formType);
        scrollToTop();
        //this.focusStep.current.focus();
        this.h2Ref.current.focus();
    };

    handleAnalyticsRFIFormStart = () => {
        if (this.state.rfi_begin !== true) {
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        const { initialValues } = this.state;
        if (name === 'fieldOfStudy') {
            formRef.current.values.fieldOfStudy = value;
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                this.resetDegreeLevel(value);
            }
        }
        if (name === 'degreeLevel') {
            formRef.current.values.degreeLevel = value;
            if (value && prevDegree !== value) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevDegree = value;
                initialValues.degreeLevel = value;
            }
        }
        if (name === 'isScheduledCall') {
            formRef.current.values.isScheduledCall = value;
            if (value === "Yes") {
                this.setState({ isScheduledCall: true });
                formRef.current.values.flex_rfiCallback = true;
                isScheduledCallActive=true;
            }
            if (value === "No") {
                this.setState({ isScheduledCall: false });
                formRef.current.values.flex_rfiCallback = false;
                isScheduledCallActive=false;
            }
            if (this.state.not_abandoned !== true) {
                this.setState({ not_abandoned: true });
            }
            if (this.state.scheduledCallRadio !== true) {
                fireCampaignRadioEvent('rfi_schedule_call', value, formName, formType);
                this.setState({ scheduledCallRadio: true });
            }
        }
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        else if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
            this.setState({ degreeLevel: getDegreeLevel(this.state.initialDegreeLevel, this.state.initialValues.fieldOfStudy, value) });
        }
        else if (name === 'isCitizenOfUnitedStates') {
            formRef.current.values.isCitizenOfUnitedStates = value;
        }
        else if (name === 'isScholarship') {
            formRef.current.values.isScholarship = value;
        }
        this.setState({ initialValues });        
    };
    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel, fosValue, formRef?.current?.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        if ((formRef.current.values.degreeLevel !== '')) {
            var optionExists = newDegreeLevelOptions.some((e) => this.state.initialValues.degreeLevel === e.value);
            //if prev degree level doesnt exist, reset
            if (!optionExists) formRef.current.values.degreeLevel = '';
        }
        this.setState({ degreeLevel: newDegreeLevelOptions }); // EX: Bachelors, Individual Courses
    }
    handleZipcodeChange = (zipcode, state) => {
        formRef.current.values.postalCode = zipcode;
        formRef.current.values.stateProvince = state;
        updatePrePopCookieValue('zipcode', zipcode);
        updatePrePopCookieValue('state', state);
    }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    handleCallback = (value, isChecked) => {// this function receives information from child checkbox component and updates the necessary formref values  
        value = typeof value.name !== "undefined" ? value.name : value;
        switch (value) {
            case 'scholarships':
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(0, 1, value); // Replaces placeholder empty string if checkbox is checked
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(0, 1, ""); // Replaces value with empty string if checkbox is unchecked
                };
                break;
            case 'transfer_credits':
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(1, 1, value);
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(1, 1, "");
                };
                break;
            case 'work_life_credits':
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(2, 1, value);
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(2, 1, "");
                };
                break;
            case 'employer_tuition_discounts':
                if (isChecked === true) {
                    waysToSaveValueArray.includes(value) ? console.log("Value already in the array") : waysToSaveValueArray.splice(3, 1, value);
                } else if (isChecked === false && waysToSaveValueArray.includes(value)) {
                    waysToSaveValueArray.splice(3, 1, "");
                };
                break;
            case 'checkAll':
                if (isChecked) { // replaces all empty string values with respective value
                    waysToSaveValueArray.includes("scholarships") ? console.log("Value already in the array") : waysToSaveValueArray.splice(0, 1, "scholarships");
                    waysToSaveValueArray.includes("transfer_credits") ? console.log("Value already in the array") : waysToSaveValueArray.splice(1, 1, "transfer_credits");
                    waysToSaveValueArray.includes("work_life_credits") ? console.log("Value already in the array") : waysToSaveValueArray.splice(2, 1, "work_life_credits");
                    waysToSaveValueArray.includes("employer_tuition_discounts") ? console.log("Value already in the array") : waysToSaveValueArray.splice(3, 1, "employer_tuition_discounts");
                }
                else if (isChecked === false) {
                    waysToSaveValueArray = ["", "", "", ""]; // replaces all values with empty strings
                }
                break;
        }
        const filtered = waysToSaveValueArray.filter(function (values, index, arr) {
            return values !== ""; // Gets rid of any placeholder empty string values
        })
        itemsToBeSentToFormRef = filtered;
        formRef.current.values.waysToSave = itemsToBeSentToFormRef.join(", "); // Sends filtered values to formref to be sent down stream
        //fireRFISavingsOptionEvent(formRef.current.values.waysToSave, formName, formType);
    }

    isCBEConsent = (event, checked) => {
        formRef.current.values.isCBE = checked;        
    }
    handlescheduledCall = (event, checked) => {
        formRef.current.values.isScheduledCall = checked; 
        this.setState({ isScheduledCallFlag: checked }); 
        formRef.current.values.flex_rfiCallback = checked;
        isScheduledCallActive=checked; 
        if (this.state.not_abandoned !== true) {
            this.setState({ not_abandoned: true });
        }
    }
    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }

    onDatePicked = (date, formProps) => {        
        let selectedDate = new Date(date);        
        let timeMin = new Date(selectedDate).getMinutes();
        let currentHour = new Date().getHours();
        let currentMin = new Date().getMinutes();
        if (selectedDate !== null) {
            if (isToday(new Date(selectedDate))) {
                if (currentHour >= 17 || (currentHour >= 16 && currentMin > 30)) {
                    selectedDate = addDays(new Date(), 1);
                    selectedDate = new Date(selectedDate).setHours(9, 0);
                } else if (currentHour <= 8 && currentMin < 30) {                    
                    selectedDate = new Date(selectedDate).setHours(9, 0);
                } else {
                    if (isBefore(new Date(selectedDate), new Date())) { selectedDate = new Date(new Date().setMinutes(timeMin + 30)); }
                }
            }
            formProps.setFieldValue("date", new Date(selectedDate).toLocaleDateString("en-US"));
            formProps.validateForm();
        }
    };
    onTimePicked = (time, formProps) => {
        formProps.setFieldValue("time", time);
        timeValue = time;
        formRef.current.values.time = timeValue;
        validateDate(dateValue, timeValue);
    };

    handleSubmit = (values) => {    
        const { activeStep, totalSteps } = this.state;
        if (formRef.current.touched['zipcode'] || formRef.current.touched['postalCode'] || touched['zipcode']) {
            formRef.current.touched['zipcode'] = undefined;
            formRef.current.touched['postalCode'] = undefined;
            touched['zipcode'] = undefined;
        }
        this.setState({ initialValues: values });
        if (activeStep === totalSteps && document.activeElement.name !== "next") {
            values.flex_Info_Requested__c = values.waysToSave;
            if (dateValue) {
                values.date = dateValue;
            }
            if (values.time) {
                let date = new Date(values.date).toLocaleDateString("en-US");
                let datetime = new Date(`${date} ${values.time}`).toLocaleString('en-US', { hour12: true });
                values.date = datetime;
                values.date = moment(values.date).format('YYYY-MM-DDTHH:mm:ss');
            }
            if (values.fieldOfStudy !== 'nondegree' && (values.degreeLevel !== 'doctoral' && values.degreeLevel !== 'nondegree')) {
                if (values.date && (values.isScheduledCall === 'Yes'|| values.isScheduledCall === true)) {
                    values.isScheduledCall = 'Yes';
                    let isoDate = new Date(values.date).toISOString().split('.')[0] + "Z";                
                    values.flex_rfiCallbackDateTime = isoDate;                
                    values.flex_RFI_Callback__c = 'rfiCallback-true ' + isoDate;
                    values.flex_rfiCallbackTimezone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4);                
                    fireCampaignEvent('rfi_schedule_call', values.isScheduledCall, formName, formType);
                } else if (values.isScheduledCall === 'No' || values.isScheduledCall === false) {
                    values.isScheduledCall = 'No';
                    fireCampaignEvent('rfi_schedule_call', values.isScheduledCall, formName, formType);
                    values.flex_RFI_Callback__c = 'rfiCallback-false';
                } else if (values.fieldOfStudy !== 'nondegree' && (values.degreeLevel !== 'doctoral' && values.degreeLevel !== 'nondegree')) {
                    values.flex_RFI_Callback__c = 'rfiCallback-blank';
                    fireCampaignEvent('rfi_schedule_call', 'NA', formName, formType);
                }
            }
            this.setState({ initialValues: values });  
            submitForm(values, this.props);
            if (window.FS) {
                if (values.fieldOfStudy !== 'nondegree' && (values.degreeLevel !== 'doctoral' && values.degreeLevel !== 'nondegree')) {
                    scheduleCallFSData.fos= values.fieldOfStudy;
                    scheduleCallFSData.degreeLevel= values.degreeLevel;
                    scheduleCallFSData.rfiCallback=values.flex_RFI_Callback__c;
                    scheduleCallFSData.rfiCallbackTimezone=values.flex_rfiCallbackTimezone;
                    //calling FS and passing the tracking data set
                    window.FS.event('Submitted M_RFI_ScheduleCall', scheduleCallFSData);
                }
            }
        }
        else if (document.activeElement.name === "next") {
            if(activeStep === 4){
                fireRFISavingsOptionEvent(formRef.current.values.waysToSave, formName, formType);
            }
            fireAnalyticsEvent('RFIStep' + (activeStep + 1), formName, formType);
            if (activeStep <= totalSteps - 1)
                this.setState({ activeStep: activeStep + 1 });
            formStep = activeStep + 1;
            scrollToTop();
            this.h2Ref.current.focus();
        }
    }

    validateDynamic5StepVariant(values, step, isScheduledCall, degreeFlag) {
        const errors = {};
        switch (step) {
          case 0:
            if (!values.modality) {
              errors.modality = 'How do you prefer to learn requires a value';
            }
            break;
          case 1:      
            if (!values.fieldOfStudy) {
              errors.fieldOfStudy = 'Area of study requires a value';
            }
            if ((values.fieldOfStudy === 'nursing' || values.fieldOfStudy === 'healthcare') && !values.isRegisteredNurse) {
              errors.isRegisteredNurse = 'Registered nurse requires a value';
            }
            break;
          case 2:
            if (!values.degreeLevel && degreeFlag === true) {
                errors.degreeLevel = 'Degree level requires a value';
            }
            else if (!values.flex_Course_Category__c && degreeFlag === false) {
                errors.flex_Course_Category__c = "Course category selection required";
            }
            break;
          case 3:
            if (!values.postalCode) {
              errors.zipcode = 'Enter a ZIP code';
            }
            if (values.postalCode && values.postalCode.length !== 5) {
              errors.zipcode = 'ZIP codes must include 5 numeric characters';
            }
            if (values.postalCode && !/^[\\0-9]*$/i.test(values.postalCode)) {
              errors.zipcode = 'ZIP code must be numeric';
            } else if (values.postalCode && values.stateProvince === 'N/A') {
              errors.zipcode = 'ZIP code appears invalid';
            }
            if (!values.emailAddress) {
              errors.emailAddress = 'Email address requires a value';
            }
            if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
              errors.emailAddress = 'Email address appears invalid';
            }
            if (values.isMilitary === '' || values.isMilitary === undefined) {
              errors.isMilitary = 'U.S. Military affiliation requires a value';
            }    
            break;
          case 4:
            break;
          case 5:
            if (!values.firstName) {
              errors.firstName = 'First name requires a value';
            }
            if (values.firstName && !values.firstName.match(/^[A-Za-z '-]*$/)) {
              errors.firstName = 'First name appears invalid';
            }
            if (values.firstName && values.firstName.match(/^[A-Za-z '-]*$/) && values.firstName.length > 20) {
              errors.firstName = 'First name cannot exceed 20 characters';
            }
            if (values.firstName) {
              const check = profanityCheck(values.firstName);
              if (check === false) {
                errors.firstName = 'First name appears invalid';
              }
            }
      
            if (!values.lastName) {
              errors.lastName = 'Last name requires a value';
            }
            if (values.lastName && !values.lastName.match(/^[A-Za-z '-]*$/)) {
              errors.lastName = 'Last name appears invalid';
            }
            if (values.lastName && values.lastName.match(/^[A-Za-z '-]*$/) && values.lastName.length > 80) {
              errors.lastName = 'Last name cannot exceed 80 characters';
            }
            if (values.lastName) {
              const check = profanityCheck(values.lastName);
              if (check === false) {
                errors.lastName = 'Last name appears invalid';
              }
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = 'Phone number requires a value';
            }
            if (values.phoneNumber && values.phoneNumber.length !== 10) {
              errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
            }
            if(isScheduledCall===true){
              if(!values.date || values.date === null){
                errors.date = 'Scheduled contact date requires a value';   
                document.getElementById(dateErrorID).innerHTML = 'Scheduled contact date requires a value';
                document.getElementById(dateErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(dateErrorID).classList.add('Mui-error');
                document.getElementById('datePicker').classList.add('uopx-wrapper--error');    
              } else if (values.date && hasErrorMsg) {
                let errorMsg= document.getElementById(dateErrorID).innerHTML.toString();
                errors.date = errorMsg;
              }
              if (!values.time || values.time === null){         
                errors.time = 'Scheduled contact time requires a value';
                document.getElementById(timeErrorID).innerHTML = `Scheduled contact time requires a value`;
                document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(timeErrorID).classList.add('Mui-error');
                document.getElementById('timePicker').classList.add('uopx-wrapper--error');
              } else if (values.time && hasErrorTimeMsg===true) {
                errors.time = 'Time must be scheduled at least 30 minutes past current time'
                document.getElementById(timeErrorID).innerHTML = `Time must be scheduled at least 30 minutes past current time`;
                document.getElementById(timeErrorID).classList.add('MuiFormHelperText-root');
                document.getElementById(timeErrorID).classList.add('Mui-error');
                document.getElementById('timePicker').classList.add('uopx-wrapper--error');
              }
            }
          default:
            break;
        }
        return errors;
    }
    render() {
        const { classes } = this.props;
        const {
            activeStep,
            initialValues,
            totalSteps,
        } = this.state;

        return (<>
            <UopHeader />
            <main className="rfi">
                <Grid container justifyContent='center' className={"mobileFormContainer international-student-rfi step" + activeStep}>
                    <Grid item md={8} sm={8} lg={8} xs={12}>
                        <Typography
                            variant='body2'
                            component='h2'
                            className='mainHeader'
                            style={{ background: 'linear-gradient(90deg, #e3f5fa 3%, #92c8d6 100% )', margin: '-24px -24px 24px', padding: '24px', fontSize: '21px' }}
                            ref={this.h2Ref}
                            tabIndex={0}
                        >
                            {activeStep === 1 ? h1Tag1Step :
                                activeStep === 2 ? h1Tag2Step :
                                    activeStep === 3 ? h1Tag3Step :
                                        activeStep === 4 ? h1Tag4step :
                                            h1Tag5Step}
                        </Typography>
                        <Grid item md={12} sm={12} lg={7} xs={12} className="stepCounter">
                            <Typography
                                variant='body2'
                                component='div'
                                className="stepCounter"
                                tabIndex={-1}
                                /* ref={this.focusStep} */
                                step={activeStep}
                            >
                                <span aria-label={`Step ${activeStep} of ${totalSteps}`}>Step <span className="activeStepCount">{activeStep}</span> of <span className="totalStepsCount">{totalSteps}</span></span>
                            </Typography>
                        </Grid>
                        {activeStep !== 4 && 
                            <Typography
                                variant='body2'
                                component='div'
                                className="stepSubHeader req-info"
                                style={{ fontSize: "1.6rem", color: "#001823" }}
                            >
                                * Required information
                            </Typography>
                        }
                        

                        <React.Fragment>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                {activeStep === 1 &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => this.validateDynamic5StepVariant(values, this.state.activeStep)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            if (!(props.values.fieldOfStudy === 'nursing' || props.values.fieldOfStudy === 'healthcare')) {
                                                props.values.isRegisteredNurse = '';
                                            }
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information"
                                                    onChange={this.handleAnalyticsRFIFormStart}
                                                    autoComplete="on"
                                                >
                                                    <div className={"step" + activeStep}>
                                                        <MaterialRadioWithBox value={formRef?.current?.values.fieldOfStudy} className="selectRadioBoxLabel-bold" onChange={this.handleChange} items={dynamicFieldOfStudyDropdownVariant} label="Which area of study are you interested in? *" name='fieldOfStudy' ariaLabel="Which area of study are you interested in?"></MaterialRadioWithBox>
                                                        {conditionalRadio(props.values.fieldOfStudy) && <>
                                                            <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} className="selectRadioLabel-bold" row={true} onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                        </>}
                                                    </div>
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next1"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 2 && formRef?.current?.values.fieldOfStudy === 'nondegree' &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => this.validateDynamic5StepVariant(values, this.state.activeStep, false, false)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information"
                                                >
                                                   <SelectField
                                                    id="flex_Course_Category__c"
                                                    name="flex_Course_Category__c"
                                                    label="Course category *"
                                                    data={courseCategoryDropdown}
                                                    className="MuiFormControl-fullWidth"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                    ></SelectField>                                                
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 2 && formRef?.current?.values.fieldOfStudy !== 'nondegree' &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => this.validateDynamic5StepVariant(values, this.state.activeStep, false, true)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information"
                                                >
                                                    <MaterialRadioWithBox value={formRef?.current?.values.degreeLevel} className="selectRadioBoxLabel-bold" onChange={this.handleChange} items={this.state.degreeLevel} label="Which degree level are you interested in? *" name='degreeLevel' ariaLabel="Which degree level are you interested in?"></MaterialRadioWithBox>
                                                    {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                        <div className='cbe'>
                                                            <SingleCheckbox
                                                                className="cbe-checkbox"
                                                                id="isCBE"
                                                                label={unescapeHTML(this.getCBEText())}
                                                                ariaLabel={unescapeHTML(this.getCBEText())}
                                                                checkboxName="isCBE"
                                                                parentCallback={this.isCBEConsent}
                                                                defaultValue={formRef.current.values.isCBE}  
                                                            />
                                                        </div>
                                                    </>}
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 3 &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => this.validateDynamic5StepVariant(values, this.state.activeStep)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form" style={{ position: 'relative' }}
                                                    aria-label="Request For Information">
                                                    <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" ariaLabel="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef} required></ZipCode>
                                                    <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />                                                    
                                                    <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className='customLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 4 && (formRef?.current?.values.degreeLevel === 'nondegree' || formRef?.current?.values.fieldOfStudy === 'nondegree') &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => this.validateDynamic5StepVariant(values, this.state.activeStep)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information">
                                                    <SingleCheckbox
                                                        id="isEmployer"
                                                        className='ways2save-checkbox'
                                                        label="Employer tuition discounts"
                                                        ariaLabel="Employer tuition discounts"
                                                        checkboxName="employer_tuition_discounts"
                                                        parentCallback={this.handleCallback}
                                                        legend="I want to explore this time and money-saving option:"
                                                        defaultValue={waysToSaveValueArray.includes("employer_tuition_discounts")} 
                                                    />
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 4 && formRef?.current?.values.degreeLevel !== 'nondegree' && formRef?.current?.values.fieldOfStudy !== 'nondegree' &&
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form"
                                                    aria-label="Request For Information">
                                                    <SingleCheckbox
                                                        className='ways2save-checkbox'
                                                        label="All of the above + more ways to save"
                                                        ariaLabel="All of the above + more ways to save"
                                                        checkboxName="checkAll"
                                                        parentCallback={this.handleCallback}
                                                        legend="I want to explore these time and money-saving options:"
                                                        options={groupedCheckboxOption}
                                                        defaultValueGroup={waysToSaveValueArray}
                                                        defaultValue={waysToSaveValueArray.filter((value) => value !== "").length == 4}
                                                    />
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='next'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-next2"
                                                            variant='outlined'
                                                        >
                                                            Next step
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                                {activeStep === 5 && <>
                                    <Formik
                                        initialValues={initialValues}
                                        onSubmit={this.handleSubmit}
                                        validate={(values) => this.validateDynamic5StepVariant(values, this.state.activeStep, isScheduledCallActive)}
                                        innerRef={form => formRef.current = form}
                                        validateOnChange
                                    >
                                        {(props) => {
                                            return (
                                                <Form className="mobile-form" style={{ position: 'relative' }}
                                                    aria-label="Request For Information">
                                                    <MaterialInput name="firstName" id="firstName"
                                                        label="First name *" ariaRequired="true" ariaLabel="Enter First name" />
                                                    <MaterialInput name="lastName" id="lastName"
                                                        label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                    <MaterialPhoneNumber id="phoneNumber" name="phoneNumber" label="Phone number *" ariaRequired="true" ariaLabel="Enter Phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                    {props.values.fieldOfStudy !== 'nondegree' && (props.values.degreeLevel !== 'nondegree' && props.values.degreeLevel !== 'doctoral') && props.values.source !== 'EMLP' && <>                                                        
                                                        <Grid item md={12} sm={12} lg={12} xs={12} className="margin-OneStepForm call-break"></Grid>
                                                        <Grid item md={12} sm={12} lg={12} xs={12} className="margin-OneStepForm schedule-call" style={{backgroundColor:"#E8F9FA", borderRadius:"4px", paddingTop:"24px", paddingRight:"14px", paddingLeft:"14px"}}>
                                                        {/* <MaterialRadio value={formRef?.current?.values.isScheduledCall} row={true} className="selectRadioLabel-semiBold" onChange={this.handleChange} items={scheduleACallRadioOption} label="Would you like to schedule time this week to talk, (Mon - Sat)?" name='isScheduledCall' ariaLabel="Would you like to schedule time this week to talk, (Mon - Sat)?"></MaterialRadio> 
                                                            <FormikCheckbox
                                                                type="checkbox"
                                                                id="isScheduledCall"
                                                                name="isScheduledCall"
                                                                className="disclaimer-checkbox"
                                                                onChangeCallback={this.handlescheduledCall}
                                                                label="Yes, Schedule a call"
                                                            />
                                                        */}
                                                            <SingleCheckbox
                                                                id="isScheduledCall"
                                                                label="Make big decisions on your terms - and your time. Schedule a call with our rep to get your questions answered."
                                                                ariaLabel="Make big decisions on your terms - and your time. Schedule a call with our rep to get your questions answered."
                                                                checkboxName="isScheduledCall"
                                                                parentCallback={this.handlescheduledCall}
                                                                defaultChecked={formRef.current.values.isScheduledCall}
                                                                defaultValue={isScheduledCallActive}
                                                            />
                                                            
                                                        </Grid>
                                                    </>}
                                                    {this.isAScheduledCall(props.values) &&  <>
                                                 <Grid style={{backgroundColor:"#e8f9fa", paddingLeft:"14px", paddingRight:"14px"}} >
                                                    <fieldset id="dateTimeFieldSet">
                                                        <legend>
                                                        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <Typography
                                                            variant='body2'
                                                            component='div'
                                                            className= 'selectRadioLabel-semiBold'
                                                            id="scheduleCallText"                                                            
                                                        >
                                                            Great! When should we contact you?
                                                        </Typography>
                                                        {/* <Typography
                                                            variant='body2'
                                                            component='div'
                                                            className="stepSubHeader req-info"
                                                            style={{ fontSize: "1.6rem", color: "#001823" }}
                                                        >
                                                            * Required information
                                                            </Typography> */}
                                                        </Grid>
                                                        </legend>                                        
                                                    <Grid item id='dateGrid' md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm"> 
                                                        <div id='datePicker' className="uopx-wrapper uopx-wrapper--default">
                                                        <label htmlFor="date">{scheduledContactDateLabel}</label>
                                                            <UOPDatePicker
                                                                identifier="date" 
                                                                min={isPastLastAppt <= 0 ? today : tomorrow}
                                                                max={endDate} 
                                                                passedDate = {(e,f) => this.isValidDate(e,f, props)}                                                              
                                                            />
                                                            <p id='dateError' role="alert" style={{margin:'0'}}></p>
                                                        </div>
                                                    </Grid>                                          
                                                    <Grid id='timeGrid' item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                        <div id='timePicker' className="uopx-wrapper uopx-wrapper--default">
                                                        <label htmlFor="UOPtime">Schedule contact time *</label>
                                                            <TimePicker
                                                                id="UOPTime"
                                                                name="UOPTime"
                                                                aria-label="Schedule Contact Time"
                                                                data={apptsAvailable}
                                                                isTodayDatePicked = {this.state.todaysDateWasPicked} // true or false 
                                                                passedTime={e => this.onTimePicked(e,props)}                                                                                                                                                                                              
                                                            ></TimePicker>
                                                            <p id='timeError' role="alert"></p>
                                                        </div>                                                        
                                                    </Grid>
                                                    </fieldset>
                                                    
                                                 </Grid>
                                                </>}
                                                    <div className="disclaimer" style={{marginTop:"24px"}}>
                                                        {unescapeHTML(disclaimerText)}
                                                    </div>
                                                    <div className="mobile-submit-btn">
                                                        <UOPXButton
                                                            name='submit'
                                                            size='large'
                                                            type='submit'
                                                            className="btn-submit"
                                                        >
                                                            Request information
                                                        </UOPXButton>
                                                    </div>
                                                    <div className="backButton">
                                                        <Button color="primary" className="redesign-link" onClick={this.handleBack} style={{ color: "#001823", fontSize: "16px", fontWeight: "normal", paddingTop: '18px' }}><span><span className="tertiary-left-arrow" ></span> <span className="tertiary-word">Back </span></span></Button>
                                                    </div>
                                                    <ErrorFocus></ErrorFocus>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </>
                                }
                            </Grid>
                        </React.Fragment>
                    </Grid>
                </Grid>                
            </main>
            <div className="mobile-footer2">
                <p>See our <a href="http://www.phoenix.edu/copyright-legal/privacy_policy.html"
                    rel="noopener noreferrer"
                    target="_blank">Privacy Policy.</a></p>
                <p>© {new Date().getFullYear()} University of Phoenix, Inc. All rights reserved.</p>
            </div>
            <MobileFooter theme="hidden" />
        </>);
    }
    isAScheduledCall(values) {
        const {degreeLevel, fieldOfStudy, isScheduledCallFlag} = values;
        if(fieldOfStudy !== 'nondegree' && (degreeLevel !== 'nondegree' && degreeLevel !== 'doctoral') && this.state.isScheduledCallFlag === true){
            isScheduledCallActive=true;
            return true;
        }
    }
}

MrfiScheduleCall.defaultProps = {
    classes: {},
};

MrfiScheduleCall.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(MrfiScheduleCall);