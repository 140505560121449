export const communityCollegeList = [
  { label: "Aims Community College", value: "Aims Community College" },
  { label: "Alamo Community College District", value: "Alamo Community College District" },
  { label: "Alvin Community College", value: "Alvin Community Colleget" },
  { label: "American Trades Institute", value: "American Trades Institute" },
  { label: "Andon Colleges, Inc.", value: "Andon Colleges, Inc." },
  { label: "Appalachian Technical College", value: "Appalachian Technical College" },
  { label: "Arizona Western College", value: "Arizona Western College" },
  { label: "Austin Community College", value: "Austin Community College" },
  { label: "Baltimore City Community College", value: "Baltimore City Community College" },
  { label: "Briarwood College, Inc.", value: "Briarwood College, Inc." },
  { label: "Capital City Trade & Technical School, Inc", value: "Capital City Trade & Technical School, Inc" },
  { label: "Carroll Community College.", value: "Carroll Community College." },
  { label: "Central Arizona College", value: "Central Arizona College." },
  { label: "California Community Colleges System", value: "California Community Colleges System" },
  { label: "Chabot-Las Positas Community College District", value: "Chabot-Las Positas Community College District" },
  { label: "Coast Community College District", value: "Coast Community College District" },
  { label: "Colorado Community College System", value: "Colorado Community College System" },
  { label: "Contra Costa Community College District", value: "Contra Costa Community College District" },
  { label: "Central Ohio Technical College", value: "Central Ohio Technical College" },
  { label: "Central Texas College", value: "Central Texas College" },
  { label: "CHI Institute", value: "CHI Institute" },
  { label: "College of Southern Nevada", value: "College of Southern Nevada" },
  { label: "College Of Western Idaho", value: "College Of Western Idaho" },
  { label: "Collin County Community College District", value: "Collin County Community College District" },
  { label: "Collins College", value: "Collins College" },
  { label: "Colorado Mountain College System", value: "Colorado Mountain College System" },
  { label: "Columbus State Community College", value: "Columbus State Community College" },
  { label: "Community College of the Air Force", value: "Community College of the Air Force" },
  { label: "Compton College", value: "Compton College" },
  { label: "Court Reporting Institute of Houston", value: "Court Reporting Institute of Houston" },
  { label: "Cuyahoga Community College", value: "Cuyahoga Community College" },
  { label: "Del Mar College", value: "Del Mar College" },
  { label: "Dorsey College", value: "Dorsey College" },
  { label: "Duff's Business Institute", value: "Duff's Business Institute" },
  { label: "Dallas College", value: "Dallas College" },
  { label: "Division of Florida Colleges", value: "Division of Florida Colleges" },
  { label: "Eastern Iowa Community College", value: "Eastern Iowa Community College" },
  { label: "East Valley Institute of Technology", value: "East Valley Institute of Technology" },
  { label: "El Paso Community College", value: "El Paso Community College" },
  { label: "Electronics Institute", value: "Electronics Institute" },
  { label: "Everest College", value: "Everest College" },
  { label: "Florida Medical Training Institute, Inc.", value: "Florida Medical Training Institute, Inc." },
  { label: "Foothill-De Anza Community College District", value: "Foothill-De Anza Community College District" },
  { label: "Georgia Military College", value: "Georgia Military College" },
  { label: "Houston Community College System (HCC)", value: "Houston Community College System (HCC)" },
  { label: "Harper College", value: "Harper College" },
  { label: "Hawaii Business College, Inc.", value: "Hawaii Business College, Inc." },
  { label: "Heald College", value: "Heald College" },
  { label: "Henry Ford Community College", value: "Henry Ford Community College" },
  { label: "Heritage College", value: "Heritage College" },
  { label: "High Tech Institute Inc", value: "High Tech Institute Inc" },
  { label: "Highland Community College", value: "Highland Community College" },
  { label: "Ivy Tech Community College of Indiana", value: "Ivy Tech Community College of Indiana" },
  { label: "International Institute of The Americas", value: "International Institute of The Americas" },
  { label: "Iowa Lakes Community College", value: "Iowa Lakes Community College" },
  { label: "ITT Technical Institute", value: "ITT Technical Institute" },
  { label: "Joliet Junior College", value: "Joliet Junior College" },
  { label: "Kansas City Kansas Community College", value: "Kansas City Kansas Community College" },
  { label: "Kentucky Community and Technical College System", value: "Kentucky Community and Technical College System" },
  { label: "Kern Community College District", value: "Kern Community College District" },
  { label: "Lone Star College System", value: "Lone Star College System" },
  { label: "Lamson College", value: "Lamson College" },
  { label: "Lawshelf Educational Media", value: "Lawshelf Educational Media" },
  { label: "Lewis and Clark Community College", value: "Lewis and Clark Community College" },
  { label: "Long Technical College", value: "Long Technical College" },
  { label: "Los Angeles Community College District", value: "Los Angeles Community College District" },
  { label: "Los Rios Community College District", value: "Los Rios Community College District" },
  { label: "Louisiana Community and Technical College System", value: "Louisiana Community and Technical College System" },
  { label: "Maricopa Community Colleges", value: "Maricopa Community Colleges" },
  { label: "Macomb Community College", value: "Macomb Community College" },
  { label: "Madison Area Technical College", value: "Madison Area Technical College" },
  { label: "Maric College", value: "Maric College" },
  { label: "Med Tech College, LLC", value: "Med Tech College, LLC" },
  { label: "Milwaukee Area Technical College", value: "Milwaukee Area Technical College" },
  { label: "Mohave Community College", value: "Mohave Community College" },
  { label: "Morton College", value: "Morton College" },
  { label: "Mountain West College", value: "Mountain West College" },
  { label: "Nashville Auto-Diesel College", value: "Nashville Auto-Diesel College" },
  { label: "Nassau Community College", value: "Nassau Community College" },
  { label: "Northface University", value: "Northface University" },
  { label: "Northwest Health Careers, L.L.C.", value: "Northwest Health Careers, L.L.C." },
  { label: "New Jersey Council of County Colleges", value: "New Jersey Council of County Colleges" },
  { label: "North Carolina Community College System", value: "North Carolina Community College System" },
  { label: "North Orange County Community College District", value: "North Orange County Community College District" },
  { label: "Oakland Community College", value: "Oakland Community College" },
  { label: "Ohio Business College", value: "Ohio Business College" },
  { label: "Oklahoma City Community College", value: "Oklahoma City Community College" },
  { label: "Patricia Steven College", value: "Patricia Steven College" },
  { label: "Professional Training Centers, Inc", value: "Professional Training Centers, Inc" },
  { label: "Pennsylvania Commission for Community Colleges", value: "Pennsylvania Commission for Community Colleges" },
  { label: "Rancho Santiago Community College District", value: "Rancho Santiago Community College District" },
  { label: "RainStar University", value: "RainStar University" },
  { label: "Remington Colleges, Inc", value: "Remington Colleges, Inc" },
  { label: "Riverside Community College District", value: "Riverside Community College District" },
  { label: "San Diego Community College District", value: "San Diego Community College District" },
  { label: "San Joaquin Valley College, Inc.", value: "San Joaquin Valley College, Inc." },
  { label: "South Carolina Technical College System", value: "South Carolina Technical College System" },
  { label: "South Orange County Community College District", value: "South Orange County Community College District" },
  { label: "State Center Community College District", value: "State Center Community College District" },
  { label: "Savannah River College", value: "Savannah River College" },
  { label: "Shawnee Community College District", value: "Shawnee Community College District" },
  { label: "Southeastern Career Institute", value: "Southeastern Career Institute" },
  { label: "Southern Career College", value: "Southern Career College" },
  { label: "Southern Careers Institute, Inc.", value: "Southern Careers Institute, Inc." },
  { label: "Springfield College", value: "Springfield College" },
  { label: "State Fair Community College", value: "State Fair Community College" },
  { label: "Student Nurses' Association of South Carolina, Inc.", value: "Student Nurses' Association of South Carolina, Inc." },
  { label: "Tarrant County College District", value: "Tarrant County College District" },
  { label: "Technical College System of Georgia", value: "Technical College System of Georgia" },
  { label: "Temple College", value: "Temple College" },
  { label: "Texas Health School, L.P.", value: "Texas Health School, L.P." },
  { label: "The Pja School Inc", value: "The Pja School Inc" },
  { label: "The Workforce Guild", value: "The Workforce Guild" },
  { label: "Tennessee Board Of Regents", value: "Tennessee Board Of Regents" },
  { label: "Truckee Meadows Community College", value: "Truckee Meadows Community College" },
  { label: "University of Hawaii System (2-year campuses)", value: "University of Hawaii System (2-year campuses)" },
  { label: "Ventura County Community College District", value: "Ventura County Community College District" },
  { label: "Virginia Community College System", value: "Virginia Community College System" },
  { label: "Wyoming Community College Commission", value: "Wyoming Community College Commission" },
  { label: "Wayne County Community College District", value: "Wayne County Community College District" },
  { label: "Western Business College", value: "Western Business College" },
  { label: "West Hills Community College District", value: "West Hills Community College District" },
  { label: "Westwood College of Technology", value: "Westwood College of Technology" },
  { label: "Wisconsin Technical College System Board", value: "Wisconsin Technical College System Board" },
  { label: "Wyoming Tech It", value: "Wyoming Tech It" },
  { label: "Xavier College School of Nursing", value: "Xavier College School of Nursing" },
  { label: "Yosemite Community College District", value: "Yosemite Community College District" },
  { label: "Yuba Community College District", value: "Yuba Community College District" },
  { label: "Yavapai College", value: "Yavapai College" },
  { label: "Other", value: "other" },
];

export const collegeDropdownDataList = {
  'Alamo Community College District': [
    { label: "Northwest Vista College", value: "Northwest Vista College" },
    { label: "Palo Alto College", value: "Palo Alto College" },
    { label: "San Antonio College", value: "San Antonio College" },
    { label: "Northeast Lakeview College", value: "Northeast Lakeview College" },
    { label: "St. Philip's College", value: "St. Philip's College" },
  ],

  'California Community Colleges System': [
    { label: "Antelope Valley College Valley", value: "Antelope Valley College" },
    { label: "Bakersfield College", value: "Bakersfield College" },
    { label: "Butte College", value: "Butte College" },
    { label: "Citrus College", value: "Citrus College" },
    { label: "College of Marin", value: "College of Marin" },
    { label: "College of the Sequoias", value: "College of the Sequoias" },
    { label: "El Camino Community College District", value: "El Camino Community College District" },
    { label: "Gavilan College", value: "Gavilan College" },
    { label: "Glendale Community College (Glendale, CA)", value: "Glendale Community College (Glendale, CA)" },
    { label: "Long Beach City College", value: "Long Beach City College" },
    { label: "Merced College", value: "Merced College" },
    { label: "MiraCosta College", value: "MiraCosta College" },
    { label: "Monterey Peninsula College", value: "Monterey Peninsula College" },
    { label: "Mt San Antonio College", value: "Mt San Antonio College" },
    { label: "Mt. San Jacinto College", value: "Mt. San Jacinto College" },
    { label: "Pasadena City College", value: "Pasadena City College" },
    { label: "Rio Hondo College", value: "Rio Hondo College" },
    { label: "San Diego City College", value: "San Diego City College" },
    { label: "San Joaquin Delta Community College District", value: "San Joaquin Delta Community College District" },
    { label: "Santa Monica College", value: "Santa Monica College" },
    { label: "Santa Rosa Junior College", value: "Santa Rosa Junior College" },
    { label: "Sierra College", value: "Sierra College" },
    { label: "Solano Community College District", value: "Solano Community College District" },
    { label: "Southwestern College (Chula Vista, CA)", value: "Southwestern College (Chula Vista, CA)" },
    { label: "St. Philip's College", value: "St. Philip's College" },
    { label: "Tidewater Community College", value: "Tidewater Community College" },
    { label: "Ventura College", value: "Ventura College" },
    { label: "Allan Hancock College", value: "Allan Hancock College" },
    { label: "Barstow Community College", value: "Barstow Community College" },
    { label: "Cabrillo College", value: "Cabrillo College" },
    { label: "Cerritos College", value: "Cerritos College" },
    { label: "Chaffey College", value: "Chaffey College" },
    { label: "City College of San Francisco", value: "City College of San Francisco" },
    { label: "College of the Canyons", value: "College of the Canyons" },
    { label: "College of the Desert", value: "College of the Desert" },
    { label: "College of the Redwoods", value: "College of the Redwoods" },
    { label: "College of the Sequoias", value: "College of the Sequoias" },
    { label: "College of the Siskiyous", value: "College of the Siskiyous" },
    { label: "Copper Mountain College", value: "Copper Mountain College" },
    { label: "Feather River College", value: "Feather River College" },
    { label: "Hartnell College", value: "Hartnell College" },
    { label: "Imperial Valley College", value: "Imperial Valley College" },
    { label: "Lake Tahoe Community College", value: "Lake Tahoe Community College" },
    { label: "Lassen Community College", value: "Lassen Community College" },
    { label: "Mendocino College", value: "Mendocino College" },
    { label: "Napa Valley College", value: "Napa Valley College" },
    { label: "Ohlone Community College District", value: "Ohlone Community College District" },
    { label: "Palo Verde College", value: "Palo Verde College" },
    { label: "Palomar College", value: "Palomar College" },
    { label: "Santa Barbara City College", value: "Santa Barbara City College" },
    { label: "Shasta College", value: "Shasta College" },
    { label: "Taft College", value: "Taft College" },
    { label: "Victor Valley College", value: "Victor Valley College" },
    { label: "Chabot-Las Positas Community College District", value: "Chabot-Las Positas Community College District" },
    { label: "Coast Community College District", value: "Coast Community College District" },
    { label: "Contra Costa Community College District", value: "Contra Costa Community College District" },
    { label: "Foothill-De Anza Community College District", value: "Foothill-De Anza Community College District" },
    { label: "Grossmont-Cuyamaca Community College District", value: "Grossmont-Cuyamaca Community College District" },
    { label: "Kern Community College District", value: "Kern Community College District" },
    { label: "Los Angeles Community College District", value: "Los Angeles Community College District" },
    { label: "Los Rios Community College District", value: "Los Rios Community College District" },
    { label: "North Orange County Community College District", value: "North Orange County Community College District" },
    { label: "Peralta Community College District", value: "Peralta Community College District" },
    { label: "Rancho Santiago Community College District", value: "Rancho Santiago Community College District" },
    { label: "Riverside Community College District", value: "Riverside Community College District" },
    { label: "San Bernardino Community College District", value: "San Bernardino Community College District" },
    { label: "San Jose/Evergreen Community College District", value: "San Jose/Evergreen Community College District" },
    { label: "San Luis Obispo County Community College District", value: "San Luis Obispo County Community College District" },
    { label: "San Mateo County Community College District", value: "San Mateo County Community College District" },
    { label: "South Orange County Community College District", value: "South Orange County Community College District" },
    { label: "State Center Community College District", value: "State Center Community College District" },
    { label: "West Hills Community College District", value: "West Hills Community College District" },
    { label: "West Valley College", value: "West Valley College" },
    { label: "Yosemite Community College District", value: "Yosemite Community College District" },
    { label: "Yuba Community College District", value: "Yuba Community College District" },
  ],

  'Chabot-Las Positas Community College District': [
    { label: "Chabot College", value: "Chabot College" },
    { label: "Las Positas College", value: "Las Positas College" },
  ],

  'Coast Community College District': [
    { label: "Orange Coast College", value: "Orange Coast College" },
    { label: "Coastline Community College", value: "Coastline Community College" },
    { label: "Golden West College", value: "Golden West College" },
  ],

  'Colorado Community College System': [
    { label: "Orange Coast College", value: "Orange Coast College" },
    { label: "Arapahoe Community College", value: "Arapahoe Community College" },
    { label: "Colorado Northwestern Community College", value: "Colorado Northwestern Community College" },
    { label: "Community College of Aurora", value: "Community College of Aurora" },
    { label: "Community College of Denver", value: "Community College of Denver" },
    { label: "Front Range Community College", value: "Front Range Community College" },
    { label: "Lamar Community College Foundation", value: "Lamar Community College Foundation" },
    { label: "Morgan Community College", value: "Morgan Community College" },
    { label: "Northeastern Junior College", value: "Northeastern Junior College" },
    { label: "Otero Junior College", value: "Otero Junior College" },
    { label: "Pikes Peak Community College", value: "Pikes Peak Community College" },
    { label: "Pueblo Community College", value: "Pueblo Community College" },
    { label: "Red Rocks Community College", value: "Red Rocks Community College" },
    { label: "Trinidad State Junior College", value: "Trinidad State Junior College" },

  ],

  'Contra Costa Community College District': [
    { label: "Trinidad State Junior College", value: "Trinidad State Junior College" },
    { label: "Contra Costa College", value: "Contra Costa College" },
  ],

  'Dallas College': [
    { label: "Brookhaven College", value: "Brookhaven College" },
    { label: "Cedar Valley College", value: "Cedar Valley College" },
    { label: "Eastfield College", value: "Eastfield College" },
    { label: "El Centro College", value: "El Centro College" },
    { label: "Mountain View College", value: "Mountain View College" },
    { label: "North Lake College", value: "North Lake College" },
    { label: "Richland College", value: "Richland College" },
  ],

  'Division of Florida Colleges': [
    { label: "Hillsborough Community College", value: "Hillsborough Community College" },
    { label: "Broward College", value: "Broward College" },
    { label: "Chipola College", value: "Chipola College" },
    { label: "College of Central Florida", value: "College of Central Florida" },
    { label: "Daytona State College", value: "Daytona State College" },
    { label: "Eastern Florida State College", value: "Eastern Florida State College" },
    { label: "Florida Gateway College", value: "Florida Gateway College" },
    { label: "Florida Keys Community College", value: "Florida Keys Community College" },
    { label: "Florida SouthWestern State College (formerly Edison)", value: "Florida SouthWestern State College (formerly Edison)" },
    { label: "Florida State College at Jacksonville", value: "Florida State College at Jacksonville" },
    { label: "Gulf Coast State College", value: "Gulf Coast State College" },
    { label: "Indian River State College", value: "Indian River State College" },
    { label: "Lake-Sumter State College", value: "Lake-Sumter State College" },
    { label: "Miami-Dade College", value: "Miami-Dade College" },
    { label: "North Florida Community College", value: "North Florida Community College" },
    { label: "Northwest Florida State College", value: "Northwest Florida State College" },
    { label: "Palm Beach State College", value: "Palm Beach State College" },
    { label: "Pasco-Hernando State College", value: "Pasco-Hernando State College" },
    { label: "Pensacola State College", value: "Pensacola State College" },
    { label: "Polk State College", value: "Polk State College" },
    { label: "Saint Petersburg College", value: "Saint Petersburg College" },
    { label: "Santa Fe College", value: "Santa Fe College" },
    { label: "Seminole State College (Sanford, FL)", value: "Seminole State College (Sanford, FL)" },
    { label: "South Florida Community College", value: "South Florida Community College" },
    { label: "St. Johns River State College", value: "St. Johns River State College" },
    { label: "State College of Florida, Manatee-Sarasota", value: "State College of Florida, Manatee-Sarasota" },
    { label: "Valencia College", value: "Valencia College" },
    { label: "Tallahassee Community College", value: "Tallahassee Community College" },
  ],

  'Eastern Iowa Community College': [
    { label: "Eastern Iowa CCD - Clinton Community College", value: "Eastern Iowa CCD - Clinton Community College" },
    { label: "Eastern Iowa CCD - Muscatine Community College", value: "Eastern Iowa CCD - Muscatine Community College" },
    { label: "Eastern Iowa CCD - Scott Community College", value: "Eastern Iowa CCD - Scott Community College" },
  ],

  'Foothill-De Anza Community College District': [
    { label: "De Anza College", value: "De Anza College" },
    { label: "Foothill Community College", value: "Foothill Community College" },
  ],

  'Houston Community College System (HCC)': [
    { label: "HCC - Central College", value: "HCC - Central College" },
    { label: "HCC - Coleman College of Health Sciences", value: "HCC - Coleman College of Health Sciences" },
    { label: "HCC - Katy Campus", value: "HCC - Katy Campus" },
    { label: "HCC - Northeast College", value: "HCC - Northeast College" },
    { label: "HCC - Southeast College", value: "HCC - Southeast College" },
    { label: "HCC - Southwest College", value: "HCC - Southwest College" },
  ],


  'Kentucky Community and Technical College System': [
    { label: "Ashland Community and Technical College", value: "Ashland Community and Technical College" },
    { label: "Bluegrass Community and Technical College", value: "Bluegrass Community and Technical College" },
    { label: "Automotive Manufacturing Technical Education Collaborative", value: "Automotive Manufacturing Technical Education Collaborative" },
    { label: "Big Sandy Community and Technical College", value: "Big Sandy Community and Technical College" },
    { label: "Elizabethtown Community and Technical College", value: "Elizabethtown Community and Technical College" },
    { label: "Gateway Community and Technical College", value: "Gateway Community and Technical College" },
    { label: "Hazard Community and Technical College", value: "Hazard Community and Technical College" },
    { label: "Henderson Community College", value: "Henderson Community College" },
    { label: "Hopkinsville Community College (Hopkinsville, KY)", value: "Hopkinsville Community College (Hopkinsville, KY)" },
    { label: "Jefferson Community and Technical College", value: "Jefferson Community and Technical College" },
    { label: "Madisonville Community College", value: "Madisonville Community College" },
    { label: "Maysville Community and Technical College", value: "Maysville Community and Technical College" },
    { label: "Owensboro Community and Technical College", value: "Owensboro Community and Technical College" },
    { label: "Somerset Community College", value: "Somerset Community College" },
    { label: "Southcentral Kentucky Community and Technical College", value: "Southcentral Kentucky Community and Technical College" },
    { label: "Southeast Kentucky Community and Technical College", value: "Southeast Kentucky Community and Technical College" },
    { label: "West Kentucky Community and Technical College", value: "West Kentucky Community and Technical College" },
  ],

  'Kern Community College District': [
    { label: "Bakersfield College", value: "Bakersfield College" },
    { label: "Cerro Coso Community College", value: "Cerro Coso Community College" },
    { label: "Porterville College", value: "Porterville College" },
  ],

  'Lone Star College System': [
    { label: "LSC - Cy-Fair College", value: "LSC - Cy-Fair College" },
    { label: "LSC - Kingwood Campus", value: "LSC - Kingwood Campus" },
    { label: "LSC - Montgomery College", value: "LSC - Montgomery College" },
    { label: "LSC - North Harris", value: "LSC - North Harris" },
    { label: "LSC- Tomball", value: "LSC- Tomball" },
    { label: "LSC University Park", value: "LSC University Park" },
  ],

  'Los Angeles Community College District': [
    { label: "East Los Angeles College", value: "East Los Angeles College" },
    { label: "Los Angeles Harbor College", value: "Los Angeles Harbor College" },
    { label: "Los Angeles Mission College", value: "Los Angeles Mission College" },
    { label: "Los Angeles Southwest College", value: "Los Angeles Southwest College" },
    { label: "Los Angeles Trade-Technical College", value: "Los Angeles Trade-Technical College" },
    { label: "Los Angeles Valley College", value: "Los Angeles Valley College" },
    { label: "Pierce College", value: "Pierce College" },
    { label: "West Los Angeles College", value: "West Los Angeles College" },
    { label: "Los Angeles City College", value: "Los Angeles City College" },
  ],

  'Los Rios Community College District': [
    { label: "American River College", value: "American River College" },
    { label: "Cosumnes River College", value: "Cosumnes River College" },
    { label: "Folsom Lake College", value: "Folsom Lake College" },
    { label: "Sacramento City College", value: "Sacramento City College" },
  ],

  'Louisiana Community and Technical College System': [
    { label: "Delgado Community College", value: "Delgado Community College" },
    { label: "Baton Rouge Community College", value: "Baton Rouge Community College" },
    { label: "Bossier Parish Community College", value: "Bossier Parish Community College" },
    { label: "Central Louisiana Technical College", value: "Central Louisiana Technical College" },
    { label: "Fletcher Technical Community College", value: "Fletcher Technical Community College" },
    { label: "Louisiana Delta Community College", value: "Louisiana Delta Community College" },
    { label: "Northshore Technical Community College", value: "Northshore Technical Community College" },
    { label: "Northwest Louisiana Technical College", value: "Northwest Louisiana Technical College" },
    { label: "Nunez Community College", value: "Nunez Community College" },
    { label: "River Parishes Community College", value: "River Parishes Community College" },
    { label: "South Louisiana Community College", value: "South Louisiana Community College" },
    { label: "Sowela Technical Community College", value: "Sowela Technical Community College" },
  ],

  'Maricopa Community Colleges': [
    { label: "Chandler-Gilbert Community College", value: "Chandler-Gilbert Community College" },
    { label: "Estrella Mountain Community College", value: "Estrella Mountain Community College" },
    { label: "GateWay Community College", value: "GateWay Community College" },
    { label: "Glendale Community College", value: "Glendale Community College" },
    { label: "Mesa Community College", value: "Mesa Community College" },
    { label: "Paradise Valley Community College", value: "Paradise Valley Community College" },
    { label: "Phoenix College", value: "Phoenix College" },
    { label: "Rio Salado College", value: "Rio Salado College" },
    { label: "Scottsdale Community College", value: "Scottsdale Community College" },
    { label: "South Mountain Community College", value: "South Mountain Community College" },
  ],

  'New Jersey Council of County Colleges': [
    { label: "Atlantic Cape Community College", value: "Atlantic Cape Community College" },
    { label: "Bergen Community College", value: "Bergen Community College" },
    { label: "Brookdale Community College", value: "Brookdale Community College" },
    { label: "Camden County College", value: "Camden County College" },
    { label: "County College of Morris", value: "County College of Morris" },
    { label: "Hudson County Community College", value: "Hudson County Community College" },
    { label: "Middlesex College", value: "Middlesex College" },
    { label: "Raritan Valley Community College", value: "Raritan Valley Community College" },
    { label: "Ocean County College", value: "Ocean County College" },
    { label: "Mercer County Community College", value: "Mercer County Community College" },
    { label: "Passaic County Community College", value: "Passaic County Community College" },
    { label: "Raritan Valley Community College", value: "Raritan Valley Community College" },
    { label: "Rowan College At Burlington County", value: "Rowan College At Burlington County" },
    { label: "Rowan College South Jersey", value: "Rowan College South Jersey" },
    { label: "Salem Community College", value: "Salem Community College" },
    { label: "Sussex County Community College", value: "Sussex County Community College" },
    { label: "Union County College", value: "Union County College" },
    { label: "Warren County Community College", value: "Warren County Community College" },
  ],

  'North Carolina Community College System': [
    { label: "Central Piedmont Community College", value: "Central Piedmont Community College" },
    { label: "Fayetteville Technical Community College", value: "Fayetteville Technical Community College" },
    { label: "South Piedmont Community College", value: "South Piedmont Community College" },
    { label: "Alamance Community College (Graham, NC)", value: "Alamance Community College (Graham, NC)" },
    { label: "Asheville-Buncombe Technical Community College", value: "Asheville-Buncombe Technical Community College" },
    { label: "Beaufort County Community College", value: "Beaufort County Community College" },
    { label: "Bladen Community College", value: "Bladen Community College" },
    { label: "Blue Ridge Community College", value: "Blue Ridge Community College" },
    { label: "Brunswick Community College", value: "Brunswick Community College" },
    { label: "Caldwell Community College & Technical Institute", value: "Caldwell Community College & Technical Institute" },
    { label: "Cape Fear Community College", value: "Cape Fear Community College" },
    { label: "Carteret Community College", value: "Carteret Community College" },
    { label: "Catawba Valley Community College", value: "Catawba Valley Community College" },
    { label: "Central Carolina Community College", value: "Central Carolina Community College" },
    { label: "Cleveland Community College", value: "Cleveland Community College" },
    { label: "Coastal Carolina Community College", value: "Coastal Carolina Community College" },
    { label: "Craven Community College", value: "Craven Community College" },
    { label: "Davidson County Community College", value: "Davidson County Community College" },
    { label: "Durham Technical Community College", value: "Durham Technical Community College" },
    { label: "Forsyth Technical Community College", value: "Forsyth Technical Community College" },
    { label: "Gaston College", value: "Gaston College" },
    { label: "Guilford Technical Community College", value: "Guilford Technical Community College" },
    { label: "Halifax Community College", value: "Halifax Community College" },
    { label: "Isothermal Community College", value: "Isothermal Community College" },
    { label: "James Sprunt Community College", value: "James Sprunt Community College" },
    { label: "Johnston Community College", value: "Johnston Community College" },
    { label: "Mayland Community College", value: "Mayland Community College" },
    { label: "McDowell Technical Community College", value: "McDowell Technical Community College" },
    { label: "Mitchell Community College", value: "Mitchell Community College" },
    { label: "Montgomery Community College", value: "Montgomery Community College" },
    { label: "Nash Community College", value: "Nash Community College" },
    { label: "Pitt Community College", value: "Pitt Community College" },
    { label: "Randolph Community College", value: "Randolph Community College" },
    { label: "Richmond Community College", value: "Richmond Community College" },
    { label: "Roanoke-Chowan Community College", value: "Roanoke-Chowan Community College" },
    { label: "Robeson Community College", value: "Robeson Community College" },
    { label: "Rockingham Community College", value: "Rockingham Community College" },
    { label: "Rowan-Cabarrus Community College", value: "Rowan-Cabarrus Community College" },
    { label: "Sampson Community College", value: "Sampson Community College" },
    { label: "Stanly Community College (Albemarle, NC)", value: "Stanly Community College (Albemarle, NC)" },
    { label: "Surry Community College", value: "Surry Community College" },
    { label: "Wake Technical Community College", value: "Wake Technical Community College" },
    { label: "Wayne Community College", value: "Wayne Community College" },
    { label: "Western Piedmont Community College", value: "Western Piedmont Community College" },
    { label: "Wilkes Community College", value: "Wilkes Community College" },
    { label: "Wilson Community College", value: "Wilson Community College" },
  
  ],

  'North Orange County Community College District': [
    { label: "Cypress College", value: "Cypress College" },
    { label: "Fullerton College", value: "Fullerton College" },
  ],

  'Pennsylvania Commission for Community Colleges': [
    { label: "Bucks County Community College", value: "Bucks County Community College" },
    { label: "Delaware County Community College", value: "Delaware County Community College" },
    { label: "Harrisburg Area Community College", value: "Harrisburg Area Community College" },
    { label: "Butler County Community College (Butler, PA)", value: "Butler County Community College (Butler, PA)" },
    { label: "Community College of Allegheny County", value: "Community College of Allegheny County" },
    { label: "Community College of Beaver County", value: "Community College of Beaver County" },
    { label: "Community College of Philadelphia", value: "Community College of Philadelphia" },
    { label: "Montgomery County Community College", value: "Montgomery County Community College" },
    { label: "Northampton Community College", value: "Northampton Community College" },
    { label: "Pennsylvania Highlands Community College (Johnstown, PA)", value: "Pennsylvania Highlands Community College (Johnstown, PA)" },
    { label: "Reading Area Community College", value: "Reading Area Community College" },
    { label: "Westmoreland County Community College (Youngwood, PA)", value: "Westmoreland County Community College (Youngwood, PA)" },
  ],

  'Rancho Santiago Community College District': [
    { label: "Santa Ana College", value: "Santa Ana College" },
    { label: "Santiago Canyon College", value: "Santiago Canyon College" },
  ],

  'Riverside Community College District': [
    { label: "Riverside City College", value: "Riverside City College" },
    { label: "Moreno Valley College", value: "Moreno Valley College" },
    { label: "Norco College", value: "Norco College" },
  ],

  'San Diego Community College District': [
    { label: "San Diego City College", value: "San Diego City College" },
    { label: "San Diego Mesa College", value: "San Diego Mesa College" },
    { label: "San Diego Miramar College", value: "San Diego Miramar College" },
    { label: "The San Diego Continuing Education Foundation", value: "The San Diego Continuing Education Foundation" },
  ],

  'San Joaquin Valley College, Inc.': [
    { label: "San Joaquin Valley College - Bakersfield", value: "San Joaquin Valley College - Bakersfield" },
    { label: "San Joaquin Valley College - Fresno", value: "San Joaquin Valley College - Fresno" },
    { label: "San Joaquin Valley College - Hesperia", value: "San Joaquin Valley College - Hesperia" },
    { label: "San Joaquin Valley College - Ontario", value: "San Joaquin Valley College - Ontario" },
  ],

  'South Carolina Technical College System': [
    { label: "Florence-Darlington Technical College", value: "Florence-Darlington Technical College" },
    { label: "Horry-Georgetown Technical College", value: "Horry-Georgetown Technical College" },
    { label: "Aiken Technical College", value: "Aiken Technical College" },
    { label: "Central Carolina Technical College", value: "Central Carolina Technical College" },
    { label: "Denmark Technical College", value: "Denmark Technical College" },
    { label: "Florence-Darlington Technical College", value: "Florence-Darlington Technical College" },
    { label: "Greenville Technical College", value: "Greenville Technical College" },
    { label: "Midlands Technical College", value: "Midlands Technical College" },
    { label: "Northeastern Technical College", value: "Northeastern Technical College" },
    { label: "Orangeburg-Calhoun Technical College", value: "Orangeburg-Calhoun Technical College" },
    { label: "Piedmont Technical College", value: "Piedmont Technical College" },
    { label: "Spartanburg Community College", value: "Spartanburg Community College" },
    { label: "Technical College of the Lowcountry", value: "Technical College of the Lowcountry" },
    { label: "Tri-County Technical College", value: "Tri-County Technical College" },
    { label: "Trident Technical College", value: "Trident Technical College" },
    { label: "Williamsburg Technical College", value: "Williamsburg Technical College" },
    { label: "York Technical College", value: "York Technical College" },
  ],

  'South Orange County Community College District': [
    { label: "Irvine Valley College", value: "Irvine Valley College" },
    { label: "Saddleback College", value: "Saddleback College" },
  ],

  'State Center Community College District': [,
    { label: "Clovis Community College", value: "Clovis Community College" },
    { label: "Fresno City College", value: "Fresno City College" },
    { label: "Madera Community College", value: "Madera Community College" },
    { label: "Reedley College", value: "Reedley College" },
  ],

  'Tarrant County College District': [
    { label: "Tarrant County College - Northeast Campus", value: "Tarrant County College - Northeast Campus" },
    { label: "Tarrant County College - Northwest Campus", value: "Tarrant County College - Northwest Campus" },
    { label: "Tarrant County College - South Campus", value: "Tarrant County College - South Campus" },
    { label: "Tarrant County College - Southeast Campus", value: "Tarrant County College - South Campus" },
    { label: "Tarrant County College - Trinity River Campus", value: "Tarrant County College - Trinity River Campus" },
  ],

  'Technical College System of Georgia': [
    { label: "Albany Technical College", value: "Albany Technical College" },
    { label: "Athens Technical College", value: "Athens Technical College" },
    { label: "Atlanta Technical College", value: "Atlanta Technical College" },
    { label: "Augusta Technical College", value: "Augusta Technical College" },
    { label: "Bainbridge College", value: "Bainbridge College" },
    { label: "Central Georgia Technical College", value: "Central Georgia Technical College" },
    { label: "Chattahoochee Technical College", value: "Chattahoochee Technical College" },
    { label: "Coastal Pines Technical College", value: "Coastal Pines Technical College" },
    { label: "Columbus Technical College", value: "Columbus Technical College" },
    { label: "Georgia Northwestern Technical College", value: "Georgia Northwestern Technical College" },
    { label: "Georgia Piedmont Technical College", value: "Georgia Piedmont Technical College" },
    { label: "Gwinnett Technical College", value: "Gwinnett Technical College" },
    { label: "Lanier Technical College", value: "Lanier Technical College" },
    { label: "North Georgia Technical College", value: "North Georgia Technical College" },
    { label: "Oconee Fall Line Technical College", value: "Oconee Fall Line Technical College" },
    { label: "Ogeechee Technical College", value: "Ogeechee Technical College" },
    { label: "Savannah Technical College", value: "Savannah Technical College" },
    { label: "South Georgia Technical College", value: "South Georgia Technical College" },
    { label: "Southeastern Technical College", value: "Southeastern Technical College" },
    { label: "Southern Crescent Technical College", value: "Southern Crescent Technical College" },
    { label: "Southern Regional Technical College", value: "Southern Regional Technical College" },
    { label: "West Georgia Technical College", value: "West Georgia Technical College" },
    { label: "Wiregrass Georgia Technical College", value: "Wiregrass Georgia Technical College" },
  ],

  'Tennessee Board Of Regents': [
    { label: "Chattanooga State Community College", value: "Chattanooga State Community College" },
    { label: "Cleveland State Community College", value: "Cleveland State Community College" },
    { label: "Columbia State Community College", value: "Columbia State Community College" },
    { label: "Dyersburg State Community College", value: "Dyersburg State Community College" },
    { label: "Jackson State Community College", value: "Jackson State Community College" },
    { label: "Motlow State Community College (Main Campus)", value: "Motlow State Community College (Main Campus)" },
    { label: "Nashville State Community College", value: "Nashville State Community College" },
    { label: "Pellissippi State Community College", value: "Pellissippi State Community College" },
    { label: "Roane State Community College", value: "Roane State Community College" },
    { label: "Southwest Tennessee Community College", value: "Southwest Tennessee Community College" },
    { label: "Volunteer State Community College", value: "Volunteer State Community College" },
    { label: "Walters State Community College", value: "Walters State Community College" },

  ],

  'University of Hawaii System (2-year campuses)': [
    { label: "Hawai'i Community College", value: "Hawai'i Community College" },
    { label: "Honolulu Community College", value: "Honolulu Community College" },
    { label: "Kapi'olani Community College", value: "Kapi'olani Community College" },
    { label: "Kaua'i Community College", value: "Kaua'i Community College" },
    { label: "Leeward Community College", value: "Leeward Community College" },
    { label: "Maui College", value: "Maui College" },
    { label: "Windward Community College", value: "Windward Community College" },
  ],

  'Ventura County Community College District': [
    { label: "Moorpark College", value: "Moorpark College" },
    { label: "Oxnard College", value: "Oxnard College" },
    { label: "Ventura County Community College District", value: "Ventura County Community College District" },
  ],

  'Virginia Community College System': [
    { label: "Blue Ridge Community College (Weyers Cave, VA)", value: "Blue Ridge Community College (Weyers Cave, VA)" },
    { label: "Central Virginia Community College", value: "Central Virginia Community College" },
    { label: "Danville Community College", value: "Danville Community College" },
    { label: "Eastern Shore Community College", value: "Eastern Shore Community College" },
    { label: "Germanna Community College", value: "Germanna Community College" },
    { label: "John Tyler Community College", value: "John Tyler Community College" },
    { label: "Lord Fairfax Community College", value: "Lord Fairfax Community College" },
    { label: "Mountain Empire Community College", value: "Mountain Empire Community College" },
    { label: "New River Community College", value: "New River Community College" },
    { label: "Northern Virginia Community College", value: "Northern Virginia Community College" },
    { label: "Patrick Henry Community College", value: "Patrick Henry Community College" },
    { label: "Paul D Camp Community College", value: "Paul D Camp Community College" },
    { label: "Piedmont Virginia Community College", value: "Piedmont Virginia Community College" },
    { label: "Rappahannock Community College", value: "Rappahannock Community College" },
    { label: "Reynolds Community College", value: "Reynolds Community College" },
    { label: "Southside Virginia Community College", value: "Southside Virginia Community College" },
    { label: "Thomas Nelson Community College", value: "Thomas Nelson Community College" },
    { label: "Tidewater Community College", value: "Tidewater Community College" },
    { label: "Virginia Highlands Community College", value: "Virginia Highlands Community College" },
    { label: "Virginia Western Community College", value: "Virginia Western Community College" },
    { label: "Wytheville Community College", value: "Wytheville Community College" },
  ],



  'Wyoming Community College Commission': [,
    { label: "Casper College", value: "Casper College" },
    { label: "Central Wyoming College", value: "Central Wyoming College" },
    { label: "Eastern Wyoming College", value: "Eastern Wyoming College" },
    { label: "Laramie County Community College", value: "Laramie County Community College" },
    { label: "Northern Wyoming Community College District", value: "Northern Wyoming Community College District" },
    { label: "Northwest College", value: "Northwest College" },
    { label: "Western Wyoming Community College", value: "Western Wyoming Community College" },
  ],

  'Yosemite Community College District': [
    { label: "Columbia College (Sonora, CA)", value: "Columbia College (Sonora, CA)" },
    { label: "Modesto Junior College", value: "Modesto Junior College" },
  ],

  'Yuba Community College District': [
    { label: "Woodland Community College", value: "Woodland Community College" },
    { label: "Yuba College", value: "Yuba College" },
  ],

}