import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import UOPHeader from '../layout/UopHeader.jsx';
import Footer from '../layout/Footer';
import { Typography, Grid } from '@mui/material/';
import emailusFormInitialValues from '../email-us/EmailusFormInitialValues'
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import { referFriendValidate } from '../email-us/EmailusValidation';
import getIPAddress, {loadCookiesData, roleChange, submitForm, pageNameByPath, } from '../CommonComponents';
import { prospectRole } from '../common/constants';
import ErrorFocus from '../common/ErrorFocus';
import axios from 'axios';
import Button from '../layout/MaterialComponents/button/Button';
import {dataLayer} from '../common/dataLayer';

const formRef = {};
var profileData = {};
let prevPersona;

class ReferFriend extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: emailusFormInitialValues,
            formRef: null,
            variant: props.variant,
            copySuccess: false
        };
        
        this.state = this.initialState;
    }
    /*
    firePersonaEvent(value) {
        window.uopSegment && window.uopSegment.track("RFI Event", {"referafriend": value});
      }
      */
    dropdownChange = (name, value) => {
        if (name === 'persona') {
            if (value && prevPersona !== value) {
            //    this.firePersonaEvent(value);
                prevPersona = value;
            }
        }
    }

    componentDidMount() {
        window.pagePath = "page-dr_nrfi";
        document.title = `Refer a friend`;
        let { initialValues } = this.state;
        initialValues =  loadCookiesData(initialValues);
        initialValues.flex_EmailCaptureSource = "REFR";
        prevPersona = initialValues.persona ? initialValues.persona : undefined;
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress);
        if (initialValues.flex_referrer_analyticsId !== '' && initialValues.flex_referrer_analyticsId !== null){
            this.referrerProfile(initialValues); 
        }
        let clink = document.createElement('link');
        clink.setAttribute("rel", "canonical");
        clink.setAttribute("href", window.location.href);
        document.head.appendChild(clink); 
        dataLayer();
    }

    referrerProfile(values) {
        const { initialValues } = this.state;
        let ID = values.flex_referrer_analyticsId;
        let URL = '/request/referFriend/'+ ID;
        axios.get(URL, {
            method: 'GET',  
        })
        .then((response) => {
            profileData = response.data;
        //    console.log ("profileData received as part of api call is " + profileData);
            initialValues.flex_referrer_fullName = profileData.entries[0].profile.name[0].firstName + " " + profileData.entries[0].profile.name[0].lastName;
            initialValues.flex_referrer_emailAddress = profileData.entries[0].profile.emailAddress[0].emailAddress;
            this.setState({ initialValues });
        })
        .catch(error => {
        console.log (error.message);   
        });
      }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    copyToClipBoard= () => {
        var input = document.createElement("input");
        input.value = "https://www.phoenix.edu/referral";
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy'); 
        document.body.removeChild(input);
        if (this.state.copySuccess !== true){
            this.setState({ copySuccess: true });
        }
        setTimeout(() => {
            this.setState({copySuccess: false});
        }, 2000)
      } 

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
        //    fireEvent('non_rfi_begin');
            this.setState({ rfi_begin: true });
        }
      }    

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    handleSubmit = (values) => {    
        if (((values.fullName).trim().indexOf(' ')) > 0) {
            const i = (values.fullName.trim()).indexOf(' ');
            values.firstName = (values.fullName.trim()).substring(0, i); 
            values.lastName = (values.fullName.trim()).substring(i + 1);
        }
        if (values.flex_referrer_analyticsId !== '' && values.flex_referrer_analyticsId !== null) {
            values.flex_referrer_firstName = profileData.entries[0].profile.name[0].firstName;
            values.flex_referrer_lastName = profileData.entries[0].profile.name[0].lastName;
        }else {
            var i =  (values.flex_referrer_fullName).indexOf(" ");
            values.flex_referrer_firstName = (values.flex_referrer_fullName).substring(0, i);
            values.flex_referrer_lastName = (values.flex_referrer_fullName).substring(i+1);
        }
        this.setState({ initialValues: values });
    //    values = mapAdditionalFields(values);
    //    submitForm(values);
        submitForm(values, this.props);
    }

    render() {
        const { classes } = this.props;
        const {
            initialValues,
            variant
        } = this.state;
        

        return (
            <div>
                <UOPHeader />    
                <main className="rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
						<Grid item md={12} sm={12} lg={12} xs={12} >
						<div className="newOneForm-Eyebrow">UNIVERSITY OF PHOENIX</div>
						<h1 className="newOneForm-title">Refer a friend</h1>
                        <div className="newOneForm-SubTitle">Invite someone you know to learn more about University of Phoenix.</div>
                        
						</Grid>
                        <Grid container >
                        <Grid item md={12} sm={12} lg={7} xs={12} >
                        <Typography
                                variant='body2'
                                component='div'
                                className="reqinfo"
                            >
                                * Required information
                            </Typography>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => referFriendValidate(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="refer-a-friend-form" style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container>
                                               <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <SelectField
                                                        id="persona"
                                                        name="persona"
                                                        label="I am"
                                                        data={prospectRole}
                                                        className="MuiFormControl-fullWidth"
                                                        formRef={formRef}
                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                        onChange={this.dropdownChange("persona", props.values.persona)}
                                                    ></SelectField>
                                                    {roleChange()}
                                                 </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="flex_referrer_fullName" id="flex_referrer_fullName"
                                                        type="text"
                                                        label="First and last name *"
                                                        ariaRequired="true"
                                                        ariaLabel="Enter your full name"/>
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="flex_referrer_emailAddress" id="flex_referrer_emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>

                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm" style={{borderBottom:'2px solid #dadada', marginBottom:'24px', marginRight:'2rem'}}></Grid>

                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">

                                                    <Typography
                                                        variant='body2'
                                                        component='div'
                                                        className= 'selectRadioLabel'
                                                        id="referral"
                                                        style={{marginBottom:'24px'}}>
                                                        Who would you like to refer?
                                                    </Typography>
												 </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="fullName" id="fullName"
                                                        type="text"
                                                        label="First and last name"
                                                        aria-required="true"
                                                        ariaLabel="Enter your full name"/>
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                    <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                <div className="disclaimer">
                                                    <p>By clicking “Submit” I understand that the person I refer will be contacted via email by the University to discuss enrollment in University programs and that my name will be disclosed as the person who made the referral.</p>
                                                </div>
                                                <Button size='large' type='submit' className="btn-submit">
                                                            Submit
                                                        </Button>
                                                </Grid>
												<ErrorFocus />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        <Grid item md={10} sm={10} lg={1} xs={12}></Grid>
                        <Grid item md={12} sm={12} lg={3} xs={12} className="maxWidthContainer-twoGridoneStepForm">
                        <h2 style={{fontSize:'24px'}}><span className="oneStepSolidBlock">Thank you</span> - this means a lot</h2>
                        <div className="oneForm-Rectangleline">
                            <p>We’ll honor your trust by addressing your friend’s/colleague’s specific questions and offer them: </p>
                            <ul >
                                <li className="oneStepForm-li">Personalized service. We’ll even request their prior college transcripts, at no cost, when possible.</li>
                                <li className="oneStepForm-li" >The best time and money savings options they qualify for.</li>
                                <li className="oneStepForm-li">The opportunity to learn career-focused skills in weeks, not months.</li>
                            </ul>
						</div>
                        </Grid>
                    </Grid>    
    {/*                    <Grid item md={11} sm={12} lg={11} xs={12} className={["maxWidthContainer-oneStepForm", "referral-form" ]}>
                            <Grid item md={5} sm={5} lg={5} xs={12} className="referral-image" ><img src="https://www.phoenix.edu/content/dam/altcloud/rfi/friend-referral.png" alt="refer a friend" /></Grid>
                            <Grid item md={1} sm={1} lg={1} xs={12}></Grid>
                            <Grid item md={5} sm={5} lg={5} xs={12} ><div class="cmp-text" style={{maxWidth:'400px'}}>
                                    <p>Community is everything. Help your network refer a future Phoenix, too.</p>
                                    <p>phoenix.edu/referral</p>
                                </div>
                                <div role="heading" aria-level="2" class="tooltip">
                                    <a data-location="content" target="_self" class="uopx-btn btn-text redesign-link " tabindex="0" onClick={this.copyToClipBoard} >{this.state.copySuccess ? <span class="tooltiptext">copied</span> : <span class="tooltiptext">Copy to clipboard</span>}Copy link</a>
                                {}
                                </div></Grid>
                            </Grid>
                    */}
                        </Grid>
                    </Grid>
                </main>
                <Footer />
            </div>
        );
    }
}

ReferFriend.defaultProps = {
    classes: {},
};

ReferFriend.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(ReferFriend);