import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import rfiFormInitialValues from '../layout/FormModels/RFIFormInitialValues'
import MaterialRadio from "../layout/MaterialComponents/MaterialRadio";
import { Formik, Form } from "formik";
import styles from '../layout/styles/MobileFormStyles';
//import SelectField from '../uopxSelectField';
import SelectField from '../UopxNativeSelectField';
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
import getIPAddress, { unescapeHTML, loadCookiesData, roleChange, submitForm, pageNameByPath, fireAnalyticsEvent, fireFOS_DlEvent, updatePrePopCookieValue } from '../CommonComponents';
import { courseCategoryDropdown, contEdDegreeLevel, radioOption } from '../common/constants';
import FormikErrorFocus from 'formik-error-focus';
import UopHeader from '../layout/UopHeader';
import Footer from '../layout/Footer';
import { ContinuingEducationValidation } from "../layout/MobileFormValidation";
import rectangle from "../layout/images/Rectangle.svg";
import Button from '../layout/MaterialComponents/button/Button';
import ZipCode from '../layout/MaterialComponents/ZipCode';
import FormikCheckbox from '../layout/MaterialComponents/checkbox/FormikCheckbox';
import {dataLayer} from '../common/dataLayer';

const formRef = {};
// eslint-disable-next-line no-unused-vars
// var feedback = {};
let prevState, prevCourseCategory;
const disclaimerText = rfiFormInitialValues.disclaimer;

const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
           valid = true;
           break;
        default:
    }
    return valid;
};

class ContinuingEducation extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
	activeStep: 1,
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            thankyouPageUrl: '/request-info/course-thank-you.html',
            formRef: null,
            variant: props.variant,
            feedback: any
        };
        this.state = this.initialState;
    }

    dropdownChange = (name, value) => {
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
    }

    componentDidMount() {
        window.pagePath = "page-dc_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - Continuing Education RFI Desktop`;
        let { initialValues } = this.state;
        initialValues =  loadCookiesData(initialValues);
        initialValues.thankyouPageUrl = '/request-info/course-thank-you.html';
        formRef.current.values.degreeLevel = 'Single course';    
        this.handleZipcodeChange(initialValues.postalCode,initialValues.state);
        this.setState({ initialValues });
        getIPAddress(this.updateIPAddress);
        formType = "ContinuingEducation RFI";
        formName = "ContinuingEducation RFI desktop - 1 step";
        const { activeStep } = this.state;
        formStep = activeStep;
        
    /*    // fetch feedback
        let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        // console.log("Current env is: " + process.env.NODE_ENV);
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }

        fetch(baseUrl + '/services/feedback/v1/summary', {
                method: 'GET',
            })
            .then(response => response.json())
            .then(data => feedback = data)
            .catch(function (error) {
                console.log(error);
            });   */
        if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        dataLayer();    
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        } 
        else if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
         }
    };

    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
      }    

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            formRef.current.values.postalCode = zipcode;
            formRef.current.values.stateProvince = state;
            updatePrePopCookieValue('zipcode',zipcode);
            updatePrePopCookieValue('state', state);
            if (state && prevState !== state) {
                this.updateAndNotify(state);
                prevState = state;
            }
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
     }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }

    handleSubmit = (values) => {
        values.degreeLevel = 'nondegree';
        if(document.activeElement.name === "submit"){
            this.setState({ initialValues: values });
        //    submitForm(values);
            submitForm(values, this.props);
        }
    }

    render() {
        const { classes } = this.props;
        const {
            initialValues,
	    activeStep,
        } = this.state;

        return (
            <div>
               <UopHeader />   
                <main className="rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer continuing-education-container">
                    <Grid container className="maxWidthContainer-oneStepForm">
						<Grid item md={12} sm={12} lg={12} xs={12} >
						<div className="newOneForm-Eyebrow">UNIVERSITY OF PHOENIX</div>
						<h1 className="newOneForm-title">We'd love to help you </h1>
						</Grid>
                        <Grid item md={12} sm={12} lg={7} xs={12} >
                            <div className="newOneForm-SubTitle">An enrollment representative will contact you soon to help answer your questions about the course(s) you're interested in.</div>
                            <Typography
                                variant='body2'
                                component='div'
                                className="reqinfo"
                            >
                                * Required information
                            </Typography>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => ContinuingEducationValidation(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Request For Information"
                                                  style={{ position: 'relative' }} onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container>
                                               <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                               <SelectField
                                                    id="flex_Course_Category__c"
                                                    name="flex_Course_Category__c"
                                                    label="Course category *"
                                                    data={courseCategoryDropdown}
                                                    className="MuiFormControl-fullWidth"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="degreeLevel" id="degreeLevel"
                                                    label="Degree level" ariaRequired="true" ariaLabel="Enter degree Level" isDisabled={true}  />
                                                {roleChange()}
                                                 </Grid>
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                </Grid>
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                 <MaterialInput name="firstName" id="firstName"
                                                    label="First name *" ariaRequired="true" ariaLabel="Enter First name"  />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="lastName" id="lastName"
                                                    label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialPhoneNumber name="phoneNumber" id="phoneNumber"  label="Phone number *" ariaRequired="true" ariaLabel="Enter phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                </Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" aria-describedby="Enter Zip code" aria-label="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef}></ZipCode>
												</Grid>
											    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className= 'selectRadioLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
												 </Grid>
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                {conditionalRadio(props.values.flex_Course_Category__c) && <>
                                                       <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} row={true} onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                    </>}
                                                    </Grid>    
                                                <div className="disclaimer">
                                                  {unescapeHTML(disclaimerText)}
                                                </div>
                                                <div className={classes.requestBtn}>
                                                    <Button
                                                        name='submit'
                                                        size='large'
                                                        type='submit'
                                                    >
                                                        Request information
                                                    </Button>
                                                </div>
                                                </Grid>
												<FormikErrorFocus offset={-50} align={'top'} focusDelay={200} ease={'linear'} duration={1000} />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <span className="message">
                                    *Transferability of credit is at the discretion of the receiving institution. It is the student's responsibility to confirm whether credits earned at University of Phoenix will be accepted by another institution of the student's choice.
                                    <br/><br/>
                                    **Although our continuing education for teacher's courses are accepted by some state agencies in the United States toward teacher certifications and endorsements, this may not be the case in all states or foreign jurisdictions. If you plan to use courses for certification or endorsement, please check with your own state agency and your school district for applicability. Continuing education for teachers' courses are not eligible for federal financial aid. Continuing teacher education courses are not eligible to apply to degree programs at University of Phoenix.
                        </span>
                            </Grid>
                        </Grid>
                        <Grid item md={10} sm={10} lg={1} xs={12}></Grid>
                        <Grid item md={12} sm={12} lg={3} xs={12} className="maxWidthContainer-twoGridoneStepForm">
                        <div className="oneForm-Rectangleline continuing-education-side-bar">
                            <div className="label">
                            Join the
                            </div>
                            <div className="students-count label">15,000+</div>
                            <span className="courses-count">non-degree students who took <br></br>24,780+ courses with us in 2023.</span>                            
                            <div className="separator"><img src={rectangle} alt=""></img></div>
                            <div className="students-trust"><strong>Students have trusted our courses to:</strong></div>
						<ul >
                        <li className="oneStepForm-li">Transfer credit-bearing courses back to their home universities.*</li>
                        <li className="oneStepForm-li">Meet admission requirements.</li>
                        <li className="oneStepForm-li">Support certification and recertification needs.**</li>
                        <li className="oneStepForm-li">Further their professional development.</li>
                        </ul>
						</div>
                        </Grid>
                          </Grid>
                    </Grid>
                </main>
               <Footer /> 
            </div>
        );
    }
}

ContinuingEducation.defaultProps = {
    classes: {},
};

ContinuingEducation.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(ContinuingEducation);
