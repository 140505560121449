import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material/';
import PropTypes from 'prop-types';
import { contactValidate } from "./EmailusValidation";
import MaterialInput from '../layout/MaterialComponents/MaterialInput';
import MaterialTextArea from "../layout/MaterialComponents/MaterialTextArea";
import { initValues, processJSONParameter } from '../common/configTools';
import {
  unescapeHTML,
  profanityCheck,
  fireAnalyticsEvent, fireNonRFISubmitEvent,
} from '../CommonComponents';
import ErrorFocus from '../common/ErrorFocus'
import styles from '../inline/SubscribeStyles';
import checkmark from '../layout/images/checkmark.svg';
import axios from 'axios';
import Button from '../layout/MaterialComponents/button/Button';

const formRef = {};
const onTextAreaChange= (e) => {
    formRef.current.values.flex_more_info__c = e.target.value;
  }

class UopxContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      initialValues: initialContactFormValues,
      variant: props.variant,
      message: null,
      formRef: null,
      currentStep: 1,
    };
    this.ref = {
      form: React.createRef(),
      container: React.createRef(),
    };
  }

  componentDidMount() {
    const { props } = this;
    let { initialValues } = this.state;
    initialValues.requestingUrl = window.location.href;
	  initValues(initialValues, this.props);
    this.setState({ initialValues });
    const { currentStep } = this.state;
    formStep = currentStep;
	  formType = 'Non-RFI';
    formName = 'UOPX contact-us form';
    if (window.pageData && window.pagePath) {
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formName"] = formName;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formType"] = formType;
      pageData[Object.keys(pageData)[0]]["xdm:rfiPage:formStep"] = formStep.toString();
    }
  }

/*
  isFieldHidden(fieldName) {
    const { fieldsHidden } = this.props;
    const fields = fieldsHidden.split(',').map(f => f.trim());
    return (fields.indexOf(fieldName) !== -1);
  }
*/
  renderIRN() {
    return (
        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm irn">
            <MaterialInput name="irn" id="irn" ariaRequired="false"
            label="IRN" ariaLabel="Enter IRN number (optional)" type='number' />
        </Grid>
    );
  }  

  handleSubmit = (values) => {
    if (document.activeElement.name === "submit"){
  //  hashValues(values);  
    values.firstName = (values.firstName).trim();
    values.lastName = (values.lastName).trim();
    this.setState({ initialValues: values });
    const headers = {
        'Content-Type': 'application/json',
    };
    let URL = 'https://www.phoenix.edu/request/sendmail';
    const data = JSON.stringify(values);
    axios.post(URL, data, {
        headers,
      })
    .then((response) => {
        if (response.data.msg === 'success'){
            console.log("Email sent, awesome!");
            this.setState({
              initialValues: values,
              isSubmit: true,
            });    
            if(document.getElementById("thanksHeaderContent")) {
              document.getElementById("thanksHeaderContent").focus();
            }
        }else if(response.data.msg === 'fail'){
            console.log("Oops, something went wrong. Try again")
        }
    })
    .catch(error => {
    console.log (error.message);   
    });
    fireNonRFISubmitEvent( 'NonRFISubmit', values, formName, formType);
    //setTimeout(function(){ fireNonRFISubmitEvent('NonRFISubmit', values, formName, formType); }, 200);
  }
}

  handleAnalyticsRFIFormStart = () => {
    if (this.state.rfi_begin !== true) {
      fireAnalyticsEvent('NonRFIStart', formName, formType);
      this.setState({ rfi_begin: true });
    }
  }

  validateForm(values) {
    const errors = {};

    if (!values.fullName) {
      errors.fullName = 'First and Last name requires a value';
    } else if (values.fullName.trim().indexOf(' ') === -1) {
      errors.fullName = 'Please enter a First and Last name';
    } else if (!profanityCheck(values.fullName)) {
      errors.fullName = 'Full Name appears invalid';
    }
    if (!values.emailAddress) {
      errors.emailAddress = 'Email Address requires a value';
    }
    if (values.emailAddress && !values.emailAddress.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      errors.emailAddress = 'Email address appears invalid';
    }
  //  console.log('errors', errors, values);
    return errors;
  }

  render() {
    const { classes } = this.props;
    // console.log('SubEmail rendering...');
    const { isSubmit, initialValues } = this.state;

    let titleText = this.props.title || '';
    let descriptionText = this.props.description || initialValues.description || '';
    let cta = this.props.cta || initialValues.cta || 'Submit';

    return (
      <div className={classes.container}>
        {/*    <UOPHeader />    */}
        <div className="UopxContactForm-main">
          <Grid container justifyContent="center" className="mobileFormContainer" ref={this.ref.container}>
            <Formik
              initialValues={initialValues}
              onSubmit={values => this.handleSubmit(values)}
              validate={(values) => contactValidate(values)}
              innerRef={this.ref.form}
              validateOnChange
            >
              <Form
                className="mobile-form" aria-label={titleText.replace(/<[^>]+>/g, '') + "Email Us"}
                style={{ position: 'relative' }}
                onChange={this.handleAnalyticsRFIFormStart}
              >
                <Grid container className="maxWidthContainer-oneStepForm" spacing={1}>
                  <Grid item md={12} sm={12} lg={12} xs={12} >
                    {unescapeHTML(titleText)}
                  </Grid>
                  <Grid item md={12} sm={12} lg={12} xs={12}>
                    {unescapeHTML(descriptionText)}
                  </Grid>
                  {!isSubmit
                    ? (
                      <>
                        <Grid item md={12} sm={12} lg={12} xs={12}>
                          <div className="reqinfo" > * Required information </div>
                          {this.state.message}
                        </Grid>
                        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                            <MaterialInput name="firstName" id="firstName"
                                label="First name *" ariaRequired="true" ariaLabel="Enter First name" ariaLabelledby="formtitle" onClick={this.handleAnalyticsRFIFormStart} />
                        </Grid>
                        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                            <MaterialInput name="lastName" id="lastName"
                                label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                        </Grid>
                        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                          <MaterialInput
                            name="emailAddress"
                            id="emailAddress"
                            label="Email address *"
                            ariaRequired="true"
                            ariaLabel="Enter Email address"
                            variant="outlined" 
                          />
                        </Grid>
                        {this.renderIRN()}
                        <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                            <MaterialTextArea
                                id="flex_more_info__c"
                                name="flex_more_info__c"
                                onChange={onTextAreaChange}
                                label="Comments / Questions *"
                                classNames="uopx-input more_info_textarea"
                                rows={6}
                                ariaLabel="Comments or Questions"
                                ariaRequired="true"
                            />
                        </Grid>
                        <Grid item md={12} sm={12} lg={12} xs={12}>
                          <div className="disclaimer"> </div>
                        </Grid>
                        <Grid item md={12} sm={12} lg={12} xs={12}>
                          <Button
                            name='submit'
                            size="large"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Grid>
                      </>
                    )
                    : (
                      <>
                        <Grid item md={12} sm={12} lg={12} xs={12} className={classes.thanksContainer}>
                        <div className='thanksHeader'>
                                <img src={checkmark} alt="Checked Circle" />
                                <span tabIndex={-1} id="thanksHeaderContent">
                                  Thanks for sharing your feedback
                                </span>
                              </div>
                        </Grid>
                      </>
                    )}
                </Grid>
                <ErrorFocus />
              </Form>
            </Formik>
          </Grid>
        </div>
      </div>
    );
  }
}
const initialContactFormValues = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    irn: '',
    flex_more_info__c: '',
    requestingUrl: '',
  };

UopxContact.defaultProps = {
  classes: {},
  fieldsHidden: '',
};

UopxContact.propTypes = {
  classes: PropTypes.instanceOf(Object),
  fieldsHidden: PropTypes.string,
};

export default withStyles(styles)(UopxContact);
