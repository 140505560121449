import React, { Component } from 'react';
import { Form, Formik } from 'formik';
import { Grid, Typography } from '@mui/material/';
import FormikErrorFocus from 'formik-error-focus';
import MaterialPhoneNumber from '../layout/MaterialComponents/MaterialPhoneNumber';
//import { fireEvent } from '../CommonComponents';
import { fireAnalyticsEvent, fireNonRFISubmitEvent } from '../CommonComponents';
import { submitUnsubPhoneForm } from './UnsubscribeAPI';
import { withStyles } from '@mui/styles';
import styles from '../inline/SubscribeStyles';
import Button from '../layout/MaterialComponents/button/Button';
import CheckCircle from '../layout/images/check-circle.svg';

function srSpeak(text, priority) {
  try{
      var el = document.createElement("div");
      var id = "make-a-screen-reader-talk-" + Date.now();
      el.setAttribute("id", id);
      el.setAttribute("aria-live", priority || "polite");
      el.classList.add("visually-hidden");
      document.body.appendChild(el);
      
      window.setTimeout(function () {
          document.getElementById(id).innerHTML = text;      
      }, 100);
      
      window.setTimeout(function () {
          if (document.getElementById(id)){
              document.body.removeChild(document.getElementById(id));
          }
      }, 1000);
  } catch(e){
      console.log ("error", e);
      throw e;
  }
}

class UnsubscribePhoneForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      initialValues: {},
      variant: props.variant,
      message: null,
    };
    this.ref = {
      form: React.createRef(),
      container: React.createRef(),
    };
  }


  componentDidMount() {
    formType = 'Non-RFI';
    formName = 'phone Unsubscribe';
    
  }

  validatePhone(values) {
    // console.log('validate phone', values);
    const errors = {};
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Phone Number requires a value';
    }
    if (values.phoneNumber && values.phoneNumber.length !== 10) {
      errors.phoneNumber = 'Please enter a ten digit USA phone number. Numeric digits only.';
    }
    return errors;
  }

  async handlePhoneSubmit(values) {
    const { classes } = this.props;
    values.requestingUrl = window.location.href;
    //fireEvent('rfi_unsubscribe_phone_submit');
    let responseJSON;
     try {
          responseJSON = await submitUnsubPhoneForm(values);
        } catch (e) {
          responseJSON = { message: `${e}`, success: false };
        }
      
      this.setState({
        initialValues: values,
        isSubmit: !!responseJSON.success,
        message: <div id='thankstitle' aria-labelledby='thankstitle' role='region' aria-live='assertive' className={responseJSON.success ? classes.unsubSuccess : classes.error}><img alt='checkmark' src={CheckCircle} style={{paddingRight: "0.5rem"}}/>{responseJSON.message || 'No Message Given'}</div>,
      });
      srSpeak("Unsubscribe Phone request sent successfully.");
    this.ref.container.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    fireNonRFISubmitEvent( 'NonRFISubmit', values, 'phone Unsubscribe', 'Non-RFI');
    }
  

    handlePhoneNumberBlur = value => {
      this.ref.form.current.values.phoneNumber = value;
    }

  handleAnalyticsRFIFormStart = () => {
    if (this.state.rfi_begin_phone !== true) {
      fireAnalyticsEvent('NonRFIStart', 'phone Unsubscribe', 'Non-RFI');
      this.setState({ rfi_begin_phone: true });
    }
  }



  render() {
    const { classes } = this.props;
    const { isSubmit, message, initialValues } = this.state;
    return (
      <div ref={this.ref.container} className="unsubscribe">
        {!isSubmit
          ? (
            <Formik
              initialValues={initialValues}
              onSubmit={values => this.handlePhoneSubmit(values)}
              validate={values => this.validatePhone(values)}
              innerRef={this.ref.form}
              validateOnChange
            >
              <Form
                className="unsubscribe-phone-form"
                style={{ position: 'relative', paddingBottom: '2rem' }}
                onChange={this.handleAnalyticsRFIFormStart}
              >
                <Grid containerid="phoneForm">
                  <Typography variant='body2' component='div' className="reqinfo"> * Required information </Typography>
                  {message}
                  <Grid
                    item
                    md={12}
                    sm={12}
                    lg={12}
                    xs={12}
                    className="margin-OneStepForm"
                    id="phoneForm"
                  >
                    <MaterialPhoneNumber
                      name="phoneNumber"
                      id="phoneNumber"
                      label="Phone number"
                      required="true"
                      ariaLabel="Enter phone number"
                      handleBlur={this.handlePhoneNumberBlur}
                      onClick={this.handleAnalyticsRFIFormStart}
                    />
                </Grid>
                  <Grid item md={12} sm={12} lg={12} xs={12}>
                    <div>
                      <Button
                        name='submit'
                        size="large"
                        type="submit"
                        className='rfi-btn-submit submit-btn'
                        ariaLabel="Submit Phone Unsubscribe Form"
                        style={{backgroundColor: "#30718d"}}
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <FormikErrorFocus
                  offset={-50}
                  align="top"
                  focusDelay={200}
                  ease="linear"
                  duration={1000}
                />
              </Form>
            </Formik>
          )
          : message}
      </div>
    );
  }
}

export default withStyles(styles)(UnsubscribePhoneForm);