import { Bachelor, Masters, Doctorate, Associate, Certificate, Individual} from '../layout/images/degrees';
import { BehavioralSciences, Business, Technology, Healthcare, Nursing, Psychology, Education, CriminalJustice, LiberalArts, Other} from '../layout/images/fieldsOfStudy';

export const fieldOfStudyDropdown = [
  { label: "Business", value: "business", icon:<Business /> },
  { label: "Technology", value: "technology", icon:<Technology /> },
  { label: "Healthcare", value: "healthcare", icon: <Healthcare /> },
  { label: "Nursing (Post-licensure)", value: "nursing", icon:<Nursing /> },
  { label: "Psychology & Human Services", value: "human-services", icon:<Psychology /> },
  { label: "Education", value: "education", icon:<Education /> },
  { label: "Criminal Justice", value: "criminal-justice", icon:<CriminalJustice /> },
  { label: "Liberal Arts", value: "liberal-arts", icon:<LiberalArts /> },
  { label: "Other programs", value: "other-programs", icon:<Other /> },
];

export const semesterDropdown = [
  { label: "I", value: "I" },
  { label: "II", value: "II" },
  { label: "III", value: "III" },
  { label: "IV", value: "IV" },
];
export const dynamicFieldOfStudyDropdown = [
  { label: "Business", value: "business", icon:<Business /> },
  { label: "Technology", value: "technology", icon:<Technology /> },
  { label: "Healthcare", value: "healthcare", icon: <Healthcare /> },
  { label: "Nursing (Post-licensure)", value: "nursing", icon:<Nursing /> },
  { label: "Education", value: "education", icon:<Education /> },
  { label: "Criminal Justice", value: "criminal-justice", icon:<CriminalJustice /> },
  { label: "Liberal Arts", value: "liberal-arts", icon:<LiberalArts /> },
  { label: "Behavioral Sciences", value: "behavioral-sciences", icon:<BehavioralSciences />},
  { label: "Psychology", value: "psychology", icon:<Psychology />},
  { label: "Other programs", value: "other-programs", icon:<Other /> },
];

export const dynamicFieldOfStudyDropdownVariant = [
  { label: "Business", value: "business", icon:<Business /> },
  { label: "Technology", value: "technology", icon:<Technology /> },
  { label: "Healthcare", value: "healthcare", icon: <Healthcare /> },
  { label: "Nursing (Post-licensure)", value: "nursing", icon:<Nursing /> },
  { label: "Education", value: "education", icon:<Education /> },
  { label: "Criminal Justice", value: "criminal-justice", icon:<CriminalJustice /> },
  { label: "Liberal Arts", value: "liberal-arts", icon:<LiberalArts /> },
  { label: "Behavioral Sciences", value: "behavioral-sciences", icon:<BehavioralSciences />},
  { label: "Psychology", value: "psychology", icon:<Psychology />},
  { label: "Single course", value: "nondegree", icon:<Other /> },
];

export const degreeLevel = [
  { label: "Bachelors", value: "bachelors", icon:<Bachelor />},
  { label: "Masters", value: "masters", icon:<Masters /> },
  { label: "Doctorate", value: "doctoral", icon:<Doctorate />},
  { label: "Associate", value: "associates", icon:<Associate />},
  { label: "Certificate", value: "certificate", icon:<Certificate />},
  { label: "Single course", value: "nondegree", icon:<Individual />},
];

export const degreeLevelVariant = [
  { label: "Bachelors", value: "bachelors", icon:<Bachelor />},
  { label: "Masters", value: "masters", icon:<Masters /> },
  { label: "Doctorate", value: "doctoral", icon:<Doctorate />},
  { label: "Associate", value: "associates", icon:<Associate />},
  { label: "Certificate", value: "certificate", icon:<Certificate />},
  { label: "Single course", value: "nondegree", icon:<Individual />},
];

export const radioOption = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const uopxRelationshipRole = [
  { label: "Prospective student", value: "Prospective student" },
  { label: "Student / Alumni", value: "Student / Alumni" },
  { label: "Media", value: "Media" },
  { label: "Employee", value: "Employee" },
  { label: "Faculty", value: "Faculty" },
  ];

export const groupedCheckboxOption = [
{ key: 0, value: "scholarships", label: "Scholarships", isChecked: false },
{ key: 1, value: "transfer_credits", label: "Transfer credits", isChecked: false },
{ key: 2, value: "work_life_credits", label: "Work & life credits", isChecked: false },
{ key: 3, value: "employer_tuition_discounts", label: "Employer tuition discounts", isChecked: false },
]

export const programTypeOptions = [
  { value: "Online", label: "Online" },
  { value: "Ground", label: "In the classroom" },
  { value: "Both", label: "Either" },
];

export const AOIFOSTextMap = {};
AOIFOSTextMap["business"] = "Business and Management";
AOIFOSTextMap["technology"] = "Technology";
AOIFOSTextMap["healthcare"] = "Health Administration";
AOIFOSTextMap["nursing"] = "Nursing";
AOIFOSTextMap["human-services"] = "Human Services";
AOIFOSTextMap["education"] = "Education";
AOIFOSTextMap["criminal-justice"] = "Criminal Justice and Security";
AOIFOSTextMap["liberal-arts"] = "Arts and Sciences";
AOIFOSTextMap["other-programs"] = "Business and Management";

export const courseCategoryDropdown = [
  { label: "Accounting", value: "accounting" },
  { label: "Business", value: "business" },
  { label: "Business Management", value: "business-management" },
  { label: "Communications & Languages", value: "communications-languages" },
  { label: "Continuing Teacher Education", value: "continuing-teacher-education" },
  { label: "Counseling", value: "counselling" },
  { label: "Criminal Justice", value: "criminal-justice" },
  { label: "Cyber Security", value: "cybersecurity" },
  { label: "Data Analytics", value: "data-analytics" },
  { label: "Digital Marketing", value: "digital-marketing" },
  { label: "Finance", value: "finance" },
  { label: "General Education", value: "general-education" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Humanities", value: "humanities" },
  { label: "Information Technology", value: "information-technology" },
  { label: "Leadership", value: "leadership" },
  { label: "Marketing", value: "marketing" },
  { label: "Math", value: "math" },
  { label: "Nursing", value: "nursing" },
  { label: "Programming", value: "programming" },
  { label: "Project Management", value: "project-management" },
  { label: "Psychology", value: "psychology" },
  { label: "Public Administration", value: "public-administration" },
  { label: "Sciences", value: "sciences" },
  { label: "Social Science", value: "social-science" },
];

export const contEdDegreeLevel = [
  { label: "Certificate", value: "certificate" },
  { label: "Individual courses", value: "nondegree" },
];

export const prospectRole = [
{ label: "a current student", value: "current_student" },
{ label: "a future student", value: "future_student" },
{ label: "an alumnus", value: "alumnus" },
{ label: "a faculty member", value: "faculty_member" },
{ label: "a University employee", value: "University_employee" },
];

export const higherEducation = [
{ label: "Earned master's degree", value: "MASTER" },
{ label: "Earned bachelor's degree", value: "BACHELOR" },
{ label: "Completed 48 or more college credits", value: "COLLEGE_3YR_OR_MORE" },
{ label: "Completed 24-47 college credits", value: "COLLEGE_2YR" },
{ label: "Completed 1-23 college credits", value: "COLLEGE_1YR" },
{ label: "Have high school diploma", value: "HSGRAD" },
{ label: "Have GED", value: "GED" },
{ label: "Does not have high school diploma or GED", value: "NOHS" },
];

export const usStates = [
{ value: "AL", label: "Alabama"},
{ value: "AK", label: "Alaska"},
{ value: "AZ", label: "Arizona"},
{ value: "AR", label: "Arkansas"},
{ value: "CA", label: "California"},
{ value: "CO", label: "Colorado"},
{ value: "CT", label: "Connecticut"},
{ value: "DE", label: "Delaware"},
{ value: "FL", label: "Florida"},
{ value: "GA", label: "Georgia"},
{ value: "HI", label: "Hawaii"},
{ value: "ID", label: "Idaho"},
{ value: "IL", label: "Illinois"},
{ value: "IN", label: "Indiana"},
{ value: "IA", label: "Iowa"},
{ value: "KS", label: "Kansas"},
{ value: "KY", label: "Kentucky"},
{ value: "LA", label: "Louisiana"},
{ value: "ME", label: "Maine"},
{ value: "MD", label: "Maryland"},
{ value: "MA", label: "Massachusetts"},
{ value: "MI", label: "Michigan"},
{ value: "MN", label: "Minnesota"},
{ value: "MS", label: "Mississippi"},
{ value: "MO", label: "Missouri"},
{ value: "MT", label: "Montana"},
{ value: "NE", label: "Nebraska"},
{ value: "NV", label: "Nevada"},
{ value: "NH", label: "New Hampshire"},
{ value: "NJ", label: "New Jersey"},
{ value: "NM", label: "New Mexico"},
{ value: "NY", label: "New York"},
{ value: "NC", label: "North Carolina"},
{ value: "ND", label: "North Dakota"},
{ value: "OH", label: "Ohio"},
{ value: "OK", label: "Oklahoma"},
{ value: "OR", label: "Oregon"},
{ value: "PA", label: "Pennsylvania"},
{ value: "RI", label: "Rhode Island"},
{ value: "SC", label: "South Carolina"},
{ value: "SD", label: "South Dakota"},
{ value: "TN", label: "Tennessee"},
{ value: "TX", label: "Texas"},
{ value: "UT", label: "Utah"},
{ value: "VT", label: "Vermont"},
{ value: "VA", label: "Virginia"},
{ value: "WA", label: "Washington"},
{ value: "WV", label: "West Virginia"},
{ value: "WI", label: "Wisconsin"},
{ value: "WY", label: "Wyoming"}
]

export const businessCategoryList = [
{ value: "Apparel", label: "Apparel"},
{ value: "Auto-Buying", label: "Auto Buying"},
{ value: "Automotive", label: "Automotive"},
{ value: "Beauty-&-Fragrance", label: "Beauty & Fragrance"},
{ value: "Books-Movies-&-Music", label: "Books, Movies & Music"},
{ value: "Business-Perks", label: "Business Perks"},
{ value: "Cell-Phones", label: "Cell Phones"},
{ value: "Education", label: "Education"},
{ value: "Electronics", label: "Electronics"},
{ value: "Financial-Wellness", label: "Financial Wellness"},
{ value: "Flowers-&-Gifts", label: "Flowers & Gifts"},
{ value: "Food", label: "Food"},
{ value: "Health-&-Wellness", label: "Health & Wellness"},
{ value: "Hobbies-&-Creative-Arts", label: "Hobbies & Creative Arts"},
{ value: "Home-&-Garden", label: "Home & Garden"},
{ value: "Home-Services", label: "Home Services"},
{ value: "Insurance-&-Protection", label: "Insurance & Protection"},
{ value: "Jewelry-&-Watches", label: "Jewelry & Watches"},
{ value: "Movie-Tickets", label: "Movie Tickets"},
{ value: "Office-&-Business", label: "Office & Business"},
{ value: "Outdoors-&-Recreation", label: "Outdoors & Recreation"},
{ value: "Pets", label: "Pets"},
{ value: "Real-Estate-&-Moving", label: "Real Estate & Moving"},
{ value: "Tickets-&-Attractions", label: "Tickets & Attractions"},
{ value: "Toys-Kids-&-Babies", label: "Toys, Kids & Babies"},
{ value: "Travel", label: "Travel"}    
];

export const apptsAvailable = [
  {label:"09:00 AM" , value:"09:00"}, 
  {label:"09:15 AM" , value:"09:15"},
  {label:"09:30 AM" , value:"09:30"}, 
  {label:"09:45 AM" , value:"09:45"},
  {label:"10:00 AM" , value:"10:00"},
  {label:"10:15 AM" , value:"10:15"},
  {label:"10:30 AM" , value:"10:30"},
  {label:"10:45 AM" , value:"10:45"},
  {label:"11:00 AM" , value:"11:00"},
  {label:"11:15 AM" , value:"11:15"},
  {label:"11:30 AM" , value:"11:30"},
  {label:"11:45 AM" , value:"11:45"},
  {label:"12:00 PM" , value:"12:00"},
  {label:"12:15 PM" , value:"12:15"},
  {label:"12:30 PM" , value:"12:30"},
  {label:"12:45 PM" , value:"12:45"},
  {label:"1:00 PM" , value:"13:00"},
  {label:"1:15 PM" , value:"13:15"},
  {label:"1:30 PM" , value:"13:30"},
  {label:"1:45 PM" , value:"13:45"},
  {label:"2:00 PM" , value:"14:00"},
  {label:"2:15 PM" , value:"14:15"},
  {label:"2:30 PM" , value:"14:30"},
  {label:"2:45 PM" , value:"14:45"},
  {label:"3:00 PM" , value:"15:00"},
  {label:"3:15 PM" , value:"15:15"},
  {label:"3:30 PM" , value:"15:30"},
  {label:"3:45 PM" , value:"15:45"}, 
  {label:"4:00 PM" , value:"16:00"},
  {label:"4:15 PM" , value:"16:15"},
  {label:"4:30 PM" , value:"16:30"},
  {label:"4:45 PM" , value:"16:45"},
]

export const reEntryDropdown = [
  /* { label: "Select a reason", value: "null" }, */
  { label: "", value:""},
  { label: "Financial Issues", value: "financial-issues" },
  { label: "Personal Issues", value: "personal-issues" },
  { label: "Health Issues", value: "health-issues" },
  { label: "Military / Reserves", value: "military-reserves" },
  { label: "Vacation", value: "vacation" },
  { label: "Natural Disaster", value: "natural-disaster" },
  { label: "Jury Duty", value: "jury-duty" },
  { label: "Moving", value: "moving" },
  { label: "Work Related", value: "work-related" },
  { label: "Course Availability / Cancellation", value: "course-availability-cancellation" },
  { label: "Other", value: "other" },
];